<template>
  <div>
    <div class="bg--white-2 d-flex align-center flex-column pa-8">
      <base-image
        src="ready_audit.svg"
        width="auto"
        height="200"
        class="my-6"
      />
      <div class="text-lg primary--text font-weight-bold py-6 text-center">
        Start your first audit
      </div>
      <nuxt-link id="js-audit-link" to="new" append tag="div">
        <base-button label="New audit">
          <template #append>
            <fa :icon="['fal', 'chevron-right']" />
          </template>
        </base-button>
      </nuxt-link>
    </div>
  </div>
</template>
<script>
import BaseImage from '@/components/BaseImage'
import BaseButton from '@/components/BaseButton'

export default {
  components: { BaseButton, BaseImage },
}
</script>
