<template>
  <section>
    <div class="centered">
      <base-image src="nozzle-logo-white.svg" width="140" height="auto" />
    </div>
    <div class="centered">
      <div class="welcome-message pt-12">
        <span class="title">You’re almost there!</span>
        <p class="content">
          Check your inbox, we’ve sent you an activation link. Be sure to check
          your spam folder too.
        </p>
        <div
          v-if="email.includes('@gmail') || email.includes('@googlemail')"
          class="gmail-box"
        >
          <img
            :src="require('/assets/images/icons/gmail.png')"
            width="25px"
            height="auto"
            alt=""
          />
          <span>
            <a
              :href="`https://mail.google.com/mail/u/${email}/#search/from%3A%40nozzle+in%3Aanywhere+newer_than%3A1d`"
              target="_blank"
            >
              Open Gmail
            </a>
          </span>
          <img
            :src="require('/assets/images/icons/outlook.png')"
            width="20px"
            height="auto"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import baseImage from '~/components/BaseImage.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'VerificationPage',
  layout: 'verification',
  components: {
    baseImage,
  },
  computed: {
    ...mapGetters({
      email: 'user/getEmail',
    }),
  },
}
</script>

<style lang="scss">
.centered {
  display: flex;
  width: 100vw;
  height: 25vh;
  justify-content: center;
  align-items: center;

  .welcome-message {
    width: 620px;
    height: 277px;
    left: 410px;
    top: 366.2px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px 25px;

    .title {
      width: 309px;
      height: 47px;
      left: 459px;
      top: 400.2px;
      font-family: 'Be Vietnam';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 47px;
      color: #244c64;
    }

    .content {
      width: 535px;
      height: 48px;
      left: 459px;
      top: 463.2px;
      font-family: 'Be Vietnam';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #244c64;
    }
    .gmail-box {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      gap: 8px;
      width: 190px;
      height: 51px;
      left: 459px;
      top: 543.2px;
      background: #ffffff;
      border: 0.5px solid #cccccc;
      box-shadow: 0px 1px 3px #969696;
      border-radius: 4px;
    }
  }
}
</style>
