<template>
  <div class="bg--white-2 d-flex align-center flex-column pa-8">
    <base-image src="init_account.png" width="600" height="auto" class="my-6" />
    <div class="text-lg primary--text font-weight-bold py-6 text-center">
      You've not linked any Amazon account yet
    </div>
    <base-button
      label="Link account"
      class="js-new-account"
      @on-click="$emit('add-account')"
    >
      <template #append>
        <fa :icon="['fal', 'chevron-right']" />
      </template>
    </base-button>
  </div>
</template>
<script>
import BaseImage from '@/components/BaseImage'
import BaseButton from '@/components/BaseButton'

export default {
  components: { BaseButton, BaseImage },
}
</script>
