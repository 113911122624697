<template>
  <div class="bg--white-2 d-flex align-center flex-column pa-8">
    <base-image src="init_account.png" width="600" height="auto" class="my-6" />
    <div class="text-lg primary--text font-weight-bold py-6 text-center">
      You've not linked any marketplaces yet
    </div>
    <nuxt-link id="js-link-profile" to="/user/account" append tag="div">
      <base-button label="Go to Settings">
        <template #append>
          <fa :icon="['fal', 'chevron-right']" />
        </template>
      </base-button>
    </nuxt-link>
  </div>
</template>
<script>
import BaseImage from '@/components/BaseImage'
import BaseButton from '@/components/BaseButton'

export default {
  components: { BaseButton, BaseImage },
}
</script>
