<template>
  <section class="hero">
    <div class="hero-body grey-1 py-2">
      <div class="container">
        <div class="nav-list">
          <div
            v-for="(item, index) in navigationList"
            :key="`nav-index-${index}`"
            v-tooltip.top-center="{
              content: navigationList[index].title,
              delay: { show: 100, hide: 100 },
              classes: [
                'tooltip-primary-top-arrow text-capitalize font-weight-semibold',
              ],
            }"
            :class="[item.id === positionEl ? 'active' : '']"
            class="nav-item"
            style="cursor: pointer"
            @click="setActivePoint(item.id), navigationTo(getactivePoint.link)"
          >
            <p
              v-if="positionEl === item.id"
              class="primary--text nav-list-point text-md"
            >
              <span>{{ item.id }}</span>
            </p>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column nav-controls" style="padding: 1rem">
            <div class="columns nav-controls__columns is-vcentered is-mobile">
              <div class="column nav-controls__button-previous text-right">
                <div class="level">
                  <div class="level-item is-hidden-mobile">
                    <p
                      class="nav-controls__button-previous-title font-weight-semibold text-md text-capitalize text-truncate"
                    >
                      {{ getactivePoint.previousTitle }}
                    </p>
                  </div>
                  <div class="level-item" style="cursor: pointer">
                    <figure class="image is-32x32">
                      <img
                        v-if="positionEl <= 1"
                        :class="{ disabled: positionEl <= 1 }"
                        src="~/assets/images/icons/nav-button-previous.svg"
                        alt=""
                        @click="
                          setActivePoint(positionEl - 1),
                            navigationTo(getactivePoint.link)
                        "
                      />
                      <img
                        v-else
                        src="~/assets/images/icons/nav-button-previous-active.svg"
                        alt=""
                        @click="
                          setActivePoint(positionEl - 1),
                            navigationTo(getactivePoint.link)
                        "
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div class="column is-8-mobile text-center">
                <h3
                  class="primary--text font-weight-bold text-mdlg text-capitalize"
                >
                  {{ getactivePoint.title }}
                </h3>
              </div>
              <div class="column nav-controls__button-next text-left">
                <div class="level">
                  <div class="level-item" style="cursor: pointer; margin: auto">
                    <figure class="image is-32x32">
                      <img
                        v-if="positionEl >= navigationList.length"
                        :class="{
                          disabled: positionEl >= navigationList.length,
                        }"
                        src="~/assets/images/icons/nav-button-next.svg"
                        alt=""
                        @click="
                          setActivePoint(positionEl + 1),
                            navigationTo(getactivePoint.link)
                        "
                      />
                      <img
                        v-else
                        src="~/assets/images/icons/nav-button-next-active.svg"
                        alt=""
                        @click="
                          setActivePoint(positionEl + 1),
                            navigationTo(getactivePoint.link)
                        "
                      />
                    </figure>
                  </div>
                  <div class="level-item is-hidden-mobile">
                    <p
                      class="nav-controls__button-next-title font-weight-semibold text-md text-capitalize text-truncate"
                    >
                      {{ getactivePoint.nextTitle }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="column is-1-desktop is-hidden-mobile">
                <div class="level">
                  <div class="level-item">
                    <a href="javascript:window.print()">
                      <div class="nav-controls__download">
                        <div class="nav-controls__download-image">
                          <figure class="image is-32x32">
                            <img
                              src="~/assets/images/icons/sticky-nav-download.svg"
                              alt=""
                            />
                          </figure>
                        </div>
                        <div class="nav-controls__download-text">
                          <p
                            class="text-md font-weight-semibold primary--text mb-0"
                          >
                            Download <br class="is-hidden-mobile" />
                            Report
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isActive: { number: 1, link: '' },
    }
  },
  computed: {
    getactivePoint() {
      const list = this.navigationList || []

      const current = list.find((item) => item.id === this.positionEl)
      const currentIndex = list.indexOf(current)

      if (currentIndex <= 0) {
        return {
          previousTitle: '',
          nextTitle: list[currentIndex + 1].title,
          link: list[0].link,
          title: list[currentIndex].title,
        }
      } else if (currentIndex >= list.length - 1) {
        return {
          previousTitle: list[currentIndex - 1].title,
          nextTitle: '',
          link: list[currentIndex].link,
          title: list[currentIndex].title,
        }
      }
      return {
        previousTitle: list[currentIndex - 1].title,
        nextTitle: list[currentIndex + 1].title,
        link: list[currentIndex].link,
        title: list[currentIndex].title,
      }
    },

    ...mapGetters({
      positionEl: 'navigation/getPosition',
      navigationList: 'navigation/getNavigationList',
    }),
  },
  methods: {
    setActivePoint(id) {
      this.$store.commit('navigation/setPosition', id)
    },
    navigationTo(element) {
      const header = document.getElementById('stickyheader')
      const yOffset = -header.offsetHeight
      const position = document.getElementById(element)
      if (position) {
        const yPosition =
          position.getBoundingClientRect().top + window.pageYOffset + yOffset

        window.scrollTo({ top: yPosition, behavior: 'smooth' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/_variables.scss';

.disabled {
  pointer-events: none;
}
.hero {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}
.hero-body {
  padding: 25px;
  @media (max-width: $desktop) {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}
.active {
  width: 30px !important;
  height: 30px !important;
  border: 3px solid #00e3b5 !important;
  transition: border 0.3s;
}
.nav-list {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  &:before {
    content: '';
    position: absolute;
    border-top: 1px solid #dee2e4;
    width: 100%;
    top: 27px;
  }
}
.nav-item {
  z-index: 2;
  width: 20px;
  height: 20px;
  border: 3px solid #b6b6b6;
  border-radius: 100px;
  background-color: #f0f5f8;
}
.nav-controls {
  margin-top: 80px;

  &__columns {
    height: 100%;
    max-width: 90%;
  }
  &__button-next {
    display: flex;
    flex-direction: row;
  }
  &__button-next-title {
    margin-left: 20px;
    max-width: 200px;
  }
  &__button-previous {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
  &__button-previous-title {
    margin-right: 20px;
    max-width: 200px;
  }
  &__download {
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  &__download-text {
    margin-left: 20px;
  }
}
.prev-button {
  float: right;
}

.nav-list-point {
  vertical-align: middle;
  text-align: center;
  line-height: 1.5;
  word-break: keep-all;
}
.nav-list-point p {
  margin: 0;
  padding: 0;
}
.secondary-title {
  color: #989898 !important;
}
.hero-body {
  position: relative;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-mdlg {
  line-height: 1.6rem;
}
</style>
