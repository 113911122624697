<template>
  <div>
    <Header title="Billing" />
    <div id="billing" class="container">
      <loader v-model="loading" message="Fetching billing data" />
      <div v-if="!loading">
        <div class="box py-6 px-8 mb-8">
          <alert
            v-if="!hasSubscriptions"
            msg-title="No active subscriptions"
            :button-external-url="generateTrialLink"
            button-label="Start 14 day free trial"
          />
          <div v-else>
            <div class="d-flex align-center justify-space-between mb-4">
              <h2 class="primary--text font-weight-bold text-lg mb-2">
                Customer analytics
              </h2>
              <base-button
                v-if="hasSubscriptions && stripeUrl"
                label="Manage subscription"
                class=""
                :link="stripeUrl"
                target="_blank"
              />
            </div>
            <div>
              <p>
                <span class="font-weight-bold text-xxlg">
                  {{
                    trialCurrency === 'GBP'
                      ? '£' + amount
                      : trialCurrency === 'USD'
                      ? '$' + amount
                      : amount + '€'
                  }}
                </span>
                <span>per month</span>
              </p>
              <p v-if="trialStart !== null && trialEnd !== null" class="mb-0">
                <strong>Trial period:</strong>
                {{
                  trialStart !== null &&
                  trialStart.toLocaleDateString(defaultLocale, dateOptions)
                }}
                -
                {{
                  trialEnd !== null &&
                  trialEnd.toLocaleDateString(defaultLocale, dateOptions)
                }}
              </p>
              <p v-if="canceledAt !== null" class="mb-0">
                <strong>Subscription was canceled at:</strong>
                {{
                  canceledAt !== null &&
                  canceledAt.toLocaleDateString(defaultLocale, dateOptions)
                }}
              </p>
              <p v-if="cancelAtPeriodEnd" class="mt-4 mb-0">
                <fa :icon="['fal', 'info-circle']" />
                You can continue to use the service until the end of your
                billing period
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="canceledSubscriptions.length > 0"
          class="box py-6 px-8 mb-8 historic"
        >
          <div class="table-header d-flex flex-columns">
            <div class="col1 font-weight-bold">Subscription</div>
            <div class="col2 font-weight-bold">Time period</div>
            <div class="col3 font-weight-bold">Status</div>
          </div>
          <div
            v-for="subscription in canceledSubscriptions"
            :key="subscription.trial_start"
            class="d-flex flex-columns table-line"
          >
            <div class="col1">Customer Analytics</div>
            <div class="col2">
              {{
                /* eslint-disable max-len */
                subscription.trial_start
                  ? convertUnixIntoDate(
                      subscription.trial_start
                    ).toLocaleDateString(defaultLocale, dateOptions)
                  : subscription.start_date
                  ? convertUnixIntoDate(
                      subscription.start_date
                    ).toLocaleDateString(defaultLocale, dateOptions)
                  : '(no date)'
              }}
              -
              {{
                subscription.canceled_at &&
                subscription.trial_end &&
                subscription.canceled_at > subscription.trial_end
                  ? convertUnixIntoDate(
                      subscription.trial_end
                    ).toLocaleDateString(defaultLocale, dateOptions)
                  : subscription.canceled_at &&
                    subscription.trial_end &&
                    subscription.trial_end > subscription.canceled_at
                  ? convertUnixIntoDate(
                      subscription.canceled_at
                    ).toLocaleDateString(defaultLocale, dateOptions)
                  : subscription.canceled_at
                  ? convertUnixIntoDate(
                      subscription.canceled_at
                    ).toLocaleDateString(defaultLocale, dateOptions)
                  : subscription.trial_end
                  ? convertUnixIntoDate(
                      subscription.trial_end
                    ).toLocaleDateString(defaultLocale, dateOptions)
                  : '(no date)'
              }}
            </div>
            <div class="col3">
              {{ capitalizeFirstLetter(subscription.status) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '~/components/BaseLoader.vue'
import BaseButton from '~/components/BaseButton.vue'
import Header from '~/components/BaseHeader.vue'
import Alert from '~/components/alerts/Alert.vue'
import { convertUnixIntoDate } from '~/utils/date'

/*

interface Subscription {
  "status": 'active' | 'canceled';
  "trial_start": number;
  "trial_end": number;
  "currency": string;
  "canceled_at": null | number;
}

*/

export default {
  components: {
    BaseButton,
    Loader,
    Header,
    Alert,
  },
  data: () => {
    /* : {
        loading: boolean,
        hasFailed: boolean,
        hasSubscriptions: boolean|null,
        trialStart: Date|null,
        trialEnd: Date|null,
        trialCurrency:  string,
        canceledAt: Date|null,
        dateOptions:  any,
        canceledSubscriptions: Subscription[],
      } { */
    return {
      stripeUrl: null,
      loading: true,
      hasFailed: false,
      hasSubscriptions: null,
      trialStart: null,
      trialEnd: null,
      canceledAt: null,
      cancelAtPeriodEnd: false,
      trialCurrency: '',
      amount: null,
      dateOptions: { day: 'numeric', month: 'long', year: 'numeric' },
      canceledSubscriptions: [],
    }
  },
  computed: {
    defaultLocale() {
      return Intl.NumberFormat().resolvedOptions().locale
    },
    generateTrialLink() {
      return this.$env.NOZZLE_ENV === 'development'
        ? 'https://www.nozzle.ai/pricing-test'
        : 'https://www.nozzle.ai/pricing'
    },
  },
  mounted() {
    this.getUserSubscriptions()
  },
  methods: {
    capitalizeFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    async getStripeManageSubscriptionLink() {
      try {
        const endpoint = `${this.$env.NOZZLE_URL}/api/v2/payments/user-portal-session`
        const { data } = await this.$axios.get(endpoint)
        this.stripeUrl = data.location
      } catch (e) {
        this.hasFailed = true
      }
    },
    async getUserSubscriptions() {
      try {
        const endpoint = `${this.$env.NOZZLE_URL}/api/v2/payments/subscriptions`
        const { data } = await this.$axios.get(endpoint)
        this.canceledSubscriptions = data.filter(
          (subscription) =>
            subscription &&
            subscription.status !== 'active' &&
            subscription.status !== 'trialing'
        )
        // sort subscriptions by start date (desc order)
        this.canceledSubscriptions = this.canceledSubscriptions
          .sort((a, b) => a.start_date - b.start_date)
          .reverse()
        this.loading = false
        if (data.lenght === 0) this.hasSubscriptions = false
        else {
          const activeSubscriptions = data.filter(
            (subscription) =>
              subscription &&
              (subscription.status === 'active' ||
                subscription.status === 'trialing')
          )
          this.hasSubscriptions =
            activeSubscriptions && activeSubscriptions.length > 0
          if (activeSubscriptions.length > 0) {
            this.trialStart =
              activeSubscriptions[0].trial_start &&
              convertUnixIntoDate(activeSubscriptions[0].trial_start)
            if (this.trialStart === null && activeSubscriptions[0].start_date) {
              this.trialStart = convertUnixIntoDate(
                activeSubscriptions[0].start_date
              )
            }
            this.trialEnd =
              activeSubscriptions[0].trial_end &&
              convertUnixIntoDate(activeSubscriptions[0].trial_end)
            this.trialCurrency =
              activeSubscriptions[0].currency &&
              activeSubscriptions[0].currency.toUpperCase()
            this.amount =
              activeSubscriptions[0].unit_amount &&
              +activeSubscriptions[0].unit_amount / 100
            this.cancelAtPeriodEnd =
              activeSubscriptions[0].cancel_at_period_end !== null &&
              activeSubscriptions[0].cancel_at_period_end
            this.canceledAt =
              activeSubscriptions[0].canceled_at &&
              convertUnixIntoDate(activeSubscriptions[0].canceled_at)
          }
        }
        this.getStripeManageSubscriptionLink()
      } catch (e) {
        this.hasFailed = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.col1 {
  width: 30%;
}
.col2 {
  width: 55%;
}
.col3 {
  width: 15%;
}
.table-header {
  font-size: 0.875rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid $primary;
}
.table-line {
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $grey-2;
}
</style>
