























export default {
  name: 'BaseDownloadBtn',
  props: {
    cssClass: { type: String, required: false, default: 'baseButton-primary' },
    cssStyle: { type: String, required: false, default: '--width: auto' },
    data: { type: Array, required: true, default: null },
    fields: { type: Object, required: true, default: null },
    formatType: { type: String, required: false, default: 'csv' },
    worksheet: { type: String, required: false, default: '' },
    fileName: { type: String, required: false, default: 'Download.csv' },
    btnTitle: { type: String, required: false, default: 'Export CSV' },
  },
  methods: {
    convertToCSV(objArray) {
      const array =
        typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''

      for (let i = 0; i < array.length; i++) {
        let line = ''
        for (const index in array[i]) {
          if (line !== '') line += ','

          line += array[i][index]
        }
        str += line + '\r\n'
      }
      return str
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers)
      }
      // Convert Object to JSON
      const jsonObject = JSON.stringify(items)

      const csv = this.convertToCSV(jsonObject)

      const exportedFilenmae = fileTitle + '.csv' || 'export.csv'

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', exportedFilenmae)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },

    download() {
      // call the exportCSVFile() function to process the JSON and trigger the download
      this.exportCSVFile(this.fields, this.data, this.fileName)
    },
  },
}
