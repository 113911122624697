<template>
  <div>
    <link
      href="https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css"
      rel="stylesheet"
    />
    <loader v-model="loading" message="Number crunching in progress" />
    <AuditReport
      v-if="!loading"
      :introduction-data="auditIntroduction"
      :audit-currency="auditCurrency"
      :audit-date="auditDate"
      :audit-types="auditTypes"
      :audit-breakdown="auditBreakdown"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AuditReport from '~/components/audit/template/AuditReport'
import Loader from '~/components/BaseLoader.vue'

export default {
  components: {
    AuditReport,
    Loader,
  },
  layout: 'report',
  data() {
    return {
      loading: true,
    }
  },
  head() {
    return {
      title: 'Audit Report ',
    }
  },
  computed: {
    ...mapGetters({
      auditFetched: 'auditReport/auditFetched',
      auditBreakdown: 'auditReport/auditBreakdown',
      auditDate: 'auditReport/auditDate',
      auditTypes: 'auditReport/auditTypes',
      auditCurrency: 'auditReport/auditCurrency',
      auditIntroduction: 'auditReport/auditIntroduction',
      auditBrands: 'auditReport/auditBrands',
    }),
    isLoading() {
      return this.$route.params.state === 'archived'
    },
  },
  watch: {
    auditFetched(fetched) {
      this.loading = !fetched
    },
  },
  mounted() {
    this.fetchAudit(this.$route.params.urid)
  },
  methods: {
    ...mapActions({ fetchAudit: 'auditReport/fetchAudit' }),
  },
}
</script>
