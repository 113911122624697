const middleware = {}

middleware['account_check'] = require('../middleware/account_check.js')
middleware['account_check'] = middleware['account_check'].default || middleware['account_check']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['redirectToNuxt3'] = require('../middleware/redirectToNuxt3.js')
middleware['redirectToNuxt3'] = middleware['redirectToNuxt3'].default || middleware['redirectToNuxt3']

export default middleware
