var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"align-self":"center"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('span',{staticClass:"font-weight-bold body-2"},[_vm._v("Show: ")]),_vm._v(" "),_c('v-btn-toggle',{attrs:{"tile":"","group":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-btn',{attrs:{"value":"All","text":""}},[_vm._v(" All ["+_vm._s(_vm.data.length)+"] ")]),_vm._v(" "),_c('v-btn',{attrs:{"value":"Selected","text":""}},[_vm._v(" Selected ["+_vm._s(_vm.selection.length)+"] ")])],1)],1),_vm._v(" "),_c('v-col',[_c('v-text-field',{staticClass:"is-outlined",attrs:{"clearable":true,"flat":true,"dense":"","solo":"","placeholder":"Filter by campaign","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.rows,"height":("" + (_vm.rows.length > 0 ? 300 : 100)),"search":_vm.searchTerm,"disable-pagination":true,"item-key":"id","fixed-header":"","show-select":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value},[(header.value === 'data-table-select')?_c('v-checkbox',{attrs:{"indeterminate":_vm.selectedAllRows && _vm.isAllSelected},on:{"change":_vm.selectAllRows},model:{value:(_vm.selectedAllRows),callback:function ($$v) {_vm.selectedAllRows=$$v},expression:"selectedAllRows"}}):_c('span',{on:{"click":function($event){return _vm.sortCol(header)}}},[_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-icon',{attrs:{"size":"14"}},[_vm._v(_vm._s(_vm.sortIcon(header.value)))])],1)],1)}),0)])]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":isSelected},on:{"change":function($event){return _vm.selectRow(item, isSelected)}}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(item.name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status.color,"x-small":""}},[_vm._v("\n          "+_vm._s(item.status.icon)+"\n        ")]),_vm._v(" "),_c('span',{directives:[{name:"capitalize",rawName:"v-capitalize"}],class:((item.status.color) + "--text")},[_vm._v("\n          "+_vm._s(item.status.value)+"\n        ")])]}},{key:"item.spend",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"is-title-size"},[_vm._v(_vm._s(_vm.currency)+_vm._s(item.spend))])]}},{key:"item.sales",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"is-title-size"},[_vm._v(_vm._s(_vm.currency)+_vm._s(item.sales))])]}},{key:"item.acos",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"is-title-size"},[_vm._v(_vm._s(item.acos)+"%")])]}},{key:"item.conversion",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"is-title-size"},[_vm._v(_vm._s(item.conversion)+"%")])]}}]),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }