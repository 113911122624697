










import Vue from 'vue'
import styleVars from '@/assets/_variables.scss'

const themes = {
  dark: {
    background: 'white',
    modal: styleVars.primary3,
    shadow: styleVars.white2,
  },
  light: {
    background: 'black',
    modal: 'white',
    shadow: styleVars.black,
  },
} as Record<
  string,
  {
    background: string
    modal: string
    shadow: string
  }
>

export default Vue.extend({
  model: {
    event: 'toggle',
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: null,
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
    theme: {
      type: String,
      required: false,
      default: () => 'light',
    },
  },
  computed: {
    cssVars(): Record<string, string> {
      return {
        '--display': this.value ? 'block' : 'none',
        '--height': this.height ? `${this.height}px` : `auto`,
        '--width': this.width ? `${this.width}px` : `75vw`,
        '--bg-color': `${themes[this.theme].background}`,
        '--modal-color': `${themes[this.theme].modal}`,
        '--modal-shadow': `${themes[this.theme].shadow}`,
      }
    },
  },
})
