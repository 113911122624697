






















import Vue from 'vue'

export default Vue.extend({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
})
