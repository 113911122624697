























import Vue from 'vue'
import BaseImage from '~/components/BaseImage.vue'

export default Vue.extend({
  components: { BaseImage },
  props: {
    imageSrc: { type: String, required: false, default: null },
    alt: { type: String, required: false, default: '' },
    invertOrder: { type: Boolean, required: false, default: false },
  },
})
