






















































































import Vue, { PropOptions } from 'vue'
import {
  daysAgo,
  monthsAgo,
  monthStartDay,
  monthEndDay,
  formatDate,
  rangeDuration,
  yesterday,
} from '../utils/date'
import { format } from 'date-fns'
import ClickOutside from './logic/ClickOutside.vue'
import BaseButton from './BaseButton.vue'

export interface DateRange {
  start?: Date
  end?: Date
}

export default Vue.extend({
  components: { BaseButton, ClickOutside },
  model: {
    prop: 'dates',
    event: 'change',
  },
  props: {
    minDate: {
      type: String,
      required: false,
      default: undefined,
    },
    maxDate: {
      type: String,
      required: false,
      default: formatDate(yesterday),
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    dates: {
      type: Object,
      required: false,
      default: () => null,
    } as PropOptions<DateRange | null>,
    clearDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): { range: DateRange | null; on: boolean } {
    return {
      on: false,
      range: this.dates,
    }
  },
  computed: {
    menuStyles(): Record<string, string> {
      return {
        '--right': this.left ? 'unset' : '0',
        '--left': this.left ? '0' : 'unset',
      }
    },
    rangeFormatted(): string {
      if (!this.range) {
        return 'No available dates'
      } else if (this.range && this.range.start && this.range.end) {
        const startDate = `${format(this.range.start, 'd LLL yy')}`
        const endDate = `${format(this.range.end, 'd LLL yy')}`
        return `${startDate}-${endDate}`
      } else {
        return 'No selected dates'
      }
    },
    rangeLength(): string | undefined {
      if (this.range && this.range.start && this.range.end) {
        const periodLength = rangeDuration(this.range.start, this.range.end)
        return `(${periodLength} days)`
      } else {
        return undefined
      }
    },
    hasSelectedDates(): boolean {
      return (
        !!this.range &&
        Object.values(this.range).some((date) => !!date) &&
        !this.disabled
      )
    },
  },
  watch: {
    dates: {
      immediate: true,
      deep: true,
      handler(dates: DateRange | null) {
        this.range = dates
      },
    },
    range: {
      immediate: true,
      deep: true,
      handler(newRange: DateRange | null) {
        this.$emit('change', newRange)
      },
    },
  },
  methods: {
    done(): void {
      this.on = false
      this.$emit('done')
    },
    resetPicker() {
      this.on = false
      this.range = { start: undefined, end: undefined }
    },
    startRangeAt(days: number) {
      const startDate = daysAgo(new Date(), days)
      const endDate = daysAgo(new Date(), 1)
      this.$emit('change', { start: startDate, end: endDate })
      this.updateCalendarNav(endDate)
    },
    selectLastMonth(): void {
      const lastMonth = monthsAgo(new Date(), 1)
      const startDate = monthStartDay(lastMonth)
      const endDate = monthEndDay(lastMonth)
      this.$emit('change', { start: startDate, end: endDate })
      this.updateCalendarNav(endDate)
    },
    selectCurrentMonth(): void {
      const startDate = monthStartDay(new Date())
      const endDate = daysAgo(new Date(), 1)
      this.$emit('change', { start: startDate, end: endDate })
      this.updateCalendarNav(endDate)
    },
    updateCalendarNav(date: Date): void {
      const picker = this.$refs?.pickerCalendar as any
      const calendar = picker.$refs?.calendar as any
      if (calendar) {
        calendar.showPageRange(date)
      }
    },
  },
})
