<script>
export default {
  computed: {
    copy() {
      return {
        searchTerms: {
          label: 'Branded search terms',
          placeholder: 'e.g. ABC, ACME Corp.',
          info: 'Type a word or phrase and press Enter to include it. You can also separate multiple words/phrases by commas.',
          limit: 100,
        },
        spendClick: {
          info: [
            'Wasted spend is money spent which results from search terms that have at least 1 click but no conversions.',
            'Here you can increase the click threshold.',
          ],
        },
        spendDays: {
          info: [
            "This toggle affects the budget efficiency section which measures how often you're spending the entire daily budget.",
            'By default, we include zero ad spend days in the analysis but, as these zero ad spend days can be caused by operational issues such as expired credit cards or no inventory to sell, we give you the option to exclude zero ad spend days from the analysis to ignore these operational issues. By default we include all zero ad spend days.',
          ],
        },
      }
    },
  },
}
</script>
