export const audits = {
  type: 'object',
  properties: {
    audits: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          created_at: {
            type: 'string',
          },
          id: {
            type: 'string',
          },
          params: {
            type: 'string',
          },
        },
      },
    },
    quota: {
      type: 'object',
      properties: {
        limit: {
          type: 'number',
        },
        used: {
          type: 'number',
        },
      },
    },
  },
}
