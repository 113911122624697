<template>
  <click-outside :trigger="hide">
    <div
      :class="`baseSelect ${disabled ? 'baseSelect-disabled' : ''} ${
        active ? 'baseSelect-active' : ''
      }`"
      @keydown.enter="displayOptions"
      @click="displayOptions"
    >
      <div class="tooltip-wrapper">
        <Tooltip
          v-for="({ value, name }, id) in sortValuesAlphabetically"
          :key="id"
          class="tooltip-absolute"
          v-if="value !== 99"
          :show="id === show"
          :text="name ? name : 'no product name found'"
          position="right"
          styling="nozzle"
          :styleCss="`width: 215px !important; margin-top: ${tooltipPosition}px; margin-left: 265px`"
        />
      </div>
      <div
        :class="`baseSelect-value text-sm font-weight-medium js-select ${
          disabled ? 'baseSelect-value-disabled' : ''
        }`"
        tabindex="0"
      >
        <div
          :class="`baseSelect-size-${size} select-wrapper select-wrapper-main`"
        >
          <div class="text-truncate">
            {{ getSelectedValue }}
          </div>
        </div>
        <div class="baseSelect-control">
          <fa
            :class="`${active ? 'rotate-180' : ''} baseSelect-control--icon`"
            :icon="['fal', 'chevron-down']"
          />
        </div>
      </div>
      <div v-if="active" class="baseSelect-list" @click.stop>
        <base-input
          inputId="asinSearch"
          class="search-input"
          placeholder="Search..."
          v-model="name"
          type="text"
          value=""
          @clear="clearModel"
          tabindex="0"
          borderColour="#b1b1b1"
          borderRadius="5px 5px 0 0"
          :searchIcon="true"
        />
        <div class="baseSelect-list-scroll">
          <template v-if="sortValuesAlphabetically.length">
            <div
              v-for="(
                { label, value, title, name }, id
              ) in sortValuesAlphabetically"
              :key="id"
              tabindex="0"
              ref="toolPosition"
              :class="`${
                isSelected(value) ? 'active' : ''
              } baseSelect-list-item-select font-weight-medium `"
              @click="toggleSelection(value)"
              @keydown.enter="toggleSelection(value)"
              @mouseover="mouseOver(id, name)"
              @mouseleave="mouseLeave"
            >
              <fa
                :class="`${
                  isSelected(value) ? 'blue-button--text' : 'grey--text'
                } text-lg`"
                :icon="[
                  `${isSelected(value) ? 'fas' : 'fal'}`,
                  `${isSelected(value) ? 'check-square' : 'square'}`,
                ]"
              />
              <div class="select-wrapper" :title="title" style="width: 100%">
                <template v-if="value !== 99">
                  <span
                    class="primary--text text-sm"
                    v-text="name ? truncateString(name) : label"
                  >
                  </span>
                  <br />
                  <small v-if="name">{{ label }}</small>
                </template>
                <span v-else class="primary--text text-sm">
                  {{ label }}
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="baseSelect-list-no-results">
              <p>no results</p>
            </div>
          </template>
        </div>
        <div class="baseSelect-list-buttons">
          <base-button
            id="reset-filters"
            label="Clear"
            :primary="false"
            class="mr-4"
            @on-click="resetFilters()"
          />
          <base-button
            id="apply-filters"
            label="Apply"
            :primary="true"
            @on-click="filter()"
          />
        </div>
      </div>
    </div>
  </click-outside>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue'
import BaseInput from '~/components/BaseInput.vue'
import ClickOutside from '~/components/logic/ClickOutside'
import Tooltip from '~/components/BaseTooltip.vue'

export default {
  components: { ClickOutside, BaseButton, BaseInput, Tooltip },

  props: {
    /**
     * @param {Array} values - Array of values that will be displayed as an selection options
     * @param {Object} { label: 'label1', value: 'value1' } - object structure of an values item
     */
    values: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Disabled.',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    tooltipPosition: 0,
    active: false,
    name: '',
    show: -1,
  }),
  computed: {
    getSelectedValue() {
      return this.selected.length <= 1
        ? this.values.find(({ value }) => value === this.selected[0])?.label ||
            `All`
        : `${this.selected.length} selected`
    },
    sortValuesAlphabetically() {
      this.show = -1
      // duplicating array to avoid vuex error if the data comes from the store
      const values = [...this.values]
      let name = this.name
      let nameCap = this.name.toUpperCase()
      let nameLowerCap = this.name.toLowerCase()

      const alphaValue = values.sort((a, b) => {
        return a.label > b.label ? 1 : -1
      })

      let sortedValues = alphaValue.filter(function (sortedValue) {
        return (
          sortedValue.sort.includes(name) ||
          sortedValue.sort.includes(nameCap) ||
          sortedValue.sort.includes(nameLowerCap)
        )
      })

      return sortedValues ? sortedValues : []
    },
  },
  methods: {
    truncateString(string) {
      let length = 45

      if (string.length >= length) {
        return `${string.substring(0, length)} ...`
      }

      return string
    },
    mouseOver(id, name) {
      if (name && name.length > 0) {
        this.tooltipPosition =
          this.$refs.toolPosition[id].getBoundingClientRect().top - 115
      } else {
        this.tooltipPosition =
          this.$refs.toolPosition[id].getBoundingClientRect().top - 130
      }

      this.show = id
    },
    mouseLeave() {
      this.show = -1
    },
    isSelected(value) {
      return this.selected.includes(value)
    },
    toggleSelection(value) {
      if (this.$route.name === 'analytics-profile-purchase-analysis') {
        this.$emit('reset')
        this.name = ''
      }
      // listen to an event on the parent element and handle received value
      this.$emit('change', value)
    },
    displayOptions() {
      if (this.disabled) return
      this.active = !this.active
    },
    hide() {
      this.active = false
    },
    resetFilters() {
      this.$emit('reset')
      this.hide()
      this.name = ''
    },
    filter() {
      this.$emit('filter')
      this.hide()
    },
    clearModel() {
      this.name = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
}
.overflow-hid-div {
  overflow-y: hidden;
  margin-left: 50px;
  width: 200px;
  height: 200px;
  background-color: blue;
}
.inner {
  width: 50px;
  height: 50px;
  background-color: green;
  position: absolute;
  top: 15%;
  left: 100%;
}

.tooltip-wrapper {
  position: relative;
}
.tooltip-absolute {
  position: absolute;
  z-index: 100;
}
.select-wrapper {
  margin-left: 10px;
  @media (max-width: $widescreen) {
    width: 100%;
    margin: 0;
  }

  small {
    font-size: 10px;
    color: #6b7696;
  }
}
.select-wrapper-main {
  white-space: nowrap;
  width: calc(100% - 2.5rem);
}

.baseSelect-list-item-wrapper {
  position: relative;
}

.baseSelect {
  position: relative;
  width: auto;
  line-height: 22px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background: $white;

  &:focus,
  &:active,
  &-active {
    border: 1px solid $blue-1;
    box-shadow: 0px 0px 0px 2px $blue-4;
  }

  &-disabled {
    cursor: not-allowed;
    background: $grey-1;
  }
  &-value {
    color: $primary;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-grow: 1;
    white-space: nowrap;
    &-disabled {
      user-select: none;
      color: $denim-2;
      background: $grey-1;
      pointer-events: none;
    }
  }
  &-list {
    margin-top: 0.5em;
    box-shadow: 0px 2px 5px $grey-1;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    z-index: 1000000;
    background-color: $white;

    &-no-results {
      height: 400px;
      text-align: center;

      p {
        color: $grey-5;
        margin: 0;
        position: relative;
        top: 50%;
      }
    }

    &-size {
      &-sm {
        padding: 0.5rem;
      }
      &-md {
        padding: 1rem;
      }
    }

    &-buttons {
      display: flex;
      padding: 12px 8px 8px;
      flex-direction: row;
      border-radius: 0 0 6px 6px;
      border: 1px solid $grey-3;
      justify-content: center;
    }

    &-scroll {
      margin-top: -1px;
      height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      border-left: 1px solid $grey-3;
      border-right: 1px solid $grey-3;

      .search-input {
        position: absolute;
        background-color: $white;
        width: 100%;
      }
    }

    &-item-select {
      cursor: pointer;
      padding: 0.4375rem 0.625rem; // 6px 10px
      display: flex;
      vertical-align: center;
      align-items: center;
      border-top: 1px solid $grey-3;
      border-bottom: 1px solid $grey-3;
      border-top: 0;
      &:first-child {
        border-top: 1px solid $grey-3;
      }
      &:hover {
        background-color: $blue-4;
      }
      &.active {
        background: $secondary-3;
      }
    }
  }

  &-size {
    &-sm {
      padding: 6px 12px;
    }
    &-md {
      padding: 12px 8px;
    }
  }

  &-control {
    border-left: 1px solid $primary;
    padding: 0 12px;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
    &--icon {
      transition-duration: 0.5s;
    }
  }
}
</style>
