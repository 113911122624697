<template>
  <div class="d-flex flex-column">
    <div class="primary--text font-weight-bold text-md">
      {{ title }}
    </div>
    <v-sheet elevation="0" max-width="100%" tile>
      <v-slide-group show-arrows>
        <v-slide-item
          v-for="item in items"
          :key="item.title"
          v-slot:default="{ toggle }"
        >
          <v-card
            :href="item.content.to"
            max-width="320"
            target="_blank"
            @click="handleSelection(item, toggle)"
          >
            <v-img
              :src="require(`~/assets/images/backgrounds/${item.img}.png`)"
              height="150"
            />
            <v-card-text>{{ item.title }}</v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <v-overlay :value="showOverlay" opacity="0.8">
      <div
        class="d-flex flex-column align-center justify-end h-100v pd-btm-xsm"
      >
        <iframe
          :src="videoId"
          frameborder="0"
          allowfullscreen
          autoplay="1"
          class="video"
        />

        <v-btn icon @click="toggleOverlay">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-overlay>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      selected: null,
    }
  },
  computed: {
    videoId() {
      return this.selected !== null &&
        typeof this.selected.content.video !== 'undefined'
        ? `//www.youtube.com/embed/${this.selected.content.video}??enablejsapi=1&html5=1&rel=0&modestbranding=0`
        : ''
    },
    items() {
      return [
        {
          title:
            'Amazon PPC expert talks through the results of a template report',
          img: 'audit_tool',
          content: {
            video: 'EDMXBg84sNg',
            to: undefined,
          },
        },
        {
          title: 'Why isolating search terms is critical to your PPC success',
          img: 'seo',
          content: {
            video: '3fWEMAZ_SAI',
            to: undefined,
          },
        },
        {
          title: 'What’s a good return on ad spend on Amazon',
          img: 'audit_cover',
          content: {
            to: 'https://insights.nozzle.ai/good-roas-amazon',
          },
        },
        {
          title: 'An Amazon ACoS Strategy Guide for 2020',
          img: 'acos_cover',
          content: {
            to: 'https://insights.nozzle.ai/amazon-acos-strategy-guide',
          },
        },
      ]
    },
  },
  methods: {
    toggleOverlay() {
      this.showOverlay = !this.showOverlay
    },
    handleSelection(item, toggleActiveItem) {
      this.selected = item
      if (typeof item.content.video !== 'undefined') {
        this.toggleOverlay()
        toggleActiveItem()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-item-group >>> .v-slide-group__next,
.v-item-group >>> .v-slide-group__prev {
  min-width: 15px;
  flex: 0 1 15px;
}
.v-item-group >>> .v-slide-group__prev {
  order: 1;
  margin: 0 0 0 16px;
}
.v-item-group >>> .v-slide-group__next {
  order: 2;
}

.v-card {
  margin: 20px 20px 20px 0;

  &:first-child {
    margin-left: 1px;
  }
}

.video {
  width: 720px;
  height: 420px;
  border-radius: 5px;
  margin-bottom: 1rem;
}
</style>
