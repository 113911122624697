var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.items.length > 0 && _vm.getSelectedAccount)?_c('v-menu',{ref:"parentMenu",staticStyle:{"z-index":"120"},attrs:{"transition":"slide-x-transition","open-delay":"250","close-delay":"500","bottom":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.expanded && _vm.classList
          ? 'menu-item expanded ' + _vm.classList
          : _vm.expanded && !_vm.classList
          ? 'menu-item expanded' + _vm.classList
          : 'menu-item'},'div',attrs,false),on),[_c('div',{staticClass:"menu-item-label text-left d-flex align-start justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-truncate"},[_vm._v("\n            "+_vm._s(_vm.getSelectedAccount.title.length > 12
                ? _vm.getSelectedAccount.title.substring(0, 12) + '...'
                : _vm.getSelectedAccount.title)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('v-img',{attrs:{"max-width":"28px","max-height":"32px"}},[(_vm.getSelectedCountryCode)?_c('country',{attrs:{"flag":_vm.getSelectedCountryCode === 'UK'
                    ? 'gb'
                    : _vm.getSelectedCountryCode,"size":"small"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"font-weight-semibold"},[_vm._v("\n              "+_vm._s(_vm.getSelectedCountryCode &&
                _vm.getSelectedCountryCode.toUpperCase() === 'GB'
                  ? 'UK'
                  : _vm.getSelectedCountryCode.toUpperCase())+"\n            ")])],1)]),_vm._v(" "),_c('div',[_c('fa',{attrs:{"icon":['fas', 'caret-right']}})],1)])])]}}],null,false,1808379988)},[_vm._v(" "),_c('v-list',{staticClass:"dropdown-content"},[_c('div',{staticClass:"dropdown-content-title"},[_vm._v("Accounts")]),_vm._v(" "),_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c('v-list-item',{key:index,class:{
          selected: item.selected === true,
          'menu-item-disabled': item.disabled,
        }},[_c('v-list-item-title',[_c('v-menu',{staticStyle:{"z-index":"120"},attrs:{"transition":"slide-x-transition","close-delay":"250","close-on-click":"","open-delay":"50","offset-x":"","offset-y":"","nudge-top":"32","nudge-right":"16"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"menu-sub-item"},'div',attrs,false),on),[_vm._t("icon"),_vm._v(" "),_c('span',{staticClass:"menu-item-label"},[_vm._v("\n                  "+_vm._s(item.title && item.title.length > 25
                      ? item.title.substring(0, 25) + '...'
                      : item.title)+"  \n                  "),(item.marketplaces.length > 0)?_c('fa',{attrs:{"icon":['fas', 'caret-right']}}):_vm._e()],1)],2)]}}],null,true)},[_vm._v(" "),_c('v-list',{staticClass:"dropdown-content dropdown-content-marketplaces"},[_c('div',{staticClass:"dropdown-content-title"},[_vm._v("Marketplaces")]),_vm._v(" "),_vm._l((item.marketplaces),function(marketplace,index){return _c('div',{key:index},[_c('v-list-item',{key:index,class:{
                    selected: marketplace.selected,
                    'menu-item-disabled':
                      marketplace.ordersStatus === '' ||
                      marketplace.ordersStatus === 'PENDING' ||
                      marketplace.adsStatus === 'NOT SYNCED' ||
                      marketplace.adsStatus === 'SYNCING',
                  },on:{"click":function($event){return _vm.handleSelectMarketplace(marketplace)}}},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex"},[_c('v-img',{attrs:{"max-width":"32px","max-height":"32px"}},[_c('country',{attrs:{"flag":marketplace.country === 'UK'
                              ? 'gb'
                              : marketplace.country,"size":"small"}})],1),_vm._v(" "),_c('div',{},[_vm._v("\n                        "+_vm._s(marketplace.title)+"\n                      ")])],1)])],1)],1)})],2)],1)],1)],1)],1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }