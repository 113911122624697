




















import Vue from 'vue'
import { CHART_SIZE } from '../sizes'

export default Vue.extend({
  name: 'ChartLegend',
  computed: {
    barsLegendTextAttrs(): { x: number; y: number } {
      const x = CHART_SIZE.width * 0.5
      const y = CHART_SIZE.marginTop * 0.7
      return { x, y }
    },
    barsLegendSymbolAttrs(): { x: number; y: number; sideWidth: number } {
      const x = this.barsLegendTextAttrs.x - CHART_SIZE.base_unit * 0.55
      const y = this.barsLegendTextAttrs.y - CHART_SIZE.base_unit * 0.07
      const sideWidth = CHART_SIZE.base_unit * 0.08
      return { x, y, sideWidth }
    },
  },
})
