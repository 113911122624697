import { countries } from '../data/countries.json'
import { ProductAsin } from '~/store/analytics'
export type Country = {
  name: string
  code: string[]
  flag?: string
  currency: string
}

export const getCountry = (code: string): Country | undefined => {
  const found = countries.find((country) =>
    country.code.includes(code && code.toLowerCase())
  )

  if (found) {
    return {
      name: found.name,
      code: found.code,
      flag: found.flag_code,
      currency: found.currency_symbol,
    }
  } else {
    return undefined
  }
}

export const getCountryFlag = (code: string) => {
  return countries.find((country) => country.code.includes(code))?.flag_code
}

export const getCountryCurrency = (code: string) => {
  return countries.find((country) => country.code.includes(code))
    ?.currency_symbol
}

export const showMarginBanner = (productAsins: ProductAsin[]): boolean => {
  /* protects it from appearing before data has loaded */
  if (productAsins?.length === 0) return false
  /* if one of the profile asins has a custom margin the banner will not be showed, so this function will return false */
  return !(
    productAsins.filter((asin) => asin.is_default_margin === false).length > 0
  )
}
