





















import Vue from 'vue'
import BaseCard from '@/components/BaseCard.vue'

export default Vue.extend({
  components: { BaseCard },
})
