















































import Vue from 'vue'
import { mapGetters } from 'vuex'
import analytics from '~/mixins/analytics'
import Dashboard from '@/components/analytics/CohortsAnalysis/Dashboard.vue'
import AccountSyncIncomplete from '~/components/alerts/AccountSyncIncomplete.vue'

export default Vue.extend({
  components: {
    Dashboard,
    AccountSyncIncomplete,
  },
  mixins: [analytics],
  middleware: 'account_check',
  head() {
    return {
      title: `Cohorts Analysis - ${this.currentProfileName} | ${this.currentProfile?.country}`,
    }
  },
  computed: {
    ...mapGetters({
      getFilters: 'analytics/getFilters',
    }),
  },
})
