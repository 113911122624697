var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('click-outside',{attrs:{"trigger":function () { return (_vm.showOverlay = false); }}},[_c('nav',{staticClass:"main-nav expanded"},[_c('div',{staticClass:"nav-wrapper"},[_c('div',{staticClass:"nav-top"},[_c('div',{staticClass:"nav-header"},[_c('div',{staticClass:"app-header-logo"},[_c('nuxt-link',{attrs:{"to":"/"}},[_c('base-image',{attrs:{"src":"nozzle-logo.svg","width":"130","height":"auto"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"nav-body"},[(!_vm.loading && _vm.smallThanTablet)?_c('menu-item',{staticClass:"app-header-button",attrs:{"type":"button","tag":"button","name":("" + (_vm.showOverlay ? 'Close' : 'Menu')),"to":""},on:{"click":_vm.toggle},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fa',{staticClass:"text-lg mr-3",attrs:{"icon":_vm.showOverlay ? ['fal', 'times'] : ['fal', 'bars']}})]},proxy:true}],null,false,3365673633)}):_vm._e(),_vm._v(" "),_c('menu-mobile',{attrs:{"show":_vm.showOverlay && _vm.smallThanTablet},on:{"goto":_vm.goto}}),_vm._v(" "),_c('menu-desktop',{attrs:{"expanded":true,"show":!_vm.smallThanTablet},on:{"goto":_vm.goto}})],1)]),_vm._v(" "),(!_vm.smallThanTablet)?_c('div',{staticClass:"nav-footer"},[_c('account-and-marketplace-selector'),_vm._v(" "),_c('menu-dropdown',{attrs:{"name":"My account","subtitle":_vm.userEmail,"type":"nuxt-link","items":[
            { title: 'Settings', link: '/user/account' },
            { title: 'Billing', link: '/billing' },
            {
              title: 'Logout',
              link: ((_vm.$env.NOZZLE_URL) + "/logout"),
              external: true,
            } ],"class-list":[
            _vm.$nuxt.$route.path === '/user/account' ||
            _vm.$nuxt.$route.path === '/billing'
              ? 'active-link'
              : '',
            'py-4' ]}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }