<template>
  <v-app>
    <div class="app-layout-default">
      <the-white-navigation />
      <main>
        <nuxt />
      </main>
    </div>
  </v-app>
</template>
<script>
import TheWhiteNavigation from '~/components/navigation/index'

export default {
  components: {
    TheWhiteNavigation,
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100vw;
  padding: 3rem 0;
}
.app-layout-default {
  display: grid;
  grid-template-rows: 80px 1fr;
  grid-template-columns: 1fr;
}
</style>
