































import Vue, { PropType } from 'vue'
import * as d3 from 'd3'
import { CHART_SIZE } from '../sizes'
import { ChartData } from '../types'

export default Vue.extend({
  name: 'DataSeriesBars',
  props: {
    axisXScale: {
      type: Function as PropType<d3.ScaleBand<string>>,
      required: true,
    },
    axisYScale: {
      type: Function as PropType<d3.ScaleLinear<number, number>>,
      required: true,
    },
    chartData: {
      type: Array as PropType<ChartData>,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  data: () => ({ CHART_SIZE }),
})
