




















































import Vue from 'vue'
export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    classList: {
      type: [String, Array],
      required: false,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
})
