import { decimal, integer, percentage, formatAs } from '~/utils/format'
import { daysInBetween, toDateString } from '~/utils/date'

export const state = () => ({
  data: {},
  hasFetched: false,
})

export const getters = {
  audit: (state) => state.data,
  auditFetched: (state) => state.hasFetched,
  auditCurrency: ({ data }) =>
    data.metadata.currency_symbol !== null ? data.metadata.currency_symbol : '',
  auditTypes: ({ data }) => Object.keys(data.match_types),
  auditDate: ({ data }) => {
    if (Object.keys(data).length > 0) {
      return {
        from: toDateString(data.metadata.date_from),
        to: toDateString(data.metadata.date_to),
        period: daysInBetween(data.metadata.date_from, data.metadata.date_to),
      }
    }
  },
  auditIntroduction: ({ data }) => {
    if (Object.keys(data).length > 0) {
      return {
        sales: integer(data.sales.total),
        spend: integer(data.ad_spend.total),
        campaigns: integer(data.campaigns.unique.total),
        market: data.metadata.market,
        keywordsUnique: integer(data.keywords.unique.total),
        keywordsCampaigns: integer(data.keywords.unique.average_per_campaign),
        searchTerms: integer(data.search_terms.unique.converting),
        asins: integer(data.asins.unique.total),
        wasted: integer(data.ad_spend.wasted),
        missedRevenue: integer(data.missed_revenue.total),
        acos: percentage(data.sales.acos),
        matchTypeNonconvertingTerms: Object.values(data.match_types).reduce(
          (acc, type) => {
            if (type.search_terms.top_non_converting.length > 0) {
              acc = [...acc, ...type.search_terms.top_non_converting]
            }
            return acc
          },
          []
        ),
      }
    }
  },
  auditBreakdown: ({ data }) => {
    if (Object.keys(data).length > 0) {
      const types = data.match_types
      return {
        intro: {
          totals: {
            spend: integer(data.ad_spend.total),
            impressions: integer(data.impressions.total),
            clicks: integer(data.clicks.total),
            ctr: percentage(data.clicks.total / data.impressions.total),
            cvr: percentage(data.conversions.conversion_rate),
            cpc: formatAs(data.clicks.cpc, decimal),
            sales: {
              total_orders: integer(data.sales.total_orders),
              total: integer(data.sales.total),
              roas: decimal(data.sales.roas),
              acos: percentage(data.sales.acos),
            },
          },
          byMatchType: Object.keys(data.match_types).map((type) => ({
            type,
            spend: integer(types[type].ad_spend.summary.total),
            sales: integer(types[type].sales.summary.total),
            acos: percentage(types[type].sales.summary.acos),
            clicks: integer(types[type].clicks.total),
            cpc: formatAs(types[type].clicks.cpc, decimal),
            cvr: percentage(types[type].conversions.conversion_rate),
          })),
        },
        wasted: {
          total: integer(data.ad_spend.wasted),
          percentage: percentage(data.ad_spend.wasted / data.ad_spend.total),
        },
        findings: {
          total: data.search_terms.unique.converting,
          exact: types.exact.search_terms.unique.converting,
          asins: data.asins.converting.total,
          byMatchType: Object.keys(types).map((type) => ({
            type,
            converting: types[type].search_terms.unique.converting,
            spendPercentage: percentage(
              types[type].ad_spend.summary.percentage_of_total
            ),
          })),
        },
        matchType: Object.keys(types).map((type) => ({
          type,
          spend: {
            converting: integer(types[type].ad_spend.converting.total),
            total: integer(types[type].ad_spend.summary.total),
            wasted: integer(types[type].ad_spend.summary.wasted),
          },
          searchTerms: {
            unique: {
              converting: integer(types[type].search_terms.unique.converting),
              convertingPercent: percentage(
                types[type].search_terms.unique.percent_converting
              ),
              nonConverting: integer(
                types[type].search_terms.unique.non_converting
              ),
              total: integer(types[type].search_terms.unique.total),
            },
            converting: types[type].search_terms.top_converting.map((term) => ({
              query: term.query,
              spend: integer(term.ad_spend),
              sales: integer(term.sales),
              acos: percentage(term.acos),
              clicks: integer(term.clicks),
              cpc: formatAs(term.cpc, decimal),
            })),
            nonConverting: types[type].search_terms.top_non_converting.map(
              (term) => ({
                query: term.query,
                spend: integer(term.ad_spend),
                sales: integer(term.sales),
                acos: percentage(term.acos),
                clicks: integer(term.clicks),
                cpc: formatAs(term.cpc, decimal),
              })
            ),
          },
          sales: {
            converting: integer(types[type].sales.converting.total),
            total: integer(types[type].sales.summary.total),
          },
          roas: {
            converting: decimal(types[type].sales.converting.roas),
            total: decimal(types[type].sales.summary.roas),
          },
          acos: {
            converting: percentage(types[type].sales.converting.acos),
            total: percentage(types[type].sales.summary.acos),
          },
        })),
        budget: {
          dailyEfficiency: {
            full: integer(
              percentage(data.budget.daily_efficiency.percent_full_spend)
            ),
            high: integer(
              percentage(data.budget.daily_efficiency.percent_high_spend)
            ),
            low: integer(
              percentage(data.budget.daily_efficiency.percent_low_spend)
            ),
          },
          underSpend: {
            total: integer(data.budget.summary.total_underspend),
            percent: percentage(data.budget.summary.percent_underspend),
          },
          byDate: data.budget.top_daily_underspend.map((day) => ({
            date: toDateString(day.date),
            budget: integer(day.total),
            spend: integer(day.total_ad_spend),
            difference: integer(day.total_underspend),
          })),
          summary: {
            budget: integer(data.budget.summary.total),
            spend: integer(data.ad_spend.total),
            difference: integer(data.budget.summary.total_underspend),
          },
        },
        search: {
          summary: {
            spend: integer(data.search_terms.duplicate.summary.ad_spend),
            sales: integer(data.search_terms.duplicate.summary.sales),
            acos: percentage(data.search_terms.duplicate.summary.acos),
          },
          byTerm: data.search_terms.duplicate.top_duplicate.map((term) => ({
            term: term.query,
            spend: integer(term.ad_spend),
            sales: integer(term.sales),
            acos: percentage(term.acos),
          })),
        },
      }
    }
  },
}

export const actions = {
  fetchAudit({ commit }, urid) {
    setTimeout(
      async function getAudit() {
        try {
          const response = await this.$axios.get(`/audit/${urid}`)
          if (response?.status === 204) {
            setTimeout(getAudit.bind(this), 2000)
          }
          if (response?.status === 200) {
            commit('updateData', response.data)
            commit('updateStatus', true)
          }
        } catch (e) {
          window.$nuxt.error({
            statusCode: 404,
            message: 'This page could not be found',
            link: '/reports',
            label: 'My Audits',
          })
          this.$sentry.captureException(e)
        }
      }.bind(this)
    )
  },
}

export const mutations = {
  updateData(state, data) {
    state.data = data
  },
  updateStatus(state, hasFetched) {
    state.hasFetched = hasFetched
  },
}
