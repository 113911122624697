












































































import Vue from 'vue'
import { NuxtError } from '@nuxt/types'
import { mapActions, mapGetters } from 'vuex'
import ApiProvider from '@/components/logic/ApiProvider.vue'
import BaseSelect from '~/components/BaseSelect.vue'
import BaseMonthPicker, { DateRange } from '~/components/BaseMonthPicker.vue'
import BaseMultiSelect from '~/components/BaseMultiSelect.vue'
import { ASIN, ASINCategory, ASINItem } from '~/store/analytics'
export default Vue.extend({
  name: 'AnalyticsFilters',
  components: {
    ApiProvider,
    BaseMonthPicker,
    BaseSelect,
    BaseMultiSelect,
  },
  data(): {
    failed: boolean
    loading: boolean
    dateRange?: DateRange
    categories?: ASINCategory[]
    asins?: ASINItem[]
    selectedAsinsFilter: ASIN[]
    categorySelected?: ASINCategory
    asinSelected?: ASINItem
    initialTimePeriod?: DateRange
    value: any
  } {
    return {
      failed: false,
      loading: true,
      categories: undefined,
      asins: undefined,
      dateRange: undefined,
      categorySelected: undefined,
      asinSelected: undefined,
      selectedAsinsFilter: [99],
      initialTimePeriod: undefined,
      value: {},
    }
  },
  computed: {
    ...mapGetters({
      getFilters: 'analytics/getFilters',
    }),
    currency(): string {
      return this.$store.getters['analytics/currency']
    },
    isEnabled(): Boolean {
      return this.$isEnabled(
        'ANALYTICS',
        this.$store.getters['user/hasAnalytics']
      )
    },
    asinCategoriesEndpoint(): { src: string } {
      if (this.$route.params.profileId !== 'no-profile-ready') {
        return {
          src: `/api/cached/profiles/${this.$route.params.profileId}/asin-categories`,
        }
      }
    },
    asinEndpoint(): { src: string } {
      if (this.$route.params.profileId !== 'no-profile-ready') {
        return {
          src: `/api/cached/profiles/${this.$route.params.profileId}/asins`,
        }
      }
    },
    asinCategoriesDropdownValuesList(): ASINCategory[] {
      if (this.categories) {
        return [
          { label: 'All categories ', asins: [], id: 0 },
          ...this.categories.map((category, idx) => ({
            ...category,
            id: idx + 1,
          })),
        ]
      } else {
        return []
      }
    },
    asinDropdownValuesList(): ASINItem[] {
      if (this.asins) {
        return [
          { label: 'All Products ', value: 99, sort: '99' },
          ...this.asins.map((category, idx) => ({ ...category, id: idx + 1 })),
        ]
      } else {
        return []
      }
    },
  },
  watch: {
    categories: {
      immediate: true,
      handler(data) {
        this.loading = !data
      },
    },
    dateRange: {
      handler(
        newValue: DateRange | undefined,
        oldValue: DateRange | undefined
      ) {
        if (this.getFilters !== undefined) {
          // first iteration and there is NO state info about filters -> save info on state
          // (runs once and update already both filters objects)
          if (newValue !== undefined && this.getFilters === null) {
            this.setFilters({
              categorySelected: this.categorySelected,
              dateRange: newValue,
            })
          }
          // first iteration and there is state info about filters -> reading from state to update UI daterange
          else if (
            newValue &&
            oldValue === undefined &&
            this.getFilters !== null
          ) {
            this.dateRange = this.getFilters.dateRange
            this.initialTimePeriod = this.getFilters.dateRange
            this.selectedAsinsFilter = [
              ...this.getFilters.categorySelected?.asins,
            ]
          }
          // dateRange has changed --> update state
          else {
            this.getFilters.dateRange !== newValue &&
              this.setFilters({
                categorySelected: this.categorySelected,
                dateRange: newValue,
              })
            this.dateRange = newValue
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setFilters: 'analytics/setFilters',
      setASINS: 'analytics/setASINS',
      setProductAsins: 'analytics/setProductAsins',
    }),
    populateCategories({ data }: { data: { items: ASINCategory[] } }) {
      this.categories = data.items
      if (
        this.getFilters &&
        this.getFilters.categorySelected &&
        this.getFilters.categorySelected.label !== ''
      ) {
        this.categorySelected = { ...this.getFilters.categorySelected }
      } else {
        this.categorySelected = this.asinCategoriesDropdownValuesList[0]
      }
      this.failed = false
    },
    populateAsin({
      data,
    }: {
      data: {
        items: {
          product_name: string
          asin: string
          is_default_margin: boolean | undefined
        }[]
      }
    }) {
      this.setASINS({ data: data.items?.map((item) => item.asin) })
      this.setProductAsins({
        data: Array.from(data.items, function (item) {
          return {
            product: item.product_name,
            asin: item.asin,
            is_default_margin: item?.is_default_margin,
          }
        }),
      })
      this.asins = data.items.map((item) => {
        let SortString = `${item.asin} - ${item.product_name}`
        return {
          name: item.product_name ? item.product_name : '',
          label: item.asin,
          value: item.asin,
          sort: SortString.toLowerCase(),
        }
      })
    },
    handleError({ status }: { status: number }) {
      if (status === 403) {
        this.$nuxt.error({
          status: 'auth',
          message: 'You are not authorized to view this page',
          link: '/analytics',
          label: 'Customer Analytics',
        } as NuxtError)
      } else {
        this.failed = true
      }
    },
    handleSelection(category: ASINCategory) {
      this.resetAsinFilter()
      this.categorySelected = category
      category.asins.length === 0
        ? this.selectedAsinsFilter.push(99)
        : (this.selectedAsinsFilter = [...category.asins])
      this.setFilters({
        categorySelected: this.categorySelected,
        dateRange: this.dateRange,
      })
    },
    handleAsinSelection(value: ASIN) {
      ;(this.$refs.categorySelect as any).resetValue()
      this.categorySelected = this.categorySelected && {
        asins: this.categorySelected.asins,
        label: this.asinCategoriesDropdownValuesList[0].label,
        id: this.asinCategoriesDropdownValuesList[0].id,
      }
      if (value === 99) {
        this.selectedAsinsFilter = []
        this.selectedAsinsFilter.push(value)
      } else {
        const currIndex = this.selectedAsinsFilter.findIndex(
          (asin) => asin === value
        )
        const absoluteValueIndex = this.selectedAsinsFilter.findIndex(
          (asin) => asin === 99
        )
        absoluteValueIndex !== -1 &&
          this.selectedAsinsFilter.splice(absoluteValueIndex, 1)
        currIndex === -1
          ? this.selectedAsinsFilter.push(value)
          : this.selectedAsinsFilter.splice(currIndex, 1)
        this.selectedAsinsFilter.length === 0 &&
          this.selectedAsinsFilter.push(99)
      }
    },
    resetAsinFilter() {
      this.selectedAsinsFilter = []
    },
    resetFilters() {
      ;(this.$refs.categorySelect as any).resetValue()
      this.selectedAsinsFilter = [99]
      this.categorySelected = {
        label: 'All categories ',
        asins: [],
        id: 0,
      }
    },
    filter() {
      this.categorySelected = {
        label: 'All categories ',
        asins: this.selectedAsinsFilter.includes(99)
          ? []
          : this.selectedAsinsFilter,
        id: 0,
      }
      this.setFilters({
        categorySelected: { ...this.categorySelected },
        dateRange: this.dateRange,
      })
    },
  },
})
