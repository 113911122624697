









































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { startOfYear } from 'date-fns'
import ProgressCircular from '~/components/BaseProgressCircular.vue'
import { CACData, LTVData, ASINCategory } from '~/store/analytics'
import PerformanceRow from '~/components/analytics/PerformanceSummary/PerformanceRow.vue'
import ToggleGroup from '~/components/BaseToggleGroup.vue'
import {
  formatCurrencyUsingAbbreviations,
  formatCurrency,
} from '~/utils/currency'

interface MonthRowValues {
  'New customers': string | number
  'Repeat customers': string | number
  'Total Sales': string | number
  'Organic Sales': string | number
  'Ad Sales': string | number
  'Ad spend': string | number
  CAC: string | number
  'First purchase profit': string | number
}
interface YearRowValues {
  'New customers': string | number
  'Repeat customers': string | number
  'Total Sales': string | number
  'Organic Sales': string | number
  'Ad Sales': string | number
  'Retention rate': string | number
}

type Filter = {
  id: 'month' | 'year'
  label: string
}

export default Vue.extend({
  components: {
    PerformanceRow,
    ProgressCircular,
    ToggleGroup,
  },
  props: {
    asinFilter: {
      type: Object as () => ASINCategory,
      required: false,
      default: undefined,
    },
  },
  data(): {
    failed: boolean
    loading: boolean
    loadingLTV: boolean
    loadingCAC: boolean
    dataLTV: LTVData[]
    dataCAC: CACData[]
    startDate: string
    endDate: string
    monthRowValues: MonthRowValues
    yearRowValues: YearRowValues
    filters: {
      items: Filter[]
      active?: Filter
    }
    updating: Boolean
  } {
    return {
      failed: false,
      loading: true,
      loadingLTV: true,
      loadingCAC: true,

      dataLTV: [],
      dataCAC: [],

      startDate: '',
      endDate: '',
      monthRowValues: {
        'New customers': '--',
        'Repeat customers': '--',
        'Total Sales': '--',
        'Organic Sales': '--',
        'Ad Sales': '--',
        'Ad spend': '--',
        CAC: '--',
        'First purchase profit': '--',
      },
      yearRowValues: {
        'New customers': '--',
        'Repeat customers': '--',
        'Total Sales': '--',
        'Organic Sales': '--',
        'Ad Sales': '--',
        'Retention rate': '--',
      },
      filters: {
        items: [],
        active: undefined,
      },
      updating: true,
    }
  },
  computed: {
    ...mapGetters({
      currentProfile: 'analytics/getProfile',
      getPaymentStatus: 'user/getPaymentStatus',
    }),
    profileId(): string {
      return this.$route.params.profileId
    },
    currency(): string {
      return this.$store.getters['analytics/currency']
    },
    currencyCode(): string {
      return this.$store.getters['analytics/getCurrencyCode']
    },
    defaultLocale(): string {
      return Intl.NumberFormat().resolvedOptions().locale
    },
  },
  watch: {
    asinFilter() {
      // fetches info for current month and current year row
      this.getLTVData()
      this.getCACData()
    },
  },
  created() {
    const filterOptions: Filter[] =
      this.getPaymentStatus === 'trialing'
        ? [{ id: 'month', label: 'Month to date' }]
        : [
            { id: 'month', label: 'Month to date' },
            { id: 'year', label: 'Year to date' },
          ]
    this.filters.items = filterOptions
    this.filters.active = filterOptions[0]
  },
  mounted() {
    // defines start date, end date to complete the endpoint
    this.defineDates()
    // fetches info for current month and current year row
    this.getLTVData()
    this.getCACData()
  },
  methods: {
    onSelect(sectionId: string) {
      this.filters.active = this.filters.items.find(
        ({ id }) => sectionId === id
      )
    },
    defineDates() {
      const today: Date = new Date()
      this.startDate = '' + today.getFullYear() + '-01-01'
      this.endDate = this.convertDateIntoString(today)
    },
    convertDateIntoString(date: Date) {
      const dateYear: string = '' + date.getFullYear()
      const dateMonth: string = ('0' + (date.getMonth() + 1)).slice(-2)
      const dateDay: string = ('0' + date.getDate()).slice(-2)
      return dateYear + '-' + dateMonth + '-' + dateDay
    },
    formatNumber(n: number): string {
      return n
        ? n.toLocaleString(this.defaultLocale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        : n.toString()
    },
    async getLTVData(period = '') {
      try {
        const dateToday = new Date()
        const date1stDayOfCurrentYear = startOfYear(dateToday)
        if (dateToday < date1stDayOfCurrentYear) {
          this.loadingLTV = false
          return
        }
        const dateTodayAsISOString = dateToday.toISOString().split('T')[0]

        const baseUrl: string = `${(this as any).$env.NOZZLE_URL}`
        const endpoint: string =
          this.profileId !== 'no-profile-ready'
            ? `/api/cached/profiles/${this.profileId}/ltv`
            : ''

        const params = new URLSearchParams({
          start_date: this.startDate,
          end_date: dateTodayAsISOString,
          ...(period !== '' && { period }),
          ...(this.asinFilter &&
            this.asinFilter.asins && {
              asins: `${this.asinFilter.asins}`,
            }),
        })

        const fullUrl: string = baseUrl + endpoint + '?' + params.toString()

        const res = await fetch(fullUrl, { credentials: 'include' })
        const data = await res.json()

        if (period !== 'day') {
          this.dataLTV = data.items.cohorts
          this.loadingLTV = false
        }
      } catch (e) {
        this.failed = true
        if (period !== 'day') this.loadingLTV = false
      }
    },
    async getCACData(period = '') {
      try {
        const baseUrl: string = `${(this as any).$env.NOZZLE_URL}`
        const endpoint: string =
          this.profileId !== 'no-profile-ready'
            ? `/api/cached/profiles/${this.profileId}/cac`
            : ''
        const params = new URLSearchParams({
          start_date: this.startDate,
          end_date: this.endDate,
          ...(this.asinFilter &&
            this.asinFilter.asins && {
              asins: `${this.asinFilter.asins}`,
            }),
        })
        const fullUrl: string = baseUrl + endpoint + '?' + params.toString()

        const res = await fetch(fullUrl, { credentials: 'include' })
        const data = await res.json()

        this.dataCAC = data.items
        this.loadingCAC = false
      } catch (e) {
        this.failed = true
        this.loadingCAC = false
      }
    },
    generateCurrentYearPerformanceData(): YearRowValues {
      const sumOfRepeatCustomers = this.dataCAC.reduce(
        (previous, current) =>
          current.repeat_customers != null
            ? previous + current.repeat_customers
            : previous,
        0
      )
      const sumOfNewCustomers = this.dataCAC.reduce(
        (previous, current) =>
          current.new_customers != null
            ? previous + current.new_customers
            : previous,
        0
      )
      const sumOfSales = this.dataCAC.reduce(
        (previous, current) =>
          current.total_sales != null
            ? previous + +current.total_sales
            : previous,
        0
      )
      const sumOfReturningCustomers = this.dataLTV.reduce(
        (previous, current) =>
          current.returning_customers != null
            ? previous + current.returning_customers
            : previous,
        0
      )
      const sumOfTotalCustomers = this.dataLTV.reduce(
        (previous, current) =>
          current.total_customers != null
            ? previous + current.total_customers
            : previous,
        0
      )

      const retentionRate =
        sumOfReturningCustomers && sumOfTotalCustomers > 0
          ? Math.round((sumOfReturningCustomers / sumOfTotalCustomers) * 100) +
            '%'
          : '-'

      const adSales = this.dataCAC.reduce(
        (previous, current) =>
          current.total_ad_sales != null
            ? parseInt(previous) + parseInt(current.total_ad_sales)
            : parseInt(previous),
        0
      )

      const organicSales = sumOfSales - adSales

      return {
        'New customers': this.formatNumber(sumOfNewCustomers),
        'Repeat customers': this.formatNumber(sumOfRepeatCustomers),
        'Total Sales': sumOfSales
          ? formatCurrencyUsingAbbreviations(this.currency, +sumOfSales)
          : '-',
        'Organic Sales': formatCurrencyUsingAbbreviations(
          this.currency,
          +organicSales
        ),
        'Ad Sales': formatCurrencyUsingAbbreviations(this.currency, +adSales),
        'Retention rate': retentionRate,
      }
    },

    generateCurrentMonthPerformanceData(): MonthRowValues {
      const NewCustomers = this.dataCAC?.find(
        (item) => item.period === this.endDate.substring(0, 7)
      )?.new_customers
      const RepeatCustomers = this.dataCAC?.find(
        (item) => item.period === this.endDate.substring(0, 7)
      )?.repeat_customers
      const AdSpend = this.dataCAC?.find(
        (item) => item.period === this.endDate.substring(0, 7)
      )?.total_spend
      const Sales = this.dataCAC?.find(
        (item) => item.period === this.endDate.substring(0, 7)
      )?.total_sales
      const CAC = this.dataCAC?.find(
        (item) => item.period === this.endDate.substring(0, 7)
      )?.cac
      const profit = this.dataLTV?.find(
        (item) => item.cohort === this.endDate.substring(0, 7)
      )?.first_purchase_profit_per_customer

      const AdSales = this.dataCAC?.find(
        (item) => item.period === this.endDate.substring(0, 7)
      )?.total_ad_sales

      const organicSales = Sales - AdSales

      return {
        'New customers': NewCustomers ? this.formatNumber(+NewCustomers) : '-',
        'Repeat customers': RepeatCustomers
          ? this.formatNumber(+RepeatCustomers)
          : '-',
        'Total Sales': Sales
          ? formatCurrencyUsingAbbreviations(this.currency, +Sales)
          : '-',
        'Organic Sales': formatCurrencyUsingAbbreviations(
          this.currency,
          +organicSales
        ),
        'Ad Sales': AdSales
          ? formatCurrencyUsingAbbreviations(this.currency, +AdSales)
          : '-',
        'Ad spend': AdSpend
          ? formatCurrencyUsingAbbreviations(this.currency, +AdSpend)
          : '-',
        CAC: CAC
          ? formatCurrency(this.currencyCode, this.defaultLocale, +CAC)
          : '-',
        'First purchase profit': profit
          ? formatCurrency(this.currencyCode, this.defaultLocale, +profit)
          : '-',
      }
    },
  },
})
