<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('close')"
  >
    <path
      d="M15.7656 15.8125C15.4844 16.0938 14.9688 16.0938 14.6875 15.8125L8.5 9.57812L2.26562 15.8125C1.98438 16.0938 1.46875 16.0938 1.1875 15.8125C0.90625 15.5312 0.90625 15.0156 1.1875 14.7344L7.42188 8.5L1.1875 2.3125C0.90625 2.03125 0.90625 1.51562 1.1875 1.23438C1.46875 0.953125 1.98438 0.953125 2.26562 1.23438L8.5 7.46875L14.6875 1.23438C14.9688 0.953125 15.4844 0.953125 15.7656 1.23438C16.0469 1.51562 16.0469 2.03125 15.7656 2.3125L9.53125 8.5L15.7656 14.7344C16.0469 15.0156 16.0469 15.5312 15.7656 15.8125Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconsCloseBtn',
}
</script>
