











import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import MegaMenuDropdown, { Account, Marketplace } from './MegaMenuDropdown.vue'
import ApiProvider from '@/components/logic/ApiProvider.vue'
import { getCountry } from '@/utils/marketplace'
import { setAccounts } from '@/utils/accounts'

export type Merchant = {
  id: string
  marketplaces: {
    campaigns: string
    createdAt: string
    id: number
    startAt: string
    adsStatus: string
    ordersStatus: string
    marketplace: {
      code: string[]
      currency: string
      flag: string
      name: string
    }
  }[]
  name: string
  storeName: string
  type: 'seller' | 'vendor'
  website: string
}

export default Vue.extend({
  components: {
    MegaMenuDropdown,
    ApiProvider,
  },
  data(): {
    loading: boolean
    failed: boolean
    accountsData: (Account | undefined)[]
  } {
    return {
      loading: true,
      failed: false,
      accountsData: [],
    }
  },
  computed: {
    ...mapGetters({
      currentProfile: 'analytics/getProfile',
      currentMerchant: 'merchants/merchant',
      marketplacesAvailable: 'merchants/marketplacesAvailable',
      merchants: 'merchants/merchants',
    }),
    amazonEndpoint(): { src: string } {
      return { src: '/api/v1/user/amazon_users' }
    },
    generateFormattedAccountsData(): Account[] {
      return (
        this.merchants &&
        this.merchants
          .map((merchant: Merchant) => ({
            merchantId: merchant.id,
            title:
              merchant.storeName && merchant.name !== merchant.storeName
                ? merchant.storeName + ' (' + merchant.name + ')'
                : merchant.name,
            disabled: merchant.marketplaces.length === 0,
            selected:
              this.currentMerchant &&
              this.currentMerchant.name === merchant.name,
            marketplaces: merchant.marketplaces
              .filter(
                (m) =>
                  m.adsStatus !== 'NOT SYNCED' &&
                  m.ordersStatus !== 'PENDING' &&
                  m.ordersStatus !== 'REVOKED' &&
                  m.ordersStatus !== ''
              )
              .map(({ id, adsStatus, ordersStatus, marketplace }) => ({
                title: marketplace.name,
                profileId: id,
                country: marketplace.flag,
                selected: this.currentProfile && this.currentProfile.id === id,
                adsStatus: adsStatus,
                ordersStatus: ordersStatus,
              })),
          }))
          .filter((merchant: Merchant) => merchant.marketplaces.length > 0)
      )
    },
  },
  watch: {
    currentProfile() {
      this.selectorAccountData()
    },
  },
  methods: {
    ...mapActions({
      setMerchants: 'merchants/setMerchants',
      setMerchant: 'merchants/setMerchant',
      setProfile: 'analytics/setProfile',
      setIsProfileLoaded: 'analytics/setIsProfileLoaded',
    }),
    selectorAccountData() {
      this.defineCurrentMerchantAndCurrentProfile()
      let data: Account[]
      if (this.merchants) {
        data = this.generateFormattedAccountsData
        data = this.sortByMerchantName(data)
        this.accountsData = this.removeDuplicates(data)
        if (!this.currentProfile) {
          this.selectFirstSyncedMarketplace()
        }
      } else {
        this.accountsData = []
      }
    },
    defineCurrentMerchantAndCurrentProfile() {
      if (!this.currentMerchant && this.currentProfile && this.merchants) {
        const currMerchant =
          this.merchants &&
          this.merchants.filter(
            (m: Merchant) => m.name === this.currentProfile.name
          )[0]
        currMerchant && this.setMerchant(currMerchant)
      } else if (
        !this.currentMerchant &&
        !this.currentProfile &&
        this.merchants
      ) {
        const merchantsWithMarketplaces = this.merchants.filter(
          (m: Merchant) => m.marketplaces.length > 0
        )
        merchantsWithMarketplaces.length > 0 &&
          this.setMerchant(merchantsWithMarketplaces[0])
      } else if (
        this.currentMerchant &&
        this.currentProfile &&
        this.merchants
      ) {
        const currMerchant =
          this.merchants &&
          this.merchants.filter(
            (m: Merchant) => m.name === this.currentProfile.name
          )[0]
        currMerchant && this.setMerchant(currMerchant)
      } else if (this.currentMerchant && !this.currentProfile) {
        this.setProfile(this.currentMerchant.marketplaces[0])
      }
    },
    sortByMerchantName(data: Account[]): Account[] {
      return (
        data &&
        data.sort(function (a, b) {
          const nameA = a.title?.toUpperCase() // ignore upper and lowercase
          const nameB = b.title?.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      )
    },
    removeDuplicates(data: Account[]): (Account | undefined)[] {
      return Array.from(new Set(data.map((a: Account) => a.merchantId))).map(
        (merchantId) => {
          return data.find((a) => a.merchantId === merchantId)
        }
      )
    },
    setAccountAndMarkeplaceAsSelected(
      accountIndex: number,
      marketplaceIndex: number
    ) {
      const account = this.accountsData[accountIndex]
      if (account) {
        account.selected = true
        const marketplace = account.marketplaces[marketplaceIndex]
        marketplace.selected = true
        this.getProfileData(
          marketplace.profileId,
          marketplace.adsStatus,
          marketplace.ordersStatus
        )
      } else {
        this.setIsProfileLoaded(true)
      }
    },
    async getProfileData(
      profileId: number | string,
      adsStatus: string | null,
      ordersStatus: string | null
    ) {
      try {
        const profileEndpoint = `${
          (this as any).$env.NOZZLE_URL
        }/api/cached/profiles/${profileId}`
        const res = await fetch(profileEndpoint, { credentials: 'include' })
        const data = await res.json()
        this.setProfile({
          id: profileId,
          name: data.account.name,
          info: data.account.account_info_name,
          country: data.marketplace.country_code,
          currency: data.marketplace.currency_symbol,
          currencyCode: data.marketplace.currency_code,
          dataBoundaryMonth: data.marketplace.data_boundary.earliest_month,
          adsStatus: adsStatus,
          ordersStatus: ordersStatus,
        })
        this.setIsProfileLoaded(true)
      } catch (e) {}
    },
    selectFirstSyncedMarketplace(): void {
      const firstSyncedAccountIndex: number = this.accountsData.findIndex(
        (a: Account | undefined) =>
          a &&
          a.marketplaces.findIndex(
            (m: Marketplace) =>
              m.adsStatus === 'SYNCED' && m.ordersStatus === 'COMPLETE'
          ) > -1
      )
      const firstSyncedMarketplaceIndex: number | undefined =
        firstSyncedAccountIndex >= 0 &&
        this.accountsData[firstSyncedAccountIndex]?.marketplaces
          ? this.accountsData[firstSyncedAccountIndex]?.marketplaces.findIndex(
              (m: Marketplace) =>
                m.adsStatus === 'SYNCED' && m.ordersStatus === 'COMPLETE'
            )
          : undefined

      if (
        firstSyncedAccountIndex > -1 &&
        firstSyncedMarketplaceIndex !== undefined &&
        firstSyncedMarketplaceIndex > -1
      ) {
        this.setAccountAndMarkeplaceAsSelected(
          firstSyncedAccountIndex,
          firstSyncedMarketplaceIndex
        )
      } else {
        this.setIsProfileLoaded(true)
      }
    },
    updateProfile() {
      const currMerchant =
        this.merchants &&
        this.merchants.filter(
          (m: Merchant) => m.name === this.currentProfile.name
        )[0]
      const currProfile =
        currMerchant &&
        currMerchant.marketplaces.filter(
          (marketplace: any) => marketplace.id === +this.currentProfile.id
        )[0]
      currProfile &&
        this.setProfile({
          ...this.currentProfile,
          adsStatus: currProfile.adsStatus,
          ordersStatus: currProfile.ordersStatus,
        })
    },
    getCountryName(country: string): string {
      return getCountry(country)?.name ?? ''
    },
    handleAmazonRequestSuccess({ data }: { data: { amazon_users: any[] } }) {
      this.loading = false
      this.failed = false
      const accountsData = setAccounts(data.amazon_users)
      if (accountsData) this.setMerchants(accountsData?.merchants)
      this.selectorAccountData()
      this.currentProfile &&
        this.currentProfile.ordersStatus === undefined &&
        this.updateProfile()
    },
    handleAmazonRequestError() {
      this.failed = true
    },
  },
})
