export const state = () => ({
  merchant: null,
  marketplace: null,
  merchants: null,
})

export const getters = {
  merchant: (state) => state.merchant,
  marketplace: (state) => state.marketplace,
  merchants: (state) => state.merchants,
  merchantsOnboarded: (state) =>
    state.merchants?.filter((merchant) => merchant.marketplaces.length > 0) ||
    null,
  marketplacesAvailable: (state) =>
    state.merchants?.some((merchant) => merchant.marketplaces.length > 0) ||
    false,
  campaignById: (getters) => (id) =>
    getters.marketplace.campaigns?.find(
      (campaign) => campaign.amazon_campaign_id === id
    ) || null,
}

export const actions = {
  setMerchants({ commit }, merchants) {
    commit('merchants', merchants)
  },
  setMerchant({ commit }, merchantData) {
    commit('merchantData', merchantData)
  },
  setMarketplace({ commit }, marketplaceData) {
    commit('marketplaceData', marketplaceData)
  },
  getCurrentMerchant({ commit, state }, marketplaceId) {
    if (state.merchants) {
      const merchantData = state.merchants.find(
        (merchant) =>
          merchant.profiles.find(
            (marketplace) => marketplace.id === marketplaceId
          ) !== undefined
      )
      merchantData !== undefined
        ? commit('merchantData', merchantData)
        : commit('merchantData', null)
    }
  },
  getCurrentMarketplaceById({ commit, state }, profileId) {
    const marketplaceData =
      state.merchant?.profiles.find((profile) => profile.id === profileId) ||
      null
    commit('marketplaceData', marketplaceData)
  },
  updateMarketplaceCampaigns({ commit }, campaigns) {
    commit('marketplaceCampaigns', campaigns)
  },
}

export const mutations = {
  merchants(state, merchants) {
    state.merchants = merchants
  },
  merchantData(state, merchantData) {
    state.merchant = merchantData
  },
  marketplaceData(state, marketplace) {
    state.marketplace = marketplace
  },
  marketplaceCampaigns(state, campaigns) {
    state.marketplace.campaigns = campaigns
  },
}
