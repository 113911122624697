











import Vue from 'vue'
export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: false,
      default: '',
    },
    tag: {
      type: String,
      required: false,
      default: 'a',
    },
    type: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    currentProperties(): Record<string, string> {
      if (this.type === 'a') {
        return {
          href: this.to,
          to: '',
          tag: 'a',
        }
      } else {
        return {
          to: this.to,
        }
      }
    },
  },
})
