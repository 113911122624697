/* eslint-disable camelcase */
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from './index'

export type CACData = {
  period: string
  cac: number | null
  new_customers: number | null
  repeat_customers: number | null
  total_customers: number | null
  total_spend: number | null
  total_sales: number | null
  made_first_and_repeat_purchase: number | null
}

export type CohortMetrics = {
  ltv_to_period: number
  acc_sale: number
  ret_customers: number
  distinct_customers: number
  avg_profit_per_active_customer: number
  avg_sales_per_customer_to_period: number
  breakeven: boolean
  repeat_customers: number
  reporting_window: string
  reporting_window_offset: number
}

export type LTVData = {
  [key: string]: number | string | CohortMetrics[]
  cohort: string
  total_customers: number
  returning_customers: number
  repeat_customer_rate: number
  first_purchase_profit_per_customer: number
  metrics: CohortMetrics[]
}

export type reportingData = {
  break_even_acos: number
  first_purchase_break_even_acos: number
  ltv_to_period: number
  reporting_window_offset: number
}

export type FormattedLTVData = {
  [key: string]: number | null | string | CohortMetrics[]
  cohort: string
  total_customers: number
  returning_customers: number
  repeat_customer_rate: number
  first_purchase_profit_per_customer: number
  metrics: CohortMetrics[]
  total_spend: number | null
  cac: number | null
}
export type Profile = {
  country: string
  currency: string
  currencyCode: string
  dataBoundaryMonth: string | null
  id: string
  name: string
  info: string
  adsStatus: string | null | undefined
  ordersStatus: string | null | undefined
}

export type ASIN = string | number

export type ProductAsin = {
  product: string
  asin: string
  is_default_margin: boolean | undefined
}

export type ASINCategory = {
  asins: ASIN[]
  label: string
  id?: number
}

export type ASINItem = {
  name?: string
  label: string
  value: ASIN
  id?: number
}

type Month = {
  label: string
  value: Date
  disabled: boolean
}

export type DateRange = {
  from: Month | null
  to: Month | null
}

type AnalyticState = {
  isProfileLoaded: boolean
  profile?: Profile
  cac?: {
    data: CACData[]
    period: [string, string]
  }
  ltv?: {
    data: LTVData[]
    period: [string, string]
  }
  reporting?: {
    data: reportingData[]
  }
  filters: null | {
    dateRange: DateRange
    categorySelected: ASINCategory
  }
  asins: null | ASIN[]
  productAsins: null | ProductAsin[]
}

export const state = (): AnalyticState => ({
  isProfileLoaded: false,
  profile: undefined,
  cac: undefined,
  ltv: undefined,
  reporting: { data: [] },
  filters: null,
  asins: null,
  productAsins: [],
})

export type AnalyticModuleState = ReturnType<typeof state>

export const getters: GetterTree<AnalyticModuleState, RootState> = {
  currency: (state) => state.profile?.currency,
  cac: (state) => state.cac?.data,
  ltv: (state) => state.ltv?.data,
  dataBoundary: (state) => state.profile?.dataBoundaryMonth,
  getProfile: (state) => state.profile,
  getProfileName: (state) =>
    state.profile?.info
      ? state.profile?.info + ' (' + state.profile?.name + ')'
      : state.profile?.name,
  getReportingData: ({ reporting }) => reporting && reporting,
  getCurrencyCode: ({ profile }) => profile && profile.currencyCode,
  getFilters: (state) => state.filters,
  getAsins: (state) => state.asins,
  getIsProfileLoaded: (state) => state.isProfileLoaded,
  getProductAsins: (state) => state.productAsins,
}

export const actions: ActionTree<AnalyticModuleState, RootState> = {
  setProfile(
    { commit },
    payload: {
      id: string
      country: string
      currency: string
      currencyCode: string
      dataBoundaryMonth: string | null
      adsStatus: string | undefined
      ordersStatus: string | undefined
      name: string
      info: string | null
    }
  ) {
    commit('profile', payload)
  },
  setFilters(
    { commit },
    payload: { categorySelected: ASINCategory; dateRange: DateRange }
  ) {
    commit('filters', payload)
  },
  setCACData(
    { commit },
    payload: { data: CACData[]; period: [string, string] }
  ) {
    commit('CACData', payload)
  },
  setLTVData(
    { commit },
    payload: { data: LTVData[]; period: [string, string] }
  ) {
    commit('LTVData', payload)
  },
  setReportingData({ commit }, payload: { data: reportingData[] }) {
    commit('setReportingData', payload)
  },
  setASINS({ commit }, payload: { data: ASIN[] }) {
    commit('setASINS', payload)
  },
  setProductAsins({ commit }, payload: { data: ProductAsin[] }) {
    commit('setProductAsins', payload)
  },
  setIsProfileLoaded({ commit }, payload: boolean) {
    commit('setIsProfileLoaded', payload)
  },
  resetReportingData({ commit }) {
    commit('resetReportingData')
  },
  resetCAC({ commit }) {
    commit('resetCAC')
  },
  resetLTV({ commit }) {
    commit('resetLTV')
  },
  resetProfile({ commit }) {
    commit('resetProfile')
  },
}

export const mutations: MutationTree<AnalyticModuleState> = {
  // eslint-disable-next-line max-len
  profile(
    state,
    payload: {
      id: string
      country: string
      currency: string
      currencyCode: string
      dataBoundaryMonth: string | null
      adsStatus: string | null
      ordersStatus: string | null
      name: string
      info: string
    }
  ) {
    state.profile = payload
  },
  filters(
    state,
    payload: { categorySelected: ASINCategory; dateRange: DateRange }
  ) {
    state.filters = {
      categorySelected: payload.categorySelected,
      dateRange: payload.dateRange,
    }
  },
  resetFilters(state) {
    state.filters = null
  },
  CACData(state, payload: { data: CACData[]; period: [string, string] }) {
    state.cac = {
      data: payload.data,
      period: payload.period,
    }
  },

  LTVData(state, payload: { data: LTVData[]; period: [string, string] }) {
    state.ltv = {
      data: payload.data,
      period: payload.period,
    }
  },
  setReportingData(state, payload: { data: reportingData[] }) {
    state.reporting = { data: payload.data }
  },
  setASINS(state, payload: { data: ASIN[] }) {
    state.asins = payload.data
  },
  setProductAsins(state, payload: { data: ProductAsin[] }) {
    state.productAsins = payload.data
  },
  setIsProfileLoaded(state, payload: boolean) {
    state.isProfileLoaded = payload
  },
  resetCAC(state) {
    state.cac = undefined
  },
  resetLTV(state) {
    state.ltv = undefined
  },
  resetProfile(state) {
    state.profile = undefined
  },
  resetReportingData(state) {
    state.reporting = { data: [] }
  },
}
