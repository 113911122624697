














































import Vue from 'vue'
import ProgressCircular from '~/components/BaseProgressCircular.vue'

export default Vue.extend({
  components: { ProgressCircular },
  props: {
    title: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    primary: { type: Boolean, required: false, default: true },
    size: { type: String, required: false, default: 'md' },
    loading: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    variation: { type: String, required: false, default: 'rounded' },
    classList: { type: String, required: false, default: '' },
    tooltip: {
      type: Object,
      required: false,
      default: () => ({ on: false, msg: '' }),
    },
    link: { type: String, required: false, default: null },
    target: { type: String, required: false, default: '_self' },
    warning: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      showTitle: false,
    }
  },
  computed: {
    loaderTheme() {
      return this.primary
        ? { stroke: 'white', bg: 'transparent' }
        : { stroke: 'blue', bg: 'transparent' }
    },
    loaderSize() {
      return this.size === 'md' ? 18 : 15
    },
    tooltipTrigger() {
      return this.tooltip.on ? 'hover' : 'manual'
    },
    cssVars() {
      return {
        '--width': 'auto',
      }
    },
  },
})
