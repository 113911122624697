import { Country, getCountry } from './marketplace'

interface ProfilesData {
  campaigns_url: string
  country_code: string
  created_datetime: string | null
  data_start_date: string | null
  kind: string
  onboarded: boolean | null
  onboarded_percent: number | null
  onboarding_sp_orders_status: string
  profile_id: number
}

interface MerchantsData {
  amazon_seller_website: string | null
  kind: string
  merchant_id: string
  merchant_name: string
  merchant_info_name: string
  merchant_type: 'seller' | 'vendor'
  profiles: ProfilesData[]
}

interface AccountData {
  account_email: string
  amazon_user_id: string
  name: string
  id: string
  kind: string
  merchants: MerchantsData[]
  onboarded: boolean
}

/*
//////////////////////////
*/

interface NzzAccountData {
  id: string
  email: string
  type: string
  merchants: any
  name: string
  amazon_user_id: string
}

interface NzzMarkeplaceData {
  id: number
  marketplace: Country | undefined
  createdAt: string | null
  startAt: string | null
  campaigns: string
  country_code: string
  ordersStatus: string
  adsStatus: 'NOT SYNCED' | 'SYNCING' | 'SYNCED' | null
}
interface NzzMerchantData {
  id: string
  name: string
  storeName: string
  type: 'vendor' | 'seller'
  website: string | null
  marketplaces: NzzMarkeplaceData[]
}

interface ACC {
  accounts: NzzAccountData[]
  merchants: NzzMerchantData[]
}

export const checkIfUserHasReachedTrialLimitOfSyncs = (
  accounts: NzzAccountData[]
): boolean => {
  // check if user has at least one marketplace that has (at least) started syncing ads
  // if so that means that we need to block new syncs, which means, returning true

  let merchants = []
  let marketplace = []

  for (let i = 0; i < accounts.length; i++) {
    merchants = Object.entries(accounts[i])
    for (let f = 0; f < merchants.length; f++) {
      if (merchants[f][0] === 'merchants') {
        let marketplaceObj = Object.entries(merchants[f][1])
        for (let j = 0; j < marketplaceObj.length; j++) {
          marketplace = Object.entries(marketplaceObj[j][1])
          for (let k = 0; k < marketplace.length; k++) {
            if (marketplace[k][0] === 'marketplaces') {
              for (let l = 0; l < marketplace[k][1].length; l++) {
                if (
                  marketplace[k][1][l].onboardedProgress !== null &&
                  marketplace[k][1][l].onboardedProgress >= 0
                ) {
                  return true
                }
              }
            }
          }
        }
      }
    }
  }
  return false
}

export const checkIfUserHasReachedTrialLimitOfOrdersAuthorizations = (
  accounts: NzzAccountData[]
): boolean => {
  // check if user has at least one marketplace that has (at least) started the authorization process for orders
  // if so that means that we need to block new authorize process, which means, returning true

  let merchants = []
  let marketplace = []

  for (let i = 0; i < accounts.length; i++) {
    merchants = Object.entries(accounts[i])
    for (let f = 0; f < merchants.length; f++) {
      if (merchants[f][0] === 'merchants') {
        let marketplaceObj = Object.entries(merchants[f][1])
        for (let j = 0; j < marketplaceObj.length; j++) {
          marketplace = Object.entries(marketplaceObj[j][1])
          for (let k = 0; k < marketplace.length; k++) {
            if (marketplace[k][0] === 'marketplaces') {
              for (let l = 0; l < marketplace[k][1].length; l++) {
                if (
                  marketplace[k][1][l].onboardingSPOrdersStatus === 'PENDING' ||
                  marketplace[k][1][l].onboardingSPOrdersStatus === 'COMPLETE'
                )
                  return true
              }
            }
          }
        }
      }
    }
  }
  return false
}

export const setAccounts = (data: AccountData[]) => {
  return data?.reduce(
    (acc: ACC, account: AccountData) => {
      /**
       * * Important: As we only support seller merchants type
       * * we are filtering the amazon data response in order not only hide other types of merchants
       * * but also making sure we hide accounts that have not seller merchants (as it would look empty)
       */

      // filter merchant by type (only allowing sellers)
      const sellers = account.merchants.filter(
        (merchant) => merchant.merchant_type === 'seller'
      )
      if (sellers.length > 0) {
        // only add account if it is has seller merchants
        // this warranties that an account would show empty as we only are showing seller type
        acc.accounts.push({
          id: account.id,
          amazon_user_id: account.amazon_user_id,
          email: account.account_email,
          name: account.name,
          type: account.kind,
          merchants: {},
        })
        // we are only saving seller merchants from amazon_users response
        sellers.forEach((merchant) => {
          const availableMarketplaces: NzzMarkeplaceData[] = []
          const accountFound: NzzAccountData | undefined = acc.accounts.find(
            ({ id }) => account.id === id
          )

          // Set merchant data if the profile is onboarded
          merchant.profiles.forEach((profile) => {
            const profileRef = {
              id: profile.profile_id,
              name: profile.country_code,
              onboarded: profile.onboarded,
              onboardedProgress: profile.onboarded_percent,
              onboardingSPOrdersStatus: profile.onboarding_sp_orders_status,
              requesting: null,
              countryCode: profile.country_code,
            }

            let adsStatus: 'NOT SYNCED' | 'SYNCING' | 'SYNCED' | null = null
            if (!profile.onboarded && !profile.onboarded_percent) {
              adsStatus = 'NOT SYNCED'
            } else if (
              !profile.onboarded &&
              profile.onboarded_percent &&
              profile.onboarded_percent < 100
            ) {
              adsStatus = 'SYNCING'
            } else if (profile.onboarded) {
              adsStatus = 'SYNCED'
            }

            availableMarketplaces.push({
              id: profile.profile_id,
              marketplace: getCountry(profile.country_code),
              createdAt: profile.created_datetime,
              startAt: profile.data_start_date,
              campaigns: profile.campaigns_url,
              country_code: profile.country_code,
              ordersStatus: profile.onboarding_sp_orders_status,
              adsStatus,
            })

            if (accountFound) {
              // Otherwise it keeps a reference to the onboarding state
              if (!accountFound.merchants[merchant.merchant_id]) {
                accountFound.merchants[merchant.merchant_id] = {
                  name: merchant.merchant_name,
                  storeName: merchant.merchant_info_name,
                  marketplaces: [profileRef],
                  type: merchant.merchant_type,
                  website:
                    merchant.merchant_type === 'seller'
                      ? merchant.amazon_seller_website
                      : null,
                }
              } else {
                accountFound.merchants[merchant.merchant_id].marketplaces.push(
                  profileRef
                )
              }
            }
          })
          // we are only saving seller merchants from amazon_users response
          if (merchant.merchant_type === 'seller') {
            acc.merchants.push({
              id: merchant.merchant_id,
              name: merchant.merchant_name,
              storeName: merchant.merchant_info_name,
              type: merchant.merchant_type,
              website: merchant.amazon_seller_website,
              marketplaces: availableMarketplaces,
            })
          }
        })
      }

      return acc
    },
    { accounts: [], merchants: [] }
  )
}
