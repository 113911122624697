<script>
export default {
  props: {
    trigger: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    const outsideListener = (e) => {
      if (e.target !== this.$el && this.$el && !this.$el.contains(e.target)) {
        this.trigger()
      }
    }

    document.addEventListener('click', outsideListener)

    this.$once('hook:beforeDestroy', function () {
      document.removeEventListener('click', outsideListener)
    })
  },
  render() {
    return this.$slots.default[0]
  },
}
</script>
