<template>
  <api-data
    ref="api"
    :url="request.url"
    :on="canFetch"
    :body="request.body"
    :retry="request.retry"
    :method="request.method"
    @success="request.onSuccess"
    @error="request.onError"
  >
    <div>
      <Header
        v-if="$route && $route.params.userEmail"
        title="Settings for external user"
        :subtitle="
          $route.params.userEmail + ' || status: ' + paymentStatusOfExternalUser
        "
      />
      <Header v-else title="Settings" :subtitle="userEmail" />
      <div id="settings" class="container">
        <loader v-model="isLoading" message="Fetching account data" />
        <div v-if="!isLoading" class="settings">
          <!-- FETCH ACCOUNTS ERROR MESSAGE !-->
          <base-toaster-message
            v-if="isFetchAccountErrorShown"
            type="error"
            data-role="fetch-accounts-error-message"
            :message-title="errors.accounts.title"
            :message-content="errors.accounts.content"
            @message-timeout="isFetchAccountErrorShown = false"
          />
          <!-- AUTH ORDERS SUCCESS MESSAGE !-->
          <base-toaster-message
            v-if="isAuthOrdersSuccessMessageShown"
            type="success"
            data-role="auth-orders-success-message"
            @message-timeout="isAuthOrdersSuccessMessageShown = false"
          >
            <span slot="message"
              ><b>We've started to download your orders data.</b> This may take
              24-48 hours. Check back soon.</span
            >
          </base-toaster-message>

          <!-- AUTH ORDERS ERROR MESSAGE !-->
          <base-toaster-message
            v-if="isAuthOrdersErrorMessageShown"
            type="error"
            data-role="auth-orders-error-message"
            @message-timeout="isAuthOrdersErrorMessageShown = false"
          >
            <span slot="message"
              ><b>Authorization failed.</b> We couldn’t access your orders data.
              Check that you are the root account holder or try again</span
            >
          </base-toaster-message>

          <!-- AMAZON DATA BOX !-->
          <div class="box py-6 px-8 mb-8">
            <div class="d-flex align-start justify-space-between pb-4">
              <div>
                <h2 class="primary--text font-weight-bold text-lg mb-2">
                  Amazon data
                </h2>
                <p v-if="accounts && accounts.length > 0" class="mb-0">
                  Select which marketplaces you would like to sync. <br />
                  Note that we only support Seller accounts.
                </p>
              </div>
              <base-button
                v-if="accounts && accounts.length > 0"
                label="Link Amazon account"
                class="js-new-account"
                data-role="link-amazon-account-btn"
                @on-click="toggleAccountForm"
              />
            </div>

            <div class="settings-main">
              <!-- LINKING ACCOUNT ERROR MESSAGE !-->
              <base-toaster-message
                v-if="isLinkAccountErrorShown"
                type="error"
                data-role="link-account-error-message"
                :message-title="errors.linkAccount.title"
                :message-content="errors.linkAccount.content"
                @message-timeout="() => (isLinkAccountErrorShown = false)"
              />
              <!-- PROFILE ADS SYNC ERROR MESSAGE !-->
              <base-toaster-message
                v-for="err in profileSyncErrors"
                :key="'error-syncing-ads-for-profile-' + err.id"
                type="error"
                data-role="sync-profile-ads-error-message"
                :message-title="err.title"
                :message-content="err.content"
                @message-timeout="removeErrorFromProfileSyncErrors(err.id)"
              />

              <!-- ALERT IF NO ACCOUNTS && CONSULTING EXTERNAL USER SETTINGS !-->
              <Alert
                v-if="
                  accounts &&
                  accounts.length === 0 &&
                  $route &&
                  $route.params.userEmail
                "
                name="ads-data-alert-empty"
                msg-title="Link your Amazon data to use Customer Analytics"
                msg-text="We can only add Seller accounts and not Vendor or Agency accounts"
                data-role="no-accounts-alert"
                :no-button="true"
              />
              <!-- ALERT IF NO ACCOUNTS !-->
              <Alert
                v-else-if="accounts && accounts.length === 0"
                name="ads-data-alert-empty"
                msg-title="Link your Amazon data to use Customer Analytics"
                msg-text="We can only add Seller accounts and not Vendor or Agency accounts"
                button-label="Link Amazon account"
                data-role="no-accounts-alert"
                @add-account="toggleAccountForm"
              />

              <!-- We are CONSULTING EXTERNAL USER SETTINGS and we do have results !-->
              <div v-else-if="accounts && $route && $route.params.userEmail">
                <account-preview
                  v-for="account in accounts"
                  :key="account.email"
                  :account="account"
                  :is-to-block-new-syncs="
                    reachTrialLimitOfSyncs &&
                    paymentStatusOfExternalUser === 'trialing'
                  "
                  :is-to-block-new-authorize-orders="
                    reachTrialLimitOfAuthorizedMarketplaces &&
                    paymentStatusOfExternalUser === 'trialing'
                  "
                  data-role="account-preview"
                  :payment-status="paymentStatusOfExternalUser"
                  @onboard-profile="onboardProfile"
                  @auth-and-sync-orders="authAndSyncOrders"
                />
              </div>

              <!-- We are accessing settings ACCOUNTS of the logged user and we have results to show !-->
              <div v-else>
                <account-preview
                  v-for="account in accounts"
                  :key="account.email"
                  :account="account"
                  :is-to-block-new-syncs="
                    reachTrialLimitOfSyncs && userPaymentStatus === 'trialing'
                  "
                  :is-to-block-new-authorize-orders="
                    reachTrialLimitOfAuthorizedMarketplaces &&
                    userPaymentStatus === 'trialing'
                  "
                  data-role="account-preview"
                  :payment-status="userPaymentStatus"
                  @onboard-profile="onboardProfile"
                  @auth-and-sync-orders="authAndSyncOrders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MODALS !-->
      <modal v-model="isAccountFormShown" :width="375">
        <template #modal="{ handleToggle }">
          <account-form data-role="account-form" @on-cancel="handleToggle" />
        </template>
      </modal>
    </div>
  </api-data>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ApiData from '@/components/logic/ApiData'
import Loader from '@/components/BaseLoader'
import BaseButton from '@/components/BaseButton'
import AccountPreview from '@/components/account/AccountPreview.vue'
import Modal from '@/components/BaseModal'
import AccountForm from '~/components/account/AccountForm.vue'
import Header from '~/components/BaseHeader.vue'
import Alert from '~/components/alerts/Alert.vue'
import BaseToasterMessage from '~/components/BaseToasterMessage.vue'

export const errors = {
  accounts: {
    title: "We couldn't fetch your accounts at this moment.",
    content:
      'Please try again in a few minutes, or contact us using the chat button below for help.',
  },
  onboarding: {
    title: "We can't link this profile unfortunately.",
    content: 'Contact support for help on this.',
  },
  linkAccount: {
    title: 'Unfortunately there are some issues connecting your data.',
    content: "We're looking into the issue so check back soon.",
  },
}

export default {
  components: {
    ApiData,
    BaseButton,
    AccountForm,
    AccountPreview,
    Loader,
    Modal,
    Header,
    Alert,
    BaseToasterMessage,
  },
  data() {
    return {
      canFetch: false,
      errors: errors,
      profileSyncErrors: [],
      isLoading: true,
      isAccountFormShown: false,
      isAuthOrdersSuccessMessageShown: false,
      isAuthOrdersErrorMessageShown: false,
      isFetchAccountErrorShown: false,
      isLinkAccountErrorShown: false,
      getUserAccountsByIdError: '',
      paymentStatusOfExternalUser: null,
      userIdOfExternalUser: null,
      request: {
        url: '',
        onSuccess: () => {},
        onError: () => {},
      },
    }
  },
  head() {
    return {
      title: 'Settings',
    }
  },
  computed: {
    ...mapGetters({
      accounts: 'user/accounts',
      onboarding: 'user/onboarding',
      hasAnalytics: 'user/hasAnalytics',
      userEmail: 'user/getEmail',
      userPaymentStatus: 'user/getPaymentStatus',
      reachTrialLimitOfSyncs: 'user/getReachTrialLimitOfSyncs',
      reachTrialLimitOfAuthorizedMarketplaces:
        'user/getReachTrialLimitOfAuthorizedMarketplaces',
    }),
    wasCallbackSuccessful() {
      return this.$route && this.$route.query.lwa_callback === 'true'
    },
    isAuthOrdersCallback() {
      return this.$route && this.$route.query.orders_callback === 'true'
    },
  },

  mounted() {
    if (this.$route && this.$route.params.userEmail) {
      this.getUserPaymentStatusByUserEmail(this.$route.params.userEmail)
    }
    if (this.wasCallbackSuccessful) {
      this.linkAccount()
    } else if (this.isAuthOrdersCallback) {
      if (this.$route && !this.$route.query.error) {
        this.isAuthOrdersSuccessMessageShown = true
        this.getAccounts()
      } else {
        this.isAuthOrdersErrorMessageShown = true
        this.getAccounts()
      }
    } else {
      this.getAccounts()
    }
  },
  methods: {
    ...mapActions({
      setAmazonAccounts: 'user/setAmazonAccounts',
      updateMarketplaceStatus: 'user/updateMarketplaceStatus',
    }),
    removeErrorFromProfileSyncErrors(id) {
      this.profileSyncErrors = this.profileSyncErrors.filter(
        (obj) => obj.id !== id
      )
    },
    getAccounts() {
      let reqUrl = 'user/amazon_users'
      if (this.$route && this.$route.params.userEmail) {
        if (this.userIdOfExternalUser) {
          reqUrl = `user/amazon_users/${this.userIdOfExternalUser}/`
        } else return
      }

      this.request = {
        url: reqUrl,
        method: 'get',
        retry: { type: 'none', limit: 0, timer: 0 },
        onSuccess: (response) => {
          if (response.data.Error) {
            this.isFetchAccountErrorShown = true
            this.isLoading = false
            this.getUserAccountsByIdError = response.data.Error
            return
          }

          if (response.data.amazon_users) {
            this.setAmazonAccounts(response.data.amazon_users)
          }
          this.isLoading = false
          this.canFetch = false
        },
        onError: () => {
          this.isFetchAccountErrorShown = true
          this.isLoading = false
          this.canFetch = false
        },
      }
      this.canFetch = true
    },
    async getUserPaymentStatusByUserEmail(userEmail) {
      const response = await this.$axios
        .get('/user/email/' + userEmail)
        .catch((error) => {
          if (error.response && error.response.status) {
            if (error.response.status === 401) {
              this.$nuxt.error({
                status: 'auth',
                message: 'You are not authorized to view this page',
                link: '/user/account',
                label: 'Settings',
              })
            } else if (error.response.status === 404) {
              this.$nuxt.error({
                message: 'User not found.',
                link: '/user/account',
                label: 'Settings',
              })
            }
            this.userIdOfExternalUser = null
          }
        })

      if (response?.status === 200) {
        this.paymentStatusOfExternalUser = response.data.payment_status
        this.userIdOfExternalUser = response.data.id
        this.getAccounts()
      }
    },

    async linkAccount() {
      try {
        const response = await this.$axios.get(
          '/onboarding/update-advertising-profiles'
        )
        if (response?.status >= 200 && response?.status < 300) {
          this.isLinkAccountErrorShown = false
          this.getAccounts()
        }
      } catch (e) {
        if (e.statusCode >= 500) {
          // if it's a server side error, try again...
          setTimeout(function () {
            this.linkAccount()
          }, 3000)
        } else {
          // if it's not on the server side, stop isLoading, show a message error
          // and the accounts that we can access
          this.isLoading = false
          this.isLinkAccountErrorShown = true
          this.getAccounts()
        }
      }
    },
    onboardProfile(data) {
      // Emitted by clicking on marketplace "SYNC ADS" button
      this.syncProfileAds(data)
    },
    async syncProfileAds(data) {
      try {
        this.updateMarketplaceStatus({ ...data, requesting: true })
        const response = await this.$axios.post('onboarding/profile', {
          profile_id: data.profileId,
          lwa_profile_user_id: data.accountId,
        })
        if (response && response.status >= 200 && response.status < 300) {
          this.updateMarketplaceStatus({ ...data, requesting: false })
          this.getAccounts()
        } else if (response?.status >= 500) {
          throw new Error(
            "Error occurred while posting 'onboarding/profile'. " +
              response?.text()
          )
        }
      } catch (e) {
        this.updateMarketplaceStatus({ ...data, requesting: false })
        this.profileSyncErrors.push({
          ...errors.onboarding,
          id: data.profileId,
        })
        this.getAccounts()
      }
    },
    toggleAccountForm() {
      this.isAccountFormShown = !this.isAccountFormShown
    },
    async authAndSyncOrders(data) {
      const url = `/sp/authorise/?country_code=${
        data.country_code
      }&merchant_id=${data.merchant_id}&amazon_user_id=${
        data.amazon_user_id
      }&next_url=${
        window.location.origin + window.location.pathname
      }?orders_callback=true`
      try {
        const { data } = await this.$axios.get(url)
        window.location.assign(data.uri)
      } catch (e) {
        this.isAuthOrdersErrorMessageShown = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.settings {
  &-main,
  &-alert {
    grid-column: 1 / span 2;
  }
}
.has-background-light-grey {
  background: $white-1;
}
</style>
