























export default {
  name: 'BaseBannerMessaging',
  props: {
    dismissible: { type: Boolean, required: false, default: false },
    classList: { type: String, required: false, default: '' },
  },
  data: function () {
    return {
      show: true,
    }
  },
}
