














































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import AuditIcon from '../icons/Audit.vue'
import AnalyticsIcon from '../icons/Analytics.vue'
import WhatsNewIcon from '../icons/WhatsNew.vue'
import MenuItem from './MenuItem.vue'
import MenuItemExpanded from './MenuItemExpanded.vue'
import MenuDropdown from './MenuDropdown.vue'
export default Vue.extend({
  components: {
    AuditIcon,
    AnalyticsIcon,
    WhatsNewIcon,
    MenuItem,
    MenuItemExpanded,
    MenuDropdown,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): {
    items: any[] | null
  } {
    return {
      items: null,
    }
  },
  computed: {
    ...mapGetters({
      hasProAccount: 'user/hasProAccount',
      profile: 'analytics/getProfile',
    }),
    menuItems(): any[] {
      const output = []
      const profileId: number | undefined = this.profile?.id

      output.push(
        {
          title: 'Performance Summary',
          link: `/analytics/${profileId}`,
        },
        {
          title: 'Customer Retention',
          link: `/analytics/${profileId}/customer-retention`,
        },
        {
          title: 'Purchase Intervals',
          link: `/analytics/${profileId}/purchase-intervals`,
        },
        {
          title: 'Purchase Analysis',
          link: `/analytics/${profileId}/purchase-analysis`,
        },
        {
          title: 'Cohort Analysis',
          link: `/analytics/${profileId}/cohort-analysis`,
        }
      )

      return output
    },
  },
  watch: {
    profile() {
      this.items = [...this.menuItems]
    },
  },
})
