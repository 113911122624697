









































export default {
  name: 'AppAccordion',
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen
      this.$emit('process')
    },
  },
}
