var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show === true)?_c('div',{class:[
    'toaster-message',
    'toaster-message--' + _vm.type,
    'd-flex',
    'justify-space-between',
    _vm.isHidden ? 'hidden' : '' ],attrs:{"id":_vm.id},on:{"mouseover":_vm.stopCounter,"mouseleave":_vm.setCounter,"focus":_vm.stopCounter,"blur":_vm.setCounter}},[_c('span',{staticClass:"message-body text-left"},[(_vm.type === 'error')?_c('span',[_c('fa',{class:[_vm.type + '-icon', 'text-md'],attrs:{"icon":['fal', 'exclamation-circle']}})],1):_vm._e(),_vm._v(" "),(_vm.type === 'warning')?_c('span',[_c('fa',{class:[_vm.type + '-icon', 'text-md'],attrs:{"icon":['fal', 'exclamation-triangle']}})],1):_vm._e(),_vm._v(" "),(_vm.type === 'info')?_c('span',[_c('fa',{class:[_vm.type + '-icon', 'text-md'],attrs:{"icon":['fal', 'info-circle']}})],1):_vm._e(),_vm._v(" "),(_vm.type === 'success')?_c('span',[_c('fa',{class:[_vm.type + '-icon', 'text-md'],attrs:{"icon":['fal', 'check-circle']}})],1):_vm._e(),_vm._v(" "),_vm._t("message"),_vm._v(" "),(!_vm.$slots['message'])?_c('span',{},[_c('b',[_vm._v(_vm._s(this.messageTitle))]),_vm._v(" "),(
          (this.messageTitle + '  ' + this.messageContent).length >
          this.oneLineMaxMessageLenght
        )?_c('br'):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.$slots['message'])?_c('span',{class:(this.messageTitle + '  ' + this.messageContent).length >
        this.oneLineMaxMessageLenght
          ? 'pl-6'
          : 'pl-1'},[_vm._v("\n      "+_vm._s(this.messageContent)+"\n    ")]):_vm._e()],2),_vm._v(" "),_c('div',[(_vm.actionTitle && _vm.actionLink)?_c('nuxt-link',{staticClass:"toaster-message__action pr-1",attrs:{"to":_vm.actionLink}},[_vm._v("\n      "+_vm._s(_vm.actionTitle))]):_vm._e(),_vm._v(" "),(_vm.dismissible)?_c('span',{staticClass:"close-btn",on:{"click":_vm.closingMessage}},[_c('fa',{staticClass:"text-md",attrs:{"icon":['fal', 'times']}})],1):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }