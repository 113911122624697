<template>
  <base-card>
    <template #content>
      <div class="font-weight-bold">We couldn't submit this audit</div>
    </template>
  </base-card>
</template>
<script>
import BaseCard from '../../BaseCard'
export default {
  components: { BaseCard },
}
</script>
