<template>
  <div>
    <component :is="component" @add-account="toggleAccountForm" />
    <SuggestionList title="Need help getting started?" class="mg-top-xlg" />
    <modal v-model="showAccountForm" :width="350" :height="510">
      <template #modal="{ handleToggle }">
        <account-form @on-cancel="handleToggle" />
      </template>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AccountLink from './AccountLink'
import ProfileLink from './ProfileLink'
import ProfileOnboarding from './ProfileOnboarding'
import AccountReady from './AccountReady'
import AccountForm from '~/components/account/AccountForm'
import SuggestionList from '~/components/BaseSuggestionList.vue'
import Modal from '~/components/BaseModal.vue'
export default {
  components: {
    AccountLink,
    ProfileLink,
    ProfileOnboarding,
    AccountReady,
    SuggestionList,
    AccountForm,
    Modal,
  },
  data() {
    return {
      showAccountForm: false,
    }
  },
  computed: {
    ...mapGetters({
      isOnboarding: 'user/onboarding',
      accounts: 'user/accounts',
      audits: 'audit/audits',
      marketplacesAvailable: 'merchants/marketplacesAvailable',
    }),
    accountAvailable() {
      return this.accounts && this.accounts.length >= 1
    },

    component() {
      if (!this.accountAvailable) {
        return 'account-link'
      } else if (!this.marketplacesAvailable) {
        return this.isOnboarding ? 'profile-onboarding' : 'profile-link'
      } else {
        return 'account-ready'
      }
    },
  },
  methods: {
    toggleAccountForm() {
      this.showAccountForm = !this.showAccountForm
    },
  },
}
</script>
