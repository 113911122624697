<template>
  <div class="circular-spinner" :style="cssVars" data-role="progress-spinner" />
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 40,
    },
    theme: {
      type: Object,
      required: true,
    },
    stroke: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  computed: {
    cssVars() {
      return {
        '--width': `${this.width}px`,
        '--height': `${this.width}px`,
        '--stroke': `${this.stroke}px`,
        '--stroke-color': this.theme.stroke,
        '--bg-color': this.theme.bg,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.circular-spinner {
  width: var(--width);
  height: var(--height);
  border-radius: 50%;
  border: var(--stroke) solid var(--stroke-color);
  border-left-color: var(--bg-color);
  animation: spinner-animation 0.5s infinite linear;
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
