<template>
  <v-app class="app-layout-no-nav-background">
    <div class="app-layout-no-nav">
      <div style="align-self: center; justify-self: center">
        <nuxt-link to="/">
          <base-image src="nozzle-logo-white.svg" width="140" height="auto" />
        </nuxt-link>
      </div>
      <main>
        <nuxt />
      </main>
    </div>
  </v-app>
</template>
<script>
import baseImage from '~/components/BaseImage.vue'

export default {
  components: {
    baseImage,
  },
  props: {
    theme: {
      type: String,
      required: false,
      default: () => 'dark',
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100vw;
  padding: 1rem 0;
}
.app-layout-no-nav {
  display: grid;
  padding: 2rem 0;
}
</style>
