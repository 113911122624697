






































































































/* eslint-disable camelcase */

import Vue from 'vue'
import { reportingData } from '~/store/analytics'
import { formatCurrency } from '~/utils/currency'

export default Vue.extend({
  computed: {
    currency(): string {
      return this.$store.getters['analytics/currency']
    },
    currencyCode(): string {
      return this.$store.getters['analytics/getCurrencyCode']
    },
    defaultLocale(): string {
      return Intl.NumberFormat().resolvedOptions().locale
    },
    reportingData(): reportingData[] {
      return this.$store.getters['analytics/getReportingData'].data
    },
    formattedRows() {
      const data: reportingData[] = this.reportingData
      // Define months we want to display reporting data for
      const timeFrames = [0, 3, 6, 12, 24]

      /**
       * Format rows with the first row being labels and we can extend the rows in the future by expanding this array
       */
      const rows: any[] = [
        {
          name: 'Monthly averages',
          info: null,
          values: [
            'First purchase',
            '3 months',
            '6 months',
            '12 months',
            '24 months',
          ],
        },
        {
          name: 'Lifetime value',
          info: 'The average amount of profit you make per customer over their lifetime.',
          values: data
            .filter(({ reporting_window_offset }) =>
              timeFrames.includes(reporting_window_offset)
            )
            .map(({ ltv_to_period }) =>
              formatCurrency(
                this.currencyCode,
                this.defaultLocale,
                +ltv_to_period
              )
            ),
        },
        {
          name: 'Break even ACoS',
          info: 'This is the maximum ACoS you could reach and still break even. It is the same as your pre-advertising margin.',
          values: data
            .filter(({ reporting_window_offset }) =>
              timeFrames.includes(reporting_window_offset)
            )
            .map(
              ({ first_purchase_break_even_acos, break_even_acos }, index) => {
                if (index === 0)
                  return `${(first_purchase_break_even_acos * 100).toFixed()}%`
                return `${(break_even_acos * 100).toFixed()}%`
              }
            ),
        },
      ]

      /**
       * If values are not loaded yet or we do not have data for that month let's just fill it with '--'
       */
      rows.map(({ values }) => {
        const fillEmptyVal = () => {
          if (values.length < 5) {
            values.push('--')
            fillEmptyVal()
          }
        }
        fillEmptyVal()
        return values
      })

      return rows
    },
  },
})
