var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary-wrapper columns is-multiline is-variable is-2"},[_c('div',{staticClass:"column mb-4 is-full-mobile is-full-tablet"},[_c('div',{staticClass:"box text-center pd-xsm h-100pct"},[_c('div',{staticClass:"summary px-5 pb-5"},_vm._l((_vm.formattedRows),function(row,id){return _c('div',{key:id,staticClass:"summary-column",attrs:{"data-role":"analytics-summary-row"}},[_c('div',{staticClass:"summary-name"},[_vm._v("\n            "+_vm._s(row.name)+"\n            "),(row.info)?_c('fa',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                content: ("<h2>" + (row.name) + "</h2>  <br/>\n                <span>" + (row.info) + "</span>"),
                classes: ['tooltip tooltip-primary-top-arrow'],
              }),expression:"{\n                content: `<h2>${row.name}</h2>  <br/>\n                <span>${row.info}</span>`,\n                classes: ['tooltip tooltip-primary-top-arrow'],\n              }",modifiers:{"bottom":true}}],staticClass:"cursor-pointer",attrs:{"icon":['fal', 'info-circle']}}):_vm._e()],1),_vm._v(" "),_vm._l((row.values),function(value,key){return _c('div',{key:key,staticClass:"text-sm text-left"},[_vm._v("\n            "+_vm._s(value)+"\n          ")])})],2)}),0)])]),_vm._v(" "),_c('div',{staticClass:"column mb-4 is-one-fifth-desktop",staticStyle:{"display":"none"}},[_c('div',{staticClass:"box text-center pd-xsm h-100pct"},[_c('div',{staticClass:"px-4 py-4"},[_c('div',{staticClass:"text-md font-weight-bold"},[_vm._v("\n          Total Sales\n          "),_c('fa',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: "<h2>Total Sales</h2>  <br/>\n              <span>The total revenue generated (ads & organic) split by customers\n                who purchased once and those who purchased multiple times</span>",
              classes: ['tooltip tooltip-primary-top-arrow'],
            }),expression:"{\n              content: `<h2>Total Sales</h2>  <br/>\n              <span>The total revenue generated (ads & organic) split by customers\n                who purchased once and those who purchased multiple times</span>`,\n              classes: ['tooltip tooltip-primary-top-arrow'],\n            }",modifiers:{"bottom":true}}],staticClass:"cursor-pointer",attrs:{"icon":['fal', 'info-circle']}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',{staticClass:"text-lg mg-btm-md font-weight-light"},[_vm._v("£ --")]),_vm._v(" "),_c('p',{staticClass:"text-sm mb-2"},[_vm._v("One time purchasers: £ --")]),_vm._v(" "),_c('p',{staticClass:"text-sm mb-2"},[_vm._v("Repeat customers: £ --")])])])]),_vm._v(" "),_c('div',{staticClass:"column mb-4 is-one-fifth-desktop justify-space-between h-100pct",staticStyle:{"display":"none"}},[_c('div',{staticClass:"box text-center pd-xsm mb-4"},[_c('div',{staticClass:"px-4 py-1"},[_c('div',{staticClass:"text-md font-weight-bold"},[_vm._v("\n          Avg repeat purchase rate (R)\n          "),_c('fa',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: "<h2>Repeat purchase rate</h2>  <br/>\n              <span>The percentage of your customers who purchased more than once for your chosen time period</span>",
              classes: ['tooltip tooltip-primary-top-arrow'],
            }),expression:"{\n              content: `<h2>Repeat purchase rate</h2>  <br/>\n              <span>The percentage of your customers who purchased more than once for your chosen time period</span>`,\n              classes: ['tooltip tooltip-primary-top-arrow'],\n            }",modifiers:{"bottom":true}}],staticClass:"cursor-pointer",attrs:{"icon":['fal', 'info-circle']}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',{staticClass:"text-lg font-weight-light"},[_vm._v("- %")])])]),_vm._v(" "),_c('div',{staticClass:"box text-center pd-xsm"},[_c('div',{staticClass:"px-4 pt-1"},[_c('div',{staticClass:"text-md font-weight-bold"},[_vm._v("\n          Avg number of purchases\n          "),_c('fa',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: "<h2>Avg number of purchases</h2>  <br/>\n              <span>The average number of purchases per customer over their lifetime</span>",
              classes: ['tooltip tooltip-primary-top-arrow'],
            }),expression:"{\n              content: `<h2>Avg number of purchases</h2>  <br/>\n              <span>The average number of purchases per customer over their lifetime</span>`,\n              classes: ['tooltip tooltip-primary-top-arrow'],\n            }",modifiers:{"bottom":true}}],staticClass:"cursor-pointer",attrs:{"icon":['fal', 'info-circle']}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',{staticClass:"text-lg font-weight-light mb-0 pb-1"},[_vm._v("-")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }