import Vue from 'vue'
import { capitalize } from '~/utils/format'

Vue.directive('capitalize', {
  bind(el) {
    el.textContent = capitalize(el.textContent)
  },
})

Vue.directive('uppercase', {
  bind(el) {
    el.textContent = el.textContent.toUpperCase()
  },
})
