









































import Vue, { PropType } from 'vue'

import * as d3 from 'd3'

import { ChartData, ChartDataPoint } from './types'
import { CHART_SIZE } from './sizes'
import {
  AxisX,
  AxisXTitle,
  AxisY,
  AxisYTitle,
  DataSeriesBars,
  DataSeriesLine,
  Legend,
  Title,
  Tooltip,
} from './components'
import NoDataChart from './NoDataChart.vue'

export default Vue.extend({
  name: 'PurchaseIntervalsChart',
  components: {
    AxisX,
    AxisXTitle,
    AxisY,
    AxisYTitle,
    DataSeriesBars,
    DataSeriesLine,
    Legend,
    Title,
    Tooltip,
    NoDataChart,
  },
  props: {
    groupedPurchaseIntervals: {
      type: Array as PropType<GroupedPurchaseIntervals>,
      required: true,
    },
  },
  data: () => ({
    CHART_SIZE,
    axisXScale: {} as d3.ScaleBand<string>,
    axisYScale: {} as d3.ScaleLinear<number, number>,
    chart: {} as SVGSVGElement,
    chartData: [] as ChartData,
    isReadyToDrawChart: false as boolean,
  }),
  watch: {
    customerRetentionData: {
      immediate: true,
      handler(): void {
        this.isReadyToDrawChart = false

        this.createChartData()
        this.createXAxisScale()
        this.createYAxisScale()

        this.isReadyToDrawChart = true
      },
    },
  },
  mounted() {
    this.chart = this.$refs.chart as SVGSVGElement
  },
  methods: {
    createChartData(): void {
      if (this.groupedPurchaseIntervals.length > 0) {
        this.chartData = this.groupedPurchaseIntervals.map((dataPoint) => ({
          xValue: dataPoint.intervalsBucket,
          yBarValue: dataPoint.customersNum.percent,
          yLineValue: dataPoint.customersNum.cumulativePercent,
        }))
      }
    },
    createXAxisScale(): void {
      const domain = this.chartData.map((dataPoint) => dataPoint.xValue)
      const range = [
        CHART_SIZE.marginLeft, // X Axis Start
        CHART_SIZE.width - CHART_SIZE.marginRight, // X Axis End
      ]
      this.axisXScale = d3.scaleBand(domain, range).padding(0.5) // Padding in %. Effectively, this defines the spacing between the bars.
    },
    createYAxisScale(): void {
      const yBarsValues = this.chartData.map((dataPoint) => dataPoint.yBarValue)
      const yLineValues = this.chartData.map(
        (dataPoint) => dataPoint.yLineValue
      )
      const domain = [0, Math.max(...yBarsValues, ...yLineValues)]
      const range = [
        CHART_SIZE.height - CHART_SIZE.marginBottom, // Y Axis Bottom
        CHART_SIZE.marginTop, // Y Axis Top
      ]
      this.axisYScale = d3.scaleLinear(domain, range)
    },
    showBarTooltip(event: MouseEvent, dataPoint: ChartDataPoint): void {
      // @ts-ignore
      this.$refs.tooltip.showBarTooltip(event, dataPoint)
    },
    showDataPointTooltip(event: MouseEvent, dataPoint: ChartDataPoint): void {
      // @ts-ignore
      this.$refs.tooltip.showDataPointTooltip(event, dataPoint)
    },
    hideBarTooltip(): void {
      // @ts-ignore
      this.$refs.tooltip.hideTooltip()
    },
    hideDataPointTooltip(): void {
      // @ts-ignore
      this.$refs.tooltip.hideTooltip()
    },
  },
})
