<template>
  <div class="base-input">
    <button v-if="btn" type="submit" @click="$emit('action', $refs)">
      <fa :icon="['fas', btn.icon]" />
      {{ btn.text }}
    </button>
    <input
      @focus="$event.target.select()"
      ref="inputvalue"
      :value="value"
      :type="type"
      tabindex="1"
      :class="[size, { 'has-error': !untouched && error !== false }]"
    />
  </div>
</template>
<script>
export default {
  name: 'BaseInputButton',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    btn: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
  },
  data() {
    return {
      inputValue: this.value,
      untouched: true,
    }
  },
}
</script>
<style lang="scss" scoped>
.base-input {
  display: flex;
  flex-direction: row-reverse;

  input {
    flex-grow: 1;
    border: 1px solid $primary-2;
    border-radius: 5px;
    font-size: 10px !important;
    padding: 9px 12px;

    &.lg {
      min-width: 100%;
    }
    &.md {
      min-width: 216px;
    }
    &.sm {
      min-width: 100px;
    }
  }

  button {
    position: absolute;
    background: $blue-1;
    color: white;
    border: 1px solid $primary-2;
    border-radius: 5px;
    font-size: 0.875rem;
    padding: 6px 12px;
    height: 35px;
  }
}
</style>
