<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 16 22"
    aria-labelledby="analytics"
    role="presentation"
    :class="classList"
  >
    <title id="analytics" lang="en">Analytics icon</title>
    <g
      id="Navigation"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Exportable-Icons"
        transform="translate(-180.000000, -20.000000)"
        :fill="
          !darkTheme && !active
            ? '#244c64'
            : !darkTheme && active
            ? '#00E3B5'
            : '#FFFFFF'
        "
      >
        <g id="Group" transform="translate(177.000000, 19.000000)">
          <g id="Group" transform="translate(3.000000, 1.000000)">
            <path
              id="Shape"
              d="M2.96283302,4.77810444 C1.6417079,4.77810444 0.570724815,5.79272 0.570724815,7.0443 L0.570724815,18.6554111 C0.570724815,19.9069667 1.6417079,20.9216556 2.96283302,20.9216556 C4.28395815,20.9216556 5.35494123,19.9069667 5.35494123,18.6554111 L5.35494123,7.04431222 C5.35494123,5.79272 4.28395815,4.77810444 2.96283302,4.77810444 Z M2.97391975,20.25 C3.95363951,20.25 4.74783951,19.4553002 4.74783951,18.475 C4.74783951,17.4946998 3.95363951,16.7 2.97391975,16.7 C1.9942129,16.7 1.2,17.4946998 1.2,18.475 C1.2,19.4553002 1.9942129,20.25 2.97391975,20.25 Z"
            />
            <path
              id="Shape"
              d="M9.46705494,0.194771111 C8.14583951,0.194771111 7.07489512,1.20938667 7.07489512,2.46097889 L7.07489512,18.6554111 C7.07489512,19.9069667 8.14583951,20.9216556 9.46705494,20.9216556 C10.7881414,20.9216556 11.8590728,19.9069667 11.8590728,18.6554111 L11.8590728,2.46097889 C11.8590728,1.20938667 10.7881414,0.194771111 9.46705494,0.194771111 Z M9.47496068,20.25 C10.4552826,20.25 11.25,19.4553002 11.25,18.475 C11.25,17.4946998 10.4552826,16.7 9.47496068,16.7 C8.49463878,16.7 7.7,17.4946998 7.7,18.475 C7.7,19.4553002 8.49463878,20.25 9.47496068,20.25 Z"
            />
            <path
              id="Shape"
              d="M16.0252685,9.71791111 C14.7041821,9.71791111 13.6332506,10.7324778 13.6332506,11.9840333 L13.6332506,18.6554111 C13.6332506,19.9069667 14.7041821,20.9215333 16.0252685,20.9215333 C17.346484,20.9215333 18.4174154,19.9069667 18.4174154,18.6554111 L18.4174154,11.9840333 C18.4174154,10.7324778 17.346484,9.71791111 16.0252685,9.71791111 Z M16.0139198,20.25 C16.9936395,20.25 17.7878395,19.4553002 17.7878395,18.475 C17.7878395,17.4946998 16.9936395,16.7 16.0139198,16.7 C15.0342,16.7 14.24,17.4946998 14.24,18.475 C14.24,19.4553002 15.0342,20.25 16.0139198,20.25 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 16,
    },
    height: {
      type: Number,
      required: false,
      default: 22,
    },
    classList: {
      type: String,
      required: false,
      default: '',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    darkTheme: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
