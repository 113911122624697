<template>
  <div class="tag-input" @click="handleTagInputFocus">
    <div class="tag-input-value">
      <v-chip
        v-for="(tag, idx) in tags"
        :key="tag"
        :class="duplicateTag === tag ? 'highlight' : ''"
        :close="isOnLimis(idx)"
        :color="isOnLimis(idx) ? 'primary' : '#eee'"
        :text-color="isOnLimis(idx) ? 'white' : '#797979'"
        small
        @click:close="handleTagDelete(tag)"
      >
        {{ tag }}
      </v-chip>
    </div>
    <span
      v-if="showPlaceholder"
      class="tag-input-placeholder"
      transition="fade-transition"
      >{{ placeholder }}</span
    >
    <input
      ref="tagField"
      v-model="tagInput"
      :size="tagInput.length < 5 ? 4 : tagInput.length - 1"
      :disabled="tagsOffLimits"
      type="text"
      class="tag-input-field"
      @input="handleTagInput($event)"
      @keydown="handleTagKeydown($event)"
      @blur="handleTagInputBlur"
    />
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: 10000000,
    },
  },
  data() {
    return {
      tagInput: '',
      tags: [],
      duplicateTag: '',
      showPlaceholder: true,
    }
  },
  computed: {
    tagsOffLimits() {
      return this.tags.length >= this.limit
    },
  },
  watch: {
    list(newValue) {
      this.showPlaceholder = newValue.length === 0
    },
  },
  methods: {
    isOnLimis(idx) {
      return idx + 1 <= this.limit
    },
    handleTagInput(ev) {
      if (ev.target.value.includes(',')) {
        this.duplicateTag = ''
        this.updateTagList()
      }
    },
    handleTagKeydown({ key }) {
      if (key === 'Enter') {
        this.duplicateTag = ''
        this.updateTagList()
      }
    },
    handleTagDelete(tag) {
      this.tags = this.tags.filter((value) => value !== tag)
      this.$emit('update:list', this.tags)
    },
    handleTagInputFocus() {
      this.$refs.tagField.focus()
      this.showPlaceholder = false
    },
    handleTagInputBlur() {
      this.tagInput = ''
    },
    updateTagList() {
      const tagInputValue = this.tagInput.split(',').reduce((acc, tag) => {
        const trimmed = tag.trim()

        if (trimmed !== '')
          this.tags.includes(trimmed)
            ? (this.duplicateTag = trimmed)
            : acc.push(trimmed)

        return acc
      }, [])
      this.tags = [...this.tags, ...tagInputValue]
      this.tagInput = ''
      this.$emit('update:list', this.tags)
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-input {
  border: 1px solid grey;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;

  &-field {
    border-radius: 15px;
    outline: none;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
    &:focus {
      background-color: #eee;
      color: $primary;
      font-size: 0.8rem;
      font-weight: 700;
      padding: 0 0.9rem;
      line-height: 1.3rem;
    }
  }

  &-value {
    display: inline;
    margin: 0;
    padding: 0;
  }
  &-placeholder {
    color: #9e9e9e;
    font-weight: bold;
    font-size: 0.8rem;
  }
}
.v-chip {
  margin: 2px;

  &.highlight {
    font-weight: 700;
  }
}
</style>
