import { daysAgo } from '~/utils/date'

export const state = () => ({
  startDate: daysAgo(new Date(), 7),
  endDate: daysAgo(new Date(), 1),
  totalPages: null,
  currentPage: 1,
})

export const getters = {
  totalPages: (state) => state.totalPages,
  currentPage: (state) => state.currentPage,
  startDate: (state) => state.startDate,
  endDate: (state) => state.endDate,
}

export const actions = {
  setCurrentPage({ commit }, page) {
    commit('currentPage', page)
  },
  setTotalPages({ commit }, pages) {
    commit('totalPages', pages)
  },
  setDates({ commit }, dateRange) {
    commit('startDate', dateRange?.start || null)
    commit('endDate', dateRange.end || null)
  },
}

export const mutations = {
  totalPages(state, pages) {
    state.totalPages = pages
  },
  currentPage(state, page) {
    state.currentPage = page
  },
  startDate(state, date) {
    state.startDate = date
  },
  endDate(state, date) {
    state.endDate = date
  },
}
