import {
  setAccounts,
  checkIfUserHasReachedTrialLimitOfSyncs,
  checkIfUserHasReachedTrialLimitOfOrdersAuthorizations,
} from '@/utils/accounts'

const ONE_MINUTE = 60000 // In milliseconds
const FIVE_MINUTES = ONE_MINUTE * 5

export const state = () => ({
  features: null,
  amsAccounts: [],
  onboarding: null,
  reachTrialLimitOfSyncs: null, // based only on accounts and not on user payment status at this point
  reachTrialLimitOfAuthorizedMarketplaces: null,
  name: '',
  email: '',
  payment_status: null,
})

export const getters = {
  auth: (state) => !!state.features,
  accounts: (state) => state.amsAccounts,
  onboarding: (state) => state.onboarding,
  hasProAccount: ({ features }) => !!features && features.audit.pro,
  hasActivation: ({ features }) => !!features && features.activation,
  hasAnalytics: ({ features }) => !!features && features.analytics,
  getName: (state) => state.name,
  getEmail: (state) => state.email,
  getPaymentStatus: (state) => state.payment_status,
  getReachTrialLimitOfSyncs: (state) => state.reachTrialLimitOfSyncs,
  getReachTrialLimitOfAuthorizedMarketplaces: (state) =>
    state.reachTrialLimitOfAuthorizedMarketplaces,
}

export const actions = {
  async nozzleAuth({ commit, state }) {
    try {
      const response = await this.$axios.get('/user/')
      if (response?.status === 200 && state.features === null) {
        const { features, email, name, onboarded, payment_status } =
          response.data
        commit('userFeatures', features)
        commit('userEmail', email)
        commit('userName', name)
        commit('userPaymentStatus', payment_status)
        this.$intercom({
          name,
          email,
          onboarded,
          auditCount: features.audit.count,
          auditTier: features.audit.current_tier,
        })
      }
    } catch (e) {
      if (process.browser) {
        window.location.assign(`${this.$env.NOZZLE_URL}/login`)
      }
    }
  },

  setAmazonAccounts({ state, dispatch, commit }, data) {
    if (data.length === 0) {
      commit('amsAccounts', [])
      commit('reachTrialLimitOfSyncs', false)
      commit('reachTrialLimitOfAuthorizedMarketplaces', false)
    } else {
      // separate accounts from merchants
      const accountsData = setAccounts(data)
      // merchants: [{id: 'A38FSOUS8SIFID', marketplaces:[], name:'A38FSOUS8SIFID', type:'seller', website:''}]
      dispatch('merchants/setMerchants', accountsData.merchants, {
        root: true,
      })
      // update amsAccounts
      commit('amsAccounts', accountsData.accounts)

      // check if there's anything onboarding and update state
      dispatch('user/updateOnboardingStatus', accountsData.accounts, {
        root: true,
      })

      // if onboarding is true, we already know that we want to block new syncs for this user
      if (state.onboarding) {
        commit('reachTrialLimitOfSyncs', true)
        commit(
          'reachTrialLimitOfAuthorizedMarketplaces',
          checkIfUserHasReachedTrialLimitOfOrdersAuthorizations(
            accountsData.accounts
          )
        )
        return
      }
      // check if user has at least one marketplace that has (at least) started syncing ads
      // if so that means that we need to block new syncs
      commit(
        'reachTrialLimitOfSyncs',
        checkIfUserHasReachedTrialLimitOfSyncs(accountsData.accounts)
      )
      commit(
        'reachTrialLimitOfAuthorizedMarketplaces',
        checkIfUserHasReachedTrialLimitOfOrdersAuthorizations(
          accountsData.accounts
        )
      )
    }
  },

  async fetchAmazonUsers({ dispatch }) {
    try {
      const response = await this.$axios.get('user/amazon_users')
      if (response && response.status >= 200 && response.status < 300) {
        setTimeout(function () {
          dispatch('setAmazonAccounts', response.data.amazon_users)
        }, FIVE_MINUTES)
      } else if (response?.status >= 500) {
        // throw error and go to catch block
        throw new Error(
          "Error occurred while fetching 'user/amazon_users'. " +
            response?.text()
        )
      }
    } catch (e) {
      setTimeout(function () {
        dispatch('fetchAmazonUsers')
      }, 1000)
    }
  },

  updateOnboardingStatus({ commit, dispatch }, data) {
    // Check if there is any process ongoing for both ads or orders sync.
    // If true, another request for amazon users will occur.
    // After that, it will enter again on this function to check if it is still on progress.
    const isOnboardingAnything =
      data.find(
        (account) =>
          Object.values(account.merchants).find(
            (merchant) =>
              merchant.marketplaces.find(
                (market) =>
                  (market.onboarded === false &&
                    +market.onboardedProgress > 0) ||
                  market.onboardingSPOrdersStatus === 'PENDING'
              ) !== undefined
          ) !== undefined
      ) !== undefined
    commit('onboarding', isOnboardingAnything)

    if (isOnboardingAnything) {
      dispatch('fetchAmazonUsers')
    }
  },
  updateMarketplaceStatus({ commit }, data) {
    const { accountId, merchantId, profileId, requesting } = data
    commit('updateMarketplaceRequestingStatus', {
      accountId,
      merchantId,
      profileId,
      requesting,
    })
  },
}

export const mutations = {
  userFeatures(state, features) {
    state.features = features
  },
  userName(state, name) {
    state.name = name
  },
  userEmail(state, email) {
    state.email = email
  },
  userPaymentStatus(state, payment_status) {
    state.payment_status = payment_status
  },
  amsAccounts(state, accounts) {
    state.amsAccounts = accounts
  },
  reachTrialLimitOfSyncs(state, reachTrialLimitOfSyncs) {
    state.reachTrialLimitOfSyncs = reachTrialLimitOfSyncs
  },
  reachTrialLimitOfAuthorizedMarketplaces(
    state,
    reachTrialLimitOfAuthorizedMarketplaces
  ) {
    state.reachTrialLimitOfAuthorizedMarketplaces =
      reachTrialLimitOfAuthorizedMarketplaces
  },
  onboarding(state, onboarding) {
    state.onboarding = onboarding
  },
  updateMarketplaceRequestingStatus(state, data) {
    state.amsAccounts = state.amsAccounts.map((account) => {
      if (account.id === data.accountId) {
        const marketplaces = [
          ...account.merchants[data.merchantId].marketplaces,
        ]
        const updatedMarketplaces = marketplaces.map((marketplace) => {
          if (marketplace.id === data.profileId) {
            return { ...marketplace, requesting: data.requesting }
          } else return { ...marketplace }
        })
        account.merchants[data.merchantId].marketplaces = updatedMarketplaces
      }
      return account
    })
  },
}
