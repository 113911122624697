<template>
  <div
    v-if="show === true"
    @mouseover="stopCounter"
    @mouseleave="setCounter"
    @focus="stopCounter"
    @blur="setCounter"
    :id="id"
    :class="[
      'toaster-message',
      'toaster-message--' + type,
      'd-flex',
      'justify-space-between',
      isHidden ? 'hidden' : '',
    ]"
  >
    <span class="message-body text-left">
      <span v-if="type === 'error'">
        <fa
          :icon="['fal', 'exclamation-circle']"
          :class="[type + '-icon', 'text-md']"
        />
      </span>
      <span v-if="type === 'warning'">
        <fa
          :icon="['fal', 'exclamation-triangle']"
          :class="[type + '-icon', 'text-md']"
        />
      </span>
      <span v-if="type === 'info'">
        <fa
          :icon="['fal', 'info-circle']"
          :class="[type + '-icon', 'text-md']"
        />
      </span>
      <span v-if="type === 'success'">
        <fa
          :icon="['fal', 'check-circle']"
          :class="[type + '-icon', 'text-md']"
        />
      </span>

      <slot name="message" class="pl-2"></slot>

      <span v-if="!$slots['message']" class="">
        <b>{{ this.messageTitle }}</b>
        <br
          v-if="
            (this.messageTitle + '  ' + this.messageContent).length >
            this.oneLineMaxMessageLenght
          "
        />
      </span>
      <span
        v-if="!$slots['message']"
        :class="
          (this.messageTitle + '  ' + this.messageContent).length >
          this.oneLineMaxMessageLenght
            ? 'pl-6'
            : 'pl-1'
        "
      >
        {{ this.messageContent }}
      </span>
    </span>
    <div>
      <nuxt-link
        v-if="actionTitle && actionLink"
        :to="actionLink"
        class="toaster-message__action pr-1"
      >
        {{ actionTitle }}</nuxt-link
      >
      <span v-if="dismissible" class="close-btn" @click="closingMessage">
        <fa :icon="['fal', 'times']" class="text-md" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dismissible: { type: Boolean, required: false, default: true },
    type: { type: String, required: false, default: 'error' },
    timeoutInSeconds: { type: Number, required: false, default: 10 },
    actionTitle: { type: String, required: false, default: null },
    actionLink: { type: String, required: false, default: null },
    messageTitle: {
      type: String,
      required: false,
      default: 'Something went wrong!!',
    },
    messageContent: {
      type: String,
      required: false,
      default: 'Please try again',
    },
  },
  data: function () {
    return {
      show: true,
      id: null,
      counter: null,
      oneLineMaxMessageLenght: 100,
      isHidden: false,
    }
  },
  mounted() {
    this.id = this._uid
    this.show = true
    this.setCounter()
  },
  methods: {
    closingMessage() {
      this.isHidden = true
      setTimeout(() => this.$emit('message-timeout'), 2000)
    },
    setCounter() {
      this.counter = setTimeout(
        this.closingMessage,
        this.timeoutInSeconds * 1000
      )
    },
    stopCounter() {
      clearTimeout(this.counter)
    },
  },
}
</script>

<style lang="scss" scoped>
.toaster-message {
  position: relative;
  background: $white;
  box-shadow: 0px 0px 7px #d8d8d8;
  border-radius: 4px;
  padding: 0.5rem;
  min-width: 100px;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  opacity: 1;
  &--error {
    border-left: 8px solid $red;
  }
  &--success {
    border-left: 8px solid $green;
  }
  &--warning {
    border-left: 8px solid $orange;
  }
  &--info {
    border-left: 8px solid $denim;
  }

  &__action {
    color: $blue-1;
    font-weight: 600;
  }
}

.error-icon {
  color: $red;
}
.warning-icon {
  color: $orange;
}
.info-icon {
  color: $denim;
}
.success-icon {
  color: $green;
}
.success-icon,
.info-icon,
.warning-icon,
.error-icon {
  margin-right: 0.25rem;
}

.close-btn {
  padding-left: 0.5rem;
  padding-right: 1rem;
}

// hidden with fadeout effect
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s ease-out;
}
</style>
