<template>
  <v-card class="ams-form-modal">
    <v-card-title>
      <div class="d-flex">
        <v-img
          :src="require('~/assets/images/branding/nozzle_avatar.svg')"
          max-width="20"
          contain
          class="mg-right-sm"
        />
        <span class="font-weight-bold">Orders data authorization</span>
        <span class="modal-close">
          <CloseBtn @close="$emit('on-cancel')" />
        </span>
      </div>
    </v-card-title>

    <v-card-text>
      <div class="text-sm primary--text mg-btm-sm font-weight-normal">
        Send the links below to the account admin. The link will redirected them
        to Amazon so they can connect their Amazon account to this Nozzle
        account.
      </div>
    </v-card-text>

    <template v-for="(data, id) in formData">
      <div class="lwa-links" :key="id">
        <BaseCountry :flag="data.country_code" size="small" class="pt-1" />
        <span class="pl-2 pt-1 lwa-county">{{
          countryData(data.country_code).name
        }}</span>
        <BaseInputButton
          class="pl-3 lwa-button"
          type="text"
          :value="data.url"
          :btn="{ icon: 'copy', text: btnTxt[id] }"
          @action="copyLink($event, id)"
        />
      </div>
      <hr />
    </template>
  </v-card>
</template>

<script>
import BaseButton from '~/components/BaseButton.vue'
import BaseInputButton from '~/components/BaseInputButton.vue'
import BaseCountry from '~/components/BaseCountry.vue'
import CloseBtn from '~/components/icons/CloseBtn.vue'
import { getCountry } from '@/utils/marketplace'

export default {
  name: 'AccountAuthForm',
  props: {
    formData: {
      type: Array,
      required: true,
    },
  },
  components: {
    BaseButton,
    BaseCountry,
    BaseInputButton,
    CloseBtn,
  },
  data() {
    return {
      btnTxt: [],
    }
  },
  mounted() {
    this.formData.forEach(() => this.btnTxt.push('copy'))
  },
  methods: {
    countryData(marketplaceName) {
      return getCountry(marketplaceName)
    },

    copyLink(refs, id) {
      try {
        refs.inputvalue.focus()
        document.execCommand('copy')
        this.$set(this.btnTxt, id, 'copied')
      } catch (e) {
        this.hasFailed = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.is-outlined >>> .v-input__slot {
  border: 1px solid $primary;
  background-color: white;
  font-size: 0.8rem;
  font-weight: 700;
  color: $primary;
}

.v-card {
  box-shadow: none;
}

.v-card__title {
  background-color: $primary;
  color: white;
  line-height: 1.5rem;
  font-size: 1rem !important;
  word-break: break-word;
  margin-bottom: 1rem;
}

.v-card__text,
.v-card__actions {
  padding: 0 1.5rem 1rem 1.5rem;
}

.formfield >>> .separator {
  margin: 0;
}

.formfield >>> .v-text-field__details {
  padding: 0;
}

.v-card >>> .v-snack__content {
  color: #e57373;
  font-weight: bold;
}

.blue-light--bg {
  background-color: #164b6412;
}
.modal-footer {
  background: $grey-1;
  color: $denim-2;
}
.modal-close {
  position: absolute;
  margin-right: 15px;
  right: 0;
}
.lwa-links {
  padding: 0 1.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;

  .small-flag {
    margin: -15px !important;
  }
  .lwa-county {
    flex-grow: 4;
    margin-right: 10px;
    width: 20%;
  }
  .lwa-button {
    flex-grow: 1;
    width: 50%;
  }
}

hr {
  height: 1px;
  color: $grey-1;
  width: 85%;
  text-align: center;
  margin: 0px auto 20px;
}
</style>
