











import Vue from 'vue'
import { CHART_SIZE } from '../sizes'

export default Vue.extend({
  name: 'AxisYTitle',
  data: () => ({ CHART_SIZE }),
})
