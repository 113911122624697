import { render, staticRenderFns } from "./BaseToggleGroup.vue?vue&type=template&id=6532b47e&scoped=true&"
import script from "./BaseToggleGroup.vue?vue&type=script&lang=js&"
export * from "./BaseToggleGroup.vue?vue&type=script&lang=js&"
import style0 from "./BaseToggleGroup.vue?vue&type=style&index=0&id=6532b47e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6532b47e",
  null
  
)

export default component.exports