/*
  formatCurrencyUsingAbbreviations("£", 12489.20) -> £12K
  formatCurrencyUsingAbbreviations("£", 6532589.20) -> £7M
  formatCurrencyUsingAbbreviations("£", 589.55) -> £590
  formatCurrencyUsingAbbreviations("$", 0.59) -> $0.59
*/
export const formatCurrencyUsingAbbreviations = (
  currency: any,
  n: number
): any => {
  if (n < 0 && n >= -10000)
    return `-${currency + Math.abs(parseFloat(n.toFixed(2)))}`
  else if (n < 100 && Number.isInteger(n)) return currency + n.toFixed(2)
  else if (n < 1e3) return currency + Math.round(n)
  else if (n >= 1e3 && n < 1e6) return currency + Math.round(n / 1e3) + 'K'
  else if (isNaN(n)) return '-'
  else {
    const rounded = (n / 1e6).toFixed(2)
    return currency + rounded + 'M'
  }
}

/*
  formatCurrency('USD','en-GB', 1234.56) -> '£1,234.56'
  formatCurrency('EUR','pt-PT', 1234.56) -> '1234,56 €'
  formatCurrency('MXP', 'es-MX', 1234.56) -> 'MXP 1,234.56'
*/
export const formatCurrency = (
  currencyCode: any,
  locale: any,
  n: number
): string => {
  return n && currencyCode && locale
    ? n.toLocaleString(locale, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '--'
}

export const formatCurrencyWithTwoDecimals = (currency: any, n: any): any => {
  if (n < 0) return `-${currency + Math.abs(parseFloat(n.toFixed(2)))}`
  else if (n >= 0) return currency + n.toFixed(2)
  else if (isNaN(n)) return '-'
}
