<template>
  <div class="tooltip-box">
    <slot />
    <div
      v-if="show"
      class="tooltip"
      :style="styleCss"
      :class="[position, styling, { 'last-tooltip': last }]"
    >
      <span>{{ text }}</span>
      <br />
      <small class="column is-12 subtooltip" v-if="subtext">{{
        subtext
      }}</small>
      <i v-if="showArrow"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    subtext: {
      type: String,
      required: false,
    },
    styleCss: {
      type: String,
      required: false,
    },
    showArrow: {
      type: Boolean,
      required: false,
      default: true,
    },
    last: {
      type: Boolean,
      required: false,
    },
    show: {
      type: Boolean,
      required: false,
    },
    position: {
      type: String,
      required: false,
      default: 'top',
    },
    styling: {
      type: String,
      required: false,
      default: 'standard',
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip-box {
  position: absolute;
  display: inline-block;
}

.tooltip {
  font-size: 10px;
  font-weight: normal;
  padding: 5px 5px;
  border-radius: 5px;
  text-align: center;
  width: 240px;
  position: absolute !important;
  z-index: 20;
  opacity: 1;
  display: block;
}

.top {
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);

  i {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
  }

  i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.right {
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);

  i {
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;
  }

  i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(50%, -50%) rotate(-45deg);
  }
}

.bottom {
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);

  i {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
  }

  i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
  }
}

.left {
  top: 50%;
  right: 100%;
  margin-right: 20px;
  transform: translate(0, -50%);

  i {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;
  }

  i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.last-tooltip {
  margin-left: -80px !important;
  i {
    left: 83% !important;
  }
}
.subtooltip {
  color: $denim-2;
}
.standard {
  color: #ffffff;
  background-color: #244c64;
  i::after {
    background-color: #244c64;
  }
}
.nozzle {
  color: #244c64;
  background-color: #ffffff;
  border: 1px solid #b1b1b1;
  i::after {
    background-color: #ffffff;
    border: 1px solid #b1b1b1;
  }
}
</style>
