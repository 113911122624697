import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faCopy as fortawesomeprolightsvgicons_faCopy } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faCopy)

    import  { faChevronRight as fortawesomeprolightsvgicons_faChevronRight } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faChevronRight)

    import  { faChevronLeft as fortawesomeprolightsvgicons_faChevronLeft } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faChevronLeft)

    import  { faChevronDown as fortawesomeprolightsvgicons_faChevronDown } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faChevronDown)

    import  { faChevronUp as fortawesomeprolightsvgicons_faChevronUp } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faChevronUp)

    import  { faPencil as fortawesomeprolightsvgicons_faPencil } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faPencil)

    import  { faCalendarAlt as fortawesomeprolightsvgicons_faCalendarAlt } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faCalendarAlt)

    import  { faInfoCircle as fortawesomeprolightsvgicons_faInfoCircle } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faInfoCircle)

    import  { faExclamationTriangle as fortawesomeprolightsvgicons_faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faExclamationTriangle)

    import  { faExclamationCircle as fortawesomeprolightsvgicons_faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faExclamationCircle)

    import  { faLayerGroup as fortawesomeprolightsvgicons_faLayerGroup } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faLayerGroup)

    import  { faCircle as fortawesomeprolightsvgicons_faCircle } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faCircle)

    import  { faTimes as fortawesomeprolightsvgicons_faTimes } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faTimes)

    import  { faSearch as fortawesomeprolightsvgicons_faSearch } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faSearch)

    import  { faLock as fortawesomeprolightsvgicons_faLock } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faLock)

    import  { faSquare as fortawesomeprolightsvgicons_faSquare } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faSquare)

    import  { faCheck as fortawesomeprolightsvgicons_faCheck } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faCheck)

    import  { faCheckCircle as fortawesomeprolightsvgicons_faCheckCircle } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faCheckCircle)

    import  { faBars as fortawesomeprolightsvgicons_faBars } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faBars)

    import  { faChevronDoubleRight as fortawesomeprolightsvgicons_faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faChevronDoubleRight)

    import  { faChevronCircleLeft as fortawesomeprolightsvgicons_faChevronCircleLeft } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faChevronCircleLeft)

    import  { faChevronCircleRight as fortawesomeprolightsvgicons_faChevronCircleRight } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faChevronCircleRight)

    import  { faBolt as fortawesomeprolightsvgicons_faBolt } from '@fortawesome/pro-light-svg-icons'
    library.add(fortawesomeprolightsvgicons_faBolt)

    import  { faCopy as fortawesomeproregularsvgicons_faCopy } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCopy)

    import  { faExclamationTriangle as fortawesomeproregularsvgicons_faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faExclamationTriangle)

    import  { faClipboard as fortawesomefreesolidsvgicons_faClipboard } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faClipboard)

    import  { faChartLine as fortawesomefreesolidsvgicons_faChartLine } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChartLine)

    import  { faBullhorn as fortawesomefreesolidsvgicons_faBullhorn } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBullhorn)

    import  { faCopy as fortawesomefreesolidsvgicons_faCopy } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCopy)

    import  { faCog as fortawesomefreesolidsvgicons_faCog } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCog)

    import  { faPowerOff as fortawesomefreesolidsvgicons_faPowerOff } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPowerOff)

    import  { faTimes as fortawesomefreesolidsvgicons_faTimes } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTimes)

    import  { faExpand as fortawesomefreesolidsvgicons_faExpand } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExpand)

    import  { faInfo as fortawesomefreesolidsvgicons_faInfo } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faInfo)

    import  { faCheckSquare as fortawesomefreesolidsvgicons_faCheckSquare } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheckSquare)

    import  { faArrowLeft as fortawesomefreesolidsvgicons_faArrowLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowLeft)

    import  { faLongArrowAltUp as fortawesomefreesolidsvgicons_faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faLongArrowAltUp)

    import  { faLongArrowAltDown as fortawesomefreesolidsvgicons_faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faLongArrowAltDown)

    import  { faArchive as fortawesomefreesolidsvgicons_faArchive } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArchive)

    import  { faCheck as fortawesomefreesolidsvgicons_faCheck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheck)

    import  { faPause as fortawesomefreesolidsvgicons_faPause } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPause)

    import  { faSearch as fortawesomefreesolidsvgicons_faSearch } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSearch)

    import  { faSort as fortawesomefreesolidsvgicons_faSort } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSort)

    import  { faExternalLinkAlt as fortawesomefreesolidsvgicons_faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExternalLinkAlt)

    import  { faDotCircle as fortawesomefreesolidsvgicons_faDotCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faDotCircle)

    import  { faExclamationTriangle as fortawesomefreesolidsvgicons_faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExclamationTriangle)

    import  { faExclamationCircle as fortawesomefreesolidsvgicons_faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExclamationCircle)

    import  { faCaretRight as fortawesomefreesolidsvgicons_faCaretRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCaretRight)

    import  { faCircle as fortawesomeproduotonesvgicons_faCircle } from '@fortawesome/pro-duotone-svg-icons'
    library.add(fortawesomeproduotonesvgicons_faCircle)

Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)
