

























import Vue from 'vue'
import BaseBannerMessaging from '~/components/BaseBannerMessaging.vue'

export default Vue.extend({
  components: { BaseBannerMessaging },
  name: 'MarginBanner',
})
