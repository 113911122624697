import { render, staticRenderFns } from "./AccountAndMarketplaceSelector.vue?vue&type=template&id=47903c62&scoped=true&"
import script from "./AccountAndMarketplaceSelector.vue?vue&type=script&lang=ts&"
export * from "./AccountAndMarketplaceSelector.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47903c62",
  null
  
)

export default component.exports