<template>
  <base-card>
    <template #title>
      <div class="font-weight-bold">
        There is no spend for this audit. Please try with a different
        marketplace.
      </div>
    </template>
    <template #content>
      <div>This won't count towards your audit quota.</div>
    </template>
  </base-card>
</template>
<script>
import BaseCard from '../../BaseCard'
export default {
  components: { BaseCard },
}
</script>
