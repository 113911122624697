<template>
  <div>
    <slot name="prepend" />
    <input
      :id="inputId"
      :placeholder="placeholder"
      :value="value"
      :type="type"
      tabindex="1"
      :class="[size, { 'has-error': !untouched && error !== false }]"
      :required="required"
      @keydown.esc="$emit('skip')"
      @keydown.enter="$emit('enter')"
      @blur="$emit('blur')"
      @focus="onFocus"
      @input="$emit('input', $event.target.value)"
      :style="cssVars"
    />
    <template v-if="searchIcon">
      <fa
        v-if="value.length < 1"
        :icon="['fal', 'search']"
        class="input-icon"
      />
      <svg
        v-else
        class="input-icon"
        @click="$emit('clear')"
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3438 4.375L11.6875 6L13.3438 7.65625C13.5312 7.84375 13.5312 8.1875 13.3438 8.375C13.1562 8.5625 12.8125 8.5625 12.625 8.375L10.9688 6.71875L9.34375 8.375C9.15625 8.5625 8.8125 8.5625 8.625 8.375C8.4375 8.1875 8.4375 7.84375 8.625 7.65625L10.2812 6L8.625 4.375C8.4375 4.1875 8.4375 3.84375 8.625 3.65625C8.8125 3.46875 9.15625 3.46875 9.34375 3.65625L10.9688 5.3125L12.625 3.65625C12.8125 3.46875 13.1562 3.46875 13.3438 3.65625C13.5312 3.84375 13.5312 4.1875 13.3438 4.375ZM16 0C17.0938 0 18 0.90625 18 2V10C18 11.125 17.0938 12 16 12H6.40625C5.875 12 5.375 11.8125 5 11.4375L0.28125 6.71875C0.09375 6.53125 0 6.28125 0 6C0 5.75 0.09375 5.5 0.28125 5.3125L5 0.59375C5.375 0.21875 5.875 0 6.40625 0H16ZM17 2C17 1.46875 16.5312 1 16 1H6.40625C6.125 1 5.875 1.125 5.6875 1.3125L0.96875 6L5.6875 10.7188C5.875 10.9062 6.125 11 6.40625 11H16C16.5312 11 17 10.5625 17 10V2Z"
          fill="#244C64"
        />
      </svg>
    </template>

    <!-- Error -->
    <p v-if="!untouched && error" class="red--text mb-0">
      <fa :icon="['fal', 'exclamation-triangle']" class="red--text pr-1" />
      <span class="text-xsm">
        <!-- first failed rule's error message -->
        {{ error }}
      </span>
    </p>
    <!-- Hint -->
    <p v-if="hint" class="hint mb-0">{{ hint }}</p>
  </div>
</template>
<script>
export default {
  props: {
    inputId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    borderColour: {
      type: String,
      required: false,
      default: '#164b65',
    },
    borderRadius: {
      type: String,
      required: false,
      default: '5px',
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
    rules: {
      type: Array,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
      untouched: true,
    }
  },
  computed: {
    error() {
      // Do not show errors if input is not required and if it's empty
      if (!this.required && this.value.length === 0) {
        return false
      }

      // Do not show errors if rules are empty
      if ((this.rules?.length || 0) === 0) {
        return false
      }

      // Checks all the rules and return error message on fail
      for (const rule of this.rules) {
        const errorMsg = rule(this.value) === true ? null : rule(this.value)
        if (errorMsg) {
          return errorMsg
        }
      }
      return false
    },
    cssVars() {
      return {
        '--border-colour': this.borderColour,
        '--border-radius': this.borderRadius,
      }
    },
  },
  methods: {
    onFocus() {
      if (this.untouched) {
        this.untouched = false
      }
      this.$emit('focus')
    },
  },
}
</script>
<style lang="scss" scoped>
input {
  max-width: 100%;
  border: 1px solid var(--border-colour);
  border-radius: var(--border-radius);
  font-size: 0.875rem;
  padding: 6px 12px;

  &:not(.has-error) {
    &:focus,
    &:active,
    &:focus-visible {
      border: 1px solid $blue-1;
      outline-color: $blue-1;
      box-shadow: 0px 0px 0px 2px $blue-4;
    }
  }

  &.has-error {
    border: 1px solid $red-1;

    &:focus,
    &:active,
    &:focus-visible {
      border: 1px solid $red-1;
      outline-color: $red-1;
      box-shadow: 0px 0px 0px 2px $red-2;
    }
  }

  &.disabled {
    background: $grey-1;
    border: 1px solid $grey-4;
    color: $denim-2;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  &.lg {
    min-width: 100%;
  }
  &.md {
    min-width: 216px;
  }
  &.sm {
    min-width: 100px;
  }
}

.input-icon {
  position: absolute;
  margin-top: 10px;
  margin-left: -25px;
}

.hint {
  color: $denim-2;
  font-size: 0.75rem; //12px
}
</style>
