
































import Vue, { PropType } from 'vue'
import * as d3 from 'd3'
import { ChartData } from '../types'

export default Vue.extend({
  name: 'DataSeriesBars',
  props: {
    axisXScale: {
      type: Function as PropType<d3.ScaleBand<string>>,
      required: true,
    },
    axisY1Scale: {
      type: Function as PropType<d3.ScaleLinear<number, number>>,
      required: true,
    },
    chartData: {
      type: Array as PropType<ChartData>,
      required: true,
    },
  },
  computed: {
    barOffset(): number {
      return this.axisXScale.bandwidth() * 0.375
    },
  },
})
