











































import Country from '~/components/BaseCountry.vue'

export default {
  components: {
    Country,
  },
}
