




import Vue from 'vue'
import * as d3 from 'd3'
import { mapGetters } from 'vuex'
import { ChartDataPoint } from '../types'

export default Vue.extend({
  name: 'ChartTooltip',
  data: () => ({ tooltip: {} as HTMLDivElement }),
  computed: {
    ...mapGetters({
      currencyCode: 'analytics/getCurrencyCode',
    }),
    userLang(): string {
      if (navigator.languages !== undefined) {
        return navigator.languages[0]
      } else {
        return navigator.language
      }
    },
  },
  mounted() {
    this.addChartTooltip()
  },
  methods: {
    addChartTooltip(): void {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'purchase-intervals-chart-tooltip')
        .node()!
    },
    showBarTooltip(event: MouseEvent, dataPoint: ChartDataPoint): void {
      const barEl = d3
        .select('[data-role="DataSeriesBars"] rect')
        .node()! as SVGRectElement
      const xOffset = barEl.getBoundingClientRect().width / 2
      const yOffset = -5
      this.showTooltip(event, dataPoint, xOffset, yOffset)
    },
    showDataPointTooltip(event: MouseEvent, dataPoint: ChartDataPoint): void {
      const circleEl = d3
        .select('[data-role="DataSeriesLine"] circle')
        .node()! as SVGCircleElement
      const xOffset = circleEl.getBoundingClientRect().width / 2
      const yOffset = -(circleEl.getBoundingClientRect().width / 2 + 1)
      this.showTooltip(event, dataPoint, xOffset, yOffset)
    },
    showTooltip(
      event: MouseEvent,
      dataPoint: ChartDataPoint,
      xOffset: number = 0,
      yOffset: number = 0
    ) {
      d3.select(this.tooltip)
        .style('visibility', 'hidden')
        .style('display', 'block')

      d3.select(this.tooltip).html(
        `<div class="svgTooltip">
            <p>${dataPoint.product}</p>
            <small>${dataPoint.asin}</small>
            <i></i>
          </div>`
      )

      const eventTarget = event.target as HTMLElement
      const eventTargetRect = eventTarget.getBoundingClientRect()
      const tooltipRect = this.tooltip.getBoundingClientRect()

      d3.select(this.tooltip)
        .style(
          'left',
          `${
            window.scrollX + eventTargetRect.x - tooltipRect.width / 2 + xOffset
          }px`
        )
        .style(
          'top',
          `${
            window.scrollY + eventTargetRect.y - tooltipRect.height + yOffset
          }px`
        )
        .style('visibility', 'visible')
    },
    hideTooltip(): void {
      d3.select(this.tooltip).style('display', 'none')
    },
  },
})
