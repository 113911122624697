<template>
  <v-form ref="auditForm" v-model="valid">
    <v-row no-gutters>
      <v-col cols="12">
        <p class="font-weight-bold">
          By pressing the Start Audit button, we’ll crunch the numbers for your
          Audit.
          <br />In most cases this takes 5-10 minutes.
        </p>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <component :is="errorComponent" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <base-form-field label="Report name">
          <template #field>
            <v-text-field
              v-model="auditName"
              :rules="validationRules.name"
              :flat="true"
              class="is-outlined"
              placeholder="e.g. Report for summer campaign Kirley shoes"
              solo
              dense
              required
            />
          </template>
        </base-form-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <base-form-field label="Account">
          <template #field>
            <base-dropdown
              v-model="auditMerchant"
              :items="merchants"
              :height="4"
              :rules="validationRules.merchant"
              label="Choose an account"
              required
            />
          </template>
        </base-form-field>
      </v-col>
      <v-col cols="12" md="4">
        <base-form-field label="Marketplace">
          <template #field>
            <base-dropdown
              ref="market"
              v-model="auditMarket"
              :items="markets"
              :rules="validationRules.marketplace"
              label="Choose a marketplace"
              required
            />
          </template>
        </base-form-field>
      </v-col>
      <v-col cols="12" md="4">
        <base-date-input
          ref="picker"
          v-model="dates"
          :min-date="minDate"
          :disabled="!hasProAccount"
          @done="onAuditDatesSelected"
        >
          <template #label>
            <div class="primary--text font-weight-bold text-md mb-1">Dates</div>
          </template>
          <template #append>
            <div class="flex-grow-0">
              <v-chip
                v-if="!hasProAccount"
                small
                color="secondary"
                text-color="#164b65"
                class="font-weight-bold"
              >
                <v-icon left x-small> mdi-lock-outline </v-icon>Pro
              </v-chip>
              <fa v-else :icon="['fal', 'pencil']" />
            </div>
          </template>
        </base-date-input>
      </v-col>
    </v-row>
    <v-item-group v-if="hasProAccount">
      <campaigns-table
        :selection.sync="auditCampaigns"
        :currency="marketCurrency"
        :data="marketCampaigns"
        title="(Optional) Filter by campaign"
      />
      <v-row>
        <v-expansion-panels
          v-if="hasProAccount"
          v-model="panels"
          :flat="true"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="font-weight-bold">Advanced settings</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="12" lg="6">
                  <base-form-field
                    :label="copy.searchTerms.label"
                    :description="copy.searchTerms.info"
                    :counter="{
                      limit: copy.searchTerms.limit,
                      value: auditBrandSearchTerms.length,
                      label: 'terms',
                    }"
                  >
                    <template #field>
                      <base-tag-input
                        :placeholder="copy.searchTerms.placeholder"
                        :list.sync="auditBrandSearchTerms"
                        :limit="copy.searchTerms.limit"
                      />
                    </template>
                  </base-form-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">
                  <base-form-field
                    :has-tooltip="true"
                    :info="copy.spendClick.info"
                    label="Wasted spend click threshold"
                  >
                    <template #field>
                      <v-text-field
                        v-model="auditwastedSpendClickThreshold"
                        :flat="true"
                        :rules="validationRules.wastedThreshold"
                        dense
                        solo
                        class="is-outlined shrink-60"
                        @blur="resetWastedSpendClick"
                      />
                    </template>
                  </base-form-field>
                </v-col>
                <v-col cols="12" md="5">
                  <base-form-field
                    :has-tooltip="true"
                    :info="copy.spendDays.info"
                    label="Exclude zero spend days"
                  >
                    <template #field>
                      <v-switch
                        v-model="auditZeroDaysSpend"
                        :ripple="false"
                        inset
                        dense
                        color="secondary"
                      >
                        <template v-slot:prepend>
                          <v-icon color="white" size="22"> mdi-check </v-icon>
                        </template>
                      </v-switch>
                    </template>
                  </base-form-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-item-group>
    <div class="page__content__footer">
      <v-btn
        :disabled="!isValidAudit"
        :loading="isSubmitting"
        color="blue-button"
        x-large
        class="mr-4"
        @click="onSubmitAudit"
      >
        Start Audit
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { differenceInDays, subDays } from 'date-fns'
import { newAudit } from '@/utils/definitions/newAudit'
import { validateSchema } from '@/utils/definitions'
import AuditError from '../Alerts/AuditError'
import AuditNoSpend from '../Alerts/AuditNoSpend'
import copy from './auditCopy'
import CampaignsTable from './CampaignsTable'
import { formatDate, yesterday } from '~/utils/date'
import BaseFormField from '~/components/BaseFormField'
import BaseDropdown from '~/components/BaseDropdown'
import BaseDateInput from '~/components/BaseDateInput'
import BaseTagInput from '~/components/BaseTagInput'

export default {
  components: {
    BaseFormField,
    BaseDropdown,
    BaseDateInput,
    BaseTagInput,
    CampaignsTable,
    AuditError,
    AuditNoSpend,
  },
  mixins: [copy],
  props: {
    data: { type: Array, required: true },
    hasProAccount: { type: Boolean, required: true },
  },
  data() {
    return {
      panels: [0],
      auditName: '',
      dates: null,
      auditMerchant: '',
      auditMarket: '',
      auditCampaigns: [],
      auditBrandSearchTerms: [],
      auditwastedSpendClickThreshold: '1',
      auditZeroDaysSpend: false,
      marketCampaigns: [],
      valid: false,
      isSubmitting: false,
      errorComponent: '',
    }
  },
  computed: {
    validationRules() {
      return {
        name: [(val) => val.length > 0 || 'Name this report'],
        merchant: [(val) => val !== null || 'Select an account'],
        marketplace: [(val) => val !== null || 'Select a marketplace'],
        wastedThreshold: [
          (value) =>
            (!isNaN(value) && Number.isInteger(Number(value)) && value >= 0) ||
            '"Enter an positive integer',
        ],
      }
    },
    merchants() {
      return this.data
        ? this.data.map((merchant) => ({
            id: merchant.id,
            text: merchant.name,
          }))
        : null
    },
    merchantData() {
      return this.auditMerchant !== ''
        ? this.data.find((merchant) => merchant.id === this.auditMerchant)
        : null
    },
    markets() {
      return (
        this.merchantData?.marketplaces.map((profile) => ({
          text: profile.marketplace.name,
          flag: profile.marketplace.flag,
        })) ?? []
      )
    },
    market() {
      return this.merchantData !== null
        ? this.merchantData.marketplaces.find(({ marketplace }) => {
            return marketplace.name === this.auditMarket
          })
        : null
    },
    minDate() {
      return this.market ? formatDate(new Date(this.market.startAt)) : undefined
    },
    marketCurrency() {
      return this.auditMerchant !== '' && this.auditMarket !== ''
        ? this.market.currency_symbol
        : null
    },
    startDateFormatted() {
      return !!this.dates && this.dates?.start
        ? formatDate(this.dates.start)
        : ''
    },
    endDateFormatted() {
      return !!this.dates && this.dates?.end
        ? formatDate(this.dates.end)
        : formatDate(yesterday)
    },
    campaignIds() {
      // if all campaigns are selected returns an empty array
      // (as this works as a filter and select all and none should have the same output)
      return this.auditCampaigns.length === this.marketCampaigns.length
        ? []
        : this.auditCampaigns.map((campaign) => `${campaign.id}`)
    },
    auditData() {
      return {
        date_from: this.startDateFormatted,
        date_to: this.endDateFormatted,
        merchant_name: this.merchantData?.name,
        merchant_type: this.merchantData?.type,
        profile_ids: `${this.market?.id}`,
        country_code: this.market?.marketplace.code[0].toUpperCase(),
        campaign_ids: this.campaignIds,
        brand_search_terms: this.auditBrandSearchTerms,
        wasted_spend_click_threshold: this.auditwastedSpendClickThreshold,
        exclude_zero_spend_days: this.auditZeroDaysSpend,
        report_name: this.auditName,
      }
    },
    isValidAudit() {
      if (this.hasProAccount) {
        return validateSchema(newAudit)(this.auditData)
      } else {
        return this.auditName && this.auditMerchant && this.auditMarket
      }
    },
  },
  watch: {
    auditMerchant() {
      this.auditMarket = ''
      this.dates = null
      this.marketCampaigns = []
    },
    auditMarket(newValue) {
      if (newValue.length > 0) {
        this.dates = {
          start:
            differenceInDays(yesterday, new Date(this.market.startAt)) >= 59
              ? subDays(yesterday, 59)
              : new Date(this.market.startAt),
          end: yesterday,
        }
        if (this.hasProAccount) {
          this.fetchMarketCampaigns()
        }
      }
    },
  },

  methods: {
    resetWastedSpendClick(ev) {
      if (ev.target.value.length === 0) {
        this.auditwastedSpendClickThreshold = 1
      }
    },
    onAuditDatesSelected() {
      if (this.hasProAccount) {
        this.fetchMarketCampaigns()
      }
    },

    onSubmitAudit() {
      if (this.$refs.auditForm.validate()) {
        this.requestAudit(this.auditData)
        this.isSubmitting = true
      }
    },
    async fetchMarketCampaigns() {
      const startDate = `?start_date=${this.startDateFormatted}`
      const endDate = `&end_date=${this.endDateFormatted}`
      const url = `${this.market.campaigns}${startDate}${endDate}`

      try {
        const { data } = await this.$axios.get(url)
        this.marketCampaigns = data
        this.auditCampaigns = []
      } catch (response) {
        this.$sentry.captureException(response)
      }
    },
    async requestAudit(auditSettings) {
      try {
        const { data } = await this.$axios.post('/audit/', auditSettings)
        return data.report_id
          ? this.$router.push({
              name: 'reports-waiting',
              params: {
                urid: data.report_id,
              },
            })
          : ((this.isSubmitting = false), (this.errorComponent = 'audit-error'))
      } catch ({ response }) {
        this.isSubmitting = false
        this.errorComponent =
          response.status === 424 ? 'audit-no-spend' : 'audit-error'
        this.$sentry.captureException(response)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.shrink-60 >>> .v-input__slot {
  max-width: 60px;
}
.shrink-60 >>> .v-messages__message {
  font-weight: 300;
}

.is-outlined >>> .v-input__slot {
  border: 1px solid $primary;
  font-size: 0.8rem;
  font-weight: 700;
  color: $primary;
}

.is-outlined >>> label.v-label.theme--light {
  font-size: 0.8rem;
  font-weight: 700;
  color: $primary;
}

.container >>> .alert {
  margin: 0 0 1em 0;
}

.v-expansion-panels {
  margin: 2em 0;
}
.v-expansion-panel-header {
  padding: 0;
  color: $primary;
  font-weight: bold;
  font-size: 1.2rem;
}

.v-expansion-panel >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
.v-expansion-panel >>> .v-expansion-panel-header {
  flex-direction: row-reverse;
}

.v-input--switch--inset >>> .v-input__prepend-outer {
  position: absolute;
  left: -2px;
  z-index: 10000;
}

.v-input--switch--inset >>> .v-input--switch__thumb {
  background-color: white;
}

.v-input--selection-controls {
  margin-top: 0;
}
</style>
