






































































import Vue from 'vue'
import { TableHeader } from '~/types/table'
import { checkIfIsThisMonth } from '~/utils/date'
import {
  formatCurrencyUsingAbbreviations,
  formatCurrencyWithTwoDecimals,
} from '~/utils/currency'
import Tooltip from '~/components/BaseTooltip.vue'
import BaseCSVDownloadBtn from '~/components/BaseCSVDownloadBtn.vue'

export default Vue.extend({
  name: 'MonthlyResults',
  components: {
    BaseCSVDownloadBtn,
    Tooltip,
  },
  props: {
    subTitle: {
      type: String,
      required: false,
      default: null,
    },
    tBodys: {
      type: Array,
      required: true,
    },
    fileSuffix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: 0,
      csvTableFields: {},
      tHeaders: [
        {
          title: '',
          key: 'period',
          type: 'date',
          style: 'width: 10%',
        },
        {
          title: 'Ad spend',
          key: 'total_spend',
          type: 'double',
          tooltip: {
            icon: 'info',
            text: 'Ad spend - Total amount you spent on Amazon ads',
          },
        },
        {
          title: 'Ad sales',
          key: 'total_ad_sales',
          type: 'double',
          tooltip: {
            icon: 'info',
            text: 'Ad sales - Revenue from all sales resulting from ads',
          },
        },
        {
          title: 'New customers',
          key: 'new_customers',
          type: 'int',
          tooltip: {
            icon: 'info',
            text: 'Number of unique new customers from both ad and organic orders',
          },
        },
        {
          title: 'CAC',
          key: 'cac',
          type: 'doubleString',
          tooltip: {
            icon: 'info',
            text: 'Customer acquisition cost is the average amount you paid to acquire a new customer that month (Ad spend / No. of new customers)',
          },
        },
        {
          title: 'First purchase profit',
          key: 'first_purchase_profit_per_customer',
          type: 'doubleString',
          tooltip: {
            icon: 'info',
            text: "The average profit from a new customer's first purchase that month (LTV at first purchase)",
          },
        },
        {
          title: 'Profit per customer',
          key: 'profit_per_customer',
          type: 'doubleString',
          tooltip: {
            icon: 'info',
            text: 'The average, cumulative net profit per customer that month (First Purchase Profit - CAC)',
          },
        },
        {
          title: 'Profit change per customer',
          key: 'profit_change_per_customer',
          type: 'calculation',
          calculation: {
            type: 'subSpecial',
            fields: ['profit_per_customer'],
          },
          tooltip: {
            icon: 'info',
            text: 'The amount Profit per customer changed compared to the previous month',
          },
        },
        {
          title: 'TACoS',
          key: 'tacos',
          type: 'calculation',
          calculation: {
            type: 'divide',
            fields: ['total_spend', 'total_sales'],
            by: 100,
          },
          tooltip: {
            icon: 'info',
            text: 'Total Advertising Cost of Sale - The percentage of sales revenue spent on advertising (Ad spend / Total sales x 100)',
            last: true,
          },
        },
      ] as Array<TableHeader>,
    }
  },
  computed: {
    csvTableData() {
      const tableArray = []

      for (let i = 0; i < this.tBodys.length; i++) {
        const tacos =
          (this.tBodys[i].total_spend / this.tBodys[i].total_sales) * 100

        const add = i + 1

        tableArray.push({
          month: checkIfIsThisMonth(this.tBodys[i].period),
          add_spend:
            this.tBodys[i].total_spend !== null &&
            this.tBodys[i].total_spend !== '0.00'
              ? Math.round(this.tBodys[i].total_spend)
              : '',
          ad_sales:
            this.tBodys[i].total_ad_sales !== null
              ? Math.round(this.tBodys[i].total_ad_sales)
              : '',
          new_customers:
            this.tBodys[i].new_customers !== null
              ? this.tBodys[i].new_customers
              : '',
          cac:
            this.tBodys[i].cac !== null
              ? Math.round(this.tBodys[i].cac * 100) / 100
              : '',
          first_purchase_profit:
            this.tBodys[i].first_purchase_profit_per_customer.toFixed(2),
          profit_per_customer: this.tBodys[i].profit_per_customer.toFixed(2),
          profit_change_per_customer:
            add < this.tBodys.length
              ? (
                  this.tBodys[i].profit_per_customer -
                  this.tBodys[add].profit_per_customer
                ).toFixed(2)
              : '',
          tacos:
            isNaN(tacos) || tacos === Infinity ? '' : (tacos / 100).toFixed(3),
        })
      }

      return tableArray
    },

    // Format the table data props.
    tableData() {
      const tableInfomation = {}
      const tableData = []

      for (let i = 0; i < this.tBodys.length; i++) {
        const keys = Object.keys(this.tBodys)
        const values = Object.values(this.tBodys)

        tableInfomation[keys[i]] = values[i]

        tableData.push(tableInfomation[i])
      }

      return tableData
    },
    currency(): string {
      return this.$store.getters['analytics/currency']
    },
    currencyCode(): string {
      return this.$store.getters['analytics/getCurrencyCode']
    },
  },
  created() {
    this.csvTableFields.month = 'Month'
    this.csvTableFields.add_spend = 'Ad spend (' + this.currency + ')'
    this.csvTableFields.ad_sales = 'Ad Sales (' + this.currency + ')'
    this.csvTableFields.new_customers = 'New Customers'
    this.csvTableFields.cac = 'CAC (' + this.currency + ')'
    this.csvTableFields.first_purchase_profit =
      'First Purchase Profit (' + this.currency + ')'
    this.csvTableFields.profit_per_customer =
      'Profit Per Customer (' + this.currency + ')'
    this.csvTableFields.profit_change_per_customer =
      'Profit Change Per Customer (' + this.currency + ')'
    this.csvTableFields.tacos = 'TACoS'
  },
  methods: {
    // Process the column data to display correct information.
    processColumnData(type, column, index, calculation, secondaryIndex) {
      // Initial check for data passed throught props.
      if (column == null) {
        return this.formatColumns(column)
      }

      // Check for calculations needed.
      if (type === 'calculation' && calculation !== undefined) {
        if (
          this.tableData[index] !== undefined &&
          this.tableData[index + 1] !== undefined
        ) {
          return this[calculation.type](index, calculation, secondaryIndex)
        }

        return '-'
      }

      // If the type is an integer.
      if (type === 'int') {
        return this.formatColumns(column)
      }

      // If the type is a date.
      if (type === 'date') {
        return checkIfIsThisMonth(column)
      }

      // If the type is a double.
      if (type === 'double') {
        if (column === '0.00') {
          column = '-'
        }
        return formatCurrencyUsingAbbreviations(this.currency, column)
      }

      // If the type is a double in a string format.
      if (type === 'doubleString') {
        return formatCurrencyWithTwoDecimals(this.currency, parseFloat(column))
      }
    },
    // function that has been set from the calculation Object.
    // Subtract special: current field - previous field.
    subSpecial(index, calculation) {
      const aux =
        this.tableData[index][calculation.fields[0]] -
        this.tableData[index + 1][calculation.fields[0]]
      return formatCurrencyWithTwoDecimals(this.currency, aux)
    },
    // function that has been set from the calculation Object.
    // Divide: 2 fields that have been provided from the calculation object and what it needs to be divided by.
    divide(index, calculation, secondaryIndex) {
      if (this.tHeaders[secondaryIndex].calculation !== undefined) {
        const divide =
          (this.tableData[index][
            this.tHeaders[secondaryIndex].calculation.fields[0]
          ] /
            this.tableData[index][
              this.tHeaders[secondaryIndex].calculation.fields[1]
            ]) *
          calculation.by

        if (isNaN(divide) || divide === Infinity) {
          return '-'
        }

        return `${divide.toFixed(1)}%`
      }
      return '-'
    },
    // Format the column: Return '-' if 0 or undefined.
    // ToDo: Move to utils folder.
    formatColumns(n: number): any {
      if (n < 1 || n === undefined) return '-'
      return n
    },
    mouseOver(id) {
      this.show = id
    },
    mouseLeave() {
      this.show = 0
    },
  },
})
