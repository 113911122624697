




















import { CHART_SIZE } from '../sizes'
import Vue from 'vue'

export default Vue.extend({
  name: 'ChartLegend',
  props: {
    text: { type: String, required: true },
    color: { type: String, required: true },
  },
  computed: {
    barsLegendTextAttrs(): { x: number; y: number } {
      const x = CHART_SIZE.width * 0.4
      const y = CHART_SIZE.marginTop * 0.7
      return { x, y }
    },
    barsLegendSymbolAttrs(): { x: number; y: number; sideWidth: number } {
      const sideWidth = CHART_SIZE.base_unit * 0.08
      const x = this.barsLegendTextAttrs.x - sideWidth * 1.5
      const y = this.barsLegendTextAttrs.y - CHART_SIZE.base_unit * 0.07
      return { x, y, sideWidth }
    },
  },
})
