import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { auditData } from '@/utils/audit'
import { Country } from '@/utils/marketplace'
import { RootState } from './index'

export type AuditApi = {
  id: string
  created_at: string
  params: string
}
export type AuditParams = {
  name: string
  dateTo: string
  dateFrom: string
  countryCode: string
  countryFlag: string
  account: string
  id: string[]
  period: number
}
export type AuditData = {
  id: string
  created: string
  name: string
  date: {
    to: string
    from: string
  }
  country?: Country
  account: string
  profile: string
}

export type Quota = {
  used: number
  limit: number
}
type AuditState = {
  audits?: AuditData[]
  quota: Quota
}

export const state = (): AuditState => ({
  audits: undefined,
  quota: { used: 0, limit: -1 },
})

export type AuditModuleState = ReturnType<typeof state>

export const getters: GetterTree<AuditModuleState, RootState> = {
  audits: (state): AuditData[] | undefined => state.audits,
  quota: (state): Quota => state.quota,
  quotaReached: (state): boolean =>
    state.quota.used ? state.quota.used >= state.quota.limit : false,
}

export const mutations: MutationTree<AuditModuleState> = {
  audits(state, audits: AuditData[]) {
    state.audits = audits
  },
  auditQuota(state, quota: Quota) {
    state.quota = quota
  },
}

export const actions: ActionTree<AuditModuleState, RootState> = {
  setAudits({ commit }, data: { audits: AuditApi[]; quota: Quota }) {
    commit('audits', auditData(data.audits))
    commit('auditQuota', data.quota)
  },
}
