import Vue from 'vue'
import { decimal, toCapitalCase, toMatchType } from '~/utils/format'

Vue.filter('matchTypeDisplay', (value) => toMatchType(value))

Vue.filter('concatMatchTypes', (value) => {
  const types = value.map((v) => toCapitalCase(toMatchType(v)))
  return types
    .slice(0, types.length - 1)
    .join(', ')
    .concat(` and ${types[types.length - 1]}`)
})

Vue.filter('number', (value, currencySymbol) => {
  if (typeof value === 'number') {
    return currencySymbol === null
      ? decimal(value)
      : `${currencySymbol}${decimal(value)}`
  } else {
    return value
  }
})
