<template>
  <api-data
    :url="request.url"
    :on="canFetch"
    :method="request.method"
    @success="request.onSuccess"
    @error="request.onError"
  >
    <div>
      <div class="container">
        <loader v-model="loading" message="Loading your accounts" />
        <div v-if="!loading">
          <h1 class="primary--text font-weight-bold text-lg">New audit</h1>
          <alert-quota v-if="quotaReached" :limit="quota.limit" />
          <api-data-error v-if="error" :message="error" />
          <audit-form
            v-else-if="!quotaReached && accounts && marketplacesAvailable"
            :data="merchantsOnboarded"
            :has-pro-account="hasProAccount"
          />
        </div>
      </div>
    </div>
  </api-data>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ApiData from '@/components/logic/ApiData'
import AuditForm from '@/components/audit/new/AuditForm'
import AlertQuota from '@/components/audit/Alerts/AuditQuota'
import ApiDataError from '@/components/errors/ApiDataError'
import Loader from '~/components/BaseLoader.vue'

export default {
  components: { ApiData, AuditForm, Loader, AlertQuota, ApiDataError },
  data() {
    return {
      request: {
        url: '',
        onSuccess: () => {},
        onError: () => {},
      },
      canFetch: false,
      error: null,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      quota: 'audit/quota',
      quotaReached: 'audit/quotaReached',
      hasProAccount: 'user/hasProAccount',
      accounts: 'user/accounts',
      marketplacesAvailable: 'merchants/marketplacesAvailable',
      merchantsOnboarded: 'merchants/merchantsOnboarded',
    }),
  },
  watch: {
    accounts(accountsList) {
      // Accounts initial value is Null. On the first render the value
      // changes from Null to an Array.
      accountsList.length > 0
        ? this.getAudits()
        : this.$router.push({ name: 'reports' })
    },
    marketplacesAvailable(hasMarketplacesOnboarded) {
      if (!hasMarketplacesOnboarded) {
        this.$router.push({ name: 'reports' })
      }
    },
  },
  mounted() {
    return this.accounts ? this.getAudits() : this.getAccounts()
  },
  methods: {
    ...mapActions({
      setAmazonAccounts: 'user/setAmazonAccounts',
      setAudits: 'audit/setAudits',
    }),
    async getAccounts() {
      await new Promise((resolve) => {
        this.request = {
          url: 'user/amazon_users',
          method: 'get',
          onSuccess: (response) => {
            this.setAmazonAccounts(response.data.amazon_users)
            this.canFetch = false
            this.error = null
          },
          onError: () =>
            (this.error = {
              title: "We couldn't retrieve your Amazon accounts",
              content:
                'Please try again in a few minutes, or contact us using the chat button below for help.',
            }),
        }
        resolve()
      })
      this.canFetch = true
    },
    async getAudits() {
      await new Promise((resolve) => {
        this.request = {
          url: 'audit/',
          method: 'get',
          onSuccess: (response) => {
            this.setAudits(response.data)
            this.canFetch = false
            this.error = null
            this.loading = false
          },
          onError: () => {
            this.error = {
              title: "We couldn't retrieve your audits at this moment",
              content:
                'Please try again in a few minutes, or contact us using the chat button below for help.',
            }
            this.loading = false
          },
        }
        resolve()
      })
      this.canFetch = true
    },
  },
  head() {
    return {
      title: 'New Audit ',
    }
  },
}
</script>
