






















import Vue from 'vue'

export default Vue.extend({
  props: {
    title: { type: String, required: true },
    description: { type: String, required: false, default: null },
    value: { type: String, required: true },
    classes: { type: String, required: false, default: null },
  },
})
