// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".centered{display:flex;width:100vw;height:25vh;justify-content:center;align-items:center}.centered .welcome-message{width:620px;height:277px;left:410px;top:366.2px;background:#fff;border-radius:8px;padding:15px 25px}.centered .welcome-message .title{width:309px;height:47px;left:459px;top:400.2px;font-family:\"Be Vietnam\";font-style:normal;font-weight:700;font-size:32px;line-height:47px;color:#244c64}.centered .welcome-message .content{width:535px;height:48px;left:459px;top:463.2px;font-family:\"Be Vietnam\";font-style:normal;font-weight:400;font-size:16px;line-height:24px;color:#244c64}.centered .welcome-message .gmail-box{box-sizing:border-box;display:flex;flex-direction:row;align-items:center;padding:16px;grid-gap:8px;gap:8px;width:190px;height:51px;left:459px;top:543.2px;background:#fff;border:.5px solid #ccc;box-shadow:0 1px 3px #969696;border-radius:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletSize": "769",
	"desktopSize": "1024",
	"widescreenSize": "1216",
	"fullhdSize": "1408",
	"primary": "#164b65",
	"primary1": "#91a5b1",
	"primary2": "#244c64",
	"primary3": "#19394d",
	"secondary1": "#00e3b5",
	"secondary2": "#09987b",
	"secondary3": "#ccf9f0",
	"tertiary": "#faf607",
	"blue1": "#303fc6",
	"blue2": "#5463e7",
	"blue3": "#cdeaf9",
	"blue4": "#e7f7ff",
	"denim1": "#7c86a2",
	"denim2": "#6b7696",
	"denim": "#6b7696",
	"grey1": "#f3f3f3",
	"grey2": "#d8d8d8",
	"grey3": "#b1b1b1",
	"grey4": "#767676",
	"red1": "#e72216",
	"red2": "#fbd9d7",
	"orange": "#e6641e",
	"white1": "#f8f8f8",
	"white2": "#f8f8f8",
	"green": "#008540"
};
module.exports = ___CSS_LOADER_EXPORT___;
