import Vue from 'vue'
import VueWaypoint from 'vue-waypoint'

Vue.use(VueWaypoint)

export default ({ app }, inject) => {
  inject('updateNavigationOnScroll', (opts) => {
    if (opts.direction === undefined) {
      return
    }

    if (opts.going === VueWaypoint.map.GOING_IN) {
      app.store.commit('navigation/setPosition', opts.el)
    }
  })
}
