// Infered types
type App = { $env: { NOZZLE_URL: string | string[] } }
type InjectFn = (arg0: string, arg1: (feature: string, canEnable?: boolean) => boolean) => void

declare module 'vue/types/vue' {
  interface Vue {
    $isEnabled(feature: string, canEnable: boolean): boolean
  }
}

export default (app: App, inject: InjectFn) => {
  inject('isEnabled', (feature:string, canEnable = true) => {
    const flags: Record<string, boolean> = {
      CAMPAIGN_IMPORT: app.$env.NOZZLE_URL.includes('allans') && canEnable,
      ANALYTICS: canEnable,
    }

    return Boolean(flags[feature])
  })
}
