<template>
  <section id="audit-breakdown">
    <introduction
      v-waypoint="waypointOptions"
      :data="data.intro"
      :currency="currency"
      class="page-break-before"
    />
    <key-findings
      v-waypoint="waypointOptions"
      :data="data.wasted"
      :types="types"
      :currency="currency"
    />
    <aggregate-match-type v-waypoint="waypointOptions" :data="data.findings" />
    <match-type-summary
      v-for="match in data.matchType"
      :key="`match-breakdown-${match.type}`"
      v-waypoint="waypointOptions"
      :match="match"
      :currency="currency"
    />
    <premium-analysis />
    <budget-efficiency
      :data="data.budget"
      :period="date.period"
      :currency="currency"
    />
    <search-section :table-data="data.search" :currency="currency" />
  </section>
</template>

<script>
/**
 * Lazy load the components for better chunking
 */
const components = {
  Introduction: () =>
    import('~/components/audit/template/section/breakdown/Introduction'),
  KeyFindings: () =>
    import('~/components/audit/template/section/breakdown/KeyFindings'),
  AggregateMatchType: () =>
    import('~/components/audit/template/section/breakdown/AggregateMatchType'),
  MatchTypeSummary: () =>
    import('~/components/audit/template/section/breakdown/MatchTypeSummary'),
  PremiumAnalysis: () =>
    import('~/components/audit/template/section/breakdown/PremiumAnalysis'),
  BudgetEfficiency: () =>
    import('~/components/audit/template/section/breakdown/BudgetEfficiency'),
  SearchSection: () =>
    import('~/components/audit/template/section/breakdown/SearchTerm'),
}

export default {
  components,
  props: {
    data: {
      type: Object,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    hasProAccount: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    waypointOptions() {
      return {
        callback: this.updateNav,
        active: true,
        options: this.$store.getters['navigation/getNavigationOptions'],
      }
    },
  },
  methods: {
    updateNav({ el, going, direction }) {
      this.$updateNavigationOnScroll({ el, going, direction })
    },
  },
}
</script>
