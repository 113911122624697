import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default (ctx) => {
  const vuetify = new Vuetify({
    theme: {
      light: false,
      themes: {
        light: {
          primary: '#244c64',
          'primary-header' : '#164b65',

          secondary: '#00edb9',

          red: '#ec4338',          
          'green-brand': '#039b7d',
          'blue-button': '#303fc6',
          
          'grey-1': '#f3f3f3',
          'grey-2': '#d8d8d8',
          'grey-3': '#b1b1b1',
          'grey-4': '#767676',
        },
      },
    },
  })

  ctx.app.vuetify = vuetify
  ctx.$vuetify = vuetify.framework
}
