



















































import { CHART_SIZE } from '../sizes'
import Vue from 'vue'

interface Circle {
  cx: number
  cy: number
  radius: number
}

interface Line {
  x1: number
  y1: number
  x2: number
  y2: number
}

export default Vue.extend({
  name: 'Legend',
  computed: {
    barsLegendTextAttrs(): { x: number; y: number } {
      const x = CHART_SIZE.width * 0.5 - CHART_SIZE.base_unit * 0.3
      const y = CHART_SIZE.marginTop * 0.75 - CHART_SIZE.base_unit * 0.05
      return { x, y }
    },
    barsLegendSymbolAttrs(): { x: number; y: number; sideWidth: number } {
      const x = this.barsLegendTextAttrs.x - CHART_SIZE.base_unit * 0.82
      const y = this.barsLegendTextAttrs.y - CHART_SIZE.base_unit * 0.07
      const sideWidth = CHART_SIZE.base_unit * 0.08
      return { x, y, sideWidth }
    },
    lineLegendTextAttrs(): { x: number; y: number } {
      const x = CHART_SIZE.width * 0.5 + CHART_SIZE.base_unit * 0.05
      const y = CHART_SIZE.marginTop * 0.75 - CHART_SIZE.base_unit * 0.05
      return { x, y }
    },
    lineLegendSymbolAttrs(): { circle: Circle; line: Line } {
      const x = CHART_SIZE.base_unit * 0.1
      const y = this.lineLegendTextAttrs.y - CHART_SIZE.base_unit * 0.03

      return {
        circle: {
          cx: this.lineLegendTextAttrs.x - x,
          cy: y,
          radius: CHART_SIZE.axisYDataPointRadius,
        },
        line: {
          x1: this.lineLegendTextAttrs.x - x - CHART_SIZE.base_unit * 0.06,
          y1: y,
          x2: this.lineLegendTextAttrs.x - x + CHART_SIZE.base_unit * 0.06,
          y2: y,
        },
      }
    },
  },
})
