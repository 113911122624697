<template>
  <div class="account js-account primary--text mt-4">
    <div
      class="account-header js-account-toggle d-flex justify-space-between"
      @click="toggleAccount(merchantListByAccount(account))"
    >
      <div class="d-flex">
        <fa
          :icon="['fal', 'chevron-right']"
          :class="`text-lg mr-4 ${showMerchants ? 'rotate-90' : 'rotate-0'}`"
        />
        <div class="font-weight-bold">
          {{ account.email }}
        </div>
      </div>
      <div v-if="showMerchants" class="d-flex">
        <span class="thead mr-4"><b>Ads</b></span>
        <span class="thead mr-2"><b>Orders</b></span>
      </div>
    </div>
    <div v-if="showMerchants" class="account-content">
      <empty-account
        v-if="!account.merchants || Object.keys(account.merchants).length === 0"
      />
      <div v-else>
        <div
          v-for="([merchantId, merchant], id) in merchantListByAccount(account)"
          :key="merchantId"
          class="js-account-merchant"
          :class="{ 'mb-8': id + 1 < merchantListByAccount(account).length }"
        >
          <div
            class="d-flex align-center justify-space-between font-weight-bold"
          >
            <div
              class="d-flex aling-center justify-space-between w-100pct position-relative"
            >
              <div class="d-flex align-center w-70pct">
                <div v-if="merchant.storeName !== null" class="text-truncate">
                  {{ merchant.storeName + ' (' + merchant.name + ')' }}
                </div>
                <div v-else class="text-truncate">
                  {{ merchant.name }}
                </div>
                <div
                  class="ml-5 text-sm blue-button--text align-self-center nonwrap"
                >
                  <fa
                    :icon="['fas', 'external-link-alt']"
                    class="ui-link-icon"
                  />
                  <a :href="merchant.website" target="_blank" class="ui-link">
                    View in Seller Central
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="marketplace in merchant.marketplaces"
            :key="marketplace.id"
            style="flex-wrap: wrap"
            class="js-merchant-marketplace"
          >
            <div
              class="d-flex js-merchant justify-space-between align-center py-1 text-sm divider"
            >
              <a
                v-if="
                  marketplace.onboarded === true &&
                  marketplace.onboardingSPOrdersStatus === 'COMPLETE'
                "
                :href="linkToPerformanceSummaryPage(marketplace.id)"
                target="_blank"
                :class="`d-flex align-center
                  ${marketplace.onboarded === null ? 'greyscale' : ''}`"
              >
                <country
                  :flag="
                    countryData(marketplace.name)
                      ? countryData(marketplace.name).flag
                      : null
                  "
                />
                <span class="pl-2 font-weight-semibold">
                  {{
                    countryData(marketplace.name)
                      ? countryData(marketplace.name).name
                      : null
                  }}</span
                >
              </a>

              <div
                v-else
                :class="`d-flex align-center
                  ${marketplace.onboarded === null ? 'greyscale' : ''}`"
              >
                <country
                  :flag="
                    countryData(marketplace.name)
                      ? countryData(marketplace.name).flag
                      : null
                  "
                />
                <span class="pl-2">
                  {{
                    countryData(marketplace.name)
                      ? countryData(marketplace.name).name
                      : null
                  }}</span
                >
              </div>
              <div class="btns d-flex align-center">
                <div
                  v-if="marketplace.onboarded === true"
                  class="js-merchant-marketplace-onboarded font-weight-bold green-brand--text"
                  data-role="ads-onboarding-status"
                >
                  <fa :icon="['fas', 'check']" />
                  <span>Synced</span>
                </div>
                <div
                  v-else-if="
                    marketplace.onboarded === false &&
                    marketplace.onboardedProgress != null
                  "
                  class="d-flex align-center justify-center spinner"
                  data-role="ads-onboarding-status"
                >
                  <progress-circular
                    :stroke="2"
                    :width="15"
                    :theme="{ stroke: 'blue', bg: 'transparent' }"
                  />
                  <span
                    class="blue-button--text pl-2 font-weight-medium cursor-pointer text-left"
                  >
                    Syncing {{ marketplace.onboardedProgress }}%
                  </span>
                </div>
                <div
                  v-else-if="marketplace.requesting"
                  data-role="ads-onboarding-status"
                  class="d-flex justify-center"
                >
                  <progress-circular
                    :stroke="2"
                    :width="15"
                    :theme="{ stroke: 'blue', bg: 'transparent' }"
                  />
                </div>
                <!-- 
                  - If trialing and we have no marketplace with ads synced: 
                    - All "Sync" ads buttons should be enabled. 
                    - Once syncing process starts, the remaining "Sync" buttons should be disabled.
                  - If trialing and we have already a marketplace with ads synced: 
                    - All "Sync" buttons should be disabled.
                -->
                <base-button
                  v-else
                  label="Sync"
                  :primary="false"
                  :disabled="
                    isToDisableThisMarketplaceSyncButton(marketplace.id)
                  "
                  :loading="isToDisplayLoadingStatus(marketplace.id)"
                  size="sm"
                  class="js-merchant-marketplace-btn"
                  class-list="px-5"
                  data-role="ads-onboarding-btn"
                  @on-click="
                    onOnboardingProfile(account.id, merchantId, marketplace.id)
                  "
                />

                <div class="ml-4">
                  <div
                    v-if="marketplace.onboardingSPOrdersStatus === 'PENDING'"
                    class="d-flex align-center justify-center"
                    data-role="orders-onboarding-status"
                  >
                    <progress-circular
                      :stroke="2"
                      :width="15"
                      :theme="{ stroke: 'blue', bg: 'transparent' }"
                    />
                    <p class="ml-1 p-0 mb-0">Syncing</p>
                  </div>

                  <div
                    v-else-if="
                      marketplace.onboardingSPOrdersStatus === 'COMPLETE'
                    "
                    class="font-weight-bold green-brand--text"
                    data-role="orders-onboarding-status"
                  >
                    <fa :icon="['fas', 'check']" />
                    <span>Synced</span>
                  </div>
                  <!-- 
                  - To have "Authorize" orders button enabled we need to have ads synced
                  - If trialing and we have no marketplace with orders authorized: 
                    - "Authorize" orders should be enabled. 
                    - Once authorization process starts, every "authorize" buttons should be disabled.
                  - If trialing and we have already a marketplace with ads and orders authorized: 
                    - All "Authorize" orders buttons should be disabled.
                -->
                  <base-button
                    v-else-if="
                      marketplace.onboardingSPOrdersStatus === 'REVOKED'
                    "
                    label="Authorize"
                    :warning="true"
                    :tooltip="{
                      on: true,
                      msg: 'No valid authorization was found',
                    }"
                    :disabled="
                      isToDisableThisMarketplaceAuthorizeButton(marketplace)
                    "
                    :primary="false"
                    :loading="authorizeButtonWasPressed === marketplace.id"
                    size="sm"
                    class="js-merchant-marketplace-btn"
                    class-list="px-2"
                    data-role="orders-onboarding-btn"
                    @on-click="
                      authAndSyncOrders(
                        marketplace.countryCode,
                        account.amazon_user_id,
                        marketplace.id,
                        merchantId
                      )
                    "
                  />
                  <base-button
                    v-else
                    label="Authorize"
                    :disabled="
                      isToDisableThisMarketplaceAuthorizeButton(marketplace)
                    "
                    :primary="false"
                    :loading="authorizeButtonWasPressed === marketplace.id"
                    size="sm"
                    class="js-merchant-marketplace-btn"
                    class-list="px-2"
                    data-role="orders-onboarding-btn"
                    @on-click="
                      authAndSyncOrders(
                        marketplace.countryCode,
                        account.amazon_user_id,
                        marketplace.id,
                        merchantId
                      )
                    "
                  />
                </div>
              </div>
            </div>

            <div
              v-if="marketplace.onboardingSPOrdersStatus === 'PENDING'"
              class="d-flex pt-2"
              style="width: 100%"
            >
              <base-banner-messaging
                style="width: 100%; z-index: 1"
                class-list="py-2"
              >
                <span class="text text-sm">
                  We are in the process of downloading your data for this
                  marketplace. This can take between 24-48 hours (<i
                    >or longer for larger accounts</i
                  >).
                </span>
              </base-banner-messaging>
            </div>
          </div>
          <div v-if="authLinks[id].isShow" class="auth-link">
            Need someone else to authorize?
            <a
              class="ui-link"
              @click="
                processAuthLink(
                  merchant.name,
                  merchantId,
                  merchant.marketplaces,
                  account.amazon_user_id
                )
              "
            >
              Send a link
            </a>
          </div>
          <br v-if="id + 1 === merchantListByAccount(account).length" />
        </div>
      </div>
    </div>
    <!-- MODALS !-->
    <modal v-model="isAuthFormShown" :width="590">
      <template #modal="{ handleToggle }">
        <auth-form
          data-role="auth-form"
          :form-data="authFormData"
          @on-cancel="handleToggle"
        />
      </template>
    </modal>
  </div>
</template>
<script>
import BaseBannerMessaging from '../BaseBannerMessaging.vue'
import ProgressCircular from '@/components/BaseProgressCircular'
import AuthForm from '~/components/account/AuthForm.vue'
import Modal from '@/components/BaseModal'
import Country from '@/components/BaseCountry'
import BaseButton from '@/components/BaseButton'
import { getCountry } from '@/utils/marketplace'
import EmptyAccount from '@/components/alerts/EmptyAccount.vue'

export default {
  components: {
    AuthForm,
    Country,
    ProgressCircular,
    BaseButton,
    EmptyAccount,
    BaseBannerMessaging,
    Modal,
  },
  props: {
    account: { type: Object, required: true },
    isToBlockNewSyncs: { type: Boolean, required: false, default: null },
    isToBlockNewAuthorizeOrders: {
      type: Boolean,
      required: false,
      default: null,
    },
    paymentStatus: { type: String, required: false, default: null },
  },
  data() {
    return {
      showMerchants: false,
      isAuthFormShown: false,
      authLinks: [],
      authFormData: [],
      syncButtonWasPressed: null,
      authorizeButtonWasPressed: null,
    }
  },
  mounted() {
    this.merchantListByAccount(this.account).forEach(() =>
      this.authLinks.push({ isShow: false })
    )
  },
  methods: {
    isToDisableThisMarketplaceSyncButton(profileId) {
      // do not disable sync buttons if payment status is not trialist
      if (this.paymentStatus !== 'trialing') return false
      // else:
      // - check if the trialist has already click on any
      //   sync button - to have an immediate effect in the remaining ones (syncButtonWasPressed)
      // - if not, check amazon response flag (isToBlockNewSyncs)
      else if (
        this.syncButtonWasPressed !== null &&
        this.syncButtonWasPressed !== profileId
      ) {
        return true
      } else if (this.syncButtonWasPressed === null) {
        return this.isToBlockNewSyncs
      }
    },
    isToDisplayLoadingStatus(profileId) {
      if (this.syncButtonWasPressed === profileId) {
        return true
      }
      return false
    },
    isToDisableThisMarketplaceAuthorizeButton(marketplace) {
      // disable button if:
      // - ads are not synced or syncing
      // - if it's a trialist, but by any chance we have 2 marketplaces
      //   that passed the previous condition, then we should disable
      //   the remaining authorize buttons immediatly after the button is clicked
      // - isToBlockNewAuthorizeOrders is true based on amazon users response (not immediate) and also payment_status

      return (
        (!marketplace.onboarded && marketplace.onboardedProgress === null) ||
        (this.authorizeButtonWasPressed &&
          this.authorizeButtonWasPressed !== marketplace.id &&
          this.paymentStatus === 'trialing') ||
        this.isToBlockNewAuthorizeOrders
      )
    },
    linkToPerformanceSummaryPage(profileId) {
      return window.location.origin + '/analytics/' + profileId
    },
    merchantListByAccount(account) {
      return Object.entries(account.merchants)
    },
    countryData(marketplaceName) {
      return getCountry(marketplaceName)
    },
    onOnboardingProfile(accountId, merchantId, profileId) {
      this.syncButtonWasPressed = profileId
      this.$emit('onboard-profile', {
        accountId,
        merchantId,
        profileId,
      })
    },
    authAndSyncOrders(countryCode, amazonUserId, profileId, merchantId) {
      this.authorizeButtonWasPressed = profileId
      if (countryCode === 'UK') countryCode = 'GB'
      this.$emit('auth-and-sync-orders', {
        country_code: countryCode,
        amazon_user_id: amazonUserId,
        merchant_id: merchantId,
      })
    },
    toggleAccount(account) {
      for (let i = 0; i < account.length; i++) {
        for (let j = 0; j < account[i][1].marketplaces.length; j++) {
          if (
            account[i][1].marketplaces[j].onboarded &&
            account[i][1].marketplaces[j].onboardingSPOrdersStatus !==
              'PENDING' &&
            account[i][1].marketplaces[j].onboardingSPOrdersStatus !==
              'COMPLETE'
          ) {
            this.authLinks[i].isShow = true
          }
        }
      }
      this.showMerchants = !this.showMerchants
    },
    async processAuthLink(
      merchantName,
      merchantId,
      marketplaces,
      amazonUserId
    ) {
      const codes = []
      for (let i = 0; i < marketplaces.length; i++) {
        if (marketplaces[i].onboarded) {
          codes.push(marketplaces[i].countryCode)
        }
      }
      const url = `/sp/authorise-external/?merchant_name=${encodeURIComponent(
        merchantName
      )}&merchant_id=${merchantId}&country_codes=${codes
        .toString()
        .replace(
          'UK',
          'GB'
        )}&amazon_user_id=${amazonUserId}&next_url=https://external-auth.nozzle.ai/?ads_callback=true`

      const { data } = await this.$axios.get(url)

      this.authFormData = data.urls

      this.isAuthFormShown = !this.isAuthFormShown
    },
  },
}
</script>
<style lang="scss" scoped>
.account {
  border-radius: 4px;
  border: 1px solid $grey-3;

  &-header {
    display: flex;
    cursor: pointer;
    padding: 1.5rem;
  }
  &-merchant-type {
    text-align: right;
    font-size: 14px;
    font-weight: 400;
  }
  &-content {
    overflow: auto;
    padding: 0 2rem;
  }
}

.divider {
  border-bottom: 1px solid $grey-2;
}

.rotate-90,
.rotate-0 {
  transition-duration: 0.2s;
}

.greyscale {
  filter: grayscale(1);
}

.thead {
  width: 100px;
  text-align: center;
}

.btns {
  min-width: 216px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  & > div.spinner {
    padding-left: 0.2rem;
    max-width: 140px;
  }
  & > div:not(.spinner) {
    min-width: 100px;
    max-width: 100px;
  }
}
.js-merchant-marketplace-btn {
  & > .baseButton-label {
    padding: 0 1.5rem !important;
  }
}
.auth-link {
  text-align: right;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;

  &a {
    color: red;
  }
}
</style>
