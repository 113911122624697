


























import Vue from 'vue'
import analytics from '~/mixins/analytics'
import Dashboard from '~/components/analytics/PurchaseIntervals/Dashboard.vue'

export default Vue.extend({
  components: {
    Dashboard,
  },
  mixins: [analytics],
  middleware: 'account_check',
  head() {
    return {
      title: `Purchase Analysis - ${this.currentProfileName} | ${this.currentProfile?.country}`,
    }
  },
})
