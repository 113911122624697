<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 17 17"
    aria-labelledby="news"
    role="presentation"
  >
    <title id="news" lang="en">What's New icon</title>
    <path
      d="m16.406 7.875-1.25-.531-.531-1.25C14.594 6.03 14.562 6 14.5 6c-.094 0-.125.031-.156.094l-.531 1.25-1.25.531c-.032 0-.063.063-.063.125 0 .063.031.125.063.156l1.25.531.53 1.22c.032.062.063.093.157.093.063 0 .094-.031.125-.094l.531-1.219 1.25-.53c.032-.032.094-.095.094-.157 0-.063-.063-.125-.094-.125Zm-4.531 1.5-3.281-.5-1.469-2.969C7 5.656 6.781 5.5 6.5 5.5h-.031c-.282 0-.532.156-.625.406L4.375 8.875l-3.281.5c-.594.063-.813.813-.407 1.219l2.375 2.312-.562 3.281v.125a.7.7 0 0 0 .719.688c.125 0 .219 0 .344-.063l2.906-1.562 2.937 1.563c.094.03.219.062.344.062a.707.707 0 0 0 .719-.719v-.093l-.563-3.282 2.375-2.312c.406-.406.188-1.156-.406-1.219Zm-3.063 3.188.563 3.218-2.906-1.531-2.875 1.531.531-3.219-2.313-2.28 3.22-.47 1.437-2.937 1.468 2.938 3.22.468-2.345 2.281Zm4.907-9-1.531-.75-.75-1.532c-.188-.343-.72-.343-.907 0l-.75 1.5-1.531.781C8.094 3.626 8 3.813 8 4s.094.375.25.438l1.531.78.75 1.5c.094.188.25.282.469.282a.471.471 0 0 0 .438-.281l.75-1.5 1.53-.782A.49.49 0 0 0 14 4a.49.49 0 0 0-.281-.438Zm-2.125.812a.555.555 0 0 0-.219.25l-.375.75-.406-.75a.556.556 0 0 0-.219-.25L9.594 4l.781-.375c.094-.063.188-.125.219-.25l.406-.75.375.75c.031.125.125.188.219.25l.781.375-.781.375Z"
      :fill="
        !darkTheme && !active
          ? '#244c64'
          : !darkTheme && active
          ? '#00E3B5'
          : '#FFFFFF'
      "
    />
  </svg>
</template>
<script>
export default {
  name: 'IconsWhatsNew',
  props: {
    width: {
      type: Number,
      required: false,
      default: 16,
    },
    height: {
      type: Number,
      required: false,
      default: 22,
    },
    classList: {
      type: String,
      required: false,
      default: '',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    darkTheme: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
