<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 16 22"
    aria-labelledby="audit"
    role="presentation"
    :class="classList"
  >
    <title id="audit" lang="en">Audit icon</title>
    <g
      id="Navigation"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Exportable-Icons" transform="translate(-33.000000, -20.000000)">
        <g id="Group" transform="translate(29.000000, 19.000000)">
          <g id="Nozzle-Audit-Icon" transform="translate(4.000000, 1.000000)">
            <polygon
              id="Path"
              fill="#F1F4F6"
              fill-rule="nonzero"
              points="12.46036 6.214626 7.10699 6.214626 7.10699 0.840169"
            />
            <path
              id="Path"
              d="M12.46069,9.7537 L12.46069,6.214714 L7.10732,0.838563 L0.6644,0.838563 C0.545138,0.841225 0.431816,0.88935 0.349118,0.972422 C0.26642,1.055505 0.221078,1.166803 0.222902728,1.282061 L0.222902728,17.28694 C0.221078,17.402 0.266486,17.51321 0.349228,17.59593 C0.43197,17.67876 0.545303,17.7265 0.6644,17.7287 L3.857139,17.7287 C3.64034,17.09708 3.52297,16.42157 3.52297,15.71966 C3.52297,12.19735 6.4779,9.34197 10.12297,9.34197 C10.9461,9.34197 11.73403,9.48761 12.46069,9.7537 Z"
              :fill="
                !darkTheme && !active
                  ? '#244c64'
                  : !darkTheme && active
                  ? '#00E3B5'
                  : '#FFFFFF'
              "
            />
            <path
              id="Shape"
              d="M10.20624,20.13143 C10.78935,20.13143 11.34639,20.02385 11.85745,19.82805 L13.68125,21.72412 C14.02984,22.08657 14.59458,22.0869 14.94273,21.725 C15.29088,21.3631 15.29044,20.77592 14.94185,20.41347 L13.41428,18.82529 C14.20298,18.04451 14.68896,16.97696 14.68896,15.79963 C14.68896,13.40735 12.6819,11.46794 10.20624,11.46794 C7.73047,11.46794 5.72341,13.40735 5.72341,15.79963 C5.72341,18.19202 7.73047,20.13143 10.20624,20.13143 Z M12.95811,15.77873 C12.95811,17.24635 11.72699,18.43611 10.20822,18.43611 C8.68945,18.43611 7.45822,17.24635 7.45822,15.77873 C7.45822,14.31111 8.68945,13.12135 10.20822,13.12135 C11.72699,13.12135 12.95811,14.31111 12.95811,15.77873 Z"
              :fill="
                !darkTheme && !active
                  ? '#244c64'
                  : !darkTheme && active
                  ? '#00E3B5'
                  : '#FFFFFF'
              "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 16,
    },
    height: {
      type: Number,
      required: false,
      default: 22,
    },
    classList: {
      type: String,
      required: false,
      default: '',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    darkTheme: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
