







































import Vue, { PropType } from 'vue'
import * as d3 from 'd3'
import { ChartData, ChartDataPoint } from './types'
import { CHART_SIZE } from './sizes'
import {
  AxisX,
  AxisY1,
  AxisY1Title,
  AxisY2,
  AxisY2Title,
  DataSeriesBars,
  DataSeriesLine,
  Legend,
  Tooltip,
} from './components'
import { CACApiData } from '~/components/analytics/PerformanceSummary/CACChart/types'

export default Vue.extend({
  name: 'CACChart',
  components: {
    AxisX,
    AxisY1,
    AxisY1Title,
    AxisY2,
    AxisY2Title,
    DataSeriesBars,
    DataSeriesLine,
    Legend,
    Tooltip,
  },
  props: {
    cacData: {
      type: Array as PropType<CACApiData>,
      required: true,
    },
  },
  data: () => ({
    CHART_SIZE,
    axisXScale: {} as d3.ScaleBand<string>,
    axisY1Scale: {} as d3.ScaleLinear<number, number>,
    axisY2Scale: {} as d3.ScaleLinear<number, number>,
    chart: {} as SVGSVGElement,
    chartData: [] as ChartData,
    isReadyToDrawChart: false as boolean,
  }),
  watch: {
    customerRetentionData: {
      immediate: true,
      handler(): void {
        this.isReadyToDrawChart = false

        this.createChartData()
        this.createXAxisScale()
        this.createY1AxisScale()
        this.createY2AxisScale()

        this.isReadyToDrawChart = true
      },
    },
  },
  mounted() {
    this.chart = this.$refs.chart as SVGSVGElement
  },
  methods: {
    createChartData(): void {
      this.chartData = this.cacData.map((dataPoint: any) => ({
        xValue: dataPoint.date,
        yBar1Value: dataPoint.data.newCustomersNum,
        yBar2Value: dataPoint.data.repeatCustomersNum,
        yLineValue: dataPoint.data.cac,
      }))
    },
    createXAxisScale(): void {
      const domain = this.chartData.map((dataPoint) => dataPoint.xValue)
      const range = [
        CHART_SIZE.marginLeft, // X Axis Start
        CHART_SIZE.width - CHART_SIZE.marginRight, // X Axis End
      ]
      this.axisXScale = d3.scaleBand(domain, range).padding(0.5)
      // Padding in %. Effectively, this defines the spacing between the bars.
    },
    createY1AxisScale(): void {
      const yBars1Values = this.chartData.map(
        (dataPoint) => dataPoint.yBar1Value || 0
      )
      const yBars2Values = this.chartData.map(
        (dataPoint) => dataPoint.yBar2Value || 0
      )
      const domain = [0, Math.max(...yBars1Values, ...yBars2Values)]
      const range = [
        CHART_SIZE.height - CHART_SIZE.marginBottom, // Y Axis Bottom
        CHART_SIZE.marginTop, // Y Axis Top
      ]
      this.axisY1Scale = d3.scaleLinear(domain, range).nice()
    },
    createY2AxisScale(): void {
      const yLineValues = this.chartData.map(
        (dataPoint) => dataPoint.yLineValue || 0
      )
      const scalingFactor = 1.1 // We must add "breathing" room for the bar's labels
      const domain = [0, Math.max(...yLineValues) * scalingFactor]
      const range = [
        CHART_SIZE.height - CHART_SIZE.marginBottom, // Y Axis Bottom
        CHART_SIZE.marginTop, // Y Axis Top
      ]
      this.axisY2Scale = d3.scaleLinear(domain, range).nice()
    },
    showBarTooltip(event: MouseEvent, dataPoint: ChartDataPoint): void {
      // @ts-ignore
      this.$refs.tooltip.showBarTooltip(event, dataPoint)
    },
    showDataPointTooltip(event: MouseEvent, dataPoint: ChartDataPoint): void {
      // @ts-ignore
      this.$refs.tooltip.showDataPointTooltip(event, dataPoint)
    },
    hideBarTooltip(): void {
      // @ts-ignore
      this.$refs.tooltip.hideTooltip()
    },
    hideDataPointTooltip(): void {
      // @ts-ignore
      this.$refs.tooltip.hideTooltip()
    },
  },
})
