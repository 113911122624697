




















































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { format, isAfter, subDays } from 'date-fns'
import Chart from './Chart/index.vue'
import { PurchaseAnalysisData } from './types'
import ApiProvider from '~/components/logic/ApiProvider.vue'
import DashboardLoader from '~/components/analytics/DashboardLoader.vue'
import BaseBannerMessaging from '~/components/BaseBannerMessaging.vue'
import { ASIN, ASINCategory, DateRange } from '~/store/analytics'
import Tooltip from '~/components/BaseTooltip.vue'
import Alert from '~/components/alerts/Alert.vue'

type ApiSuccessResponse = {
  items: PurchaseAnalysisData
}

export default Vue.extend({
  components: {
    ApiProvider,
    Chart,
    DashboardLoader,
    BaseBannerMessaging,
    Tooltip,
    Alert,
  },
  data: () => ({
    apiEndpoint: { src: '' } as { src: string },
    isDataReady: false as boolean,
    purchaseAnalysisData: null as PurchaseAnalysisData | null,
    currentASIN: null as string | number | null,
    show: false,
  }),
  computed: {
    ...mapGetters({
      filters: 'analytics/getFilters',
      getAsins: 'analytics/getAsins',
      getProductAsins: 'analytics/getProductAsins',
    }),
    productInfo() {
      let info = this.getProductAsins?.find((i) => i.asin === this.currentASIN)

      return info?.product ? info.product : this.currentASIN
    },
    purchaseAnalysisApiUrl(): string {
      const { profileId } = this.$route.params
      return `/api/cached/profiles/${profileId}/purchase-analysis`
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler(filters_: {
        categorySelected: ASINCategory
        dateRange: DateRange
      }) {
        if (!filters_ || !filters_.dateRange.from || !filters_.dateRange.to) {
          return
        }
        if (
          filters_ &&
          filters_.categorySelected &&
          filters_.categorySelected.asins &&
          filters_.categorySelected.asins.length > 0
        ) {
          this.currentASIN = filters_.categorySelected.asins[0]
        } else if (this.getAsins && this.getAsins.length > 0) {
          this.currentASIN = this.getAsins[0]
        } else if (this.getAsins && this.getAsins.length === 0) {
          this.currentASIN = null
          this.isDataReady = true
          // there's no need to do the request
          return
        }

        this.isDataReady = false

        this.updateApiEndpoint({
          asins: this.currentASIN ? [this.currentASIN] : [],
          startDate: filters_.dateRange.from.value,
          endDate: filters_.dateRange.to.value,
        })
      },
    },
  },
  methods: {
    shortenedProductName(product) {
      if (product.length >= 50) {
        return product.substring(0, 50) + '...'
      }

      return product
    },
    mouseOver() {
      this.show = true
    },
    mouseLeave() {
      this.show = false
    },
    updateApiEndpoint({
      asins,
      startDate,
      endDate,
    }: {
      asins: Array<ASIN>
      startDate: Date
      endDate: Date
    }) {
      const queryString = new URLSearchParams()

      if (asins && asins.length > 0) {
        queryString.set('asins', asins.toString())
        queryString.set('start_date', format(startDate, 'yyyy-MM-dd'))
        queryString.set('end_date', format(endDate, 'yyyy-MM-dd'))

        const apiUrl = `${this.purchaseAnalysisApiUrl}?${queryString}`
        this.apiEndpoint = { src: apiUrl }
      }
    },

    handleApiSuccess(response: { data: ApiSuccessResponse }) {
      const { items } = response.data
      this.purchaseAnalysisData = items
      this.isDataReady = true
    },

    handleApiError() {
      this.isDataReady = true
    },
  },
})
