import { AuditApi, AuditData } from '@/store/audit'
import { daysInBetween, toDateString } from './date'
import { getCountry } from './marketplace'

export const auditData = (audits: AuditApi[]): AuditData[] =>
  audits.map((audit) => {
    const parsedParams = JSON.parse(audit.params)
    const country = parsedParams?.country_code
      ? getCountry(parsedParams.country_code)
      : undefined
    return {
      created: audit.created_at,
      id: audit.id,
      name: parsedParams.report_name,
      date: {
        to: toDateString(parsedParams.date_to, 'd LLL'),
        from: toDateString(parsedParams.date_from, 'd LLL'),
        period: daysInBetween(parsedParams.date_to, parsedParams.date_from),
      },
      country,
      account: parsedParams.merchant_name,
      profile: parsedParams.profile_ids,
    }
  })
