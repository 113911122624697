var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account js-account primary--text mt-4"},[_c('div',{staticClass:"account-header js-account-toggle d-flex justify-space-between",on:{"click":function($event){_vm.toggleAccount(_vm.merchantListByAccount(_vm.account))}}},[_c('div',{staticClass:"d-flex"},[_c('fa',{class:("text-lg mr-4 " + (_vm.showMerchants ? 'rotate-90' : 'rotate-0')),attrs:{"icon":['fal', 'chevron-right']}}),_vm._v(" "),_c('div',{staticClass:"font-weight-bold"},[_vm._v("\n        "+_vm._s(_vm.account.email)+"\n      ")])],1),_vm._v(" "),(_vm.showMerchants)?_c('div',{staticClass:"d-flex"},[_vm._m(0),_vm._v(" "),_vm._m(1)]):_vm._e()]),_vm._v(" "),(_vm.showMerchants)?_c('div',{staticClass:"account-content"},[(!_vm.account.merchants || Object.keys(_vm.account.merchants).length === 0)?_c('empty-account'):_c('div',_vm._l((_vm.merchantListByAccount(_vm.account)),function(ref,id){
var merchantId = ref[0];
var merchant = ref[1];
return _c('div',{key:merchantId,staticClass:"js-account-merchant",class:{ 'mb-8': id + 1 < _vm.merchantListByAccount(_vm.account).length }},[_c('div',{staticClass:"d-flex align-center justify-space-between font-weight-bold"},[_c('div',{staticClass:"d-flex aling-center justify-space-between w-100pct position-relative"},[_c('div',{staticClass:"d-flex align-center w-70pct"},[(merchant.storeName !== null)?_c('div',{staticClass:"text-truncate"},[_vm._v("\n                "+_vm._s(merchant.storeName + ' (' + merchant.name + ')')+"\n              ")]):_c('div',{staticClass:"text-truncate"},[_vm._v("\n                "+_vm._s(merchant.name)+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"ml-5 text-sm blue-button--text align-self-center nonwrap"},[_c('fa',{staticClass:"ui-link-icon",attrs:{"icon":['fas', 'external-link-alt']}}),_vm._v(" "),_c('a',{staticClass:"ui-link",attrs:{"href":merchant.website,"target":"_blank"}},[_vm._v("\n                  View in Seller Central\n                ")])],1)])])]),_vm._v(" "),_vm._l((merchant.marketplaces),function(marketplace){return _c('div',{key:marketplace.id,staticClass:"js-merchant-marketplace",staticStyle:{"flex-wrap":"wrap"}},[_c('div',{staticClass:"d-flex js-merchant justify-space-between align-center py-1 text-sm divider"},[(
                marketplace.onboarded === true &&
                marketplace.onboardingSPOrdersStatus === 'COMPLETE'
              )?_c('a',{class:("d-flex align-center\n                " + (marketplace.onboarded === null ? 'greyscale' : '')),attrs:{"href":_vm.linkToPerformanceSummaryPage(marketplace.id),"target":"_blank"}},[_c('country',{attrs:{"flag":_vm.countryData(marketplace.name)
                    ? _vm.countryData(marketplace.name).flag
                    : null}}),_vm._v(" "),_c('span',{staticClass:"pl-2 font-weight-semibold"},[_vm._v("\n                "+_vm._s(_vm.countryData(marketplace.name)
                    ? _vm.countryData(marketplace.name).name
                    : null))])],1):_c('div',{class:("d-flex align-center\n                " + (marketplace.onboarded === null ? 'greyscale' : ''))},[_c('country',{attrs:{"flag":_vm.countryData(marketplace.name)
                    ? _vm.countryData(marketplace.name).flag
                    : null}}),_vm._v(" "),_c('span',{staticClass:"pl-2"},[_vm._v("\n                "+_vm._s(_vm.countryData(marketplace.name)
                    ? _vm.countryData(marketplace.name).name
                    : null))])],1),_vm._v(" "),_c('div',{staticClass:"btns d-flex align-center"},[(marketplace.onboarded === true)?_c('div',{staticClass:"js-merchant-marketplace-onboarded font-weight-bold green-brand--text",attrs:{"data-role":"ads-onboarding-status"}},[_c('fa',{attrs:{"icon":['fas', 'check']}}),_vm._v(" "),_c('span',[_vm._v("Synced")])],1):(
                  marketplace.onboarded === false &&
                  marketplace.onboardedProgress != null
                )?_c('div',{staticClass:"d-flex align-center justify-center spinner",attrs:{"data-role":"ads-onboarding-status"}},[_c('progress-circular',{attrs:{"stroke":2,"width":15,"theme":{ stroke: 'blue', bg: 'transparent' }}}),_vm._v(" "),_c('span',{staticClass:"blue-button--text pl-2 font-weight-medium cursor-pointer text-left"},[_vm._v("\n                  Syncing "+_vm._s(marketplace.onboardedProgress)+"%\n                ")])],1):(marketplace.requesting)?_c('div',{staticClass:"d-flex justify-center",attrs:{"data-role":"ads-onboarding-status"}},[_c('progress-circular',{attrs:{"stroke":2,"width":15,"theme":{ stroke: 'blue', bg: 'transparent' }}})],1):_c('base-button',{staticClass:"js-merchant-marketplace-btn",attrs:{"label":"Sync","primary":false,"disabled":_vm.isToDisableThisMarketplaceSyncButton(marketplace.id),"loading":_vm.isToDisplayLoadingStatus(marketplace.id),"size":"sm","class-list":"px-5","data-role":"ads-onboarding-btn"},on:{"on-click":function($event){return _vm.onOnboardingProfile(_vm.account.id, merchantId, marketplace.id)}}}),_vm._v(" "),_c('div',{staticClass:"ml-4"},[(marketplace.onboardingSPOrdersStatus === 'PENDING')?_c('div',{staticClass:"d-flex align-center justify-center",attrs:{"data-role":"orders-onboarding-status"}},[_c('progress-circular',{attrs:{"stroke":2,"width":15,"theme":{ stroke: 'blue', bg: 'transparent' }}}),_vm._v(" "),_c('p',{staticClass:"ml-1 p-0 mb-0"},[_vm._v("Syncing")])],1):(
                    marketplace.onboardingSPOrdersStatus === 'COMPLETE'
                  )?_c('div',{staticClass:"font-weight-bold green-brand--text",attrs:{"data-role":"orders-onboarding-status"}},[_c('fa',{attrs:{"icon":['fas', 'check']}}),_vm._v(" "),_c('span',[_vm._v("Synced")])],1):(
                    marketplace.onboardingSPOrdersStatus === 'REVOKED'
                  )?_c('base-button',{staticClass:"js-merchant-marketplace-btn",attrs:{"label":"Authorize","warning":true,"tooltip":{
                    on: true,
                    msg: 'No valid authorization was found',
                  },"disabled":_vm.isToDisableThisMarketplaceAuthorizeButton(marketplace),"primary":false,"loading":_vm.authorizeButtonWasPressed === marketplace.id,"size":"sm","class-list":"px-2","data-role":"orders-onboarding-btn"},on:{"on-click":function($event){return _vm.authAndSyncOrders(
                      marketplace.countryCode,
                      _vm.account.amazon_user_id,
                      marketplace.id,
                      merchantId
                    )}}}):_c('base-button',{staticClass:"js-merchant-marketplace-btn",attrs:{"label":"Authorize","disabled":_vm.isToDisableThisMarketplaceAuthorizeButton(marketplace),"primary":false,"loading":_vm.authorizeButtonWasPressed === marketplace.id,"size":"sm","class-list":"px-2","data-role":"orders-onboarding-btn"},on:{"on-click":function($event){return _vm.authAndSyncOrders(
                      marketplace.countryCode,
                      _vm.account.amazon_user_id,
                      marketplace.id,
                      merchantId
                    )}}})],1)],1)]),_vm._v(" "),(marketplace.onboardingSPOrdersStatus === 'PENDING')?_c('div',{staticClass:"d-flex pt-2",staticStyle:{"width":"100%"}},[_c('base-banner-messaging',{staticStyle:{"width":"100%","z-index":"1"},attrs:{"class-list":"py-2"}},[_c('span',{staticClass:"text text-sm"},[_vm._v("\n                We are in the process of downloading your data for this\n                marketplace. This can take between 24-48 hours ("),_c('i',[_vm._v("or longer for larger accounts")]),_vm._v(").\n              ")])])],1):_vm._e()])}),_vm._v(" "),(_vm.authLinks[id].isShow)?_c('div',{staticClass:"auth-link"},[_vm._v("\n          Need someone else to authorize?\n          "),_c('a',{staticClass:"ui-link",on:{"click":function($event){return _vm.processAuthLink(
                merchant.name,
                merchantId,
                merchant.marketplaces,
                _vm.account.amazon_user_id
              )}}},[_vm._v("\n            Send a link\n          ")])]):_vm._e(),_vm._v(" "),(id + 1 === _vm.merchantListByAccount(_vm.account).length)?_c('br'):_vm._e()],2)}),0)],1):_vm._e(),_vm._v(" "),_c('modal',{attrs:{"width":590},scopedSlots:_vm._u([{key:"modal",fn:function(ref){
              var handleToggle = ref.handleToggle;
return [_c('auth-form',{attrs:{"data-role":"auth-form","form-data":_vm.authFormData},on:{"on-cancel":handleToggle}})]}}]),model:{value:(_vm.isAuthFormShown),callback:function ($$v) {_vm.isAuthFormShown=$$v},expression:"isAuthFormShown"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"thead mr-4"},[_c('b',[_vm._v("Ads")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"thead mr-2"},[_c('b',[_vm._v("Orders")])])}]

export { render, staticRenderFns }