<script>
export default {
  mounted() {
    this.$nuxt.error({
      status: 'auth',
      message: 'You are not currently authorized to view this page',
      link: '/user/account',
      label: 'My Settings',
    })
  },
  render() {
    return null
  },
}
</script>
