// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svgTooltip{width:200px;text-align:center}.svgTooltip p{color:#164b65}.svgTooltip small{color:#6b7696}.svgTooltip i{top:100%;margin-left:-12px;width:24px;overflow:hidden}.svgTooltip i,.svgTooltip i:after{position:absolute;left:50%;height:12px}.svgTooltip i:after{content:\"\";width:12px;transform:translate(-50%,-50%) rotate(45deg);border:1px solid #b1b1b1}.purchase-intervals-chart-tooltip{background-color:#fff;border-radius:4px;box-shadow:-1px 1px 19px 2px rgba(0,0,0,.2);display:none;font-size:12px;line-height:20px;padding:16px;position:absolute;margin-top:-10px;margin-left:25px;border:1px solid #b1b1b1;z-index:10}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletSize": "769",
	"desktopSize": "1024",
	"widescreenSize": "1216",
	"fullhdSize": "1408",
	"primary": "#164b65",
	"primary1": "#91a5b1",
	"primary2": "#244c64",
	"primary3": "#19394d",
	"secondary1": "#00e3b5",
	"secondary2": "#09987b",
	"secondary3": "#ccf9f0",
	"tertiary": "#faf607",
	"blue1": "#303fc6",
	"blue2": "#5463e7",
	"blue3": "#cdeaf9",
	"blue4": "#e7f7ff",
	"denim1": "#7c86a2",
	"denim2": "#6b7696",
	"denim": "#6b7696",
	"grey1": "#f3f3f3",
	"grey2": "#d8d8d8",
	"grey3": "#b1b1b1",
	"grey4": "#767676",
	"red1": "#e72216",
	"red2": "#fbd9d7",
	"orange": "#e6641e",
	"white1": "#f8f8f8",
	"white2": "#f8f8f8",
	"green": "#008540"
};
module.exports = ___CSS_LOADER_EXPORT___;
