






















































import Vue, { PropType } from 'vue'
import BaseCSVDownloadBtn from '@/components/BaseCSVDownloadBtn.vue'

export default Vue.extend({
  components: {
    BaseCSVDownloadBtn,
  },
  props: {
    customerRetentionData: {
      type: Array as PropType<CustomerRetentionData>,
      required: true,
    },
    fileSuffix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableFields: {
        order: 'Order',
        no_cust: 'No. of customers',
        retention_rate: 'Retention rate from prev order',
      },
      json_meta: [
        {
          key: 'charset',
          value: 'utf-8',
        },
      ],
    }
  },
  computed: {
    tableData() {
      const dataArray = []
      for (let i = 0; i < this.customerRetentionData.length; i++) {
        dataArray.push({
          order: this.customerRetentionData[i].order,
          no_cust: this.customerRetentionData[i].customersNum,
          retention_rate:
            this.customerRetentionData[i].retentionRate.toFixed(2),
        })
      }

      return dataArray
    },
  },
})
