<template>
  <v-row justify="end">
    <v-col align-self="center">
      <div class="font-weight-bold">
        {{ title }}
      </div>
    </v-col>
    <v-col cols="12" md="5">
      <span class="font-weight-bold body-2">Show: </span>
      <v-btn-toggle v-model="show" tile group>
        <v-btn value="All" text> All [{{ data.length }}] </v-btn>
        <v-btn value="Selected" text> Selected [{{ selection.length }}] </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col>
      <v-text-field
        v-model="searchTerm"
        :clearable="true"
        :flat="true"
        dense
        solo
        placeholder="Filter by campaign"
        prepend-inner-icon="mdi-magnify"
        class="is-outlined"
      />
    </v-col>
    <v-col cols="12">
      <v-data-table
        v-model="selection"
        :headers="headers"
        :items="rows"
        :height="`${rows.length > 0 ? 300 : 100}`"
        :search="searchTerm"
        :disable-pagination="true"
        item-key="id"
        fixed-header
        show-select
        hide-default-footer
        hide-default-header
        class="elevation-1"
      >
        <template v-slot:header="{ props }">
          <thead>
            <tr>
              <th v-for="header in props.headers" :key="header.value">
                <v-checkbox
                  v-if="header.value === 'data-table-select'"
                  v-model="selectedAllRows"
                  :indeterminate="selectedAllRows && isAllSelected"
                  @change="selectAllRows"
                />
                <span v-else @click="sortCol(header)">
                  {{ header.text }}
                  <v-icon size="14">{{ sortIcon(header.value) }}</v-icon>
                </span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.data-table-select="{ isSelected, item }">
          <v-checkbox
            :value="isSelected"
            @change="selectRow(item, isSelected)"
          />
        </template>
        <template v-slot:item.name="{ item }">
          <span class="font-weight-black">{{ item.name }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-icon :color="item.status.color" x-small>
            {{ item.status.icon }}
          </v-icon>
          <span v-capitalize :class="`${item.status.color}--text`">
            {{ item.status.value }}
          </span>
        </template>
        <template v-slot:item.spend="{ item }">
          <span class="is-title-size">{{ currency }}{{ item.spend }}</span>
        </template>
        <template v-slot:item.sales="{ item }">
          <span class="is-title-size">{{ currency }}{{ item.sales }}</span>
        </template>
        <template v-slot:item.acos="{ item }">
          <span class="is-title-size">{{ item.acos }}%</span>
        </template>
        <template v-slot:item.conversion="{ item }">
          <span class="is-title-size">{{ item.conversion }}%</span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import { percentage, integer } from '~/utils/format'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    selection: {
      type: Array,
      required: true,
    },
    currency: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      rows: [],
      show: 'All',
      searchTerm: '',
      selectedAllRows: false,
      selectedCol: { sortdesc: true },
      selectedRows: [],
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Campaign name',
          value: 'name',
          width: 300,
          isNumericalSorted: false,
        },
        {
          text: 'Status',
          value: 'status',
          width: 150,
          isNumericalSorted: false,
        },
        {
          text: 'Ad spend',
          value: 'spend',
          width: 100,
          isNumericalSorted: true,
        },
        {
          text: 'Ad sales',
          value: 'sales',
          width: 100,
          isNumericalSorted: true,
        },
        { text: 'ACoS', value: 'acos', width: 100, isNumericalSorted: true },
        {
          text: 'Conv. rate',
          value: 'conversion',
          width: 200,
          isNumericalSorted: true,
        },
      ]
    },
    rowsData() {
      return this.data.map((campaign) => ({
        id: campaign.campaignId,
        name: campaign.campaignName,
        status: this.statusData(campaign.status),
        spend: integer(campaign.cost),
        sales: integer(campaign.sales),
        acos: percentage(campaign.acos),
        conversion: percentage(campaign.conversion_rate_clicks),
      }))
    },
    isAllSelected() {
      const allRows =
        this.show === 'All' && this.selection.length !== this.data.length

      const selectedRows =
        this.show === 'Selected' && this.selection.length !== this.rows.length

      return allRows || selectedRows
    },
  },
  watch: {
    data(value) {
      if (value.length !== 0) {
        this.rows = this.rowsData
      } else {
        this.rows = []
        this.selectedRows = []
        this.$emit('update:selection', this.selectedRows)
      }
    },
    show(isShowing) {
      this.rows = isShowing === 'All' ? this.rowsData : this.selectedRows
    },
  },

  methods: {
    sortCol(header) {
      this.selectCol(header.value)
      const isSelected = this.selectedCol.value === header.value

      const sorted = this.rows.sort((a, b) => {
        const one = a[header.value]
        const two = b[header.value]

        if (header.isNumericalSorted) {
          return Number(one) - Number(two)
        } else {
          return typeof one !== 'object'
            ? one.localeCompare(two)
            : one.value.localeCompare(two.value)
        }
      })

      this.rows =
        isSelected && this.selectedCol.sortdesc ? sorted.reverse() : sorted
    },
    selectCol(col) {
      if (this.selectedCol.value === col) {
        this.selectedCol = {
          ...this.selectedCol,
          sortdesc: !this.selectedCol.sortdesc,
        }
      } else {
        this.selectedCol = {
          value: col,
          sortdesc: true,
        }
      }
    },
    selectAllRows() {
      if (this.selectedAllRows && this.show === 'All') {
        this.selectedRows = this.rowsData
      } else if (!this.selectedAllRows) {
        this.selectedRows = []
        this.rows = this.show === 'Selected' ? [] : this.rowsData
      }

      this.$emit('update:selection', this.selectedRows)
    },
    selectRow(item, isSelected) {
      this.selectedRows = !isSelected
        ? [...this.selectedRows, item]
        : this.selectedRows.filter((x) => x.id !== item.id)

      this.$emit('update:selection', this.selectedRows)
    },
    sortIcon(col) {
      return this.selectedCol.value === col && this.selectedCol.sortdesc
        ? 'mdi-arrow-down'
        : 'mdi-arrow-up'
    },
    statusData(status) {
      switch (status) {
        case 'active':
          return {
            value: status,
            color: 'primary',
            icon: 'mdi-check',
          }
        case 'paused':
          return {
            value: status,
            color: 'red',
            icon: 'mdi-pause',
          }
        default:
          return {
            value: status,
            color: 'grey',
            icon: 'mdi-archive-outline',
          }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.v-btn {
  letter-spacing: normal;
}

.v-data-table th {
  cursor: pointer;
}
.selected {
  font-weight: 700;
}
.v-input--selection-controls {
  margin: 0;
  padding: 0;
}
.v-input--selection-controls >>> .mdi-minus-box,
.v-input--selection-controls >>> .mdi-checkbox-marked {
  color: $blue;
}

.is-outlined >>> .v-input__slot {
  border: 1px solid $primary;
  font-size: 0.8rem;
}
.is-title-size {
  font-size: 1.2rem;
}
.v-input--selection-controls {
  margin: 0;
  padding: 0;
}

.v-data-table td {
  font-size: 0.9rem;
}
.v-item--active {
  font-weight: 700;
}
.v-item-group >>> .v-btn {
  text-transform: none;
}
</style>
