<template>
  <div class="px-8 py-8">
    <div
      class="has-background-light-grey d-flex flex-column align-center py-12 mt-4 text-center"
    >
      <fa
        :icon="['fal', error.status === 'auth' ? 'lock' : 'search']"
        class="grey--text fa-4x pb-4"
      />
      <h2 class="text-lg font-weight-bold mb-6">Oops. {{ error.message }}</h2>
      <nuxt-link
        v-if="error && error.link"
        :to="error.link"
        class="font-weight-bold text-sm ui-link"
        style="max-width: 650px"
      >
        <fa
          :icon="['fal', 'chevron-left']"
          style="text-decoration: none"
        /><span class="ml-2">Go back to {{ error.label }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */

export default {
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.has-background-light-grey {
  background: $white-1;
}
</style>
