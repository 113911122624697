










import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import * as d3 from 'd3'
import { CHART_SIZE } from '../sizes'
import { formatCurrency } from '~/utils/currency'

export default Vue.extend({
  name: 'AxisY2',
  props: {
    axisY2Scale: {
      type: Function as PropType<d3.ScaleLinear<number, number>>,
      required: true,
    },
  },
  data: () => ({ CHART_SIZE }),
  computed: {
    ...mapGetters({
      currencyCode: 'analytics/getCurrencyCode',
    }),
    userLang(): string {
      if (navigator.languages !== undefined) {
        return navigator.languages[0]
      } else {
        return navigator.language
      }
    },
  },
  mounted() {
    this.addAxisY()
  },
  methods: {
    addAxisY(): void {
      const axisGenerator = d3
        .axisRight(this.axisY2Scale)
        .tickFormat((d) => formatCurrency(this.currencyCode, this.userLang, +d))

      d3.select(this.$refs.axisY as SVGGElement)
        .call(axisGenerator)
        // D3 adds default font-size to the element the axis is attached to.
        // To control font-size via CSS, we must then clear font-size attr.
        .attr('font-size', null)
    },
  },
})
