














import Vue from 'vue'
export default Vue.extend({
  props: {
    type: {
      type: String,
      required: false,
      default: 'error',
      validator: (val: string) =>
        ['error', 'info', 'placeholder'].includes(val),
    },
    classList: {
      type: String,
      required: false,
      default: '',
    },
    close: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): { show: boolean } {
    return {
      show: true,
    }
  },
})
