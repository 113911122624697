







import Vue from 'vue'
import Loader from '~/components/BaseLoader.vue'
import TrialPromoBox from '~/components/trial/TrialPromoBox.vue'

export default Vue.extend({
  middleware: ['redirect'],
  components: {
    TrialPromoBox,
    Loader,
  },
  layout: 'noNav',
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    isEnabled(): Boolean {
      return this.$isEnabled(
        'ANALYTICS',
        this.$store.getters['user/hasAnalytics']
      )
    },
    isToRedirect(): Boolean {
      if (this.isEnabled) {
        window.$nuxt.$router.push('/')
      }
      return false
    },
  },
  mounted() {
    this.loading = false
  },
})
