



import CountryFlag from 'vue-country-flag'

export default {
  components: {
    CountryFlag,
  },
  props: {
    flag: {
      type: String as () => string | null,
      required: true,
    },
    size: {
      type: String,
      default: 'normal',
      validator: (val) =>
        ['normal', 'big', 'small'].includes(val.toLowerCase()),
    },
  },
}
