














import Vue from 'vue'
import { mapGetters } from 'vuex'
import Loader from '~/components/BaseLoader.vue'
import TrialPromo from '~/components/trial/TrialPromo.vue'
import Header from '~/components/BaseHeader.vue'
import AccountSyncIncomplete from '~/components/alerts/AccountSyncIncomplete.vue'

export default Vue.extend({
  components: {
    TrialPromo,
    Loader,
    Header,
    AccountSyncIncomplete,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      profile: 'analytics/getProfile',
      merchants: 'merchants/merchants',
      isProfileLoaded: 'analytics/getIsProfileLoaded',
    }),
    isEnabled(): boolean {
      return this.$isEnabled(
        'ANALYTICS',
        this.$store.getters['user/hasAnalytics']
      )
    },
  },
  watch: {
    profile() {
      if (this.profile && this.isEnabled) {
        if (this.$route.path !== `/analytics/${this.profile?.id}`) {
          window.$nuxt.$router.push(`/analytics/${this.profile?.id}`)
        }
        this.loading = false
      }
    },
    isProfileLoaded: {
      handler(newIsProfileLoaded) {
        if (newIsProfileLoaded === true) {
          this.loading = false
        }
      },
      immediate: true,
    },
  },
})
