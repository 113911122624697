








































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { formatDate } from '~/utils/date'
import { ASIN, ASINCategory } from '~/store/analytics'
import ApiProvider from '~/components/logic/ApiProvider.vue'
import { DateRange } from '~/components/BaseMonthPicker.vue'
import Chart from './Chart/index.vue'
import DashboardLoader from '../DashboardLoader.vue'
import FeaturedNumberBox from './FeaturedNumberBox.vue'

type ApiSuccessResponse = {
  items: Array<{
    days_lag: number
    customers: number
  }>
}

const INTERVALS: number[] = [0, 8, 16, 31, 61, 91, 121, 151, 180]

export default Vue.extend({
  components: {
    ApiProvider,
    Chart,
    DashboardLoader,
    FeaturedNumberBox,
  },
  data: () => ({
    apiEndpoint: { src: '' } as { src: string },
    groupedPurchaseIntervals: [] as GroupedPurchaseIntervals,
    isDataReady: false as boolean,
    purchaseIntervals: [] as PurchaseIntervals,
  }),
  computed: {
    ...mapGetters({
      filters: 'analytics/getFilters',
    }),

    bestRetargetingWindow(): string {
      if (this.groupedPurchaseIntervals.length === 0) {
        return '-'
      }

      if (this.groupedPurchaseIntervals.length === 1) {
        return `${this.groupedPurchaseIntervals[0].intervalsBucket} days`
      }

      let dataPointWithMaxCustomerNum = this.groupedPurchaseIntervals[0]

      // exclude last interval values as they are alredy a sum of all days > 180
      for (let i = 1; i < this.groupedPurchaseIntervals.length - 1; i++) {
        const dataPoint = this.groupedPurchaseIntervals[i]

        if (
          dataPoint.customersNum.absolute >
          dataPointWithMaxCustomerNum.customersNum.absolute
        ) {
          dataPointWithMaxCustomerNum = dataPoint
        }
      }

      return `${dataPointWithMaxCustomerNum.intervalsBucket} days`
    },

    purchaseIntervalsApiUrl(): string {
      const { profileId } = this.$route.params
      return `/api/cached/profiles/${profileId}/purchase-intervals`
    },

    totalCustomers(): number {
      let total = 0
      for (const dataPoint of this.purchaseIntervals) {
        total += dataPoint.customersNum
      }
      return total
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler(filters: {
        categorySelected: ASINCategory
        dateRange: DateRange
      }) {
        if (
          !filters ||
          !filters.dateRange.from ||
          !filters.dateRange.to ||
          !filters.categorySelected
        ) {
          return
        }

        this.isDataReady = false

        this.updateApiEndpoint({
          asins: filters.categorySelected.asins,
          startDate: filters.dateRange.from.value,
          endDate: filters.dateRange.to.value,
        })
      },
    },
  },
  methods: {
    ...mapActions({
      setCACData: 'analytics/setCACData',
      setLTVData: 'analytics/setLTVData',
      setReportingData: 'analytics/setReportingData',
    }),

    updateApiEndpoint({
      asins,
      startDate,
      endDate,
    }: {
      asins: Array<ASIN>
      startDate: Date
      endDate: Date
    }) {
      const queryString = new URLSearchParams()
      if (asins.length > 0) {
        queryString.set('asins', asins.toString())
      }
      queryString.set('start_date', formatDate(startDate))
      queryString.set('end_date', formatDate(endDate))

      const apiUrl = `${this.purchaseIntervalsApiUrl}?${queryString}`
      this.apiEndpoint = { src: apiUrl }
    },

    handleApiSuccess(response: { data: ApiSuccessResponse }) {
      const { items } = response.data
      this.purchaseIntervals = items.map((item) => ({
        customersNum: item.customers,
        daysLag: item.days_lag,
      }))
      if (this.purchaseIntervals.length > 0) {
        this.groupPurchaseIntervals()
      }
      this.isDataReady = true
    },

    handleApiError() {
      this.isDataReady = true
    },

    groupPurchaseIntervals() {
      let intervalsBucket: string
      let customersNumWithinInterval: number
      let customersPercentWithinInterval: number
      let cumulativeCustomersPercentUpToAndIncludingInterval: number

      let customersNumAccumulator = 0

      this.groupedPurchaseIntervals = INTERVALS.map((interval, index) => {
        const isLastInterval = index === INTERVALS.length - 1

        if (!isLastInterval) {
          // Example:
          // If the current interval is 30 and the next one 60, the resulting
          // intervals bucket boundaries should be [30, 59].
          const lowerBound = interval
          const upperBound = INTERVALS[index + 1] - 1

          intervalsBucket = `${lowerBound}-${upperBound}`
          customersNumWithinInterval = this.calculateCustomersNumWithinInterval(
            lowerBound,
            upperBound
          )
        } else {
          intervalsBucket = `${interval}+`
          customersNumWithinInterval =
            this.calculateCustomersNumWithinInterval(interval)
        }

        customersNumAccumulator += customersNumWithinInterval

        customersPercentWithinInterval =
          (customersNumWithinInterval / this.totalCustomers) * 100
        cumulativeCustomersPercentUpToAndIncludingInterval =
          (customersNumAccumulator / this.totalCustomers) * 100

        return {
          intervalsBucket: intervalsBucket,
          customersNum: {
            absolute: customersNumWithinInterval,
            percent: customersPercentWithinInterval,
            cumulativePercent:
              cumulativeCustomersPercentUpToAndIncludingInterval,
          },
        }
      })
    },

    calculateCustomersNumWithinInterval(
      daysLagLowerBound: number,
      daysLagUpperBound: number = Number.MAX_VALUE
    ): number {
      let customersNumWithinInterval = 0

      for (const dataPoint of this.purchaseIntervals) {
        if (
          dataPoint.daysLag >= daysLagLowerBound &&
          dataPoint.daysLag <= daysLagUpperBound
        ) {
          customersNumWithinInterval += dataPoint.customersNum
        }
      }
      return customersNumWithinInterval
    },

    calculateNumOfDaysToReachTargetPercentOfTotalCustomers({
      targetPercent,
    }: {
      targetPercent: number
    }): string {
      if (this.purchaseIntervals.length === 0) {
        return '-'
      }

      let currentCustomersNum = 0
      const targetCustomersNum = this.totalCustomers * (targetPercent / 100)

      for (const dataPoint of this.purchaseIntervals) {
        currentCustomersNum += dataPoint.customersNum
        if (currentCustomersNum > targetCustomersNum) {
          return `${dataPoint.daysLag} days`
        }
      }
      const lastDataPoint =
        this.purchaseIntervals[this.purchaseIntervals.length - 1]
      return `${lastDataPoint.daysLag} days`
    },
  },
})
