











import Vue from 'vue'
import { CHART_SIZE } from '../sizes'

export default Vue.extend({
  name: 'AxisXTitle',
  data: () => ({ CHART_SIZE }),
})
