<template>
  <div class="mt-4">
    <ReportCard
      v-for="report in reports"
      :key="report.profile_id"
      :report="report"
    />
  </div>
</template>
<script>
import ReportCard from '~/components/audit/list/ReportCard.vue'

export default {
  components: {
    ReportCard,
  },
  props: {
    reports: {
      type: Array,
      required: true,
    },
  },
}
</script>
