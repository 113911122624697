


































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import styleVars from '../../assets/_variables.scss'
import BaseImage from '../../components/BaseImage.vue'
import ClickOutside from '../../components/logic/ClickOutside.vue'
import MenuDesktop from './MenuDesktop.vue'
import MenuMobile from './MenuMobile.vue'
import MenuItem from './MenuItem.vue'
import AccountAndMarketplaceSelector from './AccountAndMarketplaceSelector.vue'
import MenuDropdown from './MenuDropdown.vue'

type Route = {
  name: string
}

export default Vue.extend({
  components: {
    MenuDesktop,
    MenuMobile,
    MenuItem,
    BaseImage,
    ClickOutside,
    MenuDropdown,
    AccountAndMarketplaceSelector,
  },
  data(): {
    showOverlay: boolean
    windowWidth: number
    loading: boolean
    failed: boolean
  } {
    return {
      showOverlay: false,
      windowWidth: 0,
      loading: true,
      failed: false,
    }
  },
  computed: {
    ...mapGetters({
      hasAnalytics: 'user/hasAnalytics',
      marketplacesAvailable: 'merchants/marketplacesAvailable',
      userEmail: 'user/getEmail',
    }),
    analyticsEnabled(): boolean {
      return this.$isEnabled('ANALYTICS', this.hasAnalytics)
    },
    smallThanTablet(): boolean {
      return this.windowWidth < Number(styleVars.tabletSize)
    },
  },
  watch: {
    smallThanTablet(smallThanTablet) {
      if (!smallThanTablet) {
        this.showOverlay = false
      }
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },

  methods: {
    toggle() {
      this.showOverlay = !this.showOverlay
    },
    goto(route: Route) {
      this.toggle()
      if (route && this.$route.name !== route.name) {
        // to avoid redundant navigation to current location error.
        this.$nextTick(() => this.$router.push(route))
      }
    },
  },
})
