












































































import Vue from 'vue'
import {
  getYear,
  subMonths,
  differenceInCalendarYears,
  subYears,
  addYears,
  isAfter,
  format,
  isBefore,
  isSameMonth,
  startOfMonth,
  lastDayOfMonth,
} from 'date-fns'
import BaseButton from './BaseButton.vue'
import ClickOutside from '~/components/logic/ClickOutside.vue'

type Month = {
  label: string
  value: Date
  disabled: boolean
}

export type DateRange = {
  from: Month | null
  to: Month | null
}

export type Item = {
  label: string
  value: Date | number | string | boolean
  checked: boolean
}

export default Vue.extend({
  components: { ClickOutside, BaseButton },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    allowedPeriod: {
      type: Number,
      required: false,
      default: 24,
    },
    initial: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): {
    dateRange: DateRange
    currentPage: number
    currentCalendar: Date
    isShown: boolean
    period: number
  } {
    return {
      currentPage: 1,
      isShown: false,
      currentCalendar: subMonths(new Date(), 0),
      dateRange: { from: null, to: null },
      period: 6,
    }
  },
  computed: {
    totalPages(): number {
      return differenceInCalendarYears(
        new Date(),
        subMonths(new Date(), this.allowedPeriod)
      )
    },
    months(): Month[] {
      return [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ].map((monthLabel, idx) => {
        const monthDate = new Date(`${this.currentYear}/${idx + 1}/01`)
        return {
          label: monthLabel,
          value: monthDate,
          disabled: this.withinAllowPeriod(monthDate),
        }
        // eslint-disable-next-line comma-dangle
      })
    },
    currentYear(): number {
      return getYear(this.currentCalendar)
    },
    startDateStr(): string {
      return this.dateRange.from
        ? format(this.dateRange.from.value, 'MMM yyyy')
        : ''
    },
    endDateStr(): string {
      return this.dateRange.to
        ? `- ${format(this.dateRange.to.value, 'MMM yyyy')}`
        : ''
    },
    placeholder(): string {
      return this.dateRange.from
        ? `${this.startDateStr} ${this.endDateStr} `
        : 'Select dates'
    },
  },
  watch: {
    period: {
      immediate: true,
      deep: true,
      handler(newPeriod) {
        const startDate = subMonths(startOfMonth(new Date()), newPeriod)
        const endDate = lastDayOfMonth(subMonths(new Date(), 0))
        this.dateRange = {
          from: {
            value: startDate,
            label: format(startDate, 'MMM'),
            disabled: false,
          },
          to: {
            value: endDate,
            label: format(endDate, 'MMM'),
            disabled: false,
          },
        }
      },
    },
    currentPage(newPage, prevPage) {
      prevPage < newPage
        ? (this.currentCalendar = subYears(this.currentCalendar, 1))
        : (this.currentCalendar = addYears(this.currentCalendar, 1))
    },
    initial(oldValue, newValue) {
      if (oldValue !== undefined) {
        this.dateRange = this.initial
        this.$emit('change', this.dateRange)
      }
    },
  },
  mounted() {
    if (this.initial) this.dateRange = this.initial
    else this.setPeriod(6)
    this.$emit('change', this.dateRange)
  },
  methods: {
    setPeriod(period: number) {
      this.period = period
    },
    setPickerDate(from: Month, to: Month | null = null) {
      this.dateRange = { from, to }
    },
    resetPickerDate() {
      this.dateRange = { from: null, to: null }
      this.$emit('change', this.dateRange)
    },
    selectMonth(month: Month) {
      const { from, to } = this.dateRange
      if (!from) {
        this.setPickerDate(month)
      } else if (!to) {
        return isAfter(month.value, from.value)
          ? this.setPickerDate(this.dateRange.from as Month, month)
          : this.setPickerDate(month, this.dateRange.from)
      } else {
        this.setPickerDate(month)
      }
    },
    getDateRangeEnd() {
      if (this.dateRange.to !== null) {
        return lastDayOfMonth(this.dateRange.to.value)
      } else {
        return this.dateRange.from
          ? lastDayOfMonth(this.dateRange.from.value)
          : null
      }
    },
    getDateRange() {
      this.isShown = false
      this.dateRange = {
        ...this.dateRange,
        to: (this.getDateRangeEnd() !== null
          ? {
              ...this.dateRange.to,
              value: this.getDateRangeEnd(),
            }
          : null) as Month | null,
      }
      this.$emit('change', this.dateRange)
    },
    isStartDate(date: Date): boolean {
      return this.dateRange.from !== null
        ? isSameMonth(date, this.dateRange.from.value)
        : false
    },
    isEndDate(date: Date): boolean {
      return this.dateRange.to !== null
        ? isSameMonth(date, this.dateRange.to.value)
        : false
    },
    isInRange(date: Date): boolean {
      return this.dateRange.from !== null && this.dateRange.to !== null
        ? isAfter(date, this.dateRange.from.value) &&
            isBefore(date, this.dateRange.to.value)
        : false
    },
    withinAllowPeriod(date: Date): boolean {
      return !isSameMonth(date, new Date()) && isAfter(date, new Date())
    },
    getMonthStyles(month: Month) {
      const disabledStyle = month.disabled
        ? 'grey--text cursor-disabled'
        : 'cursor-pointer'
      const selectionStyles = this.isStartDate(month.value)
        ? 'rangeStart'
        : this.isEndDate(month.value)
        ? 'rangeEnd'
        : this.isInRange(month.value)
        ? 'inrange'
        : ''
      return `${selectionStyles} ${disabledStyle}`
    },
  },
})
