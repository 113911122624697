<template>
  <base-card>
    <template #title>
      <div class="font-weight-bold">
        Unfortunately you don’t have any free audits left out of your monthly
        quota of {{ limit }}
      </div>
    </template>
    <template #content>
      <div>
        Alternatively, why not sign up to our paid plans where you can do many
        more audits along with more detailed analysis?
      </div>
    </template>
  </base-card>
</template>
<script>
import BaseCard from '@/components/BaseCard'

export default {
  components: { BaseCard },
  props: {
    limit: { type: Number, required: true },
  },
}
</script>
