var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"title":"Billing"}}),_vm._v(" "),_c('div',{staticClass:"container",attrs:{"id":"billing"}},[_c('loader',{attrs:{"message":"Fetching billing data"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_vm._v(" "),(!_vm.loading)?_c('div',[_c('div',{staticClass:"box py-6 px-8 mb-8"},[(!_vm.hasSubscriptions)?_c('alert',{attrs:{"msg-title":"No active subscriptions","button-external-url":_vm.generateTrialLink,"button-label":"Start 14 day free trial"}}):_c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between mb-4"},[_c('h2',{staticClass:"primary--text font-weight-bold text-lg mb-2"},[_vm._v("\n              Customer analytics\n            ")]),_vm._v(" "),(_vm.hasSubscriptions && _vm.stripeUrl)?_c('base-button',{attrs:{"label":"Manage subscription","link":_vm.stripeUrl,"target":"_blank"}}):_vm._e()],1),_vm._v(" "),_c('div',[_c('p',[_c('span',{staticClass:"font-weight-bold text-xxlg"},[_vm._v("\n                "+_vm._s(_vm.trialCurrency === 'GBP'
                    ? '£' + _vm.amount
                    : _vm.trialCurrency === 'USD'
                    ? '$' + _vm.amount
                    : _vm.amount + '€')+"\n              ")]),_vm._v(" "),_c('span',[_vm._v("per month")])]),_vm._v(" "),(_vm.trialStart !== null && _vm.trialEnd !== null)?_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Trial period:")]),_vm._v("\n              "+_vm._s(_vm.trialStart !== null &&
                _vm.trialStart.toLocaleDateString(_vm.defaultLocale, _vm.dateOptions))+"\n              -\n              "+_vm._s(_vm.trialEnd !== null &&
                _vm.trialEnd.toLocaleDateString(_vm.defaultLocale, _vm.dateOptions))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.canceledAt !== null)?_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Subscription was canceled at:")]),_vm._v("\n              "+_vm._s(_vm.canceledAt !== null &&
                _vm.canceledAt.toLocaleDateString(_vm.defaultLocale, _vm.dateOptions))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.cancelAtPeriodEnd)?_c('p',{staticClass:"mt-4 mb-0"},[_c('fa',{attrs:{"icon":['fal', 'info-circle']}}),_vm._v("\n              You can continue to use the service until the end of your\n              billing period\n            ")],1):_vm._e()])])],1),_vm._v(" "),(_vm.canceledSubscriptions.length > 0)?_c('div',{staticClass:"box py-6 px-8 mb-8 historic"},[_vm._m(0),_vm._v(" "),_vm._l((_vm.canceledSubscriptions),function(subscription){return _c('div',{key:subscription.trial_start,staticClass:"d-flex flex-columns table-line"},[_c('div',{staticClass:"col1"},[_vm._v("Customer Analytics")]),_vm._v(" "),_c('div',{staticClass:"col2"},[_vm._v("\n            "+_vm._s(/* eslint-disable max-len */
              subscription.trial_start
                ? _vm.convertUnixIntoDate(
                    subscription.trial_start
                  ).toLocaleDateString(_vm.defaultLocale, _vm.dateOptions)
                : subscription.start_date
                ? _vm.convertUnixIntoDate(
                    subscription.start_date
                  ).toLocaleDateString(_vm.defaultLocale, _vm.dateOptions)
                : '(no date)')+"\n            -\n            "+_vm._s(subscription.canceled_at &&
              subscription.trial_end &&
              subscription.canceled_at > subscription.trial_end
                ? _vm.convertUnixIntoDate(
                    subscription.trial_end
                  ).toLocaleDateString(_vm.defaultLocale, _vm.dateOptions)
                : subscription.canceled_at &&
                  subscription.trial_end &&
                  subscription.trial_end > subscription.canceled_at
                ? _vm.convertUnixIntoDate(
                    subscription.canceled_at
                  ).toLocaleDateString(_vm.defaultLocale, _vm.dateOptions)
                : subscription.canceled_at
                ? _vm.convertUnixIntoDate(
                    subscription.canceled_at
                  ).toLocaleDateString(_vm.defaultLocale, _vm.dateOptions)
                : subscription.trial_end
                ? _vm.convertUnixIntoDate(
                    subscription.trial_end
                  ).toLocaleDateString(_vm.defaultLocale, _vm.dateOptions)
                : '(no date)')+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"col3"},[_vm._v("\n            "+_vm._s(_vm.capitalizeFirstLetter(subscription.status))+"\n          ")])])})],2):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-header d-flex flex-columns"},[_c('div',{staticClass:"col1 font-weight-bold"},[_vm._v("Subscription")]),_vm._v(" "),_c('div',{staticClass:"col2 font-weight-bold"},[_vm._v("Time period")]),_vm._v(" "),_c('div',{staticClass:"col3 font-weight-bold"},[_vm._v("Status")])])}]

export { render, staticRenderFns }