export default ({ app }, inject) => {
  inject('intercom', ({ name, email, onboarded, auditCount, auditTier }) => {
    const APP_ID = app.$env.INTERCOM
    ;(function () {
      const w = window
      const ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        const d = document
        const i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        const l = function () {
          const s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://widget.intercom.io/widget/' + APP_ID
          const x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        if (document.readyState === 'complete') {
          l()
        } else if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    })()

    window.Intercom('boot', {
      app_id: APP_ID,
      name,
      email,
      onboarded,
      audit_count: auditCount,
      audit_tier: auditTier,
    })
  })
}
