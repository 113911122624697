<template>
  <div id="audit-report">
    <module-loader />
    <audit-header :date="auditDate" />
    <div id="stickyheader" class="sticky">
      <transition name="slide">
        <module-nav v-if="displayNavigation" class="is-hidden-print" />
      </transition>
    </div>
    <main>
      <audit-introduction
        v-waypoint="waypointOptions"
        :data="introductionData"
        :date="auditDate"
        :currency="auditCurrency"
      />
      <audit-breakdown
        :data="auditBreakdown"
        :currency="auditCurrency"
        :types="auditTypes"
        :date="auditDate"
        :has-pro-account="hasProAccount"
      />
      <audit-glossary v-waypoint="waypointOptions" />
      <audit-footer />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModuleLoader from './modules/ModuleLoader'
import AuditFooter from '~/components/audit/template/modules/AuditFooter'
import AuditBreakdown from '~/components/audit/template/section/AuditBreakdown'
import ModuleNav from '~/components/audit/template/modules/ModuleNav'
import { toMatchType } from '~/utils/format'

/**
 * Lazy load static components
 */
const components = {
  AuditHeader: () => import('~/components/audit/template/section/AuditHeader'),
  AuditIntroduction: () =>
    import('~/components/audit/template/section/AuditIntroduction'),
  AuditGlossary: () =>
    import('~/components/audit/template/section/AuditGlossary'),
}

export default {
  components: {
    AuditBreakdown,
    ModuleNav,
    ModuleLoader,
    AuditFooter,
    ...components,
  },
  props: {
    introductionData: {
      type: Object,
      required: true,
    },
    auditDate: {
      type: Object,
      required: true,
    },
    auditCurrency: {
      type: String,
      required: true,
    },
    auditTypes: {
      type: Array,
      required: true,
    },
    auditBreakdown: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    displayNavigation: false,
  }),
  computed: {
    ...mapGetters({
      navigationList: 'navigation/getNavigationList',
      navigationOptions: 'navigation/getNavigationOptions',
      hasProAccount: 'user/hasProAccount',
      audit: 'auditReport/audit',
    }),
    generateNavigationList() {
      const allNavOptions = [
        {
          title: 'Introduction and Summary',
          link: 'audit-introduction',
        },
        {
          title:
            'Aggregate Conversion Funnel and Search Term Targeting Type Breakdown',
          link: 'breakdown-introduction',
        },
        {
          title:
            'Aggregate Wasted Spend, Match Type, Keyword Harvesting and Product Targeting Breakdown',
          link: 'breakdown-keyfindings',
        },
        {
          title: 'Aggregate Breakdown',
          link: 'aggregate-breakdown',
        },
        ...this.auditTypes.map((type) => ({
          title: toMatchType(type),
          link: `breakdown-${type}`,
        })),
        {
          title: 'Branded Analysis',
          link: 'branded-analysis',
        },
        {
          title: 'Budget Efficiency',
          link: 'budget-efficiency',
        },
        {
          title: 'Search Term Duplicates Across Match Types',
          link: 'search-term',
        },
        {
          title: 'Glossary',
          link: 'audit-glossary',
        },
      ]

      const availableNav =
        this.audit && this.audit.metadata.brand_search_terms === null
          ? allNavOptions.filter((item) => item.link !== 'branded-analysis')
          : allNavOptions

      availableNav.map((item, idx) => (item.id = idx + 1))

      return availableNav
    },
    waypointOptions() {
      return {
        callback: this.updateNav,
        active: true,
        options: this.navigationOptions,
      }
    },
  },
  watch: {
    navigationList() {
      setTimeout(() => {
        this.displayNavigation = true
      }, 500)
    },
  },
  mounted() {
    this.$store.commit('navigation/setList', this.generateNavigationList)
  },
  methods: {
    updateNav({ el, going, direction }) {
      this.$updateNavigationOnScroll({ el, going, direction })
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 10;

  .level-right {
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .level-item:not(:last-child) {
    @media (max-width: 1000px) {
      margin-bottom: 0;
    }
  }

  .mobile-level {
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  .sticky-text {
    @media (max-width: 1000px) {
      padding-bottom: 10px;
    }
  }
}
</style>

<style>
@import '~/assets/_print.scss';

#audit-report {
  -webkit-print-color-adjust: exact;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
