<template>
  <div class="primary--text">
    <TwoColumnsRow image-src="analytics-trial/analytics_bolt.png" alt="">
      <template>
        <h2 class="text-lg font-weight-bold mb-8">
          Maximise your profit per customer and outsmart your competition
        </h2>
        <ul class="checklist mb-8">
          <li>
            <fa :icon="['fas', 'check']" class="secondary--text mr-2" />
            Find out your true breakeven ACoS, accounting for repeat purchases
          </li>
          <li>
            <fa :icon="['fas', 'check']" class="secondary--text mr-2" />
            See ASINs ranked by biggest growth potential based on your customer
            lifetime value
          </li>
          <li>
            <fa :icon="['fas', 'check']" class="secondary--text mr-2" />
            Increase your organic rankings and reduce your acquisition costs
          </li>
        </ul>
        <base-button
          v-scroll-to="'#promobox'"
          label="Start 14-day free trial"
          :primary="true"
        />
      </template>
    </TwoColumnsRow>
    <TwoColumnsRow invert-order image-src="analytics-trial/shot2.png" alt="">
      <template>
        <h2 class="text-lg font-weight-bold mb-8">
          Understand your customers, know where to find them
        </h2>
        <ul class="bullets">
          <li>
            Calculate and understand your <b>customer acquisition cost (CAC)</b>
          </li>
          <li>Track <b>ad spend vs. profit</b></li>
          <li>See exactly where you can <b>sell smarter</b></li>
        </ul>
      </template>
    </TwoColumnsRow>
    <TwoColumnsRow image-src="analytics-trial/shot3.png" alt="">
      <template>
        <h2 class="text-lg font-weight-bold mb-8">
          Your customers are more valuable than you think
        </h2>
        <ul class="bullets">
          <li>Easily find out your <b>breakeven ACoS</b></li>
          <li>Identify your <b>customer lifetime value (CLV)</b></li>
          <li>Set your <b>budget and strategy</b> based on affordability</li>
        </ul>
      </template>
    </TwoColumnsRow>
    <TwoColumnsRow invert-order image-src="analytics-trial/shot4.png" alt="">
      <template>
        <h2 class="text-lg font-weight-bold mb-8">
          Sick of wasting time gathering data?
        </h2>
        <ul class="bullets">
          <li>Uncover <b>growth opportunities</b></li>
          <li>
            Understand what
            <b>drives your customers to purchase and re-purchase</b>
          </li>
          <li><b>Automate insights</b> into one handy dashboard</li>
        </ul>
      </template>
    </TwoColumnsRow>
    <div id="promobox" class="mt-8 pt-8">
      <TrialPromoBox />
    </div>
  </div>
</template>
<script>
import TrialPromoBox from '~/components/trial/TrialPromoBox.vue'
import TwoColumnsRow from '~/components/BaseTwoColumnsRow.vue'
import BaseButton from '~/components/BaseButton.vue'

export default {
  components: { TrialPromoBox, TwoColumnsRow, BaseButton },
}
</script>
