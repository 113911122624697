












import Vue from 'vue'
import Modal from './BaseModal.vue'
import ProgressLinear from './BaseProgressLinear.vue'

export default Vue.extend({
  components: { Modal, ProgressLinear },
  props: {
    message: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
})
