<template>
  <click-outside :trigger="hide">
    <div>
      <slot v-if="$scopedSlots.label" name="label" />
      <div
        :class="`baseSelect 
          ${disabled ? 'baseSelect-disabled' : ''}
          ${showRuleError ? 'has-error' : ''}
        `"
        tabindex="0"
      >
        <div
          :class="`baseSelect-value text-sm 
          font-weight-medium js-select 
          ${disabled ? 'baseSelect-value-disabled' : ''}`"
          @click="toggle"
        >
          <div :class="`baseSelect-size-${size}`">
            <slot name="item-value" :value="value" />
          </div>
          <div class="baseSelect-control">
            <fa
              :class="`${show ? 'rotate-180' : ''} baseSelect-control--icon`"
              :icon="['fal', 'chevron-down']"
            />
          </div>
        </div>
        <div v-if="show" class="baseSelect-list">
          <div
            v-for="item in items"
            :key="item && (item.text || item.label)"
            :class="`${selected === item ? 'selected' : ''}
                    baseSelect-list-item font-weight-medium`"
            @click="onSelect(item)"
          >
            <slot name="item" :item="item" />
          </div>
        </div>
      </div>

      <!-- Error -->
      <p
        v-if="this.showRuleError"
        class="red--text mb-0 baseSelect-error-message"
      >
        <fa :icon="['fal', 'exclamation-triangle']" class="red--text pr-1" />
        <span class="text-xsm">
          <!-- first failed rule's error message -->
          {{ error }}
        </span>
      </p>
    </div>
  </click-outside>
</template>
<script>
import ClickOutside from '~/components/logic/ClickOutside'

export default {
  components: { ClickOutside },
  props: {
    items: {
      type: Array,
      required: true,
    },
    initialValue: {
      type: Object,
      required: false,
      default: undefined,
    },
    size: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: this.initialValue,
      show: false,
      showRuleError: false,
      selected: null,
    }
  },
  computed: {
    itemsList() {
      return this.items.filter((item) => item.id !== this.value.id)
    },
    error() {
      // Do not show errors if input is not required and if it's empty
      if (!this.required && (this.value === undefined || this.value === null)) {
        return false
      }
      // Do not show errors if rules are empty
      if ((this.rules?.length || 0) === 0) {
        return false
      }
      // Checks all the rules and return error message on fail
      for (const rule of this.rules) {
        const errorMsg = rule(this.value) === true ? null : rule(this.value)
        if (errorMsg) {
          return errorMsg
        }
      }
      return false
    },
  },

  methods: {
    onSelect(item) {
      this.selected = item
      this.value = item
      this.show = false
      this.showRuleError = false
      this.$emit('change', item)
    },
    toggle() {
      this.show = !this.show
      if (this.required && !this.show && !this.selected) {
        this.showRuleError = true
      }
    },
    hide() {
      this.show = false
    },
    resetValue() {
      this.value = this.initialValue
    },
  },
}
</script>
<style lang="scss">
.baseSelect {
  position: relative;
  width: auto;
  line-height: 22px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background: white;

  &:not(.has-error) {
    &:focus,
    &:active,
    &:focus-visible {
      border: 1px solid $blue-1;
      outline-color: $blue-1;
      box-shadow: 0px 0px 0px 2px $blue-4;
    }
  }

  &.has-error {
    border: 1px solid $red-1;

    &:focus,
    &:active,
    &:focus-visible {
      border: 1px solid $red-1;
      outline-color: $red-1;
      box-shadow: 0px 0px 0px 2px $red-2;
    }
  }

  &-disabled {
    cursor: not-allowed;
    background: $grey-1;
  }
  &-value {
    color: $primary;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-grow: 1;
    white-space: nowrap;

    &-disabled {
      user-select: none;
      color: $denim-2;
      background: $grey-1;
      pointer-events: none;
    }
  }

  &-size {
    &-xsm {
      padding: 2px 12px;
    }
    &-sm {
      padding: 6px 12px;
    }
    &-md {
      padding: 12px 8px;
    }
  }

  &-control {
    border-left: 1px solid $primary;
    padding: 0 12px;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
    &--icon {
      transition-duration: 0.5s;
    }
  }

  &-list {
    font-size: 0.875rem;
    margin-top: 0.5em;
    box-shadow: 0px 2px 5px $grey-1;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    z-index: 1000000;
    background-color: $white;

    &-size {
      &-sm {
        padding: 0.5rem;
      }
      &-md {
        padding: 1rem;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      border: 1px solid $grey-3;
      border-top: 0;
      min-height: 36px;
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-top: 1px solid $grey-3;
      }
      &:hover {
        background-color: $blue-4;
      }
      &.active,
      &.selected {
        background: $secondary-3;
      }
      &:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &-subtitle {
        color: $denim-2;
      }
    }
  }
}
</style>
