<script>
/**
 * Renderless component for fetching our API V2
 * ✓ Should handle any request method (GET, POST, PUT, DELETE)
 * ✓ Should handle single and chained requests
 * ✓ Should emit an event when the request succeeded
 * ✓ Should emit an event when the request fails
 * ✓ Should expose the data retrieved (result) and status code to which data belongs
 * ✓ Should expose the state of the request (hasFailed, isFetching)
 **/

export default {
  props: {
    request: {
      type: Object,
      required: false,
      default: undefined,
    },
    body: {
      type: String,
      required: false,
      default: undefined,
    },
    headers: {
      type: Object,
      required: false,
      default: () => ({
        'Content-Type': 'application/json',
      }),
    },
    mode: {
      type: String,
      required: false,
      default: 'fire-forget',
      validator: (val) => ['fire-forget', 'chain', 'polling'].includes(val),
    },
    method: {
      type: String,
      required: false,
      default: 'GET',
    },
    immediate: {
      type: Boolean,
      required: false,
      default: false,
    },
    pollingOpts: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      on: this.immediate,
      hasFailed: false,
      status: undefined,
      counter: 0,
      result: [],
      pollId: null,
    }
  },
  computed: {
    options() {
      return {
        method: this.method,
        req: this.request,
        body: this.body,
        headers: this.headers,
        cb: this.onSuccess,
      }
    },
    isFetching() {
      return this.counter > 0
    },
  },
  watch: {
    request: {
      immediate: true,
      deep: true,
      handler: function (request) {
        if (request) this.on = Boolean(request.src?.length > 0)
      },
    },
  },
  beforeDestroy() {
    this.reset()
  },
  mounted() {
    // Initiates watcher after component mounts
    this.$watch(
      'on',
      function (isOn) {
        if (isOn)
          if (this.mode === 'fire-forget') {
            this.reset()
            this.requestBy()
          } else {
            this.reset()
            this[this.mode]()
          }
      },
      { immediate: true }
    )
  },
  methods: {
    async requestBy({ method, req, body, headers, cb } = this.options) {
      this.counter = this.counter + 1
      let response
      let request = ''
      try {
        if (req && req.src) {
          request = req.src
        }
        response = await fetch(`${this.$env.NOZZLE_URL}${request}`, {
          method: method,
          credentials: 'include',
          body: body,
          headers: headers,
        })

        const responseTxt = (await response.text()) || ''
        const data =
          responseTxt && response.headers.get('content-type').includes('json')
            ? JSON.parse(responseTxt)
            : responseTxt

        return response.ok
          ? cb(data, response, req)
          : await Promise.reject(response)
      } catch (error) {
        this.onError({
          error,
          response,
          self: req,
          timestamp: req?.timestamp || new Date().getTime(),
        })
      }
    },
    chain() {
      this.request.src
        .reduce((acc, request) => {
          return acc.then(() => {
            return new Promise((resolve) => {
              this.requestBy({
                method: 'GET',
                req: request,
                body: undefined,
                headers: undefined,
                cb: (data, response, req) => {
                  const resData = { ...data, type: req.type }
                  this.result = !this.result
                    ? [resData]
                    : [...this.result, resData]
                  this.onRequestProcessed()
                  resolve({
                    data: {
                      kind: 'collection',
                      items: this.result,
                    },
                    self: this.request,
                    status: response?.status,
                    timestamp: this.request?.timestamp || new Date().getTime(),
                  })
                },
              })
            })
          })
        }, Promise.resolve())
        .then((combinedData) => {
          this.$emit('success', combinedData)
        })
    },
    polling() {
      this.pollId = setInterval(() => {
        this.requestBy({
          method: this.method,
          req: this.request,
          body: this.body,
          headers: this.headers,
          cb: (data, response, req) => {
            if (this.pollingOpts.keepAlive(data)) {
              this.onSuccess(data, response, req)
              clearInterval(this.pollId)
            } else if (this.pollingOpts.failOn(data)) {
              this.onError({
                response: { status: 200 },
                self: req,
                timestamp: new Date().getTime(),
              })
            } else {
              this.request.src = data.self
            }
          },
        })
      }, 3000)
    },
    onSuccess(data, response, req) {
      this.result = data
      this.onRequestProcessed()
      this.$nextTick(() =>
        this.$emit('success', {
          data,
          status: response?.status,
          self: req,
          timestamp: req?.timestamp || new Date().getTime(),
        })
      )
    },
    onError({ error, response, self, timestamp }) {
      this.onRequestProcessed()
      this.$nextTick(() =>
        this.$emit('error', { status: response?.status, self, timestamp })
      )
      this.$sentry.captureException(error, {
        tags: {
          targetPath: self?.src,
        },
      })
    },
    onRequestProcessed() {
      this.counter = this.counter - 1
      this.hasFailed = false
      this.on = false
    },
    reset() {
      if (this.pollId) clearInterval(this.pollId)
      Object.assign(this.$data, this.$options.data.apply(this))
    },
  },

  render() {
    return this.$scopedSlots.default({
      isFetching: this.isFetching,
      hasFailed: this.hasFailed,
    })
  },
}
</script>
