<template>
  <div class="d-flex justify-space-around py-6">
    <div class="box py-8 px-10">
      <div class="text-center">
        <base-toaster-message
          v-if="hasFailed"
          type="error"
          :dismissible="false"
          data-role="trial-error-msg"
          @message-timeout="hasFailed = false"
        />
        <div class="mb-8 d-flex justify-end">
          <ToggleGroup
            :items="currencies.items"
            :active-id="currencies.active.id"
            @select="onSelect"
          />
        </div>
        <img src="~/assets/images/branding/cohort.svg" alt />
        <p>
          <span class="text-lg font-weight-light"> Customer Analytics </span
          ><br />
          <span class="text-xxlg font-weight-bold">
            {{ currencies.active.price }} </span
          ><br />
          <span class="text-md"> per month </span>
        </p>
        <base-button
          class-list="mx-auto"
          label="Start 14-day free trial"
          @on-click="openStripePage"
        />

        <ul class="text-left mt-5 pt-2 pl-0 text-md">
          <li class="py-2">
            <fa :icon="['fas', 'check']" class="secondary--text mr-2 mt-1" />
            Cohort analysis
          </li>
          <li class="py-2">
            <fa :icon="['fas', 'check']" class="secondary--text mr-2 mt-1" />
            Customer lifetime value analysis
          </li>
          <li class="py-2">
            <fa :icon="['fas', 'check']" class="secondary--text mr-2 mt-1" />
            Track customer acquisition cost over time
          </li>
          <li class="py-2">
            <fa :icon="['fas', 'check']" class="secondary--text mr-2 mt-1" />
            True break even ACoS
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue'
import BaseToasterMessage from '@/components/BaseToasterMessage.vue'
import ToggleGroup from '~/components/BaseToggleGroup.vue'

export default {
  components: {
    BaseButton,
    BaseToasterMessage,
    ToggleGroup,
  },
  data: () => {
    return {
      currencies: {
        items: [{ id: 'USD', label: 'USD', price: '$250' }],
        active: { id: 'USD', label: 'USD', price: '$250' },
      },
      hasFailed: false,
    }
  },

  created() {
    // If it detects that user is in UK, show price in pounds, otherwise show dollars
    const defaultLocale = Intl.NumberFormat().resolvedOptions().locale
    this.onSelect('USD')
  },
  methods: {
    async openStripePage() {
      try {
        const id = this.$env.STRIPE_PRICE_ID_USD
        const endpoint = `${this.$env.NOZZLE_URL}/api/v2/payments/stripe/session/${id}`
        const data = await this.$axios.get(endpoint)
        window.location.assign(data.data.session.url)
      } catch (e) {
        this.hasFailed = true
      }
    },
    onSelect(sectionId) {
      if (this.currencies.active.id !== sectionId) {
        this.currencies.active = this.currencies.items.find(
          ({ id }) => sectionId === id
        )
      }
    },
  },
}
</script>
