





































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Filters from './Filters.vue'
import MarginBanner from './MarginMessageBanner.vue'
import Country from '~/components/BaseCountry.vue'
import { getCountry, showMarginBanner } from '@/utils/marketplace'

export default Vue.extend({
  components: {
    Country,
    Filters,
    MarginBanner,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    analytics: {
      type: Boolean,
      required: false,
      default: false,
    },
    profile: {
      type: Object,
      required: false,
      default: null,
    },
    /*filters: {
      type: Array,
      required: false,
      default: ['categories', 'asins', 'time'],
    },*/
  },
  data(): {} {
    return {}
  },
  methods: {
    countryData(marketplaceName) {
      return getCountry(marketplaceName)
    },
    shouldMarginBannerBeVisible(productAsins) {
      return showMarginBanner(productAsins)
    },
  },
  computed: {
    ...mapGetters({
      getProductAsins: 'analytics/getProductAsins',
    }),
  },
})
