












import Vue, { PropType } from 'vue'
import * as d3 from 'd3'
import { CHART_SIZE } from '../sizes'
import { ChartData } from '../types'

export default Vue.extend({
  name: 'AxisX',
  props: {
    axisXScale: {
      type: Function as PropType<d3.ScaleBand<string>>,
      required: true,
    },
    chartData: {
      type: Array as PropType<ChartData>,
      required: true,
    },
  },
  mounted() {
    this.addAxisX()
  },
  methods: {
    addAxisX(): void {
      const axisGenerator = d3
        .axisLeft(this.axisXScale)
        .tickSizeInner(0)
        .tickSizeOuter(0)
        .tickPadding(6)

      d3.select(this.$refs.axisX as SVGGElement)
        .call(axisGenerator)
        // D3 adds default font-size to the element the axis is attached to.
        // To control font-size via CSS, we must then clear font-size attr.
        .attr('font-size', '0.75rem')
    },
  },
  data: () => ({ CHART_SIZE }),
})
