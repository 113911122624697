<template>
  <img
    :src="imgSrc"
    :alt="`${alt} image`"
    :class="`img  ${styles}`"
    :style="cssVars"
    @error="setDefaultImg"
    @click="$emit('click')"
  />
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    styles: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    imgUrl: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    placeholderImg() {
      return require('~/assets/images/branding/product_placeholder.svg')
    },
    imgSrc() {
      if (this.imgUrl) {
        return this.src
      } else {
        return this.src.length > 0
          ? require(`~/assets/images/backgrounds/${this.src}`)
          : this.placeholderImg
      }
    },
    cssVars() {
      return {
        '--img-width': `${this.width}px`,
        '--img-height': `${this.height}px`,
      }
    },
  },
  methods: {
    setDefaultImg(ev) {
      ev.target.src = this.placeholderImg
    },
  },
}
</script>
<style lang="scss" scoped>
.img {
  object-fit: contain;
  width: var(--img-width);
  height: var(--img-height);
}
</style>
