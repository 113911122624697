





















import Vue, { PropType } from 'vue'
import * as d3 from 'd3'
import { CHART_SIZE } from '../sizes'
import { ChartData } from '../types'

export default Vue.extend({
  name: 'DataSeriesLine',
  props: {
    axisXScale: {
      type: Function as PropType<d3.ScaleBand<string>>,
      required: true,
    },
    axisY2Scale: {
      type: Function as PropType<d3.ScaleLinear<number, number>>,
      required: true,
    },
    chartData: {
      type: Array as PropType<ChartData>,
      required: true,
    },
  },
  data: () => ({
    CHART_SIZE,
    lineDataPointXOffset: 0 as number,
  }),
  computed: {
    chartDataWithDrawableLineDataPoints(): ChartData {
      return this.chartData.filter(
        (dataPoint) => typeof dataPoint.yLineValue === 'number'
      )
    },
    lineDataPointsCoordinates(): string {
      const coordinates = d3.line()(
        this.chartDataWithDrawableLineDataPoints.map((dataPoint) => {
          const x = this.axisXScale(dataPoint.xValue)
          if (typeof x === 'undefined') {
            throw new TypeError(
              `x is undefined for dataPoint.x=${dataPoint.xValue}`
            )
          }

          const y = this.axisY2Scale(dataPoint.yLineValue || 0)

          return [x + this.lineDataPointXOffset, y]
        })
      )

      if (typeof coordinates !== 'string') {
        throw new TypeError(
          `Cannot create line coordinates. data=${this.chartData}`
        )
      }
      return coordinates
    },
  },
  mounted() {
    // We want line data elements to be at the middle of their related bar's,
    // so we need to offset those by half of the bar's width.
    this.lineDataPointXOffset = this.axisXScale.bandwidth() / 2
  },
})
