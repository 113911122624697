









import Vue from 'vue'
import { mapActions } from 'vuex'
import { format, isAfter, isSameMonth } from 'date-fns'
import ApiProvider from '@/components/logic/ApiProvider.vue'
import { ASINCategory, CACData } from '~/store/analytics'
import { DateRange } from '@/components/BaseMonthPicker.vue'
import { formatBoundaryDate } from '~/utils/date'

export default Vue.extend({
  components: {
    ApiProvider,
  },
  props: {
    resize: {
      type: Boolean,
      required: true,
    },
    dateFilter: {
      type: Object,
      required: false,
      default: undefined,
    },
    asinFilter: {
      type: Object as () => ASINCategory,
      required: false,
      default: undefined,
    },
    graph: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data(): {
    endpoint?: { src: string }
    data: any[]
    status: 'success' | 'failed' | 'iddle'
    updating: boolean
  } {
    return {
      data: [],
      status: 'iddle',
      endpoint: undefined,
      updating: true,
    }
  },
  computed: {
    boundary(): Date | null {
      const d = this.$store.getters['analytics/dataBoundary']
      return d ? formatBoundaryDate(d) : null
    },
    error(): boolean {
      return this.status === 'failed'
    },
  },
  watch: {
    endpoint: {
      immediate: true,
      handler() {
        this.updating = true
      },
    },
    dateFilter: {
      deep: true,
      immediate: true,
      handler(dateFilter) {
        if (dateFilter && dateFilter.from && dateFilter.to) {
          this.setEndpoint(dateFilter, this.asinFilter)
        } else {
          this.endpoint = { src: '' }
          this.data = []
          this.status = 'success'
        }
      },
    },
    asinFilter(asinFilter) {
      this.setEndpoint(this.dateFilter, asinFilter)
    },
  },
  beforeDestroy() {
    this.resetCAC()
  },
  methods: {
    ...mapActions({
      setCACData: 'analytics/setCACData',
      resetCAC: 'analytics/resetCAC',
    }),
    setEndpoint(dateFilter: DateRange, asinFilter: ASINCategory) {
      if (dateFilter && dateFilter.from && dateFilter.to) {
        const startDate = format(dateFilter.from.value, 'yyyy-MM-dd')
        const endDate = format(dateFilter.to.value, 'yyyy-MM-dd')
        const asinsParam =
          asinFilter?.asins?.length > 0 ? `&asins=${asinFilter.asins}` : ''
        // eslint-disable-next-line max-len
        if (this.$route.params.profileId !== 'no-profile-ready') {
          this.endpoint = {
            src: `/api/cached/profiles/${this.$route.params.profileId}/cac?start_date=${startDate}&end_date=${endDate}${asinsParam}`,
          }
        }
      }
    },
    withinDataBoundary(itemPeriod: string) {
      const itemPeriodDate = new Date(itemPeriod)
      const boundaryDate = this.boundary ? new Date(this.boundary) : null
      return boundaryDate
        ? isAfter(itemPeriodDate, boundaryDate) ||
            isSameMonth(itemPeriodDate, boundaryDate)
        : false
    },
    handleSuccess({ data }: { data: { items: CACData[] } }) {
      this.data = data.items.reduce((acc: any[], item) => {
        acc.push({
          date: item.period,
          data: {
            cac: this.withinDataBoundary(item.period) ? item.cac : null,
            new_customers: item.new_customers,
            repeat_customers: item.repeat_customers,
          },
        })
        return acc
      }, [])
      this.status = 'success'
      this.updating = false
      this.handleCACUpdate(data.items)
    },
    handleError() {
      this.data = []
      this.status = 'failed'
      this.updating = false
    },
    handleCACUpdate(data: CACData[]) {
      if (this.dateFilter) {
        this.setCACData({
          data,
          period: [this.dateFilter.from.value, this.dateFilter.to.value],
        })
      }
    },
  },
})
