























import Vue, { PropType } from 'vue'

import * as d3 from 'd3'

import { ChartData } from './types'
import { CHART_SIZE } from './sizes'
import { AxisX, DataSeriesBars, Legend, Title } from './components'

export default Vue.extend({
  name: 'CustomerRetentionChart',
  components: {
    AxisX,
    DataSeriesBars,
    Legend,
    Title,
  },
  props: {
    customerRetentionData: {
      type: Array as PropType<CustomerRetentionData>,
      required: true,
    },
  },
  data: () => ({
    CHART_SIZE,
    axisXScale: {} as d3.ScaleBand<string>,
    axisYScale: {} as d3.ScaleLinear<number, number>,
    chart: {} as SVGSVGElement,
    chartData: [] as ChartData,
    isReadyToDrawChart: false as boolean,
  }),
  watch: {
    customerRetentionData: {
      immediate: true,
      handler(): void {
        this.isReadyToDrawChart = false

        this.createChartData()
        this.createXAxisScale()
        this.createYAxisScale()

        this.isReadyToDrawChart = true
      },
    },
  },
  mounted() {
    this.chart = this.$refs.chart as SVGSVGElement
  },
  methods: {
    createChartData(): void {
      this.chartData = this.customerRetentionData
        .slice(0, 5)
        .map((dataPoint) => ({
          xValue: `Order ${dataPoint.order}`,
          yValue: dataPoint.customersNum,
          yPrimaryLabel: dataPoint.customersNum.toLocaleString('en'),
          ySecondaryLabel:
            dataPoint.order === 1
              ? ''
              : dataPoint.retentionRate * 100 > 100
              ? '-'
              : `${(dataPoint.retentionRate * 100).toFixed(0)}% retained`,
        }))
    },
    createXAxisScale(): void {
      const domain = this.chartData.map((dataPoint) => dataPoint.xValue)
      const range = [
        CHART_SIZE.marginTop, // X Axis Top
        CHART_SIZE.height - CHART_SIZE.marginBottom, // X Axis Bottom
      ]
      // Padding in %. Effectively, this defines the spacing between the bars.
      this.axisXScale = d3.scaleBand(domain, range).padding(0.3)
    },
    createYAxisScale(): void {
      const yValues = this.chartData.map((dataPoint) => dataPoint.yValue)
      const scalingFactor = 1.3 // We must add "breathing" room for the bar's labels
      const domain = [0, Math.max(...yValues) * scalingFactor]
      const range = [
        CHART_SIZE.marginLeft, // Y Axis Start
        CHART_SIZE.width - CHART_SIZE.marginRight, // Y Axis End
      ]
      this.axisYScale = d3.scaleLinear(domain, range)
    },
  },
})
