export const state = () => ({
  currency: undefined,
  constraints: undefined,
  targeting: undefined,
  adgroups: undefined,
})

export const getters = {
  adgroupsList: (state) => state.adgroups,
  targeting: (state) => state.targeting,
  currency: (state) => state.currency,
  keywordConstraints: (state) =>
    state.constraints.sp.keyword.map(({ message, pattern }) => {
      return typeof pattern !== 'string' ? pattern : { message, pattern }
    }),
  bidConstraints: (state) => ({
    min: Number(state.constraints.sp.bid.min),
    max: Number(state.constraints.sp.bid.max),
  }),
}

export const actions = {
  setProfileData({ commit }, profileData) {
    commit('currency', profileData.currency_symbol)
    commit('constraints', profileData.constraints)
  },
  setCampaignTargeting({ commit }, targeting) {
    commit('targeting', targeting)
  },
  setCampaignAdgroups({ commit }, adgroups) {
    commit('adgroups', adgroups)
  },
}
export const mutations = {
  currency(state, currency) {
    state.currency = currency
  },
  constraints(state, constraints) {
    state.constraints = constraints
  },
  targeting(state, targeting) {
    state.targeting = targeting
  },
  adgroups(state, adgroups) {
    state.adgroups = adgroups
  },
}
