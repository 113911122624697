<template>
  <section style="-webkit-print-color-adjust: exact">
    <section class="hero grey-1 is-hidden-print">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-vcentered">
            <div class="column is-4">
              <figure class="image pre-footer__image is-96x96">
                <img
                  src="~/assets/images/icons/audit-prefoter.svg"
                  alt="audit-prefooter-image"
                />
              </figure>
              <h4
                class="text-lg font-weight-light primary--text text-center mb-6"
              >
                To discuss your results further,
                <br />
                <span class="text-lg font-weight-bold primary--text"
                  >contact us or schedule a call</span
                >
              </h4>
              <div class="level">
                <div class="level-item pre-footer__cta primary-header">
                  <a
                    href="https://meetings.hubspot.com/daniel-durling"
                    target="_blank"
                  >
                    <h3 class="text-lg secondary--text font-weight-semibold">
                      Schedule a call
                    </h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer page-break-before is-hidden-print">
      <div class="container text-center">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <div>
                <nav class="level">
                  <div class="level-left" style="align-items: baseline">
                    <figure class="image level-left">
                      <img
                        style="height: 35px"
                        src="~assets/images/branding/logo.svg"
                      />
                    </figure>
                    <h4 class="text-md level-item white--text pl-2">2019</h4>
                    <h4 class="text-md level-item white--text">&copy;</h4>
                  </div>
                </nav>
                <p
                  class="font-weight-light white--text footer__left-text is-family-secondary print-14 pt-2"
                >
                  Nozzle is powered by MediaGamma Ltd
                </p>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item d-flex footer-right-flex">
              <p>
                <a
                  class="secondary--text print-12"
                  href="https://www.nozzle.ai/legal/privacy-policy"
                  target="_blank"
                  >View our Privacy Policy</a
                >
                <span class="font-weight-light">|</span>
                <a
                  class="secondary--text print-12"
                  href="https://www.nozzle.ai/legal/terms-of-service"
                  target="_blank"
                  >Terms & Conditions</a
                >
              </p>
              <p
                class="font-weight-light white--text is-family-secondary print-12"
              >
                Fora, 9 Dallington St, Clerkenwell, London, United Kingdom EC1V
                0LN
              </p>
            </div>
          </div>
        </nav>
      </div>
    </footer>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.pre-footer {
  &__image {
    margin: auto;
    margin-bottom: 50px;
  }
  &__cta {
    margin: 10px;
    border-radius: 50px;
    padding: 10px;
    transition: ease-in-out 0.5s;
    &:hover {
      background-color: rgba(22, 75, 101, 0.9) !important;
    }
  }
  &__text {
    margin-bottom: 120px;
  }
}
.footer {
  background: $primary;
  padding: 3rem 1.5rem;
  &__left-text {
    margin-top: -30px;
  }
}
.footer-right-flex {
  flex-direction: column;
  align-items: flex-end;
}
</style>
