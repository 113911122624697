
















import Vue from 'vue'
import { mapActions } from 'vuex'
import Summary from './SummaryTable.vue'
import LTV from './LTV.vue'
import CAC from './CAC.vue'
import { ASINCategory } from '~/store/analytics'

export default Vue.extend({
  components: {
    CAC,
    LTV,
    Summary,
  },
  props: {
    dateFilter: {
      type: Object,
      required: false,
      default: undefined,
    },
    asinFilter: {
      type: Object as () => ASINCategory,
      required: false,
      default: undefined,
    },
    profileInfo: {
      type: String,
      required: true,
    },
  },
  data(): {
    resize: boolean
  } {
    return {
      resize: false,
    }
  },
  beforeDestroy() {
    this.resetProfile()
  },
  methods: {
    ...mapActions({
      resetProfile: 'analytics/resetProfile',
    }),
  },
})
