export const state = () => ({
  positionEl: 1,
  navigationOptions: {
    root: null,
    rootMargin: '-24% 0px -75% 0px',
    thresholds: 0,
  },
  list: [],
})

export const mutations = {
  setPosition(state, position) {
    if (typeof position !== 'number') {
      state.positionEl = parseInt(position.attributes.navId.value)
    } else {
      state.positionEl = position
    }
  },
  setList(state, list) {
    state.list = list
  },
}

export const getters = {
  getPosition(state) {
    return state.positionEl
  },
  getNavigationOptions(state) {
    return state.navigationOptions
  },
  getNavigationList({ list }) {
    return list
  },
  getNavIDFromElementID:
    ({ list }) =>
    (id) => {
      const found = list.find((listItem) => listItem.link === id)
      return found || {}
    },
}
