var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('toggle-group',{staticClass:"col-8 justify-start",attrs:{"items":_vm.filters.items,"prefix":"View by:"},on:{"select":_vm.onSelect}}),_vm._v(" "),_c('div',{staticClass:"col-3"},[(_vm.tableData && _vm.tableFields)?_c('BaseCSVDownloadBtn',{staticStyle:{"float":"right"},attrs:{"data":_vm.tableData,"fields":_vm.tableFields,"file-name":((_vm.filters.active.id) + "_" + _vm.fileSuffix)}}):_vm._e()],1),_vm._v(" "),(_vm.rawData.length > 0)?_c('div',{staticClass:"col-11 ltv-container"},[_c('div',{staticClass:"ltv-container-table-wrapper",style:(_vm.cssVars),on:{"scroll":_vm.hideTableGradient}},[_c('table',{staticClass:"ltv-table"},[_c('thead',[_vm._m(0),_vm._v(" "),_c('tr',{staticClass:"header-one"},_vm._l((_vm.columns),function(col,index){return _c('th',{key:col.id,class:index < 6
                  ? ("primary-column column-" + (index + 1))
                  : 'secondary-column'},[(col.info)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                  content: ("<h2>" + (col.name) + "</h2>  <br/>\n                   <span>" + (col.info) + "</span>"),
                  classes: ['tooltip tooltip-primary-top-arrow'],
                  delay: { show: 0, hide: 50 },
                }),expression:"{\n                  content: `<h2>${col.name}</h2>  <br/>\n                   <span>${col.info}</span>`,\n                  classes: ['tooltip tooltip-primary-top-arrow'],\n                  delay: { show: 0, hide: 50 },\n                }",modifiers:{"bottom":true}}]},[_c('span',[_vm._v(_vm._s(col.name))]),_vm._v(" "),_c('fa',{staticClass:"cursor-pointer",attrs:{"icon":['fal', 'info-circle']}})],1):_vm._e(),_vm._v(" "),(!col.info)?_c('div',[_c('span',[_vm._v(_vm._s(col.name))])]):_vm._e()])}),0)]),_vm._v(" "),_c('tbody',_vm._l((_vm.rows),function(row,rowId){return _c('tr',{key:rowId},_vm._l((row),function(cell,cellId){return _c('td',{key:(rowId + "-" + cellId),class:cell.highlight && cellId < 6
                  ? ("primary-column column-" + (cellId + 1) + " highlight")
                  : cell.highlight && cellId === 6
                  ? 'secondary-column highlight'
                  : cell.highlight && cellId > 6
                  ? 'highlight'
                  : !cell.highlight && cellId === 6
                  ? 'secondary-column'
                  : !cell.highlight && cellId < 6
                  ? ("primary-column column-" + (cellId + 1))
                  : null},[(cell.tooltip && cell.value)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                  content: ("\n                  <h2>" + (cell.tooltip.date) + "</h2><br/>\n                   <span class='breakeven_info'>Break even month</span>\n                   <span>Months since first purchase: </span> " + (cell.tooltip.monthsSinceFirstPurchs) + " <br/>\n                   <span>Cohort: </span> " + (cell.tooltip.cohort) + " <br/>\n                  <p>\n                    <span>Accumulated profit per customer: </span> " + (cell.tooltip.ltvToPeriod) + "\n                  </p>\n                  <p>\n                   <span>Accumulated sales per customer: </span> " + (cell.tooltip.avgSalesPerCustomer) + "\n                  </p>\n                   <span>Customers returning: </span> " + (cell.tooltip.customersReturning) + "% <br/>\n                   <span>Repeat customers: </span> " + (cell.tooltip.repeatCustomers) + " <br/>\n                  "),
                  classes: cell.highlight
                    ? ['tooltip-white', 'tooltip-breakeven']
                    : ['tooltip-white'],
                  delay: { show: 0, hide: 50 },
                }),expression:"{\n                  content: `\n                  <h2>${cell.tooltip.date}</h2><br/>\n                   <span class='breakeven_info'>Break even month</span>\n                   <span>Months since first purchase: </span> ${cell.tooltip.monthsSinceFirstPurchs} <br/>\n                   <span>Cohort: </span> ${cell.tooltip.cohort} <br/>\n                  <p>\n                    <span>Accumulated profit per customer: </span> ${cell.tooltip.ltvToPeriod}\n                  </p>\n                  <p>\n                   <span>Accumulated sales per customer: </span> ${cell.tooltip.avgSalesPerCustomer}\n                  </p>\n                   <span>Customers returning: </span> ${cell.tooltip.customersReturning}% <br/>\n                   <span>Repeat customers: </span> ${cell.tooltip.repeatCustomers} <br/>\n                  `,\n                  classes: cell.highlight\n                    ? ['tooltip-white', 'tooltip-breakeven']\n                    : ['tooltip-white'],\n                  delay: { show: 0, hide: 50 },\n                }",modifiers:{"bottom":true}}],staticClass:"cursor-pointer"},[_vm._v("\n                "+_vm._s(cell.value)+"\n              ")]):_c('div',[_vm._v("\n                "+_vm._s(cell.value || '-')+"\n              ")])])}),0)}),0)])]),_vm._v(" "),_c('div',{staticClass:"table__gradient"}),_vm._v(" "),_vm._m(1)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"header-zero"},[_c('th',{staticClass:"header-zero",attrs:{"colspan":"6"}},[_vm._v(" ")]),_vm._v(" "),_c('th',{staticClass:"header-zero header-zero__title",attrs:{"colspan":"25"}},[_vm._v("\n              Months since first purchase\n            ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__legend"},[_c('div',{staticClass:"table__legend-square"}),_vm._v(" "),_c('div',[_vm._v("Break even month")])])}]

export { render, staticRenderFns }