export default ({ app }) => {
  // Sentry module is not using runtime variables from $config
  // see: https://github.com/nuxt-community/sentry-module/issues/202
  // This plugin injects runtime variables when the app initiates.
  // But we need to disable it on local dev

  if (app.$env.NOZZLE_ENV !== 'development') {
    app.$sentry.configureScope(function (scope) {
      // When the following PR is merged
      // https://github.com/nuxt-community/sentry-module/pull/254
      // we should update nuxt.config.js to use it to set the
      // environment at runtime.
      // Until then we can set an 'env' tag at runtime. We can then filter
      // using the 'env' tag eg. 'env:development', 'env:staging',
      // or 'env:production'

      // Note: this will change the scope for all sentry logs, including the
      // global logger.
      scope.setTag('environment', app.$env.NOZZLE_ENV)
      scope.setTag('framework', 'nuxt')
    })
  }
}
