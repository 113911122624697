import Vue from 'vue'
import Router from 'vue-router'

import NotFound from '~/pages/404'
import Auth from '~/pages/auth'
import Audit from '~/pages/ppc'
import AuditNew from '~/pages/ppc/auditNew.vue'
import AuditWaiting from '~/pages/ppc/auditWaiting.vue'
import AuditReport from '~/pages/ppc/auditReport.vue'
import Settings from '~/pages/settings'
import Analytics from '~/pages/analytics/index.vue'
import CohortsAnalysis from '~/pages/analytics/cohortsAnalysis.vue'
import PerformanceSummary from '~/pages/analytics/performanceSummary.vue'
import PurchaseIntervals from '~/pages/analytics/purchaseIntervals.vue'
import CustomerRetention from '~/pages/analytics/customerRetention'
import PurchaseAnalysis from '~/pages/analytics/purchaseAnalysis.vue'
import Verification from '~/pages/verification.vue'
import Trial from '~/pages/trial/index.vue'
import Billing from '~/pages/settings/billing/index.vue'

Vue.use(Router)

export function createRouter() {
  return new Router({
    base: decodeURI('/'),
    mode: 'history',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    routes: [
      {
        path: '/verification',
        name: 'verification',
        component: Verification,
      },
      {
        path: '/reports/new',
        name: 'reports-new',
        component: AuditNew,
      },
      {
        path: '/reports/:urid',
        name: 'reports-waiting',
        component: AuditWaiting,
      },
      {
        path: '/reports/:urid/report',
        name: 'reports-urid-report',
        component: AuditReport,
      },
      {
        path: '/user/:userEmail/account',
        name: 'external-user-account',
        component: Settings,
      },
      {
        path: '/user/account',
        name: 'user-account',
        component: Settings,
      },
      {
        path: '/billing',
        name: 'billing',
        component: Billing,
      },
      {
        path: '/analytics/',
        name: 'analytics',
        component: Analytics,
      },
      {
        path: '/trial/',
        name: 'Trial',
        component: Trial,
      },
      {
        path: '/analytics/:profileId/cohort-analysis',
        name: 'analytics-profile-cohorts',
        component: CohortsAnalysis,
      },
      {
        path: '/analytics/:profileId',
        name: 'analytics-profile',
        component: PerformanceSummary,
      },
      {
        path: '/analytics/:profileId/purchase-intervals',
        name: 'analytics-profile-purchase-intervals',
        component: PurchaseIntervals,
      },
      {
        path: '/analytics/:profileId/purchase-analysis',
        name: 'analytics-profile-purchase-analysis',
        component: PurchaseAnalysis,
      },
      {
        path: '/analytics/:profileId/customer-retention',
        name: 'analytics-profile-customer-retention',
        component: CustomerRetention,
      },
      {
        path: '/',
        name: 'home',
        redirect: '/analytics',
      },
      {
        path: '/auth',
        name: 'auth',
        component: Auth,
      },
      {
        path: '*',
        component: NotFound,
        name: '404',
      },
    ],
  })
}
