<template>
  <transition name="fade">
    <section v-if="display" class="hero white is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-vcentered">
            <div class="column is-6">
              <div id="loader-container" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import lottie from 'lottie-web'
export default {
  data: () => ({
    loader: {},
    display: true,
  }),
  watch: {
    display(current) {
      return current ? this.loader.play() : this.loader.stop()
    },
  },
  mounted() {
    const container = document.getElementById('loader-container')

    this.loader = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: `/animations/loader.json`,
      rendererSettings: {
        progressiveLoad: true,
        hideOnTransparent: true,
      },
    })
    this.loader.setSpeed(1.1)
    this.loader.addEventListener('complete', () => (this.display = false))
  },
  beforeDestroy() {
    this.loader.removeEventListener('complete', () => {})
  },
}
</script>

<style lang="scss" scoped>
.hero {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
  z-index: 31;
}
</style>
