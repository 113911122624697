import { format } from 'date-fns'

export const integer = (val) => {
  if (typeof val === 'number' || typeof val === 'string') {
    return Math.round(val)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    return null
  }
}

export const decimal = (val) => {
  if (typeof val === 'number' || typeof val === 'string') {
    return Number(val)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    return null
  }
}

export const formatAs = (num, transform) =>
  num === null ? 'N/A' : transform(num)

export const percentage = (val) => (val * 100)?.toFixed(2) ?? 0

export const toCapitalCase = (value) =>
  `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`

export const capitalize = (value) => {
  const words = value.split(' ')
  return words.map((w) => toCapitalCase(w)).join(' ')
}

export const toMatchType = (type) =>
  type === 'asin' ? 'product targeting' : type

export const conversions = (clicks, sales) =>
  sales > 0 ? (clicks / sales).toFixed(2) : 0

export const formattedValue = (data, formatting) => {
  switch (formatting) {
    case 'percentage':
      return `${percentage(data.value)}%`
    case 'date':
      return format(new Date(data.value), 'd LLL yy')
    case 'date-short':
      return format(new Date(data.value), 'd-LL')
    case 'currency':
      return `${data.prefix}${data.value.toFixed(2)}`
    case 'currency-round':
      return `${data.prefix}${integer(data.value)}`
    case 'integer':
      return integer(data.value)
    default:
      return data.value
  }
}
