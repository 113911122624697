



import Vue from 'vue'
import styleVars from '@/assets/_variables.scss'

const themes = {
  primary: {
    background: styleVars.white1,
    color: styleVars.primary,
  },
  secondary: {
    background: styleVars.secondary,
    color: styleVars.secondary3,
  },
} as Record<
  string,
  {
    background: string
    color: string
  }
>

export default Vue.extend({
  props: {
    width: {
      type: String,
      required: false,
      default: '100',
    },
    height: {
      type: String,
      required: false,
      default: '10',
    },
    theme: {
      type: String,
      required: false,
      default: 'secondary',
    },
  },
  computed: {
    cssVars(): Record<string, string> {
      return {
        '--width': `${this.width}px`,
        '--height': `${this.height}px`,
        '--bar-background': `${themes[this.theme].background}`,
        '--bar-color': `${themes[this.theme].color}`,
      }
    },
  },
})
