<template>
  <nav v-if="show" class="app-menu-overlay">
    <ul>
      <menu-item
        type="button"
        name="Customer Analytics"
        tag="button"
        @click="$emit('goto', { name: 'analytics' })"
      >
        <template #icon>
          <AnalyticsIcon
            :width="23"
            :height="23"
            class-list="mr-3"
            :dark-theme="false"
          />
        </template>
      </menu-item>
      <menu-item
        v-if="$env.NOZZLE_ENV !== 'production'"
        type="button"
        name="PPC Audit"
        tag="button"
        @click="$emit('goto', { name: 'reports' })"
      >
        <template #icon>
          <AuditIcon
            :width="23"
            :height="23"
            class-list="mr-3"
            :dark-theme="false"
          />
        </template>
      </menu-item>
    </ul>
    <div class="separator" />
    <ul>
      <menu-item
        name="Account"
        type="button"
        @click="$emit('goto', { name: 'user-account' })"
      >
        <template #icon>
          <AccountIcon
            :width="23"
            :height="23"
            class-list="mr-3"
            :dark-theme="false"
          />
        </template>
      </menu-item>
      <menu-item
        name="Billing"
        type="button"
        @click="$emit('goto', { name: 'billing' })"
      >
        <template #icon>
          <BillingIcon
            :width="23"
            :height="23"
            class-list="mr-3"
            :dark-theme="false"
          />
        </template>
      </menu-item>
      <menu-item
        type="a"
        tag="li"
        name="Logout"
        :to="`${$env.NOZZLE_URL}/logout`"
      >
        <template #icon>
          <LogoutIcon
            :width="23"
            :height="23"
            class-list="mr-3"
            :dark-theme="false"
          />
        </template>
      </menu-item>
    </ul>
  </nav>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AuditIcon from '../icons/Audit.vue'
import AnalyticsIcon from '../icons/Analytics.vue'
import AccountIcon from '../icons/Account.vue'
import BillingIcon from '../icons/Billing.vue'
import LogoutIcon from '../icons/Logout.vue'
import MenuItem from './MenuItem.vue'

export default Vue.extend({
  components: {
    AuditIcon,
    AnalyticsIcon,
    AccountIcon,
    BillingIcon,
    LogoutIcon,
    MenuItem,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      hasActivation: 'user/hasActivation',
    }),
  },
})
</script>
<style lang="scss" scoped>
.app-menu-overlay {
  position: fixed;
  top: 60px;
  right: 0;
  width: auto;
  z-index: 1000;
  background-color: $white;
  color: $primary-2;
  box-shadow: 0px 0px 7px $grey-2;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.separator {
  border-top: 1px solid $primary-2;
}
.menu-item {
  padding: 1em 2em;
}
</style>
