
















import Vue, { PropType } from 'vue'

import { CHART_SIZE } from './sizes'
import { Legend, Title } from './components'
import Alert from '@/components/alerts/Alert.vue'

export default Vue.extend({
  name: 'NoDataChart',
  components: {
    Legend,
    Title,
    Alert,
  },
  data: () => ({
    CHART_SIZE,
  }),
})
