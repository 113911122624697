































export default {
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    info: {
      type: Array,
      required: false,
      default: null,
    },
    counter: {
      type: Object,
      required: false,
      default: null,
    },
    hasTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
