<template>
  <div :name="name" :class="`has-background-light-grey mt-4`">
    <div class="d-flex flex-column align-center py-12 px-6 text-center">
      <fa :icon="['fal', icon]" class="grey--text fa-4x pb-4" />
      <h2 class="text-lg font-weight-bold">
        {{ msgTitle }}
      </h2>
      <p v-if="msgText" class="pb-0 pt-2 msgText">
        {{ msgText }}
      </p>

      <div v-if="!noButton">
        <nuxt-link
          v-if="buttonLabel && buttonInternalUrl"
          :to="buttonInternalUrl"
        >
          <base-button :label="buttonLabel" class="mt-4" />
        </nuxt-link>

        <a
          v-else-if="buttonLabel && buttonExternalUrl"
          :href="buttonExternalUrl"
          target="_blank"
        >
          <base-button :label="buttonLabel" class="mt-4" />
        </a>

        <base-button
          v-else
          :label="buttonLabel"
          class="js-new-account mt-4"
          @on-click="$emit('add-account')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton'

export default {
  components: { BaseButton },
  props: {
    name: { type: String, required: false, default: null },
    msgTitle: { type: String, required: true },
    msgText: { type: String, required: false, default: null },
    noButton: { type: Boolean, required: false, default: false },
    buttonInternalUrl: { type: String, required: false, default: null },
    buttonExternalUrl: { type: String, required: false, default: null },
    buttonLabel: { type: String, required: false, default: null },
    calendlyId: { type: String, required: false, default: null },
    icon: { type: String, required: false, default: 'bolt' },
  },
}
</script>
<style lang="scss" scoped>
.has-background-light-grey {
  background: $white-1;
}
.msgText {
  max-width: 650px;
}
</style>
