



































































import Vue, { PropType } from 'vue'

import * as d3 from 'd3'

import { ChartData, ChartDataPoint } from './types'
import { CHART_SIZE } from './sizes'
import { AxisX, DataSeriesBars, Legend, Title } from './components'
import Alert from '@/components/alerts/Alert.vue'
import Tooltip from './components/Tooltip.vue'

type TopAsin = {
  asin: string
  name: string
  quantity_sum: number
  quantity_share: number
}

export default Vue.extend({
  name: 'purchaseAnalysisChart',
  components: {
    Alert,
    AxisX,
    DataSeriesBars,
    Legend,
    Title,
    Tooltip,
  },
  props: {
    purchaseAnalysisData: {
      type: Array as PropType<TopAsin[]>,
      required: true,
    },
    isBoughtNextGraph: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    CHART_SIZE,
    tooltipProduct: 'no product name found',
    subtooltip: '',
    axisXScale: {} as d3.ScaleBand<string>,
    axisYScale: {} as d3.ScaleLinear<number, number>,
    chart: {} as SVGSVGElement,
    chartData: [] as ChartData,
    isReadyToDrawChart: false as boolean,
    boughtNext: {
      title: 'What customers bought next',
      color: '#303FC6',
      legend: '% of customers',
    } as any,
    boughtTogether: {
      title: 'Frequently bought together',
      color: '#00E3B5',
      legend: 'Times bought together',
    } as any,
  }),
  mounted() {
    this.chart = this.$refs.chart as SVGSVGElement
  },
  watch: {
    purchaseAnalysisData: {
      immediate: true,
      handler(): void {
        this.isReadyToDrawChart = false

        this.createChartData()
        this.createXAxisScale()
        this.createYAxisScale()

        this.isReadyToDrawChart = true
      },
    },
  },
  methods: {
    createChartData(): void {
      this.chartData = this.purchaseAnalysisData.map((dataPoint) => ({
        xValue: dataPoint.title
          ? dataPoint.asin + ' - ' + dataPoint.title.substring(0, 14) + '...'
          : dataPoint.asin,
        yValue: !this.isBoughtNextGraph
          ? dataPoint.quantity_sum
          : dataPoint.quantity_share,
        yPrimaryLabel: !this.isBoughtNextGraph
          ? dataPoint.quantity_sum.toLocaleString('en')
          : (dataPoint.quantity_share * 100).toFixed(1) + '%',
        product: dataPoint.title ? dataPoint.title : '',
        asin: dataPoint.asin,
      }))
    },
    createXAxisScale(): void {
      const domain = this.chartData.map((dataPoint) => dataPoint.xValue)
      const range = [
        CHART_SIZE.marginTop, // X Axis Top
        CHART_SIZE.height - CHART_SIZE.marginBottom,
      ]
      this.axisXScale = d3.scaleBand(domain, range).padding(0.3) // Padding in %. Effectively, this defines the spacing between the bars.
    },
    createYAxisScale(): void {
      const yValues = this.chartData.map((dataPoint) => dataPoint.yValue)
      const scalingFactor = 1.3 // We must add "breathing" room for the bar's labels
      const domain = [0, Math.max(...yValues) * scalingFactor]
      const range = [
        CHART_SIZE.marginLeft, // Y Axis Start
        CHART_SIZE.width - CHART_SIZE.marginRight, // Y Axis End
      ]
      this.axisYScale = d3.scaleLinear(domain, range)
    },
    showBarTooltip(event: MouseEvent, dataPoint: ChartDataPoint): void {
      //@ts-ignore
      this.$refs.tooltip.showBarTooltip(event, dataPoint)
    },
    hideBarTooltip(): void {
      //@ts-ignore
      this.$refs.tooltip.hideTooltip()
    },
    showTitleTooltip(event: MouseEvent, dataPoint: ChartDataPoint): void {
      //@ts-ignore
      let chartData = dataPoint.find(
        //@ts-ignore
        (dp) => dp.xValue === event.target.__data__
      )
      //
      let data = {
        product: chartData?.product || '',
        asin: chartData?.asin,
      }
      this.$refs.tooltip.showBarTooltip(event, data)
    },
    hideTitleTooltip(): void {
      //@ts-ignore
      this.$refs.tooltip.hideTooltip()
    },
  },
})
