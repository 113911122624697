




















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { formatDate } from '~/utils/date'
import Chart from './Chart/index.vue'
import Table from './Table.vue'
import ApiProvider from '~/components/logic/ApiProvider.vue'
import DashboardLoader from '~/components/analytics/DashboardLoader.vue'
import { ASIN, ASINCategory, DateRange } from '~/store/analytics'

type ApiSuccessResponse = {
  items: {
    retention: Array<{
      customers: number
      order_sequence: number
      retention_rate: number
      retention_rate_from_previous: number
    }>
  }
}

export default Vue.extend({
  name: 'CustomerRetentionDashboard',
  components: {
    ApiProvider,
    Chart,
    DashboardLoader,
    Table,
  },
  props: {
    fileSuffix: {
      type: String,
      required: true,
      default: '',
    },
  },

  data: () => ({
    apiEndpoint: { src: '' } as { src: string },
    customerRetentionData: [] as CustomerRetentionData,
    isDataReady: false as boolean,
  }),
  computed: {
    ...mapGetters({
      filters: 'analytics/getFilters',
    }),

    customerRetentionApiUrl(): string {
      const { profileId } = this.$route.params
      return `/api/cached/profiles/${profileId}/customer-retention`
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler(filters_: {
        categorySelected: ASINCategory
        dateRange: DateRange
      }) {
        if (
          !filters_ ||
          !filters_.dateRange.from ||
          !filters_.dateRange.to ||
          !filters_.categorySelected
        ) {
          return
        }

        this.isDataReady = false

        this.updateApiEndpoint({
          asins: filters_.categorySelected.asins,
          startDate: filters_.dateRange.from.value,
          endDate: filters_.dateRange.to.value,
        })
      },
    },
  },
  methods: {
    updateApiEndpoint({
      asins,
      startDate,
      endDate,
    }: {
      asins: Array<ASIN>
      startDate: Date
      endDate: Date
    }) {
      const queryString = new URLSearchParams()

      if (asins.length > 0) {
        queryString.set('asins', asins.toString())
      }

      queryString.set('start_date', formatDate(startDate))

      queryString.set('end_date', formatDate(endDate))

      const apiUrl = `${this.customerRetentionApiUrl}?${queryString}`
      this.apiEndpoint = { src: apiUrl }
    },

    handleApiSuccess(response: { data: ApiSuccessResponse }) {
      const { items } = response.data
      this.customerRetentionData = items.retention.map((item) => ({
        customersNum: item.customers,
        order: item.order_sequence,
        retentionRate: item.retention_rate_from_previous,
      }))
      this.isDataReady = true
    },

    handleApiError() {
      this.isDataReady = true
    },
  },
})
