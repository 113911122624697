var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AnalyticsHeader',{attrs:{"title":"Cohort Analysis","analytics":"","filters":['categories', 'asins', 'time'],"profile":_vm.currentProfile}}),_vm._v(" "),_c('div',{staticClass:"analytics"},[_c('div',{staticClass:"px-8 py-8"},[_c('loader',{attrs:{"message":"Loading profile data"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"py-4 mb-4"},[((!_vm.currentProfile && _vm.noProfileReady === true) || _vm.failed)?_c('analytics-alert-manager',{attrs:{"api-failed":_vm.failed,"no-profile-ready":_vm.noProfileReady}}):[(
              _vm.currentProfile &&
              _vm.currentProfile.ordersStatus &&
              _vm.currentProfile.ordersStatus !== 'COMPLETE'
            )?_c('account-sync-incomplete'):_c('dashboard',{attrs:{"date-filter":_vm.getFilters && _vm.getFilters.dateRange,"asin-filter":_vm.getFilters && _vm.getFilters.categorySelected,"profileInfo":_vm.currentProfile
                ? encodeURIComponent(
                    (_vm.currentProfile.info
                      ? _vm.currentProfile.info
                      : _vm.currentProfile.name) +
                      '_' +
                      _vm.currentProfile.country
                  ).replaceAll('%20', '_')
                : ''}})]],2):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }