






















/* eslint-disable camelcase */

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { formatDate } from '~/utils/date'
import LTVTable from '~/components/analytics/CohortsAnalysis/LTVTable.vue'
import ApiProvider from '@/components/logic/ApiProvider.vue'
import { ASINCategory } from '~/store/analytics'
import { DateRange } from '@/components/BaseMonthPicker.vue'
import DashboardEmpty from '~/components/analytics/DashboardEmpty.vue'
import DashboardError from '~/components/analytics/DashboardError.vue'
import DashboardLoader from '~/components/analytics/DashboardLoader.vue'
import { LTVData, reportingData } from '@/store/analytics'

declare module 'vue/types/vue' {
  interface Vue {
    dataLTV: LTVData[]
    reporting: reportingData[]
    status: 'success' | 'failed' | 'iddle'
    endpoint?: { src: string }
    updating: boolean
    formatted: boolean
    expand: boolean
    setEndpoint: (dateFilter: DateRange, asinFilter: ASINCategory) => void
    handleLTVUpdate: () => void
    formattedLTVData: () => any[]
    resetLTV(): () => void
    resetReportingData(): () => void
    setReportingData: ({ data }: { data: reportingData[] }) => void
    setLTVData: ({ data, period }: { data: LTVData[]; period: any[] }) => void
  }
}

export default Vue.extend({
  components: {
    ApiProvider,
    DashboardEmpty,
    DashboardError,
    DashboardLoader,
    LTVTable,
  },
  props: {
    resize: {
      type: Boolean,
      required: true,
    },
    dateFilter: {
      type: Object,
      required: false,
      default: undefined,
    },
    asinFilter: {
      type: Object as () => ASINCategory,
      required: false,
      default: undefined,
    },
    profileInfo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataLTV: [] as LTVData[],
      reporting: [] as reportingData[],
      status: 'iddle' as 'success' | 'failed' | 'iddle',
      endpoint: undefined as { src: string } | undefined,
      updating: true as boolean,
      formatted: false as boolean,
      expand: true as boolean,
    }
  },
  computed: {
    cssVars(): Record<string, string> {
      return {
        '--width': '100%',
      }
    },
    emptyData(): boolean {
      return (
        this.formatted &&
        this.formattedLTVData &&
        this.formattedLTVData.length === 0
      )
    },
    error(): boolean {
      return this.status === 'failed'
    },

    formattedLTVData() {
      this.formatted = false
      const requestsAreReady =
        this.getCacData != null &&
        this.getCacData.length > 0 &&
        this.dataLTV != null &&
        this.dataLTV.length > 0
      // inject cac, total_spend in to LTV data and use this data to power the table
      const data = requestsAreReady
        ? this.dataLTV?.map(({ cohort, ...rest }) => {
            const m = this.getCacData?.find(
              ({ period }: { period: string }) => period === cohort
            )
            return {
              ...rest,
              cac: m?.cac || null,
              total_spend: m?.total_spend || null,
              cohort,
            }
          })
        : null
      this.formatted = data !== null // only is true if requests are ready
      return data
    },
    ...mapGetters({
      getCacData: 'analytics/cac',
      dataBoundary: 'analytics/dataBoundary',
    }),
  },
  watch: {
    endpoint: {
      immediate: true,
      handler() {
        this.updating = true
      },
    },
    dateFilter: {
      deep: true,
      immediate: true,
      handler(dateFilter) {
        if (dateFilter && dateFilter.from && dateFilter.to) {
          this.setEndpoint(dateFilter, this.asinFilter)
        } else {
          this.endpoint = { src: '' }
          this.dataLTV = []
          this.status = 'success'
        }
      },
    },
    formattedLTVData() {
      this.updating = false
    },
    asinFilter(asinFilter) {
      this.setEndpoint(this.dateFilter, asinFilter)
    },
  },
  beforeDestroy() {
    this.resetLTV()
    this.resetReportingData()
  },
  methods: {
    ...mapActions({
      setLTVData: 'analytics/setLTVData',
      setReportingData: 'analytics/setReportingData',
      resetLTV: 'analytics/resetLTV',
      resetReportingData: 'analytics/resetReportingData',
    }),
    setEndpoint(dateFilter: DateRange, asinFilter: ASINCategory) {
      if (dateFilter && dateFilter.from && dateFilter.to) {
        const startDate = formatDate(dateFilter.from.value)
        const endDate = formatDate(dateFilter.to.value)
        const asinsParam =
          asinFilter?.asins.length > 0 ? `&asins=${asinFilter.asins}` : ''
        if (this.$route.params.profileId !== 'no-profile-ready') {
          this.endpoint = {
            // eslint-disable-next-line max-len
            src: `/api/cached/profiles/${this.$route.params.profileId}/ltv?start_date=${startDate}&end_date=${endDate}${asinsParam}`,
          }
        }
      }
    },
    handleSuccess({
      data,
    }: {
      data: {
        items: { cohorts: LTVData[]; reporting_window_offset: reportingData[] }
      }
    }) {
      this.reporting = data.items.reporting_window_offset
      this.dataLTV = data.items.cohorts
      this.status = 'success'
      this.handleLTVUpdate()
    },
    handleError() {
      this.dataLTV = []
      this.status = 'failed'
      this.updating = false
    },
    handleExpand(expand: boolean) {
      this.expand = expand
      this.$emit('update:resize', expand)
    },
    handleLTVUpdate() {
      // Global state will updates on every load
      this.setReportingData({ data: this.reporting })
      this.dateFilter &&
        this.setLTVData({
          data: this.dataLTV,
          period: [this.dateFilter.from.value, this.dateFilter.to.value],
        })
    },
  },
})
