<template>
  <v-app>
    <div class="d-flex align-strech wrapper">
      <the-white-navigation style="flex: 0 0 auto" />
      <main style="flex: 1 1 auto">
        <nuxt />
      </main>
    </div>
  </v-app>
</template>
<script>
import TheWhiteNavigation from '~/components/navigation/index'

export default {
  components: {
    TheWhiteNavigation,
  },
  middleware: 'redirectToNuxt3',
}
</script>
<style lang="scss" scoped>
.wrapper {
  flex-direction: row;

  @media (max-width: $tablet) {
    flex-direction: column;
  }
}
.container {
  padding: 3rem 0;
}

main {
  padding: 4rem 0;
}
</style>
