import { render, staticRenderFns } from "./cohortsAnalysis.vue?vue&type=template&id=23b65bd1&scoped=true&"
import script from "./cohortsAnalysis.vue?vue&type=script&lang=ts&"
export * from "./cohortsAnalysis.vue?vue&type=script&lang=ts&"
import style0 from "./cohortsAnalysis.vue?vue&type=style&index=0&id=23b65bd1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b65bd1",
  null
  
)

export default component.exports