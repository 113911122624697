<template>
  <div class="ui-toggle-group">
    <span class="ui-toggle-group__prefix">{{ prefix }}</span>
    <div
      v-for="item in items"
      :id="item.label.replace(/\s+/g, '-').toLowerCase()"
      :key="item.id"
      :style="cssVars"
      :class="`ui-toggle-group-item js-toggle-item
      ${
        active === item.id
          ? 'ui-toggle-group-item-on'
          : 'ui-toggle-group-item-off'
      }`"
      @click="onSelect(item)"
    >
      <slot name="prepend" :item="item" />
      <span>{{ item.label }}</span>
      <slot name="append" :item="item" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prefix: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array,
      required: true,
      validator: (value) => value.every((item) => item.id && item.label),
    },
    width: {
      type: String,
      required: false,
      default: 'auto',
    },
    activeId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      active: null,
    }
  },
  computed: {
    cssVars() {
      return {
        '--width': this.width,
      }
    },
  },
  watch: {
    active(itemId) {
      this.$emit('select', itemId)
    },
  },
  mounted() {
    this.active = this.activeId ? this.activeId : this.items[0].id
  },
  methods: {
    onSelect(item) {
      this.active = item.id
    },
  },
}
</script>
<style lang="scss" scoped>
.ui-toggle-group {
  font-size: 1rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &__prefix {
    padding-right: 0.75rem;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.8rem;
    border: 1px solid $white-1;
    border-radius: 0;
    color: $primary;
    width: var(--width);
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;

    border-width: 1px;
    border-color: $primary-2;
    font-weight: 600;
    font-size: 0.875rem; // 14px

    &:nth-child(2) {
      border-radius: 6px 0px 0px 6px;
    }

    &:last-child {
      border-radius: 0px 6px 6px 0px;
    }
    &:not(.ui-toggle-group-item-on):not(:last-child) {
      border-right: none; /* Prevent double borders */
    }

    &-on {
      background: $secondary-3;
      & + .ui-toggle-group-item {
        border-left: 0;
      }
      &:hover {
        background: $blue-4;
      }
    }

    &-off {
      &:hover {
        background: $blue-4;
      }
    }
  }
}
</style>
