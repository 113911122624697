














































































import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'
import { isAfter, isSameMonth } from 'date-fns'
import { formatDate } from '~/utils/date'
import analytics from '~/mixins/analytics'
import ProgressTracker from '~/components/analytics/PerformanceSummary/ProgressTracker.vue'
import DashboardEmpty from '~/components/analytics/DashboardEmpty.vue'
import DashboardError from '~/components/analytics/DashboardError.vue'
import DashboardLoader from '~/components/analytics/DashboardLoader.vue'
import CACChart from '~/components/analytics/PerformanceSummary/CACChart/index.vue'
import MonthlyResults from '~/components/analytics/PerformanceSummary/MonthlyResults.vue'

export default Vue.extend({
  components: {
    ProgressTracker,
    CACChart,
    DashboardEmpty,
    DashboardError,
    DashboardLoader,
    MonthlyResults,
  },
  mixins: [analytics],
  middleware: 'account_check',
  data(): {
    loadingTable: boolean
    resize: Boolean
    tableBodys: any
    queryString: string
    cacGraphData: any[]
    graphDataIsReady: boolean
  } {
    return {
      loadingTable: true,
      graphDataIsReady: false,
      resize: false,
      tableBodys: [],
      queryString: '',
      cacGraphData: [],
    }
  },
  head() {
    return {
      title: `Performance Summary - ${this.currentProfileName} | ${this.currentProfile?.country}`,
    }
  },

  computed: {
    ...mapGetters({
      getFilters: 'analytics/getFilters',
      dataBoundary: 'analytics/dataBoundary',
    }),
    defaultLocale(): string {
      return Intl.NumberFormat().resolvedOptions().locale
    },
  },

  watch: {
    getFilters() {
      this.loadingTable = true
      const qStr = new URLSearchParams()
      if (
        this.getFilters &&
        this.getFilters.categorySelected &&
        this.getFilters.categorySelected.asins.length > 0
      ) {
        qStr.set('asins', this.getFilters.categorySelected.asins.toString())
      }
      if (
        this.getFilters &&
        this.getFilters.dateRange.from &&
        this.getFilters.dateRange.to
      ) {
        qStr.set('start_date', formatDate(this.getFilters.dateRange.from.value))
        qStr.set('end_date', formatDate(this.getFilters.dateRange.to.value))
      }

      this.graphDataIsReady = false
      this.updateApiEndpoint(qStr.toString())
    },
  },
  mounted() {
    this.updateApiEndpoint(new URLSearchParams())
  },
  beforeDestroy() {
    this.resetCAC()
  },
  methods: {
    ...mapActions({
      setCACData: 'analytics/setCACData',
      resetCAC: 'analytics/resetCAC',
    }),
    withinDataBoundary(itemPeriod: string) {
      try {
        const itemPeriodDate = new Date(itemPeriod)
        const boundaryDate = new Date(this.dataBoundary)
        return (
          isSameMonth(itemPeriodDate, boundaryDate) ||
          isAfter(itemPeriodDate, boundaryDate)
        )
      } catch {
        return false
      }
    },
    async updateApiEndpoint(queryString: string) {
      const baseUrl: string = `${(this as any).$env.NOZZLE_URL}`
      const cacEndpoint: string =
        queryString.length > 0
          ? `/api/cached/profiles/${this.profileId}/cac?${queryString}`
          : `/api/cached/profiles/${this.profileId}/cac`
      const ltvEndpoint: string =
        queryString.length > 0
          ? `/api/cached/profiles/${this.profileId}/ltv?${queryString}`
          : `/api/cached/profiles/${this.profileId}/ltv`

      if (this.profileId !== 'no-profile-ready') {
        try {
          const cacData = await this.$axios.get(baseUrl + cacEndpoint)
          const ltvData = await this.$axios.get(baseUrl + ltvEndpoint)

          const updatedCacData = cacData.data.items
          const ltvCohorts = ltvData.data.items.cohorts

          if (updatedCacData && ltvCohorts) {
            // Prepare data to cac's chart
            if (this.dataBoundary) {
              this.cacGraphData = updatedCacData?.map((item) => ({
                date: item.period,
                data: {
                  cac: this.withinDataBoundary(item.period)
                    ? Number(item.cac)
                    : null,
                  newCustomersNum: item.new_customers,
                  repeatCustomersNum: item.repeat_customers,
                },
              }))
              this.graphDataIsReady = true
            }

            // Prepare data to montly results table
            for (let i = 0; i < updatedCacData.length; i++) {
              ltvCohorts.map((e) => {
                if (e.cohort === updatedCacData[i].period) {
                  updatedCacData[i] = {
                    ...updatedCacData[i],
                    first_purchase_profit_per_customer:
                      e.first_purchase_profit_per_customer,
                    profit_per_customer:
                      e.first_purchase_profit_per_customer -
                      updatedCacData[i].cac,
                    profit_change_per_customer: 0,
                  }
                }
              })
            }
            this.tableBodys = updatedCacData.reverse()
            this.loadingTable = false
          }
        } catch (e) {
          this.$sentry.captureException(e)
          this.failed = true
        }
      }
    },
    findCohort(key, array) {
      // The variable results needs var in this case (without 'var' a global variable is created)
      const results = []
      for (let i = 0; i < array.length; i++) {
        if (array[i].indexOf(key) === 0) {
          results.push(array[i])
        }
      }
      return results
    },
  },
})
