





import Vue from 'vue'
import { mapGetters } from 'vuex'

/* Alerts */
import APIError from '~/components/alerts/ApiError.vue'
import AccountSyncIncomplete from '~/components/alerts/AccountSyncIncomplete.vue'
import NoAccounts from '~/components/alerts/NoAccounts.vue'
import NoMarketplacesOnboardedOrOnboarding from '~/components/alerts/NoMarketplacesOnboardedorOnboarding.vue'

export default Vue.extend({
  middleware: 'account_check',
  components: {
    APIError,
    AccountSyncIncomplete,
    NoMarketplacesOnboardedOrOnboarding,
    NoAccounts,
  },
  props: {
    apiFailed: {
      type: Boolean,
      required: true,
      default: false,
    },
    noProfileReady: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data(): {} {
    return {}
  },
  computed: {
    ...mapGetters({
      accounts: 'user/accounts',
      onboarding: 'user/onboarding',
    }),

    findOutWhyThereIsNoProfileReady(): string {
      if (this.accounts.length === 0) return 'NoAccounts'
      if (this.onboarding) return 'AccountSyncIncomplete'
      else return 'NoMarketplacesOnboardedOrOnboarding'
    },
  },
})
