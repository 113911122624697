



















































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import ApiData from '@/components/logic/ApiData.vue'
import BaseButton from '@/components/BaseButton.vue'
import AlertQuota from '@/components/audit/Alerts/AuditQuota.vue'
import ApiDataError from '@/components/errors/ApiDataError.vue'
import { audits } from '@/utils/definitions/audits'
import { validateSchema } from '@/utils/definitions'
import { AuditApi, Quota } from '@/store/audit'
import AuditEmptyCase from '~/components/audit/list/AuditEmptyCase.vue'
import Loader from '~/components/BaseLoader.vue'
import ReportsList from '~/components/audit/list/ReportsList.vue'
import Header from '~/components/BaseHeader.vue'

const error = {
  audits: {
    title: "We couldn't retrieve your audits at this moment",
    content:
      'Please try again in a few minutes, or contact us using the chat button below for help.',
  },
  accounts: {
    title: "We couldn't retrieve your Amazon accounts",
    content:
      'Please try again in a few minutes, or contact us using the chat button below for help.',
  },
}

export default Vue.extend({
  components: {
    AuditEmptyCase,
    ReportsList,
    BaseButton,
    ApiData,
    AlertQuota,
    ApiDataError,
    Loader,
    Header,
  },
  data(): {
    error: null | { title: string; content: string }
    canFetch: boolean
    loading: boolean
    request?: {
      url: string
      method?: string
      retry?: object
      onSuccess: Function
      onError: Function
    }
    showAccountForm: boolean
    loaderMsg: string
  } {
    return {
      error: null,
      canFetch: false,
      loading: true,
      request: undefined,
      showAccountForm: false,
      loaderMsg: 'Loading your accounts',
    }
  },
  computed: {
    ...mapGetters({
      accounts: 'user/accounts',
      marketplacesAvailable: 'merchants/marketplacesAvailable',
      isOnboarding: 'user/onboarding',
      audits: 'audit/audits',
      quota: 'audit/quota',
      quotaReached: 'audit/quotaReached',
    }),
    quotaInfo(): string {
      const quota: Quota = this.quota
      const total = quota.limit > 1000000 ? 'unlimited' : quota.limit
      return quota.used ? `Used ${quota.used} of ${total}` : ''
    },
    hasAudits(): boolean {
      const audits: AuditApi[] | null = this.audits
      return audits ? audits.length > 0 : false
    },
  },
  // beforeDestroy () {
  // (this.$refs.api as any).clearPolling()
  // },
  mounted() {
    if (this.accounts && this.marketplacesAvailable) {
      this.getAudits()
    } else {
      this.request = {
        url: 'user/amazon_users',
        onSuccess: (response: { data: { amazon_users: object } }) => {
          this.canFetch = false
          this.setAmazonAccounts(response.data.amazon_users)
          this.$nextTick(() => {
            this.error = null
            this.getAudits()
          })
        },
        onError: () => {
          this.error = error.accounts
          this.loading = false
        },
      }
      this.canFetch = true
    }
  },

  methods: {
    ...mapActions({
      setAudits: 'audit/setAudits',
      amsAccountsAPI: 'user/amsAccountsAPI',
      setAmazonAccounts: 'user/setAmazonAccounts',
    }),
    getAudits() {
      this.loading = true
      this.loaderMsg = 'Loading your audits'
      this.request = {
        url: 'audit/',
        onSuccess: ({ data }: { data: AuditApi[] }) => {
          if (validateSchema(audits)(data)) {
            this.setAudits(data)
            this.error = null
          } else {
            this.error = error.audits
          }
          this.$nextTick(() => {
            this.canFetch = false
            this.loading = false
            if (this.isOnboarding) {
              this.pollAccounts()
            }
          })
        },
        onError: () => {
          this.error = error.audits
          this.loading = false
        },
      }
      this.canFetch = true
    },
    pollAccounts() {
      this.request = {
        url: 'user/amazon_users',
        // method: 'polling',
        // retry: { type: 'continuous', limit: 0, timer: 10000 },
        onSuccess: (response: { data: { amazon_users: object } }) => {
          this.setAmazonAccounts(response.data.amazon_users)
          this.canFetch = false
          this.error = error ? this.error : null
        },
        onError: () => (this.error = error.accounts),
      }
      this.canFetch = true
    },
  },
  head() {
    return {
      title: 'PPC Audit ',
    }
  },
})
