<template>
  <v-app class="app-layout-no-nav-background">
    <div class="app-layout-no-nav">
      <main>
        <nuxt />
      </main>
    </div>
  </v-app>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.app-layout-no-nav {
  display: grid;
  padding: 2rem 0;
}
</style>
