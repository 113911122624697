










import Vue, { PropType } from 'vue'
import * as d3 from 'd3'
import { CHART_SIZE } from '../sizes'

export default Vue.extend({
  name: 'AxisX',
  props: {
    axisXScale: {
      type: Function as PropType<d3.ScaleBand<string>>,
      required: true,
    },
  },
  mounted() {
    this.addAxisX()
  },
  methods: {
    addAxisX(): void {
      const axisGenerator = d3.axisBottom(this.axisXScale).tickSizeOuter(0)

      d3.select(this.$refs.axisX as SVGGElement)
        .call(axisGenerator)
        // D3 adds default font-size to the element the axis is attached to.
        // To control font-size via CSS, we must then clear font-size attr.
        .attr('font-size', null)
    },
  },
  data: () => ({ CHART_SIZE }),
})
