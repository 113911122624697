<template>
  <div class="bg--white-2 d-flex align-center flex-column pa-8">
    <base-image src="onboarding.svg" width="auto" height="200" class="my-6" />
    <div class="text-lg primary--text font-weight-bold py-6 text-center">
      <div>Almost there</div>
      <div class="d-flex align-center">
        <progress-circular
          :stroke="2"
          :width="15"
          :theme="{ stroke: '#60738e', bg: 'transparent' }"
        />
        <span class="text-md ml-3">
          Give us a few minutes. We're just linking your account and
          marketplaces
        </span>
      </div>
      <div
        class="blue-button--text text-sm pt-4 cursor-pointer"
        @click="$emit('add-account')"
      >
        Link more accounts and marketplaces
      </div>
    </div>
  </div>
</template>
<script>
import BaseImage from '@/components/BaseImage'
import ProgressCircular from '@/components/BaseProgressCircular'

export default {
  components: { BaseImage, ProgressCircular },
}
</script>
