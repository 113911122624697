<template>
  <v-container>
    <v-card :flat="true" color="blue-grey lighten-5">
      <div class="d-flex pd-md align-center">
        <div class="flex-grow-0 w-80">
          <div v-if="!auditFetched" class="circle-loader" />
          <v-icon v-else class="circle" size="24" color="white">
            mdi-check
          </v-icon>
        </div>
        <div class="flex-grow-1">
          <div class="text-lg font-weight-bold primary--text">
            {{ title }}
          </div>
          <div v-if="!auditFetched" class="text-md primary--text">
            This should only take a few seconds, but particularly data heavy
            reports may take a couple of minutes.
          </div>
        </div>

        <div class="flex-grow-0">
          <transition name="slide" mode="out-in">
            <v-btn
              v-if="auditFetched"
              color="blue-button"
              large
              depressed
              @click="handleClick"
            >
              View Report
            </v-btn>
          </transition>
        </div>
      </div>
    </v-card>

    <SuggestionList
      title="Whilst you wait feel free to take a look at our content"
      class="mg-top-xlg"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SuggestionList from '~/components/BaseSuggestionList'

export default {
  components: {
    SuggestionList,
  },
  computed: {
    ...mapGetters({ auditFetched: 'auditReport/auditFetched' }),
    title() {
      return this.auditFetched
        ? 'Your audit is ready'
        : 'Number crunching in progress'
    },
  },
  mounted() {
    this.fetchAudit(this.$route.params.urid)
  },
  methods: {
    ...mapActions({ fetchAudit: 'auditReport/fetchAudit' }),
    handleClick() {
      this.$router.push({ name: 'reports' })
      window.open(`/reports/${this.$route.params.urid}/report`)
    },
  },
}
</script>
<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.circle-loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #d4dbdf;
  border-left-color: white;
  animation: rotate-loader 1s infinite linear;
  margin: 0 1rem 0 0;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid white;
  background-color: $primary;
  margin: 0 1rem 0 0;
}

@keyframes rotate-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
