<script>
export default {
  mounted() {
    this.$nuxt.error({
      statusCode: 404,
      message: 'This page could not be found',
      link: '/',
      label: 'Homepage',
    })
  },
  render() {
    return null
  },
}
</script>
