



















































































































































import Vue, { PropType } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Country from '~/components/BaseCountry.vue'

type Merchant = {
  id: string
  marketplaces: {
    campaigns: string
    createdAt: string
    id: number
    startAt: string
    marketplace: {
      code: string[]
      currency: string
      flag: string
      name: string
    }
  }[]
  name: string
  storeName: string
  type: 'seller' | 'vendor'
  website: string
}

export interface Marketplace {
  title: string
  country: string
  selected: boolean
  profileId: number
  adsStatus: string | null
  ordersStatus: string | null
}
export interface Account {
  merchantId: string
  title: string
  selected: boolean
  disabled: boolean
  marketplaces: Marketplace[]
}

export default Vue.extend({
  components: {
    Country,
  },
  data(): {
    selectedOption: null | Account
  } {
    return {
      selectedOption: null,
    }
  },
  props: {
    classList: {
      type: [String, Array],
      required: false,
      default: '',
    },
    items: {
      type: Array as PropType<Array<Account>>,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      merchants: 'merchants/merchants',
      currentProfile: 'analytics/getProfile',
    }),
    getSelectedAccount(): Account | null {
      const selected: Account[] =
        this.items &&
        this.items.filter(
          (account: Account) => account.selected === true && !account.disabled
        )
      const enabled: Account[] = this.items.filter(
        (account: Account) => account.disabled !== true
      )
      return selected.length > 0 ? selected[0] : enabled[0]
    },

    getSelectedCountryCode(): string {
      if (
        this.getSelectedAccount &&
        this.getSelectedMarketplace(this.getSelectedAccount.marketplaces)
          .length > 0
      ) {
        return this.getSelectedMarketplace(
          this.getSelectedAccount.marketplaces
        )[0].country
      } else if (
        this.getSelectedAccount &&
        this.getSelectedAccount.marketplaces[0]
      ) {
        return this.getSelectedAccount.marketplaces[0].country
      }
      return ''
    },
  },
  methods: {
    ...mapActions({
      setMerchant: 'merchants/setMerchant',
      setProfile: 'analytics/setProfile',
    }),
    async getProfileData(
      profileId: number | string,
      adsStatus: string | null,
      ordersStatus: string | null
    ) {
      try {
        const profileEndpoint = `${
          (this as any).$env.NOZZLE_URL
        }/api/cached/profiles/${profileId}`
        const res = await fetch(profileEndpoint, { credentials: 'include' })
        const data = await res.json()
        this.setProfile({
          id: profileId,
          name: data.account.name,
          info: data.account.account_info_name,
          country: data.marketplace.country_code,
          currency: data.marketplace.currency_symbol,
          currencyCode: data.marketplace.currency_code,
          dataBoundaryMonth: data.marketplace.data_boundary.earliest_month,
          adsStatus: adsStatus,
          ordersStatus: ordersStatus,
        })
        // this.loading = false
      } catch (e) {
        // this.failed = true
        // this.loading = false
      }
    },

    getSelectedMarketplace(accountMarketplaces: Marketplace[]): Marketplace[] {
      return accountMarketplaces.filter(
        (marketplace: Marketplace) =>
          this.currentProfile &&
          this.currentProfile.id === marketplace.profileId
      )
    },
    handleSelectMarketplace(marketplace: Marketplace) {
      this.getProfileData(
        marketplace.profileId,
        marketplace.adsStatus,
        marketplace.ordersStatus
      )
      ;(this.$refs as any).parentMenu.isActive = false

      // keep user in the same page but update profileId
      if (
        this.$route.params.profileId &&
        this.$route.params.profileId !== marketplace.profileId.toString()
      ) {
        this.$router.push({
          name: `${(this.$router as any).history.current.name}`,
          params: {
            profileId: `${marketplace.profileId}`,
          },
        })
      }

      this.$emit('on-click')
    },
  },
})
