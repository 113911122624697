















import Vue from 'vue'
import BaseCard from '@/components/BaseCard.vue'
import ProgressCircular from '~/components/BaseProgressCircular.vue'

export default Vue.extend({
  components: { BaseCard, ProgressCircular },
})
