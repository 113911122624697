<script>
/**
 * Renderless component for fetching our API
 * ✓ Should emit an event when the request succeeded
 * ✓ Should expose the data retrieved (payload) and status code to which data belongs
 * ✓ Should expose the state of the request (hasFailed, isFetching)
 * ✓ Should emit an event when the request fails
 * ✓ Can schedule retries when the request failed
 * Note: a failed request has a narrow meaning, either: a) the request could not be completed, or b) no json data was returned from the request
 **/

export default {
  props: {
    url: { type: String, required: true },
    version: { type: String, required: false, default: '/v1/' },
    on: { type: Boolean, default: false },
    method: { type: String, required: false, default: 'get' },
    body: { type: Object, required: false, default: null },
    retry: {
      type: Object,
      required: false,
      default: () => ({ type: 'none', limit: 0, timer: 0 }),
    },
  },
  data() {
    return {
      payload: null,
      isFetching: false,
      hasFailed: false,
      retryCounter: 0,
      pollId: null,
    }
  },
  watch: {
    on(ok) {
      if (ok) this[this.method]()
    },
    url() {
      this.retryCounter = 0
    },
    isFetching(val) {
      this.$emit('fetching', val)
    },
    method(methodType) {
      if (methodType === 'polling' && this.retry.type === 'continuous')
        this.setPolling()
    },
  },
  mounted() {
    if (this.on) this[this.method]()
  },
  methods: {
    async get() {
      this.isFetching = true
      try {
        const response = await fetch(
          `${this.$env.API}${this.version}${this.url}`,
          {
            method: 'GET',
            credentials: 'include',
          }
        )

        const responseTxt = (await response.text()) || ''
        const data =
          responseTxt && response.headers.get('content-type').includes('json')
            ? JSON.parse(responseTxt)
            : responseTxt

        return response.ok
          ? this.onSuccess(data, { status: response?.status })
          : await Promise.reject(response)
      } catch (error) {
        this.onError(error)
      }
    },
    async polling() {
      this.isFetching = true
      try {
        const response = await fetch(
          `${this.$env.API}${this.version}${this.url}`,
          {
            method: 'GET',
            credentials: 'include',
          }
        )

        const responseTxt = (await response.text()) || ''
        const data =
          responseTxt && response.headers.get('content-type').includes('json')
            ? JSON.parse(responseTxt)
            : responseTxt

        return response.ok
          ? this.onSuccess(data, { status: response?.status })
          : await Promise.reject(response)
      } catch (error) {
        if (this.retry.type === 'withLimit') {
          this.shortPolling(error)
        } else if (this.retry.type === 'continuous') {
          this.longPollingFailed(error)
        } else {
          this.onError(error)
        }
      }
    },
    async post() {
      this.isFetching = true
      try {
        const response = await fetch(
          `${this.$env.API}${this.version}${this.url}`,
          {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(this.body),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const responseTxt = (await response.text()) || ''
        const data =
          responseTxt && response.headers.get('content-type').includes('json')
            ? JSON.parse(responseTxt)
            : responseTxt

        return response.ok
          ? this.onSuccess(data, { status: response?.status })
          : await Promise.reject(response)
      } catch (error) {
        this.onError(error)
      }
    },
    setPolling() {
      this.pollId = setInterval(() => this.polling(), this.retry.timer)
    },
    clearPolling() {
      clearInterval(this.pollId)
    },
    shortPolling(error) {
      if (this.retryCounter === 0) {
        this.retryCounter += 1
        this.setPolling()
      } else if (this.retryCounter < this.retry.limit) {
        this.retryCounter += 1
      } else {
        this.clearPolling()
        this.onError(error)
      }
    },
    longPollingFailed(error) {
      // When fetching fails on a long polling,
      // it falls back to the limit, so it's not retrying forever.
      if (this.retryCounter < this.retry.limit) {
        this.retryCounter += 1
      } else {
        this.clearPolling()
        this.onError(error)
      }
    },
    onError(error) {
      this.isFetching = false
      this.hasFailed = true
      this.$emit('error', error)

      this.$sentry.captureException(error)
    },
    onSuccess(data, response) {
      this.payload = data
      this.hasFailed = false
      this.isFetching = false
      this.$emit('success', { data, status: response.status })
    },
  },
  render() {
    return this.$scopedSlots.default({
      payload: this.payload,
      isFetching: this.isFetching,
      hasFailed: this.hasFailed,
      get: this.get,
      post: this.post,
    })
  },
}
</script>
