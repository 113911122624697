<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 16 22"
    aria-labelledby="logout"
    role="presentation"
    :class="classList"
  >
    <title id="logout" lang="en">Logout icon</title>
    <g
      id="Navigation"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Exportable-Icons"
        transform="translate(-328.000000, -22.000000)"
        :fill="
          !darkTheme && !active
            ? '#244c64'
            : !darkTheme && active
            ? '#00E3B5'
            : '#FFFFFF'
        "
        fill-rule="nonzero"
      >
        <g id="Group" transform="translate(325.000000, 19.000000)">
          <g id="power-off-solid" transform="translate(3.000000, 3.000000)">
            <path
              id="Shape"
              d="M14.2258065,1.93214286 C16.5120968,3.53928571 17.9999934,6.16785714 17.9999934,9.14285714 C17.9999934,14.0285714 13.9790323,17.9892857 9.01814516,18.0000216 C4.06451613,18.0107143 0.00725806452,14.0357143 -6.62357669e-06,9.15714286 C-0.00362903226,6.18214286 1.48427419,3.54642857 3.76693548,1.93571429 C4.19153226,1.63928571 4.78306452,1.76428571 5.03709677,2.21071429 L5.61048387,3.21428571 C5.82459677,3.58928571 5.72298387,4.06428571 5.37096774,4.32142857 C3.86491935,5.42142857 2.90322581,7.16428571 2.90322581,9.13928571 C2.89959677,12.4357143 5.60685484,15.1428571 8.99999338,15.1428571 C12.3241935,15.1428571 15.1185484,12.4928571 15.0967742,9.10357143 C15.0858871,7.25357143 14.2004032,5.46785714 12.6254032,4.31785714 C12.2733871,4.06071429 12.1754032,3.58571429 12.3895161,3.21428571 L12.9629032,2.21071429 C13.2169355,1.76785714 13.8048387,1.63571429 14.2258065,1.93214286 Z M10.4516129,9.42857143 L10.4516129,0.857142857 C10.4516129,0.382142857 10.0633065,0 9.58064516,0 L8.41935484,0 C7.93669355,0 7.5483871,0.382142857 7.5483871,0.857142857 L7.5483871,9.42857143 C7.5483871,9.90357143 7.93669355,10.2857143 8.41935484,10.2857143 L9.58064516,10.2857143 C10.0633065,10.2857143 10.4516129,9.90357143 10.4516129,9.42857143 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 16,
    },
    height: {
      type: Number,
      required: false,
      default: 22,
    },
    classList: {
      type: String,
      required: false,
      default: '',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    darkTheme: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
