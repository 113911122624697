
























































































































































import AppAccordion from '~/components/AppAccordion.vue'
import BaseFormField from '~/components/BaseFormField.vue'
import BaseButton from '~/components/BaseButton.vue'
import BaseInput from '~/components/BaseInput.vue'
import BaseProgressCircular from '~/components/BaseProgressCircular.vue'
import BaseInputButton from '~/components/BaseInputButton.vue'
import BaseSelect from '~/components/BaseSelect.vue'
import BaseToasterMessage from '~/components/BaseToasterMessage.vue'

const errorMsg = Object.freeze({
  invalidName: 'Enter your account name here',
  invalidRegion: 'Select your region',
})
const regionsData = [
  {
    text: 'North America (NA)',
    subtitle: 'United States, Canada, Mexico, Brazil',
    lwa_url: 'https://na.account.amazon.com/ap/oa',
  },
  {
    text: 'Europe (EU)',
    subtitle:
      'United Kingdom, France, Germany, Italy, Spain, United Arab Emirates, India, Turkey',
    lwa_url: 'https://eu.account.amazon.com/ap/oa',
  },
  {
    text: 'Far East (FE)',
    subtitle: 'Australia, Japan, Singapore, China',
    lwa_url: 'https://apac.account.amazon.com/ap/oa',
  },
]

export default {
  components: {
    AppAccordion,
    BaseFormField,
    BaseButton,
    BaseInput,
    BaseInputButton,
    BaseProgressCircular,
    BaseSelect,
    BaseToasterMessage,
  },
  data() {
    return {
      valid: false,
      hasFailed: false,
      processing: false,
      name: '',
      region: '',
      lwaLink: '',
      btnTxt: 'copy',
      regions: [...regionsData],
      rules: {
        name: [(val) => val.length > 0 || errorMsg.invalidName],
        region: [
          (val) =>
            (val !== null && val !== undefined) || errorMsg.invalidRegion,
        ],
      },
    }
  },
  methods: {
    async processLwaLink() {
      if (!this.lwaAccordion) {
        return
      }

      try {
        this.processing = true

        const amsRegion = this.regions.find((opt) => opt.text === this.region)

        const location = 'https://external-auth.nozzle.ai/?ads_callback=true'

        const url = `/lwa/lwa-login-uri?auth_type=external&merchant_name=${encodeURIComponent(
          this.name
        )}&merchant_description=${encodeURIComponent(
          this.name
        )}&amazon_lwa_url=${
          amsRegion.lwa_url
        }&next_url=${location}?lwa_callback=true`

        const { data } = await this.$axios.get(url)
        this.processing = false
        this.lwaLink = data.uri
      } catch (e) {
        this.hasFailed = true
      }
    },
    copyLink(refs) {
      try {
        refs.inputvalue.focus()
        document.execCommand('copy')
        this.btnTxt = 'copied'
      } catch (e) {
        this.hasFailed = true
      }
    },
    handleSelection(region) {
      this.region = region?.text
    },
    handleSubmit() {
      const fields = ['name', 'region']
      for (const field of fields) {
        for (const fieldRule of this.rules[field]) {
          const isValidField = fieldRule(this[field]) === true
          if (!isValidField) {
            this.hasFailed = true
            this.$refs.amsForm.scrollTop = 0 // scroll to the top to see the error message
            return
          }
        }
      }
      const amsRegion = this.regions.find((opt) => opt.text === this.region)
      if (amsRegion) {
        this.addAccount(amsRegion.lwa_url)
      }
    },
    async addAccount(lwaUrl) {
      // eslint-disable-next-line max-len
      const url = `/lwa/lwa-login-uri?merchant_name=${encodeURIComponent(
        this.name
      )}&merchant_description=${encodeURIComponent(
        this.name
      )}&amazon_lwa_url=${lwaUrl}&next_url=${
        window.location.origin + window.location.pathname
      }?lwa_callback=true`
      try {
        const { data } = await this.$axios.get(url)
        window.location.assign(data.uri)
      } catch (e) {
        this.hasFailed = true
      }
    },
  },
  watch: {
    name: function (val) {
      if (val.length < 2 && this.$refs.accordion.isOpen) {
        this.$refs.accordion.toggleAccordion()
      }
    },
    region: function (val) {
      if (val.length > 0 && this.$refs.accordion.isOpen) {
        this.$refs.accordion.toggleAccordion()
      }
    },
  },
  computed: {
    lwaAccordion() {
      return this.name.length && this.region.length ? true : false
    },
  },
}

export { errorMsg, regionsData }
