<template>
  <base-card>
    <template #title>
      <div class="font-weight-bold">
        {{ message.title }}
      </div>
    </template>
    <template #content>
      <div>
        {{ message.content }}
      </div>
    </template>
  </base-card>
</template>
<script>
import BaseCard from '@/components/BaseCard'

export default {
  components: { BaseCard },
  props: {
    message: { type: Object, required: true },
  },
}
</script>
