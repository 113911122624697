import Vue from 'vue'
import VCalendar from 'v-calendar'

Vue.use(VCalendar, {
  theme: {
    highlightStartEndClass: 'vc-rounded-full',
    bgAccentLow: {
      light: 'dp-bg-accent-low',
    },
    bgAccentHigh: {
      light: 'dp-bg-accent-high',
    },
    bgLow: {
      light: 'vc-bg-white vc-border-2 dp-bg-low',
    },
    dayNotInMonth: 'dp-not-in-month',
    weekdays: {
      light: 'vc-text-sm vc-font-bold dp-text-color',
    },
    dayContent:
      'vc-font-medium vc-text-xs vc-cursor-pointer focus:vc-font-bold vc-rounded-full dp-text-color',
    contentAccentContrast: 'dp-text-accent-contrast',
    dayContentDisabled: {
      light: 'dp-not-in-month',
    },
    title: {
      light:
        'vc-text-sm vc-text-gray-700 vc-font-semibold hover:vc-opacity-75 ',
    },
    arrows: {
      light: 'vc-nav',
    },
  },
})
