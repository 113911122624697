import {
  differenceInDays,
  parseISO,
  format,
  subDays,
  startOfMonth,
  subMonths,
  lastDayOfMonth,
  endOfYesterday,
  isThisMonth,
} from 'date-fns'

export const rangeDuration = (
  start: Date | number,
  end: Date | number
): number => differenceInDays(end, start) + 1 // inclusive days

export const daysInBetween = (from: any, to: any) =>
  differenceInDays(parseISO(to), parseISO(from)) + 1 // inclusive days

export const toDateString = (date: any, formatStr = 'd LLL yyyy') => {
  return format(parseISO(date), formatStr)
}

export const parseDateStr = (dateStr: string) => parseISO(dateStr)

export const formatDate = (date: any) => format(date, 'yyyy-MM-dd')
export const formatCohortDate = (date: string) => {
  const regex = /(?<year>\d{4})-(?<month>\d{2})/
  const { year, month } = date?.match(regex)?.groups || {}
  if (year && month) {
    // defining a date within this format will avoid errors with timezones
    // new Date(1995, 11) - note that we only need a month and a year to represent a cohort
    return new Date(+year, +month - 1)
  }
  return new Date()
}
export const formatBoundaryDate = (d: string): Date | null => {
  const dt = d && d.split('-')
  if (dt && dt.length === 3) {
    return new Date(+dt[0], +dt[1] - 1, +dt[2])
  }
  return null
}

export const monthStartDay = (date: Date) => startOfMonth(date)
export const monthEndDay = (date: Date) => lastDayOfMonth(date)
export const daysAgo = (date: Date, duration: number) => subDays(date, duration)
export const monthsAgo = (date: Date, duration: number) =>
  subMonths(date, duration)

export const dateOfDaysAgo = (duration: number) => subDays(new Date(), duration)
export const yesterday = endOfYesterday()

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString)

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export const convertUnixIntoDate = (unixTimestamp): Date => {
  return new Date(unixTimestamp * 1000)
}

// Return the date to human readable (used at BaseDataTable for shwoing monthly results)
export const checkIfIsThisMonth = (date: string): string => {
  if (isThisMonth(formatCohortDate(date))) {
    return 'This month'
  }
  return format(formatCohortDate(date), 'LLL yy')
}
