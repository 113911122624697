










// @ts-nocheck
import Vue, { PropType } from 'vue'
import * as d3 from 'd3'
import { format } from 'date-fns'
import { CHART_SIZE } from '../sizes'
import { formatCohortDate } from '~/utils/date'

export default Vue.extend({
  name: 'AxisX',
  props: {
    axisXScale: {
      type: Function as PropType<d3.ScaleBand<string>>,
      required: true,
    },
  },
  data: () => ({ CHART_SIZE }),
  mounted() {
    this.addAxisX()
  },
  methods: {
    addAxisX(): void {
      const axisGenerator = d3
        .axisBottom(this.axisXScale)
        .tickFormat((d) => format(formatCohortDate(d), 'LLL yy'))

      d3.select(this.$refs.axisX as SVGGElement)
        .call(axisGenerator)
        // D3 adds default font-size to the element the axis is attached to.
        // To control font-size via CSS, we must then clear font-size attr.
        .attr('font-size', null)
        .selectAll('text')
        .attr('transform', 'translate(-15,10) rotate(-50)')
    },
  },
})
