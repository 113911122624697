<template>
  <v-card
    v-if="report"
    :flat="true"
    :hover="false"
    :ripple="false"
    :to="`/reports/${report.id}/report`"
    nuxt
    target="_blank"
  >
    <div class="d-flex align-center">
      <div class="flex-grow-0 card-divider align-self-strech d-flex">
        <v-avatar
          v-if="country"
          tile
          width="100"
          min-width="90"
          class="align-self-center"
        >
          <country :flag="report.country.flag" />
          <span class="primary--text font-weight-bold text-uppercase">
            {{ report.country.code[0] }}
          </span>
        </v-avatar>
      </div>
      <div
        class="card-divider d-flex flex-wrap align-center align-self-strech flex-grow-1 pd-20"
      >
        <div
          v-capitalize
          :class="`primary--text font-weight-bold text-name-wrapper text-truncate flex-grow-1 ${heightModifier}`"
        >
          {{ report.name }}
        </div>
        <div class="flex-grow-0 w-300 text-sm">
          <span
            class="primary--text text-account-wrapper d-inline-block text-truncate"
          >
            {{ accountName }}
          </span>
          <span class="primary--text d-inline-block text-truncate">
            <span class="font-weight-bold">
              {{ reportDates }}
            </span>
            [{{ report.period }} days]
          </span>
        </div>
      </div>
      <div class="flex-grow-0 pd-20">
        <v-btn
          :ripple="{ class: 'white--text' }"
          color="primary"
          small
          outlined
        >
          View
          <v-icon right color="primary" small> mdi-open-in-new </v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import Country from '@/components/BaseCountry'

export default {
  components: {
    Country,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  computed: {
    country() {
      return this.report?.country
    },
    accountName() {
      return this.report.account ? this.report.account : this.report.profile
    },
    reportDates() {
      return `${this.report.date.from} to ${this.report.date.to}`
    },
    heightModifier() {
      return this.report.name && this.report.name.length > 0 ? 'h-30' : ''
    },
  },
}
</script>
<style lang="scss" scoped>
.v-card {
  margin: 1em 0;
  border-radius: 2px;
  box-shadow: 0px 0px 8px -3px #b5a8a8;
  min-width: 600px;
  line-height: 1.4rem;
}
.v-card--link:before {
  background-color: #a6989b;
}
.card-divider {
  border-right: 1px solid #eee;
}
.flag + span {
  margin-left: 4px;
}
.text-name-wrapper {
  width: 200px;
  height: auto;
}
.text-account-wrapper {
  max-width: 120px;
}
.pd-20 {
  padding: 20px;
}
.w-300 {
  width: 300px;
  white-space: nowrap;
}
.h-30 {
  height: 30px;
}
.align-self-strech {
  align-self: stretch;
}
</style>
