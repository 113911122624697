// Size Base Unit comes in handy when setting chart's elements sizes with
// the proportions in mind rather than specific units.
const CHART_SIZE_BASE_UNIT = 100

const CHART_SIZE = {
  axisYDataPointRadius: CHART_SIZE_BASE_UNIT * 0.03,
  base_unit: CHART_SIZE_BASE_UNIT,
  height: CHART_SIZE_BASE_UNIT * 2,
  marginBottom: 0,
  marginLeft: CHART_SIZE_BASE_UNIT * 0.43,
  marginRight: 0,
  marginTop: CHART_SIZE_BASE_UNIT * 0.5,
  width: CHART_SIZE_BASE_UNIT * 4,
}

export { CHART_SIZE }
