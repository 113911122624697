










import Vue, { PropType } from 'vue'
import * as d3 from 'd3'
import { CHART_SIZE } from '../sizes'

export default Vue.extend({
  name: 'AxisY1',
  props: {
    axisY1Scale: {
      type: Function as PropType<d3.ScaleLinear<number, number>>,
      required: true,
    },
  },
  data: () => ({ CHART_SIZE }),
  mounted() {
    this.addAxisY()
  },
  methods: {
    addAxisY(): void {
      const axisGenerator = d3.axisLeft(this.axisY1Scale)

      d3.select(this.$refs.axisY as SVGGElement)
        .call(axisGenerator)
        // D3 adds default font-size to the element the axis is attached to.
        // To control font-size via CSS, we must then clear font-size attr.
        .attr('font-size', null)
    },
  },
})
