










import Vue from 'vue'
import Alert from '@/components/alerts/Alert.vue'

export default Vue.extend({
  components: { Alert },
  name: 'NoMarketplacesOnboardedOrOnboarding',
})
