












import Vue from 'vue'
import { CHART_SIZE } from '../sizes'

export default Vue.extend({
  name: 'ChartTitle',
  props: {
    title: { type: String, required: true },
  },
  data: () => ({ CHART_SIZE }),
})
