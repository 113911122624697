import { NuxtError } from '@nuxt/types'
import { mapGetters, mapActions } from 'vuex'
import Loader from '~/components/BaseLoader.vue'
import AnalyticsHeader from '~/components/analytics/Header.vue'
import AnalyticsAlertManager from '~/components/alerts/AnalyticsAlertManager.vue'

export default {
  components: {
    Loader,
    AnalyticsHeader,
    AnalyticsAlertManager,
  },
  data(): {
    failed: boolean
    loading: boolean
    noProfileReady: boolean
  } {
    return {
      failed: false,
      loading: true,
      noProfileReady: null,
    }
  },
  computed: {
    ...mapGetters({
      currentProfile: 'analytics/getProfile',
      currentProfileName: 'analytics/getProfileName',
    }),
    profileId(): string {
      return this.$route.params.profileId
    },
    currency(): string {
      return this.$store.getters['analytics/currency']
    },
    isEnabled(): Boolean {
      return this.$isEnabled(
        'ANALYTICS',
        this.$store.getters['user/hasAnalytics']
      )
    },
  },
  mounted() {
    if (this.$route.params.profileId === 'no-profile-ready') {
      this.loading = false
      this.noProfileReady = true
      return false
    }
    if (!this.isEnabled) {
      this.$nuxt.error({
        status: 'auth',
        message: 'You are not authorized to view this page',
        link: '/user/account',
        label: 'My Settings',
      } as NuxtError)
    } else {
      if (!this.currency && this.profileId !== 'no-profile-ready')
        this.getProfileData()
      this.loading = false
    }
  },

  methods: {
    ...mapActions({
      setProfile: 'analytics/setProfile',
    }),
    async getProfileData() {
      try {
        const profileEndpoint = `${
          (this as any).$env.NOZZLE_URL
        }/api/cached/profiles/${this.profileId}`
        const res = await fetch(profileEndpoint, { credentials: 'include' })
        const data = await res.json()
        this.setProfile({
          id: this.profileId,
          name: data.account.name,
          info: data.account.account_info_name,
          country: data.marketplace.country_code,
          currency: data.marketplace.currency_symbol,
          currencyCode: data.marketplace.currency_code,
          dataBoundaryMonth: data.marketplace.data_boundary.earliest_month,
        })
        this.loading = false
      } catch (e) {
        this.failed = true
        this.loading = false
      }
    },
  },
}
