export const newAudit = {
  type: 'object',
  properties: {
    date_from: { type: 'string', minLength: 8 },
    date_to: { type: 'string', minLength: 8 },
    merchant_name: { type: 'string', minLength: 3 },
    merchant_type: { type: 'string', minLength: 3 },
    profile_ids: { type: 'string', minLength: 3 },
    country_code: { type: 'string', minLength: 2 },
    campaign_ids: {
      type: 'array',
      items: { type: 'string' },
    },
    brand_search_terms: {
      type: 'array',
      items: { type: 'string' },
      wasted_spend_click_threshold: { type: 'string', minLength: 1 },
    },
    exclude_zero_spend_days: { type: 'boolean' },
    report_name: { type: 'string', minLength: 3 },
  },
}
