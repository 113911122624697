


























































































































import Vue from 'vue'
import { format, isAfter, isSameMonth, parseISO } from 'date-fns'
import ToggleGroup from '~/components/BaseToggleGroup.vue'
import { FormattedLTVData } from '~/store/analytics'
import { percentage } from '~/utils/format.js'
import { formatCurrency } from '~/utils/currency'
import { formatCohortDate, formatBoundaryDate } from '~/utils/date'
import BaseCSVDownloadBtn from '@/components/BaseCSVDownloadBtn.vue'

type Unit = 'currency' | 'date' | 'percentage' | 'integer'

type Col = {
  key: string
  id: string
  name: string
  fixed: boolean
  unit: Unit
  info?: string
  primary?: boolean
}
type Cell = {
  tooltip: Tooltip
  value: number | string | null
  highlight: boolean
  unit: Unit
}
type Tooltip = {
  date: string
  monthsSinceFirstPurchs: string
  cohort: string
  ltvToPeriod: string
  avgSalesPerCustomer: Unit
  customersReturning: number
  repeatCustomers: number
}
type Filter = {
  id: 'ltv_to_period' | 'acc_sale' | 'ret_customers'
  label: string
}

const filterOptions: Filter[] = [
  { id: 'ltv_to_period', label: 'Accumulated profit' },
  { id: 'acc_sale', label: 'Accumulated sales' },
  { id: 'ret_customers', label: 'Returning customers %' },
]

export default Vue.extend({
  components: {
    BaseCSVDownloadBtn,
    ToggleGroup,
  },
  props: {
    rawData: {
      type: Array as () => FormattedLTVData[],
      required: false,
      default: undefined,
    },
    fileSuffix: {
      type: String,
      required: true,
    },
  },
  data(): {
    expand: boolean
    filters?: {
      items: Filter[]
      active?: Filter
    }
    rowHasBreakevenMonth: boolean
    tableFields: Object | null
    json_meta: [
      {
        key: String
        value: String
      }
    ]
  } {
    return {
      expand: true,
      filters: {
        items: filterOptions,
        active: filterOptions[0],
      },
      rowHasBreakevenMonth: false,
      tableFields: {},
      json_meta: [
        {
          key: 'charset',
          value: 'utf-8',
        },
      ],
    }
  },
  computed: {
    boundary(): Date | null {
      const d = this.$store.getters['analytics/dataBoundary']
      return d ? formatBoundaryDate(d) : null
    },
    currency(): string {
      return this.$store.getters['analytics/currency']
    },
    currencyCode(): string {
      return this.$store.getters['analytics/getCurrencyCode']
    },
    defaultLocale(): string {
      return Intl.NumberFormat().resolvedOptions().locale
    },
    cssVars(): Record<string, string> {
      return {
        '--table-width': '100%',
      }
    },
    pinnedCols(): number[] {
      return [3, 6, 12, 24]
    },
    monthCols(): Col[] {
      return this.rawData.length > 0
        ? Object.entries(this.rawData[0]).reduce((acc: Col[], [key, value]) => {
            if (key === 'metrics') {
              typeof value === 'object' &&
                value !== null &&
                Object.entries(value).forEach(([_metricKey, metric]) =>
                  acc.push({
                    name: `${metric.reporting_window_offset}`,
                    id: `${metric.reporting_window_offset}`,
                    fixed: this.pinnedCols.includes(
                      metric.reporting_window_offset
                    ),
                    key: 'month',
                    unit:
                      this.filters.active?.id === 'ret_customers'
                        ? 'percentage'
                        : 'currency',
                  })
                )
            }
            return acc
          }, [])
        : []
    },
    columns(): Col[] {
      const visibleCols = this.expand
        ? this.monthCols
        : this.monthCols.filter((col: Col) => col.fixed)
      return visibleCols.length > 0
        ? [
            {
              id: 'cohorts',
              name: 'Cohort',
              fixed: true,
              key: 'cohorts',
              unit: 'date',
              info: 'This groups customers by the month they made their first purchase and tracks them through all future purchases in the following months',
              primary: true,
            },
            {
              id: 'returning_percentage',
              name: 'R%',
              fixed: true,
              key: 'returning_percentage',
              unit: 'percentage',
              info: 'The percentage of customers in each cohort who purchased more than once',
              primary: true,
            },
            {
              id: 'customers',
              name: 'Total new customers',
              fixed: true,
              key: 'total_customers',
              unit: 'integer',
              info: 'The total amount of new customers you gained in a month. A new customer is anyone who we have not seen before',
              primary: true,
            },
            {
              id: 'spend',
              name: 'Ad spend',
              fixed: true,
              key: 'total_spend',
              unit: 'currency',
              info: 'The total amount you spent that month across Sponsored Products & Sponsored Brands',
              primary: true,
            },
            {
              id: 'cac',
              name: 'CAC',
              fixed: true,
              key: 'cac',
              unit: 'currency',
              info: 'Customer Acquisition Cost is the average amount you paid to acquire a customer that month (ad spend / no. of new customers)',
              primary: true,
            },
            {
              id: 'first_purchase',
              name: 'First purchase profit',
              fixed: true,
              key: 'first_purchase_profit_per_customer',
              unit: 'currency',
              primary: true,
            },
            ...visibleCols,
          ]
        : []
    },
    rows(): Cell[][] {
      return (
        this.rawData?.reduce((acc: Cell[][], data: FormattedLTVData) => {
          const cells: Cell[] = this.columns.map((col: Col) =>
            this.getCellData(col, data)
          )
          acc.push(cells)
          return acc
        }, []) ?? []
      )
    },
    tableData() {
      const lvtTableArray = []

      for (let i = 0; i < this.rawData.length; i++) {
        if (this.filters.active.id === 'ltv_to_period') {
          lvtTableArray.push({
            cohort: this.rawData[i].cohort,
            rpercent: +this.rawData[i].repeat_customer_rate,
            total_new_customers: +this.rawData[i].total_customers,
            add_spend: +this.rawData[i].total_spend,
            cac: +this.rawData[i].cac,
            first_purchase_profit:
              +this.rawData[i].first_purchase_profit_per_customer,
            month_0: this.rawData[i].metrics[0]
              ? +this.rawData[i].metrics[0].ltv_to_period
              : '',
            month_1: this.rawData[i].metrics[1]
              ? +this.rawData[i].metrics[1].ltv_to_period
              : '',
            month_2: this.rawData[i].metrics[2]
              ? +this.rawData[i].metrics[2].ltv_to_period
              : '',
            month_3: this.rawData[i].metrics[3]
              ? +this.rawData[i].metrics[3].ltv_to_period
              : '',
            month_4: this.rawData[i].metrics[4]
              ? +this.rawData[i].metrics[4].ltv_to_period
              : '',
            month_5: this.rawData[i].metrics[5]
              ? +this.rawData[i].metrics[5].ltv_to_period
              : '',
            month_6: this.rawData[i].metrics[6]
              ? +this.rawData[i].metrics[6].ltv_to_period
              : '',
            month_7: this.rawData[i].metrics[7]
              ? +this.rawData[i].metrics[7].ltv_to_period
              : '',
            month_8: this.rawData[i].metrics[8]
              ? +this.rawData[i].metrics[8].ltv_to_period
              : '',
            month_9: this.rawData[i].metrics[9]
              ? +this.rawData[i].metrics[9].ltv_to_period
              : '',
            month_10: this.rawData[i].metrics[10]
              ? +this.rawData[i].metrics[10].ltv_to_period
              : '',
            month_11: this.rawData[i].metrics[11]
              ? +this.rawData[i].metrics[11].ltv_to_period
              : '',
            month_12: this.rawData[i].metrics[12]
              ? +this.rawData[i].metrics[12].ltv_to_period
              : '',
            month_13: this.rawData[i].metrics[13]
              ? +this.rawData[i].metrics[13].ltv_to_period
              : '',
            month_14: this.rawData[i].metrics[14]
              ? +this.rawData[i].metrics[14].ltv_to_period
              : '',
            month_15: this.rawData[i].metrics[15]
              ? +this.rawData[i].metrics[15].ltv_to_period
              : '',
            month_16: this.rawData[i].metrics[16]
              ? +this.rawData[i].metrics[16].ltv_to_period
              : '',
            month_17: this.rawData[i].metrics[17]
              ? +this.rawData[i].metrics[17].ltv_to_period
              : '',
            month_18: this.rawData[i].metrics[18]
              ? +this.rawData[i].metrics[18].ltv_to_period
              : '',
            month_19: this.rawData[i].metrics[19]
              ? +this.rawData[i].metrics[19].ltv_to_period
              : '',
            month_20: this.rawData[i].metrics[20]
              ? +this.rawData[i].metrics[20].ltv_to_period
              : '',
            month_21: this.rawData[i].metrics[21]
              ? +this.rawData[i].metrics[21].ltv_to_period
              : '',
            month_22: this.rawData[i].metrics[22]
              ? +this.rawData[i].metrics[22].ltv_to_period
              : '',
            month_23: this.rawData[i].metrics[23]
              ? +this.rawData[i].metrics[23].ltv_to_period
              : '',
            month_24: this.rawData[i].metrics[24]
              ? +this.rawData[i].metrics[24].ltv_to_period
              : '',
          })
        }
        if (this.filters.active.id === 'acc_sale') {
          lvtTableArray.push({
            cohort: this.rawData[i].cohort,
            rpercent: this.rawData[i].repeat_customer_rate,
            total_new_customers: this.rawData[i].total_customers,
            add_spend: this.rawData[i].total_spend,
            cac: this.rawData[i].cac,
            first_purchase_profit:
              this.rawData[i].first_purchase_profit_per_customer,
            month_0: this.rawData[i].metrics[0]
              ? this.rawData[i].metrics[0].avg_sales_per_customer_to_period
              : '-',
            month_1: this.rawData[i].metrics[1]
              ? this.rawData[i].metrics[1].avg_sales_per_customer_to_period
              : '-',
            month_2: this.rawData[i].metrics[2]
              ? this.rawData[i].metrics[2].avg_sales_per_customer_to_period
              : '-',
            month_3: this.rawData[i].metrics[3]
              ? this.rawData[i].metrics[3].avg_sales_per_customer_to_period
              : '-',
            month_4: this.rawData[i].metrics[4]
              ? this.rawData[i].metrics[4].avg_sales_per_customer_to_period
              : '-',
            month_5: this.rawData[i].metrics[5]
              ? this.rawData[i].metrics[5].avg_sales_per_customer_to_period
              : '-',
            month_6: this.rawData[i].metrics[6]
              ? this.rawData[i].metrics[6].avg_sales_per_customer_to_period
              : '-',
            month_7: this.rawData[i].metrics[7]
              ? this.rawData[i].metrics[7].avg_sales_per_customer_to_period
              : '-',
            month_8: this.rawData[i].metrics[8]
              ? this.rawData[i].metrics[8].avg_sales_per_customer_to_period
              : '-',
            month_9: this.rawData[i].metrics[9]
              ? this.rawData[i].metrics[9].avg_sales_per_customer_to_period
              : '-',
            month_10: this.rawData[i].metrics[10]
              ? this.rawData[i].metrics[10].avg_sales_per_customer_to_period
              : '-',
            month_11: this.rawData[i].metrics[11]
              ? this.rawData[i].metrics[11].avg_sales_per_customer_to_period
              : '-',
            month_12: this.rawData[i].metrics[12]
              ? this.rawData[i].metrics[12].avg_sales_per_customer_to_period
              : '-',
            month_13: this.rawData[i].metrics[13]
              ? this.rawData[i].metrics[13].avg_sales_per_customer_to_period
              : '-',
            month_14: this.rawData[i].metrics[14]
              ? this.rawData[i].metrics[14].avg_sales_per_customer_to_period
              : '-',
            month_15: this.rawData[i].metrics[15]
              ? this.rawData[i].metrics[15].avg_sales_per_customer_to_period
              : '-',
            month_16: this.rawData[i].metrics[16]
              ? this.rawData[i].metrics[16].avg_sales_per_customer_to_period
              : '-',
            month_17: this.rawData[i].metrics[17]
              ? this.rawData[i].metrics[17].avg_sales_per_customer_to_period
              : '-',
            month_18: this.rawData[i].metrics[18]
              ? this.rawData[i].metrics[18].avg_sales_per_customer_to_period
              : '-',
            month_19: this.rawData[i].metrics[19]
              ? this.rawData[i].metrics[19].avg_sales_per_customer_to_period
              : '-',
            month_20: this.rawData[i].metrics[20]
              ? this.rawData[i].metrics[20].avg_sales_per_customer_to_period
              : '-',
            month_21: this.rawData[i].metrics[21]
              ? this.rawData[i].metrics[21].avg_sales_per_customer_to_period
              : '-',
            month_22: this.rawData[i].metrics[22]
              ? this.rawData[i].metrics[22].avg_sales_per_customer_to_period
              : '-',
            month_23: this.rawData[i].metrics[23]
              ? this.rawData[i].metrics[23].avg_sales_per_customer_to_period
              : '-',
            month_24: this.rawData[i].metrics[24]
              ? this.rawData[i].metrics[24].avg_sales_per_customer_to_period
              : '-',
          })
        }
        if (this.filters.active.id === 'ret_customers') {
          lvtTableArray.push({
            cohort: this.rawData[i].cohort,
            rpercent: this.rawData[i].repeat_customer_rate,
            total_new_customers: this.rawData[i].total_customers,
            add_spend: this.rawData[i].total_spend,
            cac: this.rawData[i].cac,
            first_purchase_profit:
              this.rawData[i].first_purchase_profit_per_customer,
            month_0: this.rawData[i].metrics[0]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[0].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_1: this.rawData[i].metrics[1]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[1].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_2: this.rawData[i].metrics[2]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[2].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_3: this.rawData[i].metrics[3]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[3].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_4: this.rawData[i].metrics[4]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[4].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_5: this.rawData[i].metrics[5]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[5].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_6: this.rawData[i].metrics[6]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[6].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_7: this.rawData[i].metrics[7]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[7].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_8: this.rawData[i].metrics[8]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[8].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_9: this.rawData[i].metrics[9]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[9].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_10: this.rawData[i].metrics[10]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[10].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_11: this.rawData[i].metrics[11]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[11].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_12: this.rawData[i].metrics[12]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[12].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_13: this.rawData[i].metrics[13]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[13].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_14: this.rawData[i].metrics[14]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[14].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_15: this.rawData[i].metrics[15]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[15].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_16: this.rawData[i].metrics[16]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[16].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_17: this.rawData[i].metrics[17]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[17].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_18: this.rawData[i].metrics[18]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[18].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_19: this.rawData[i].metrics[19]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[19].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_20: this.rawData[i].metrics[20]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[20].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_21: this.rawData[i].metrics[21]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[21].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_22: this.rawData[i].metrics[22]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[22].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_23: this.rawData[i].metrics[23]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[23].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
            month_24: this.rawData[i].metrics[24]
              ? this.calculatePercentage(
                  this.rawData[i].metrics[24].distinct_customers,
                  this.rawData[i].total_customers
                )
              : '-',
          })
        }
      }
      return lvtTableArray
    },
  },
  created() {
    this.tableFields.cohort = 'Cohort'
    this.tableFields.rpercent = 'R%'
    this.tableFields.total_new_customers = 'Total New Customers'
    this.tableFields.add_spend = 'Ad Spend (' + this.currency + ')'
    this.tableFields.cac = 'CAC (' + this.currency + ')'
    this.tableFields.first_purchase_profit =
      'First Purchase Profit (' + this.currency + ')'
    this.tableFields.month_0 = 'Month 0 (' + this.currency + ')'
    this.tableFields.month_1 = 'Month 1 (' + this.currency + ')'
    this.tableFields.month_2 = 'Month 2 (' + this.currency + ')'
    this.tableFields.month_3 = 'Month 3 (' + this.currency + ')'
    this.tableFields.month_4 = 'Month 4 (' + this.currency + ')'
    this.tableFields.month_5 = 'Month 5 (' + this.currency + ')'
    this.tableFields.month_6 = 'Month 6 (' + this.currency + ')'
    this.tableFields.month_7 = 'Month 7 (' + this.currency + ')'
    this.tableFields.month_8 = 'Month 8 (' + this.currency + ')'
    this.tableFields.month_9 = 'Month 9 (' + this.currency + ')'
    this.tableFields.month_10 = 'Month 10 (' + this.currency + ')'
    this.tableFields.month_11 = 'Month 11 (' + this.currency + ')'
    this.tableFields.month_12 = 'Month 12 (' + this.currency + ')'
    this.tableFields.month_13 = 'Month 13 (' + this.currency + ')'
    this.tableFields.month_14 = 'Month 14 (' + this.currency + ')'
    this.tableFields.month_15 = 'Month 15 (' + this.currency + ')'
    this.tableFields.month_16 = 'Month 16 (' + this.currency + ')'
    this.tableFields.month_17 = 'Month 17 (' + this.currency + ')'
    this.tableFields.month_18 = 'Month 18 (' + this.currency + ')'
    this.tableFields.month_19 = 'Month 19 (' + this.currency + ')'
    this.tableFields.month_20 = 'Month 20 (' + this.currency + ')'
    this.tableFields.month_21 = 'Month 21 (' + this.currency + ')'
    this.tableFields.month_22 = 'Month 22 (' + this.currency + ')'
    this.tableFields.month_23 = 'Month 23 (' + this.currency + ')'
    this.tableFields.month_24 = 'Month 24 (' + this.currency + ')'
  },

  methods: {
    calculatePercentage(figureOne: number, figureTwo: number) {
      const calc = (figureOne / figureTwo) * 100

      if (calc > 0.0 && calc < 100) {
        return `${calc.toFixed(2)}%`
      }
      return '-'
    },
    onSelect(sectionId: string) {
      this.filters.active = this.filters.items.find(
        ({ id }) => sectionId === id
      )
    },
    handleExpand() {
      this.expand = !this.expand
      this.$emit('expand', this.expand)
    },
    hideTableGradient(e: any) {
      const table = e.target
      const gradient = document.querySelector('.table__gradient')
      if (table) {
        const maxScrollLeft = table?.scrollWidth - table?.clientWidth
        gradient?.classList.toggle(
          'hidden',
          table?.scrollLeft === maxScrollLeft ||
            navigator.appVersion.includes('Win')
        )
      }
    },
    withinDataBoundary(cohort: string) {
      const cohortDate = formatCohortDate(cohort)
      return this.boundary
        ? isAfter(cohortDate, this.boundary) ||
            isSameMonth(cohortDate, this.boundary)
        : false
    },
    getCellData(col: Col, data: FormattedLTVData) {
      if (col.name === 'Cohort') this.rowHasBreakevenMonth = false // reset variable for the new row
      switch (col.key) {
        case 'returning_percentage':
          return {
            value:
              data.returning_customers && data.total_customers > 0
                ? `${(
                    (data.returning_customers / data.total_customers) *
                    100
                  ).toFixed(2)}%`
                : '-',
            highlight: false,
            unit: 'percentage' as Unit,
          }
        case 'cohorts':
          return {
            value: format(formatCohortDate(data.cohort), 'MMM yy'),
            highlight: false,
            unit: 'date' as Unit,
          }
        case 'total_customers':
          return {
            value: Intl.NumberFormat().format(data.total_customers),
            highlight: false,
            unit: 'integer' as Unit,
          }
        case 'total_spend': {
          const fmt: any = data[col.key] ? data[col.key] : null
          const value =
            this.withinDataBoundary(data.cohort) && fmt
              ? formatCurrency(this.currencyCode, this.defaultLocale, +fmt)
              : '-'

          return {
            value,
            highlight: false,
            unit: 'currency' as Unit,
          }
        }
        case 'first_purchase_profit_per_customer':
          this.rowHasBreakevenMonth =
            this.withinDataBoundary(data.cohort) &&
            data.cac &&
            data.first_purchase_profit_per_customer
              ? data.first_purchase_profit_per_customer > data.cac
              : false
          return {
            value: data.first_purchase_profit_per_customer
              ? formatCurrency(
                  this.currencyCode,
                  this.defaultLocale,
                  data.first_purchase_profit_per_customer
                )
              : '-',
            // Check if the cac data for this cohort has a full month of data.
            highlight:
              this.withinDataBoundary(data.cohort) &&
              data.cac &&
              data.first_purchase_profit_per_customer
                ? data.first_purchase_profit_per_customer > data.cac
                : false,
            unit: 'currency' as Unit,
          }
        case 'month': {
          // Build the cohort window (the columns) based on what tab on the table is active
          const metrics = data.metrics.find(
            // eslint-disable-next-line camelcase
            ({ reporting_window_offset }) =>
              Number(reporting_window_offset) === Number(col.id)
          )
          const reportingDate = metrics && metrics.reporting_window
          const toolTipData = {
            date: `Month ${metrics && metrics.reporting_window_offset} - ${
              reportingDate && format(parseISO(reportingDate), 'MMM yy')
            }`,
            monthsSinceFirstPurchs: metrics && metrics.reporting_window_offset,
            cohort: format(formatCohortDate(data.cohort), 'MMM yy'),
            ltvToPeriod:
              metrics &&
              formatCurrency(
                this.currencyCode,
                this.defaultLocale,
                +metrics.ltv_to_period
              ),
            avgSalesPerCustomer:
              metrics &&
              formatCurrency(
                this.currencyCode,
                this.defaultLocale,
                +metrics.avg_sales_per_customer_to_period
              ),
            customersReturning:
              metrics &&
              percentage(metrics.repeat_customers / data.total_customers),
            repeatCustomers: metrics && metrics.repeat_customers,
          }

          const activeFilter = this.filters.active ?? null
          let value = ''
          let highlight = false

          if (activeFilter && metrics) {
            switch (activeFilter.id) {
              case 'ltv_to_period':
                value = formatCurrency(
                  this.currencyCode,
                  this.defaultLocale,
                  metrics[activeFilter.id]
                )
                // Check if the cac data for this cohort
                // has a full month of data.
                if (this.withinDataBoundary(data.cohort)) {
                  highlight =
                    !this.rowHasBreakevenMonth && data.cac
                      ? metrics[activeFilter.id] > data.cac
                      : false
                  if (highlight) {
                    this.rowHasBreakevenMonth = true
                  }
                }
                break
              case 'ret_customers':
                value =
                  metrics.repeat_customers && data.total_customers
                    ? Intl.NumberFormat(this.defaultLocale, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(metrics.repeat_customers / data.total_customers)
                    : ''
                break
              case 'acc_sale':
                value = formatCurrency(
                  this.currencyCode,
                  this.defaultLocale,
                  metrics.avg_sales_per_customer_to_period
                )
                break
              default:
                value = ''
            }
          }

          return {
            value,
            tooltip: toolTipData || false,
            highlight: highlight,
            unit: 'percentage' as Unit,
          }
        }
        default: {
          const format: any = data[col.key] ? data[col.key] : null
          const cellValue =
            format != null
              ? formatCurrency(this.currencyCode, this.defaultLocale, format)
              : '-'

          // Ads related metrics need to be limited to the full calendar window of data
          // that is available. Therefore we need to only show that data with the boundary date.
          // Similar logic to the highlighting conditionals above in first purchase and months.
          const value =
            col.key === 'cac' || col.key === 'total_spend'
              ? this.withinDataBoundary(data.cohort)
                ? formatCurrency(
                    this.currencyCode,
                    this.defaultLocale,
                    +cellValue
                  )
                : null
              : cellValue
          return {
            value,
            highlight: false,
            unit: 'currency' as Unit,
          }
        }
      }
    },
  },
})
